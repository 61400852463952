<div class="modal-container">
  <svg
    class="dismiss-cross"
    (click)="data.cancelFunct()"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <div>
    <div class="dialog-header-text">{{ headerText }}</div>
    <div class="dialog-body-text">{{ bodyText }}</div>
  </div>

  <mat-select class="input-container" [placeholder]="dropdownPlaceholderText">
    <mat-option
      *ngFor="let option of availableCustomModules"
      [value]="option?.module_id"
      >{{ option?.module_title }}</mat-option
    >
  </mat-select>

  <div class="dialog-btn">
    <div class="dialog-btn-container">
      <div class="dialog-cancel-btn-text" (click)="data.cancelFunct()">
        {{ cancelBtnText }}
      </div>
      <button
        (click)="data.confirmFunct()"
        class="blue dialog-confirm-btn-text"
        mat-button
      >
        {{ confirmBtnText }}
      </button>
    </div>
  </div>
</div>
