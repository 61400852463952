<div *ngIf="!goalsCreatedData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div *ngIf="goalsCreatedData" class="goals-created-wrapper">
  <h4 class="h5-bold text-color-neutral-800">
    {{ copy?.headers?.goals_created }}
  </h4>
  <div class="chart-legend-wrapper">
    <div class="chart-wrapper">
      <div class="goals-created-text">
        <div class="h4-bold text-color-neutral-700">
          {{ goalsCreatedCount }}
        </div>
        <div class="caption-medium text-color-neutral-800">
          financial goals created
        </div>
      </div>
      <canvas id="canvas"></canvas>
    </div>
    <div class="custom-legend caption-medium text-color-neutral-600">
      <div class="legend-item">
        <span [ngStyle]="{ backgroundColor: brandPrimaryHexCode }"></span>
        {{ goalsCreatedData.goals[0].goal_name }}
      </div>
      <div class="legend-item">
        <span [ngStyle]="{ backgroundColor: brandGreenHexCode }"></span>
        {{ goalsCreatedData.goals[1].goal_name }}
      </div>
      <div class="legend-item">
        <span [ngStyle]="{ backgroundColor: brandGoldHexCode }"></span>
        {{ goalsCreatedData.goals[2].goal_name }}
      </div>
    </div>
  </div>
</div>
