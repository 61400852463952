<div class="highlight-wrapper">
  <div *ngIf="productHighlights" class="product-highlights" #scrollContainer>
    <div
      class="highlight-card"
      *ngFor="let highlight of productHighlights?.product_highlights"
      (click)="openHighlightsModal(highlight)"
    >
      <div *ngIf="highlight?.is_new" class="new-text">
        <zogo-badge type="notice" label="NEW"> </zogo-badge>
      </div>
      <img class="highlight-img" [src]="highlight?.img_url" />
      <div class="h5-bold text-color-neutral-800 margin-top-16">
        {{ highlight?.title }}
      </div>
      <markdown
        class="caption-medium text-color-neutral-600"
        [data]="highlight?.short_description"
      ></markdown>
    </div>
  </div>
  <zogo-pagination-arrow
    *ngIf="productHighlights?.product_highlights?.length > 1"
    class="back-arrow"
    type="bold"
    icon="left"
    [disabled]="disableScrollLeft"
    (pageChange)="scrollBack()"
  >
  </zogo-pagination-arrow>
  <zogo-pagination-arrow
    *ngIf="productHighlights?.product_highlights?.length > 1"
    class="next-arrow"
    type="bold"
    icon="right"
    [disabled]="disableScrollRight"
    (pageChange)="scrollNext()"
  >
  </zogo-pagination-arrow>
</div>
