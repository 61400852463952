import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogService } from 'src/app/guards/dialog.service';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';

export interface CustomSurveyQuestionElement {
  id: number;
  question: string;
  status: string;
}

const ELEMENT_DATA: CustomSurveyQuestionElement[] = [];

@Component({
  selector: 'app-survey-questions',
  templateUrl: './survey-questions.component.html',
  styleUrls: ['./survey-questions.component.css'],
})
export class SurveyQuestionComponent implements OnInit {
  displayedColumns: string[] = ['id', 'question', 'status', 'selection'];
  dataSource = ELEMENT_DATA;
  @ViewChild(MatTable) table: MatTable<any>;
  surveyQuestionOriginalOrder: any;
  surveyQuestionCurrentOrder: any;
  surveyQuestionData: any;
  originalSurveyQuestionData: any;
  question: string;
  description: string;
  answers: string[];
  orderChanges: boolean;

  constructor(
    public appCopy: CopyService,
    private req: RequestsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    // Amplitude event - Custom Surveys Viewed
    this.req.ampTrack('Custom Surveys Viewed');
    this.req.getSurveyQuestions().subscribe({
      next: data => {
        this.surveyQuestionData = data;
        this.originalSurveyQuestionData = JSON.parse(
          JSON.stringify(this.surveyQuestionData)
        );
        this.surveyQuestionOriginalOrder = this.getSurveyQuestionOrder(
          this.surveyQuestionData.surveys
        );
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  createSurveyQuestion() {
    this.router.navigate(['question-editor'], {
      relativeTo: this.activatedRoute,
    });
  }

  editSurveyQuestion(element: any) {
    this.router.navigate(['question-editor'], {
      relativeTo: this.activatedRoute,
      state: { question: element },
    });
  }

  handleDisableAddButton() {
    return !(
      this.surveyQuestionData?.surveys.length <
      this.surveyQuestionData?.available_count +
        this.surveyQuestionData?.created_count
    );
  }

  deleteSurveyQuestion(id: number, index: number) {
    this.dialog.open(ConfirmationModalComponent, {
      width: '488px',
      data: {
        headerText: 'Delete Survey Question? ',
        bodyText:
          'This survey question will be permanently deleted from your list.',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Cancel',
        confirmFunct: () => {
          this.req.deleteSurveyQuestion(id).subscribe({
            next: data => {
              // Amplitude event - Custom Survey Deleted
              this.req.ampTrack('Custom Survey Deleted', { survey_id: id });
              this.surveyQuestionData.surveys.splice(index, 1);
              this.dialog.closeAll();
              this.req.openSnackBar('Survey question deleted.', '');
              this.originalSurveyQuestionData = JSON.parse(
                JSON.stringify(this.surveyQuestionData)
              );
              this.surveyQuestionData.available_count++;
              this.surveyQuestionData.created_count--;
              this.surveyQuestionData.surveys = [
                ...this.surveyQuestionData.surveys,
              ];
            },
            error: err => {
              this.req.openSnackBar(
                'There was an error: ' + err.error.message,
                'Okay'
              );
            },
          });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  handleSubmitForApproval(index: number) {
    this.originalSurveyQuestionData.surveys[index].status = {
      value: 'pending',
      display: 'Pending Approval',
    };
    this.req
      .createSurveyQuestionOrUpdate(
        this.originalSurveyQuestionData.surveys[index]
      )
      .subscribe({
        next: data => {
          // Amplitude event - Custom Survey Submitted for Approval
          this.req.ampTrack('Custom Survey Submitted for Approval', {
            survey_id: this.originalSurveyQuestionData.surveys[index].id,
          });
          this.surveyQuestionData.surveys[index].status = {
            value: 'pending',
            display: 'Pending Approval',
          };
          this.req.openSnackBar('Survey question submitted for approval.', '');
          this.surveyQuestionData.surveys = [
            ...this.surveyQuestionData.surveys,
          ];
        },
        error: err => {
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  onDragEnded(event: CdkDragDrop<any>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    moveItemInArray(
      this.surveyQuestionData.surveys,
      event.previousIndex,
      event.currentIndex
    );
    this.surveyQuestionCurrentOrder = this.getSurveyQuestionOrder(
      this.surveyQuestionData.surveys
    );

    // redraw to update its data!
    this.surveyQuestionData.surveys = [...this.surveyQuestionData.surveys];

    this.orderChanges = this.areThereOrderChanges();
  }

  savePositionOrder() {
    this.req
      .putSurveyQuestionOrder(
        this.getSurveyQuestionOrder(this.surveyQuestionData.surveys)
      )
      .subscribe({
        next: data => {
          this.surveyQuestionOriginalOrder = this.getSurveyQuestionOrder(
            this.surveyQuestionData.surveys
          );
          this.orderChanges = this.areThereOrderChanges();
          this.req.openSnackBar('Survey question order updated.', '');
        },
        error: err => {
          this.req.openSnackBar(
            'There was a problem saving: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  areThereOrderChanges() {
    if (
      this.surveyQuestionOriginalOrder.length ===
      this.surveyQuestionCurrentOrder?.length
    ) {
      for (let i = 0; i < this.surveyQuestionOriginalOrder.length; i++) {
        if (
          this.surveyQuestionOriginalOrder[i] !==
          this.surveyQuestionCurrentOrder[i]
        ) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  getSurveyQuestionOrder(list) {
    const idList = [];
    for (let i = 0; i < list.length; i++) {
      idList.push(list[i].id);
    }
    return idList;
  }

  handleUndoChanges() {
    this.surveyQuestionData.surveys = [
      ...this.originalSurveyQuestionData.surveys,
    ];
    this.surveyQuestionCurrentOrder = this.getSurveyQuestionOrder(
      this.surveyQuestionData.surveys
    );
    this.orderChanges = false;
  }

  validateQuestion(element): boolean {
    return (
      this.validateQuestionField(element) && this.validateAnswerFields(element)
    );
  }

  validateQuestionField(element): boolean {
    if (element.spanish_enabled) {
      if (
        element.question['en-US']?.length > 0 &&
        element.question['es-US']?.length > 0
      ) {
        return true;
      }
    } else if (element.question['en-US']?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validateAnswerFields(element): boolean {
    if (element.answers.length > 1) {
      if (element.spanish_enabled) {
        return element.answers.every(
          answer => answer['en-US']?.length > 0 && answer['es-US']?.length > 0
        );
      } else {
        return element.answers.every(answer => answer['en-US']?.length > 0);
      }
    }
    return false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.areThereOrderChanges()) {
      return this.dialogService.confirm(
        'You have unsaved changes! Are you sure you want to leave?'
      );
    }
    return true;
  }
}
