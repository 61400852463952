<div class="add-member-modal-wrapper">
  <zogo-icon
    class="dismiss-icon text-color-neutral-800"
    iconName="x"
    (click)="dismiss()"
  ></zogo-icon>
  <div class="d-flex-col gap-32">
    <h2 class="h4-bold">{{ headerText }}</h2>
    <form #form="ngForm" (ngSubmit)="saveChanges()">
      <div class="d-flex-col gap-12 margin-bottom-24">
        <div class="d-flex-row gap-12">
          <zogo-text-input
            [(ngModel)]="firstName"
            name="firstName"
            class="name-input"
            inputId="first-name"
            inputLabel="First Name"
            autocompleteType="given-name"
            ariaLabel="Team Member First Name"
            [isRequired]="true"
            [currentValue]="firstName"
            #firstNameInput="ngModel"
          ></zogo-text-input>
          <zogo-text-input
            [(ngModel)]="lastName"
            name="lastName"
            class="name-input"
            inputId="last-name"
            inputLabel="Last Name"
            autocompleteType="family-name"
            ariaLabel="Team Member Last Name"
            [isRequired]="true"
            [currentValue]="lastName"
            #lastNameInput="ngModel"
          ></zogo-text-input>
        </div>

        <zogo-text-input
          [(ngModel)]="email"
          name="contactEmail"
          inputId="contact-email"
          inputLabel="Email Address"
          inputType="email"
          autocompleteType="email"
          ariaLabel="Team Member Email"
          [isRequired]="true"
          [currentValue]="email"
          appCustomValidation="email"
          #memberEmailInput="ngModel"
        ></zogo-text-input>
        <div
          *ngIf="
            memberEmailInput.errors?.invalidEmailFormat &&
            memberEmailInput.touched
          "
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Invalid email format</p>
        </div>

        <div class="d-flex-col gap-4">
          <p class="caption-medium">Permissions</p>
          <zogo-radio-group
            [(ngModel)]="accessLevel"
            name="accessLevel"
            class="d-flex-row gap-24"
            required
            [initialValue]="accessLevel"
            #radioGroup="ngModel"
          >
            <zogo-radio-button value="admin" label="Admin"></zogo-radio-button>
            <zogo-radio-button
              value="view_only"
              label="View-only"
            ></zogo-radio-button>
          </zogo-radio-group>
        </div>
      </div>
      <div class="d-flex-row gap-8">
        <zogo-btn
          [disabled]="form.invalid"
          type="submit"
          label="Save Changes"
        ></zogo-btn>
        <zogo-btn
          buttonStyle="tertiary"
          label="Cancel"
          (clickFunction)="dismiss()"
        ></zogo-btn>
      </div>
    </form>
  </div>
</div>
