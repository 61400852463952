import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestsService } from 'src/app/requests.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-zogo-web-skill-card',
  templateUrl: './zogo-web-skill-card.component.html',
  styleUrls: ['./zogo-web-skill-card.component.scss'],
})
export class ZogoWebSkillCardComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() skillCardData: any;
  @Input() iconPack: 'classic' | 'playful' | 'clean' = 'classic';
  uniqueIDIncrement: number = 0;

  localID: string = this.req.getUniqueID().toString();

  constructor(public req: RequestsService, private http: HttpClient) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getRawSVG(this.skillCardData.image[this.iconPack]).subscribe({
      next: data => {
        const element = document.getElementById(this.localID);
        if (element) {
          element.innerHTML = data
            .replaceAll('black', 'currentColor')
            .replace(
              'zogo-web-icon-pack-colorable-member',
              'zogo-web-icon-pack-colorable-member svg-height'
            );
        }
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getRawSVG(this.skillCardData.image[this.iconPack]).subscribe({
      next: data => {
        const element = document.getElementById(this.localID);
        if (element) {
          element.innerHTML = data
            .replaceAll('black', 'currentColor')
            .replace(
              'zogo-web-icon-pack-colorable-member',
              'zogo-web-icon-pack-colorable-member svg-height'
            );
        }
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  getRawSVG(url: any): Observable<any> {
    const headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers.set('Accept', 'image/svg+xml');
    return this.http.get(url, { headers, responseType: 'text' });
  }
}
