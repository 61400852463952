<app-onboarding-header></app-onboarding-header>
<div class="form-container d-flex-col gap-24">
  <h1 class="h3-bold">Billing Contact</h1>
  <form #form="ngForm" (ngSubmit)="onSubmitForm()">
    <div class="d-flex-col gap-12 margin-bottom-24">
      <zogo-text-input
        [(ngModel)]="billingFormData.contactName"
        name="contactName"
        inputId="contact-name"
        inputLabel="Full Name"
        autocompleteType="name"
        ariaLabel="Billing Contact Name"
        [isRequired]="true"
        [currentValue]="billingFormData.contactName"
        #contactNameInput="ngModel"
      ></zogo-text-input>

      <zogo-text-input
        [(ngModel)]="billingFormData.contactEmail"
        name="contactEmail"
        inputId="contact-email"
        inputLabel="Email Address"
        inputType="email"
        autocompleteType="email"
        ariaLabel="Billing Contact Email"
        [isRequired]="true"
        [currentValue]="billingFormData.contactEmail"
        appCustomValidation="email"
        #contactEmailInput="ngModel"
      ></zogo-text-input>
      <div
        *ngIf="
          contactEmailInput.errors?.invalidEmailFormat &&
          contactEmailInput.touched
        "
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Invalid email format</p>
      </div>

      <zogo-text-input
        [(ngModel)]="billingFormData.contactPhone"
        name="contactPhone"
        inputId="contact-phone"
        inputLabel="Phone Number"
        autocompleteType="tel"
        ariaLabel="Billing Contact Phone"
        [isRequired]="true"
        [currentValue]="billingFormData.contactPhone"
        (valueChange)="onPhoneChange($event)"
        #contactPhoneInput="ngModel"
      ></zogo-text-input>
      <div
        *ngIf="hasPhoneInputError && contactPhoneInput.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Invalid phone number format</p>
      </div>
    </div>
    <div class="d-flex-col gap-16">
      <zogo-btn
        type="submit"
        size="large"
        label="Continue"
        [fullWidth]="true"
        [disabled]="form.invalid || hasPhoneInputError"
      ></zogo-btn>
      <zogo-decorated-link
        [displayOnly]="true"
        class="skip-cta"
        label="Skip for now"
        (click)="handleSkip()"
      ></zogo-decorated-link>
    </div>
  </form>
</div>
