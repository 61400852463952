<div class="d-flex-row gap-8 ion-align-items-center">
  <div
    class="zogo-checkbox"
    [class.disabled]="isDisabled"
    (click)="toggleChecked()"
    (keydown.enter)="toggleChecked()"
    (keydown.space)="toggleChecked()"
    tabindex="0"
    tappable
  >
    <svg
      class="text-color-brand-primary"
      [ngClass]="getCheckboxClass()"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        *ngIf="!isChecked && !isDisabled"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 3.66667H7C5.15905 3.66667 3.66667 5.15905 3.66667 7V17C3.66667 18.8409 5.15905 20.3333 7 20.3333H17C18.8409 20.3333 20.3333 18.8409 20.3333 17V7C20.3333 5.15905 18.8409 3.66667 17 3.66667ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7Z"
      />
      <rect
        *ngIf="isChecked"
        x="2"
        y="2"
        width="20"
        height="20"
        rx="4"
        fill="currentColor"
      />
      <path
        *ngIf="isChecked"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2761 7.72386C18.7968 8.24456 18.7968 9.08878 18.2761 9.60948L9.99992 17.8856L5.72378 13.6095C5.20308 13.0888 5.20308 12.2446 5.72378 11.7239C6.24448 11.2032 7.0887 11.2032 7.60939 11.7239L9.99992 14.1144L16.3904 7.72386C16.9111 7.20316 17.7554 7.20316 18.2761 7.72386Z"
      />
      <path
        *ngIf="isDisabled"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 3.66667H7C5.15905 3.66667 3.66667 5.15905 3.66667 7V17C3.66667 18.8409 5.15905 20.3333 7 20.3333H17C18.8409 20.3333 20.3333 18.8409 20.3333 17V7C20.3333 5.15905 18.8409 3.66667 17 3.66667ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7Z"
      />
    </svg>
  </div>

  <p class="caption-book" *ngIf="terms; else dynamicText">
    I accept the
    <a href="./terms-of-service" target="_blank" class="caption-bold"
      >Terms & Conditions</a
    >
  </p>
  <ng-template #dynamicText>
    <p class="caption-book">{{ label }}</p>
  </ng-template>
</div>
