<!-- Absolutely positioned text to force the fonts to pre-load -->
<div [ariaHidden] class="pre-load-fonts">
  <div class="noto-sans-font">
    <p style="font-weight: 400">test</p>
    <p style="font-weight: 500">test</p>
    <p style="font-weight: 700">test</p>
    <p class="caption-bold">test</p>
  </div>
  <div class="raleway-font">
    <p style="font-weight: 400">test</p>
    <p style="font-weight: 500">test</p>
    <p style="font-weight: 700">test</p>
  </div>
  <div class="merriweather-font">
    <p style="font-weight: 400">test</p>
    <p style="font-weight: 500">test</p>
    <p style="font-weight: 700">test</p>
  </div>
</div>
<div class="preview-wrapper">
  <div class="header">
    <div class="left-header">
      <img class="institution-logo" [src]="userData.institution.image" alt="" />
      <div class="powered-by-zogo-branding">
        <div
          class="caption-c3-medium powered-by-zogo-branding-text secondary-text-color"
        >
          POWERED BY
        </div>
        <img
          class="powered-by-zogo-branding-image"
          src="https://zogo-files.s3.amazonaws.com/enterprise-web/zogo_header_logo.png"
          alt=""
        />
      </div>
      <div class="search-frame secondary-text-color">
        <svg
          _ngcontent-ng-c2206775998=""
          role="presentation"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g _ngcontent-ng-c2206775998="" opacity="0.7">
            <path
              _ngcontent-ng-c2206775998=""
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              _ngcontent-ng-c2206775998=""
              d="M21 21L15.8 15.8"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        <div class="body-b1-book secondary-text-color">Search Content</div>
      </div>
    </div>
    <div class="right-header">
      <div class="points-wrapper">
        <span class="header-h5-bold primary-text-color"> 1200</span>
        <svg
          _ngcontent-ng-c2206775998=""
          role="img"
          id="points-container"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="primary-app-color"
          aria-label="Experience Icon"
        >
          <path
            _ngcontent-ng-c2206775998=""
            d="M0 5.625C0 2.5184 2.5184 0 5.625 0H14.375C17.4816 0 20 2.5184 20 5.625V14.375C20 17.4816 17.4816 20 14.375 20H5.625C2.5184 20 0 17.4816 0 14.375V5.625Z"
            fill="currentColor"
          ></path>
          <path
            _ngcontent-ng-c2206775998=""
            d="M4.49036 12.6957C4.49036 13.112 4.82145 13.3674 5.13416 13.3674C5.44686 13.3674 5.6308 13.2255 5.82394 12.9701L7.31389 10.974L8.82222 13.0079C8.99697 13.2444 9.17171 13.3674 9.47522 13.3674C9.87989 13.3674 10.1742 13.0552 10.1742 12.6484C10.1742 12.4403 10.073 12.2511 9.96267 12.1092L8.2336 9.92394L9.8707 7.86164C10.0087 7.6819 10.0822 7.51162 10.0822 7.3035C10.0822 6.88726 9.75113 6.63184 9.43843 6.63184C9.12573 6.63184 8.94178 6.77374 8.74864 7.02916L7.35067 8.92117L5.94351 6.99132C5.76876 6.75482 5.59402 6.63184 5.29051 6.63184C4.88583 6.63184 4.59153 6.94402 4.59153 7.3508C4.59153 7.55892 4.69269 7.74812 4.80306 7.89003L6.44015 9.94286L4.70189 12.1376C4.56393 12.3173 4.49036 12.4876 4.49036 12.6957Z"
            fill="white"
          ></path>
          <path
            _ngcontent-ng-c2206775998=""
            d="M11.3695 12.639C11.3695 13.0458 11.6822 13.3674 12.0777 13.3674C12.4731 13.3674 12.7858 13.0458 12.7858 12.639V11.324H13.8619C15.3059 11.324 16.4647 10.5294 16.4647 8.99685V8.97793C16.4647 7.62514 15.5358 6.6886 13.9999 6.6886H12.0777C11.6822 6.6886 11.3695 7.01024 11.3695 7.41702V12.639ZM12.7858 10.028V8.00355H13.8803C14.5885 8.00355 15.0299 8.35357 15.0299 9.00631V9.02523C15.0299 9.59284 14.6161 10.028 13.9079 10.028H12.7858Z"
            fill="white"
          ></path>
        </svg>
      </div>
      <div class="login-in-button button-b2-bold primary-app-color">Log In</div>
      <div class="sign-up-button button-b2-bold text-color-neutral-white">
        Sign Up
      </div>
      <div class="profile-button">
        <svg
          _ngcontent-ng-c2206775998=""
          role="presentation"
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="primary-app-color"
        >
          <circle
            _ngcontent-ng-c2206775998=""
            cx="20.2856"
            cy="20"
            r="20"
            fill="currentColor"
            fill-opacity="0.1"
          ></circle>
          <path
            _ngcontent-ng-c2206775998=""
            d="M28.8567 29.6424V27.4996C28.8567 25.1326 26.9379 23.2139 24.571 23.2139H15.9996C13.6326 23.2139 11.7139 25.1326 11.7139 27.4996V29.6424"
            stroke="currentColor"
            stroke-width="2.85714"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            _ngcontent-ng-c2206775998=""
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2852 18.9284C22.6522 18.9284 24.5709 17.0096 24.5709 14.6426C24.5709 12.2757 22.6522 10.3569 20.2852 10.3569C17.9183 10.3569 15.9995 12.2757 15.9995 14.6426C15.9995 17.0096 17.9183 18.9284 20.2852 18.9284Z"
            stroke="currentColor"
            stroke-width="2.85714"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        <svg
          _ngcontent-ng-c2206775998=""
          role="presentation"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-ng-c2206775998=""
            d="M1.5 1.75L6 6.25L10.5 1.75"
            stroke="#323232"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
    </div>
  </div>
  <div
    class="hero-wrapper"
    [ngClass]="{ 'colored-background': !config?.image }"
  >
    <img class="hero-banner-image" [src]="config?.image" />
    <div class="hero-container">
      <div class="hero-text-body">
        <markdown
          class="caption-c2-medium text-color-alt hero-text-item"
          [data]="
            config?.preview_type === 'zogo-web'
              ? substituteInstitutionName(config?.sub_header)
              : substituteClassName(config?.classroom_sub_header)
          "
        >
        </markdown>
        <markdown
          class="header-h1-bold text-color-alt hero-text-item"
          [data]="
            config?.preview_type === 'zogo-web'
              ? substituteInstitutionName(config?.header)
              : substituteClassName(config?.classroom_header)
          "
        >
        </markdown>
        <markdown
          class="body-b1-medium text-color-alt hero-text-item"
          [data]="
            config?.preview_type === 'zogo-web'
              ? substituteInstitutionName(config?.description)
              : substituteClassName(config?.classroom_description)
          "
        >
        </markdown>
        <div class="quick-start-button button-b2-bold">Quick Start</div>
      </div>
      <div class="trending-skills-container">
        <div class="trending-skills">
          <div class="header-h4-bold primary-text-color">Trending Skills</div>
          <app-zogo-web-skill-card
            *ngFor="let skill of trendingSkillCardData"
            [skillCardData]="skill"
            [iconPack]="config.icon_pack"
          ></app-zogo-web-skill-card>
        </div>
      </div>
    </div>
  </div>
  <div class="cta-clout-container">
    <div>
      <div class="header-h2-bold primary-app-color">72+</div>
      <div class="body-b1-medium primary-text-color">Finance skills</div>
    </div>
    <div>
      <div class="header-h2-bold primary-app-color">#1</div>
      <div class="body-b1-medium primary-text-color">Rated Fin. Ed app</div>
    </div>
    <div>
      <div class="header-h2-bold primary-app-color">2 min</div>
      <div class="body-b1-medium primary-text-color">Bite-sized modules</div>
    </div>
  </div>
  <div class="skills-header">
    <div class="header-h1-bold primary-text-color">
      Explore categories and skills
    </div>
    <div class="body-b1-medium secondary-text-color">
      Find the skill that's right for you
    </div>
  </div>
  <div class="skill-select-container">
    <div class="category-select-container">
      <div class="line-runner"></div>
      <div class="category-item">
        <div class="header-h5-bold category-name primary-app-color">
          Teen Finance
          <div class="selected-underline"></div>
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Financial Basics
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Major Milestones
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Investing
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Career
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          College
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Risk Management
        </div>
        <div class="header-h5-bold category-name secondary-text-color color">
          Entrepreneurship
        </div>
      </div>
    </div>
    <div class="skill-category-container-outer-flex">
      <div class="skill-category-container-inner-flex">
        <app-zogo-web-skill-card
          *ngFor="let skill of leftColumnSkillCardData"
          [skillCardData]="skill"
          [iconPack]="config.icon_pack"
        ></app-zogo-web-skill-card>
      </div>
      <div class="skill-category-container-inner-flex">
        <app-zogo-web-skill-card
          *ngFor="let skill of rightColumnSkillCardData"
          [skillCardData]="skill"
          [iconPack]="config.icon_pack"
        ></app-zogo-web-skill-card>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-row">
      <div class="footer-left">
        <div class="body-b2-medium text-color-alt">
          © 2023 Zogo, Inc. All Rights Reserved.
        </div>
        <div class="body-b2-medium text-color-alt">Contact us</div>
        <div class="body-b2-medium text-color-alt">Terms of Service</div>
      </div>
      <div class="language-selector body-b2-medium text-color-alt">
        <svg
          _ngcontent-ng-c2185556595=""
          role="presentation"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g _ngcontent-ng-c2185556595="" clip-path="url(#clip0_1621_25676)">
            <path
              _ngcontent-ng-c2185556595=""
              d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              _ngcontent-ng-c2185556595=""
              d="M1.5 9H16.5"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              _ngcontent-ng-c2185556595=""
              d="M9 1.5C10.876 3.55376 11.9421 6.21903 12 9C11.9421 11.781 10.876 14.4462 9 16.5C7.12404 14.4462 6.05794 11.781 6 9C6.05794 6.21903 7.12404 3.55376 9 1.5Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
          <defs _ngcontent-ng-c2185556595="">
            <clipPath _ngcontent-ng-c2185556595="" id="clip0_1621_25676">
              <rect
                _ngcontent-ng-c2185556595=""
                width="18"
                height="18"
                fill="currentColor"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        Language
      </div>
    </div>
  </div>
</div>
