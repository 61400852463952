<header>
  <div class="dialog-header-text text-color-neutral-800 h4-bold">
    Request PDF
  </div>
  <svg
    class="dismiss-cross"
    (click)="data.dismiss()"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</header>
<div class="modal-container">
  <div class="intro-text text-color-neutral-800 body-medium">
    We’ll send a generated report of the Analytics to your email based on the
    filters you selected.
  </div>
  <div *ngIf="data.currentSegment" class="filter-section">
    <div class="filter-header h5-bold text-color-neutral-800">Access codes</div>
    <app-community-segment-select
      *ngIf="userData.institution.community_segments_enabled"
      (segmentSelected)="updateSegment($event)"
    ></app-community-segment-select>
  </div>
  <div class="filter-section">
    <div class="filter-header h5-bold text-color-neutral-800">Date</div>
    <zogo-radio-group
      class="h5-medium text-color-neutral-800"
      (valueChange)="onTimeFrameChange($event)"
      initialValue="all"
    >
      <zogo-radio-button value="all" label="All time"></zogo-radio-button>
      <zogo-radio-button
        value="thirty"
        label="Last 30 days"
      ></zogo-radio-button>
      <zogo-radio-button
        value="ninety"
        label="Last 90 days"
      ></zogo-radio-button>
      <zogo-radio-button
        value="custom"
        label="Custom Date Range"
      ></zogo-radio-button>
    </zogo-radio-group>
    <mat-form-field
      appearance="none"
      *ngIf="dateOption === 'custom'"
      id="contact-date"
      appearance="fill"
      (click)="$event.stopPropagation()"
    >
      <mat-label>Select date range</mat-label>
      <mat-date-range-input (click)="picker.open()" [rangePicker]="picker">
        <input
          matInput
          (dateChange)="setDate('start', $event)"
          matStartDate
          placeholder="Start date"
        />
        <input
          matInput
          (dateChange)="setDate('end', $event)"
          matEndDate
          placeholder="End date"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div
    *ngIf="userData?.institution.platform_access_types.length > 1"
    class="filter-section"
  >
    <div class="filter-header h5-bold text-color-neutral-800">Products</div>
    <div class="filter-details text-color-neutral-800 body-medium">
      Select at least 1 product
    </div>
    <zogo-checkbox
      class="h5-medium text-color-neutral-800"
      *ngFor="let platform of userData?.institution.platform_access_types"
      [label]="platform.display_name"
      initialValue="true"
      (valueChange)="togglePlatform(platform.platform)"
    >
    </zogo-checkbox>
  </div>
</div>
<footer>
  <div class="dialog-btn">
    <div class="dialog-btn-container">
      <zogo-btn
        label="Cancel"
        [fullWidth]="false"
        size="medium"
        buttonStyle="secondary"
        (clickFunction)="data.dismiss()"
      >
      </zogo-btn>
      <zogo-btn
        label="Submit"
        [fullWidth]="false"
        size="medium"
        buttonStyle="primary"
        [disabled]="!hasValidInputs()"
        (clickFunction)="handleSubmit()"
      >
      </zogo-btn>
    </div>
  </div>
</footer>
