import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

@Component({
  selector: 'zogo-pagination-arrow',
  templateUrl: './zogo-pagination-arrow.component.html',
  styleUrls: ['./zogo-pagination-arrow.component.scss'],
  standalone: true,
  imports: [CommonModule, ZogoIconComponent],
})
export class ZogoPaginationArrowComponent {
  @Input() icon: 'left' | 'right' = 'right';
  @Input() type: 'bordered' | 'borderless' | 'bold' = 'bordered';
  @Input() disabled: boolean = false;
  @Input() pageNumber: number = 0;
  @Output() pageChange = new EventEmitter<number>();

  constructor() {}

  handlePageChange() {
    this.pageChange.emit(this.pageNumber);
  }
}
