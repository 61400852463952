import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { RequestsService } from '../../requests.service';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss'],
})
export class UserMessagesComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  userMessagesData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  errorLoading: boolean = false;

  _filter: any = 'All';
  set filter(val) {
    this.filterMessagesBy(val);
    this._filter = val;
  }
  get filter() {
    return this._filter;
  }

  _selectedMsg: any;

  get selectedMsg() {
    return this._selectedMsg;
  }

  set selectedMsg(msg: any) {
    this._selectedMsg = msg;
    setTimeout(() => {
      $('#messageContainer').animate(
        { scrollTop: $('#messageContainer').prop('scrollHeight') },
        400
      );
      // $('#messageContainer').scrollTop($('#messageContainer')[0].scrollHeight);
    }, 150);
    if (!msg.is_read) {
      this.req.markAsRead(msg.id).subscribe({
        next: data => {
          console.log('Message marked as read');
        },
        error: err => {},
      });

      this._selectedMsg.is_read = true;
    }
  }

  messages: any;
  allMessages: any = [];

  constructor(public req: RequestsService) {}

  ngOnInit() {
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateUserMessagesData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateUserMessagesData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateUserMessagesData();
      },
      error: err => {},
    });

    this.updateUserMessagesData();
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateUserMessagesData() {
    this.userMessagesData = null;
    this.errorLoading = false;
    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.userMessagesData = data;
          this.allMessages = this.sanitizeMessages(this.userMessagesData.inbox);
          if (this.allMessages.length > 0) {
            this.messages = this.allMessages;
          }
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }

  getTruncatedName(first: string, last: string) {
    if (!last) {
      return `${first}`;
    }
    return `${first} ${last[0]}.`;
  }

  getFormattedTime(time) {
    const msgTime = moment(time);
    const daysSince = Math.abs(msgTime.diff(moment(), 'days'));
    if (daysSince < 1) {
      return msgTime.format('h:mA');
    }

    return msgTime.format('M/D/YYYY');
  }

  getFormattedDate(timestamp) {
    const date = moment(timestamp).format('MMM DD, YYYY');
    const time = moment(timestamp).format('HH:mmA');
    return `${date} @ ${time}`;
  }

  getNumberUnread() {
    if (!this.messages) {
      return 0;
    }

    let count = 0;
    this.messages.forEach(msg => {
      if (!msg.is_read) {
        count++;
      }
    });
    return count;
  }

  getInitials(first: string, last: string) {
    if (!last) {
      return first[0].toUpperCase();
    }
    return (first[0] + last[0]).toUpperCase();
  }

  // Remove any entries with null first/last name
  sanitizeMessages(messages) {
    const sanitized = [];
    messages.forEach(msg => {
      if (msg.first_name) {
        sanitized.push(msg);
      }
    });
    return sanitized;
  }

  filterMessagesBy(criteria: string) {
    this.messages = this.allMessages.filter(msg => {
      switch (criteria) {
        case 'Read':
          return msg.is_read;
        case 'Unread':
          return !msg.is_read;
        default:
          return true;
      }
    });
  }

  sendUserResponse(id) {
    this.req.messageThankYou(id).subscribe({
      next: (data: any) => {
        this._selectedMsg.thank_you_text = data?.message_data.thank_you_text;
        this._selectedMsg.zogo_response_message =
          data?.message_data.zogo_response_message;
      },
      error: err => {},
    });
  }

  copyUserEmail(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.req.openSnackBar('User email address copied (' + val + ')', 'Okay');
  }

  sendUserEmail(address, subject, body) {
    const email = 'mailto:' + address + '?subject=' + subject + '&body=' + body; // add the links to body
    window.location.href = email;
  }
}
