<div class="dashboard-view d-flex-col">
  <div class="settings-header d-flex-col gap-8">
    <h1 class="h3-bold">My Institution</h1>
    <p class="body-book">{{ userData?.institution.long_name }}</p>
  </div>

  <section
    class="settings-tab-bar h5-medium text-color-neutral-600 margin-bottom-24"
  >
    <div
      tappable
      tabindex="0"
      *ngFor="let tabInfo of navTabs"
      (click)="selectTab(tabInfo.param)"
      class="padding-bottom-8"
      [class.text-color-brand-primary]="activeTab === tabInfo.param"
      [class.selected]="activeTab === tabInfo.param"
    >
      {{ tabInfo.label }}
    </div>
  </section>

  <section *ngIf="activeTab === 'team-members'" class="d-flex-col gap-24">
    <app-team-members></app-team-members>
  </section>

  <section *ngIf="activeTab === 'teacher-admins'" class="d-flex-col gap-24">
    <app-teacher-admin></app-teacher-admin>
  </section>

  <section *ngIf="activeTab === 'account-settings'" class="d-flex-col gap-24">
    <app-my-account></app-my-account>
  </section>

  <section *ngIf="activeTab === 'billing-contact'" class="d-flex-col gap-24">
    <app-payments></app-payments>
  </section>

  <section *ngIf="activeTab === 'zip-codes'" class="d-flex-col gap-24">
    <app-zip-codes></app-zip-codes>
  </section>
</div>
