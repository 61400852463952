<div class="dashboard-view">
  <div class="overview-header-section">
    <div class="header-wrapper">
      <img *ngIf="req.isShareView" src="global/logos/zogo-blue.svg" alt="" />
      <h2 class="h3-bold text-color-neutral-800">Engagement</h2>
    </div>
    <div class="date-select-section">
      <mat-select
        class="input-container caption-medium text-color-neutral-800"
        [placeholder]="copy?.timeframes?.all_time"
        [(ngModel)]="timeFrameSelectedOption"
        (selectionChange)="onSelectionChange($event)"
      >
        <mat-option value="all">{{ copy?.timeframes?.all_time }}</mat-option>
        <mat-option value="thirty">{{
          copy?.timeframes?.thirty_days
        }}</mat-option>
        <mat-option value="ninety">{{
          copy?.timeframes?.ninety_days
        }}</mat-option>
      </mat-select>
      <app-community-segment-select
        class="community-segment-input"
        *ngIf="
          userData.institution.community_segments_enabled &&
          activeTab?.platform === 'native'
        "
        (segmentSelected)="updateSegment($event)"
      ></app-community-segment-select>
      <zogo-btn
        *ngIf="
          !req.isShareView && userData?.institution?.can_request_pdf_reports
        "
        label="Get Report"
        [fullWidth]="false"
        size="medium"
        buttonStyle="primary"
        (clickFunction)="openRequestPdfDialog()"
      >
      </zogo-btn>
    </div>
  </div>
  <div
    *ngIf="
      !req.isShareView &&
      userData.institution.enable_portal_share_link &&
      userData.institution.id !== 100
    "
    class="get-share-link-btn-wrapper"
  >
    <button
      class="get-share-link-btn overline-medium text-color-brand-primary margin-top-16"
      tappable
      (click)="getViewOnlyLink()"
    >
      <zogo-icon size="small" iconName="link-2"></zogo-icon> Share view-only
      link
    </button>
  </div>
  <h2
    *ngIf="!req.isShareView"
    class="h4-bold text-color-neutral-800 margin-top-64"
  >
    Zogo Product Highlights
  </h2>
  <div *ngIf="!req.isShareView && !productHighlights" class="text-align-center">
    <ion-spinner class="text-align-center" name="circular"></ion-spinner>
  </div>
  <app-product-highlights
    *ngIf="!req.isShareView && productHighlights"
    [productHighlights]="productHighlights"
  ></app-product-highlights>
  <app-notif-alert
    *ngIf="isNotifAlertEnabled2"
    [status]="notifAlertStatus2"
    [message]="notifAlertMessage2"
    [dismiss]="dismissNotifAlert2.bind(this)"
    [buttonText]="notifAlertButtonText2"
    [buttonFunc]="notifAlertButtonFunc2"
  ></app-notif-alert>

  <div *ngIf="!overviewInfoData">
    <ion-spinner class="overview-loader" name="circular"></ion-spinner>
  </div>
  <div *ngIf="overviewInfoData" class="platform-tabs-section margin-top-64">
    <div
      *ngFor="let tab of overviewInfoData.tabs"
      [ngClass]="
        activeTab.platform === tab.platform
          ? 'selected h5-bold text-color-brand-primary'
          : 'h5-medium text-color-neutral-600'
      "
      (click)="selectTab(tab)"
    >
      {{ tab.tab_name }}
    </div>
  </div>
  <div
    *ngIf="overviewInfoData && activeTab.sub_tabs?.length"
    class="sub-tabs-section"
  >
    <zogo-chip
      *ngFor="let subTab of activeTab.sub_tabs"
      class="margin-bottom-8"
      [label]="subTab.sub_tab_name"
      [isSelected]="subTab.platform === activeSubTab.platform"
      [shouldManageSelect]="false"
      (selectedChange)="handleSubTabSelection(subTab)"
    ></zogo-chip>
  </div>

  <app-widget-display
    *ngIf="overviewInfoData"
    [tabData]="activeTab.widget_groups ? activeTab : activeSubTab"
    [TEST_DATA]="TEST_DATA"
  ></app-widget-display>
</div>
