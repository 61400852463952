<div class="icon-selector-container">
  <h3 class="icon-select-header">Please select an icon from the list below</h3>
  <div class="card icon-select-container">
    <img
      *ngFor="let image of dialogData.iconList.custom_icons"
      class="icon"
      [src]="image.image_url"
      (click)="addIcon(image)"
      (mouseenter)="changePreviewImage(image)"
    />
  </div>
  <div class="module-preview-container">
    <div class="module-radius">
      <div class="module-icon-frame">
        <img class="icon" [src]="previewImage?.image_url" />
        <svg
          _ngcontent-gyu-c5=""
          class="star none"
          fill="none"
          height="28"
          viewBox="0 0 30 28"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          ng-reflect-klass="star"
          ng-reflect-ng-class="none"
        >
          <path
            _ngcontent-gyu-c5=""
            d="M19.5698 8.21024L18.3287 4.39058C17.281 1.16599 12.719 1.16598 11.6713 4.39057L10.4302 8.21024C10.3633 8.41625 10.1713 8.55573 9.95469 8.55573H5.93846C2.54794 8.55573 1.13821 12.8944 3.88121 14.8873L7.13041 17.248C7.30565 17.3753 7.37898 17.601 7.31205 17.807L6.07096 21.6266C5.02324 24.8512 8.7139 27.5327 11.4569 25.5398L14.7061 23.1791C14.8814 23.0518 15.1186 23.0518 15.2939 23.1791L18.5431 25.5398C21.2861 27.5327 24.9768 24.8512 23.929 21.6266L22.688 17.807C22.621 17.601 22.6943 17.3753 22.8696 17.248L26.1188 14.8873C28.8618 12.8944 27.4521 8.55573 24.0615 8.55573H20.0453C19.8287 8.55573 19.6367 8.41625 19.5698 8.21024Z"
            fill="#FFD058"
            stroke="white"
            stroke-width="3"
          ></path>
        </svg>
      </div>
    </div>
    <div class="module-title">
      {{ dialogData?.moduleName }}
    </div>
  </div>
</div>
