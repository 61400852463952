import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { RequestsService } from '../../requests.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModuleConfirmationDialogComponent } from './module-confirmation-dialog/module-confirmation-dialog.component';
import { MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogService } from 'src/app/guards/dialog.service';
import { Observable } from 'rxjs';

export interface CustomModulesElement {
  image: string;
  name: string;
  created: string;
  status: string;
  completed: number;
  answered: number;
  correct: string;
}

const ELEMENT_DATA: CustomModulesElement[] = [];

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css'],
})
export class ModulesComponent implements OnInit {
  displayedColumns: string[] = [
    'image',
    'name',
    'status',
    'completed',
    'correct',
  ];
  introModuleOriginalOrder: any;
  currentIntroModuleOrder: any;
  dataSource = ELEMENT_DATA;
  dashboardData: any;
  userData: any;
  isAdmin: boolean;
  @ViewChild(MatTable) table: MatTable<any>;
  placementCTA: boolean = true;
  orderChanges: boolean;

  constructor(
    public req: RequestsService,
    public router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    // Amplitude event - Custom Modules Viewed
    this.req.ampTrack('Custom Modules Viewed');
    this.req.getDashboardModule().subscribe({
      next: data => {
        this.dashboardData = data;
        this.introModuleOriginalOrder = this.getIntroModuleOrder(
          this.dashboardData.intro_modules
        );
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });

    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
      this.displayedColumns = [
        'image',
        'name',
        'status',
        'completed',
        'correct',
        'selection',
      ];
    } else {
      this.isAdmin = false;
    }
  }

  openModule(element, isNewModule?) {
    const module = element;
    module.is_new_module = isNewModule;
    if (module) {
      this.req.moduleToEdit(module);
      this.req.storeIntroModuleInfo({
        num_modules: this.dashboardData.intro_modules.length,
        max_modules: this.dashboardData.max_intro_modules,
      });
    }
    this.router.navigate(['editor']);
  }

  getIntroModuleOrder(list) {
    const idList = [];
    for (let i = 0; i < list.length; i++) {
      idList.push(list[i].module.module_id);
    }
    return idList;
  }

  createModule() {
    this.req.postDashboardModule().subscribe({
      next: data => {
        this.openModule(data, true);
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  submitModule(ev, module) {
    const content_ids = [];
    const moduleData = module;
    for (let i = 0; i < moduleData.module.content.snippets.values.length; i++) {
      content_ids.push(moduleData.module.content.snippets.values[i].content_id);
    }
    for (
      let i = 0;
      i < moduleData.module.content.questions.values.length;
      i++
    ) {
      content_ids.push(
        moduleData.module.content.questions.values[i].content_id
      );
    }
    moduleData.module.content_ids = content_ids;
    const moduleDataToSubmit = JSON.parse(JSON.stringify(moduleData));
    moduleDataToSubmit.module.status.value = 'Pending Approval';

    this.req
      .putDashboardModule(moduleDataToSubmit, moduleData.module.spanish_enabled)
      .subscribe({
        next: data => {
          // Amplitude event - Custom Module Submitted for Approval
          this.req.ampTrack('Custom Module Submitted for Approval', {
            custom_module_id: moduleData.module.module_id,
          });
          this.req.openSnackBar('Submitted for approval.', '👍');
          module.module.status.value = 'Pending Approval';
        },
        error: err => {
          this.req.openSnackBar(
            'There was a problem saving: ' + err.error.message,
            'Okay',
            6000
          );
        },
      });
  }

  deleteModule(module) {
    this.dialog.open(ModuleConfirmationDialogComponent, {
      width: '488px',
      height: '273px',
      data: {
        header: 'Delete Custom module?',
        body: 'This custom module will be permanently deleted from your list. If placed, it will be deleted from all platforms.',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Back to Edit',
        confirmFunct: data => {
          this.req.deleteDashboardModule(module.module.module_id).subscribe({
            next: data => {
              // Amplitude event - Custom Module Deleted
              this.req.ampTrack('Custom Module Deleted', {
                custom_module_id: module.module.module_id,
              });

              let indexToRemove = this.dashboardData.modules.findIndex(
                originalModule =>
                  originalModule.module.module_id === module.module.module_id
              );
              if (indexToRemove !== -1) {
                this.dashboardData.modules.splice(indexToRemove, 1);
                this.dashboardData.modules = [...this.dashboardData.modules];
              } else {
                indexToRemove = this.dashboardData.intro_modules.findIndex(
                  originalModule =>
                    originalModule.module.module_id === module.module.module_id
                );
                this.dashboardData.intro_modules.splice(indexToRemove, 1);
                this.dashboardData.intro_modules = [
                  ...this.dashboardData.intro_modules,
                ];
              }
            },
            error: err => {
              console.log(err);
              this.req.openSnackBar(
                'There was an error: ' + err.error.message,
                'Okay'
              );
            },
          });
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  getTooltipContent(placedPlatforms: any[]): string {
    if (!placedPlatforms || placedPlatforms.length === 0) {
      return 'No platforms and skills available';
    }
    const tooltipContent = placedPlatforms
      .map(platform => {
        return `${platform.platform} - ${platform.skill}`;
      })
      .join('\n');

    return tooltipContent;
  }

  onDragEnded(event: CdkDragDrop<any>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    moveItemInArray(
      this.dashboardData.intro_modules,
      event.previousIndex,
      event.currentIndex
    );
    this.currentIntroModuleOrder = this.getIntroModuleOrder(
      this.dashboardData.intro_modules
    );

    // this is how you tell a table to redraw to update its data!
    this.dashboardData.intro_modules = [...this.dashboardData.intro_modules];

    this.orderChanges = this.areThereOrderChanges();
  }

  savePositionOrder() {
    this.req
      .putDashboardModuleIntroOrder(
        this.getIntroModuleOrder(this.dashboardData.intro_modules)
      )
      .subscribe({
        next: data => {
          this.introModuleOriginalOrder = this.getIntroModuleOrder(
            this.dashboardData.intro_modules
          );
          this.orderChanges = this.areThereOrderChanges();
          this.req.openSnackBar('Intro module order updated.', '👍');
        },
        error: err => {
          this.req.openSnackBar(
            'There was a problem saving: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  areThereOrderChanges() {
    if (
      this.introModuleOriginalOrder.length ===
      this.currentIntroModuleOrder?.length
    ) {
      for (let i = 0; i < this.introModuleOriginalOrder.length; i++) {
        if (
          this.introModuleOriginalOrder[i] !== this.currentIntroModuleOrder[i]
        ) {
          console.log('returning false');
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  getPlacableModuleCount() {
    let placeableModuleCount = 0;
    for (let i = 0; i < this.dashboardData.modules.length; i++) {
      if (
        this.dashboardData.modules[i].module.placed_status !== 'placed' &&
        this.dashboardData.modules[i].module.status.value !== 'Inactive'
      ) {
        placeableModuleCount++;
      }
    }
    return placeableModuleCount;
  }

  dismissPlacementCTA() {
    this.placementCTA = false;
  }

  placeModule() {
    this.router.navigate(['content-library']);
  }

  openIntroModuleExplanationDialog() {
    this.dialog.open(ModuleConfirmationDialogComponent, {
      width: '488px',
      height: '273px',
      data: {
        header: 'Intro module details',
        body: 'Intro modules are placed at the beginning of each skill. They will appear in the order they are listed here.',
        confirmBtnText: 'Got It',

        confirmFunct: data => {
          this.dialog.closeAll();
        },
      },
    });
  }

  canModuleBeSubmitted(module) {
    return this.areQuestionsValid(module) && this.areSnippetsValid(module);
  }

  areQuestionsValid(module) {
    if (
      module.content.questions.values.length >
      module.content.questions.max_count
    ) {
      return false;
    }
    return true;
  }

  areSnippetsValid(module) {
    if (
      module.content.snippets.values.length < 3 ||
      module.content.snippets.values.length > module.content.snippets.max_count
    ) {
      return false;
    }
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.areThereOrderChanges()) {
      return this.dialogService.confirm(
        'You have unsaved changes! Are you sure you want to leave?'
      );
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.areThereOrderChanges()) {
      $event.returnValue = true;
    }
  }
}
