import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CopyService } from 'src/app/copy.service';
import { RequestPdfModalComponent } from 'src/app/dialogs/request-pdf-modal/request-pdf-modal.component';
import { TEST_DATA } from './TEST_DATA';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent implements OnInit {
  TEST_DATA: any;
  overviewInfoData: any;
  copy: any;
  copyGlobal: any;
  userData: any;
  notifAlertMessage1: string;
  notifAlertButtonText1: string;
  notifAlertButtonFunc1: any;
  notifAlertStatus1: string;
  isNotifAlertEnabled1: boolean = false;
  notifAlertMessage2: string;
  notifAlertButtonText2: string;
  notifAlertButtonFunc2: any;
  notifAlertStatus2: string;
  isNotifAlertEnabled2: boolean = false;
  timeFrameSelectedOption: any = 'all'; // thirty | ninety | all
  currentSegment: any;
  activeTab: any;
  activeSubTab: any;
  productHighlights: any;

  ALL_ACCESS_CODE_OPTION: any = {
    value: 'all',
    display_value: 'All Users',
  };

  constructor(
    public req: RequestsService,
    public appCopy: CopyService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Amplitude event - Engagement Viewed
    this.req.ampTrack('Engagement Viewed');
    // Client Success event - Engagement Viewed
    this.req.csTrack('Engagement Viewed');

    this.TEST_DATA = TEST_DATA;
    this.copy = this.appCopy.getOverviewPage();
    this.copyGlobal = this.appCopy.getDashboardGlobalCopy();
    this.userData = JSON.parse(localStorage.getItem('userData'));

    if (this.userData.institution.community_segments_enabled) {
      this.currentSegment = this.ALL_ACCESS_CODE_OPTION;
    }
    this.req.updateOverviewTimeFrame(this.timeFrameSelectedOption);
    this.req.updateOverviewCommunitySegment(this.currentSegment);
    if (!this.req.isShareView) {
      this.req.getProductHighlights().subscribe({
        next: data => {
          this.productHighlights = data;
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
    }
    this.req.getDashboardOverviewInfo().subscribe({
      next: data => {
        this.overviewInfoData = data;
        this.activeTab = this.overviewInfoData.tabs[0];
        if (this.activeTab.sub_tabs?.length) {
          this.activeSubTab = this.activeTab.sub_tabs[0];
          this.req.updateOverviewPlatform(this.activeSubTab.platform);
        } else {
          this.req.updateOverviewPlatform(this.activeTab.platform);
        }
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  selectTab(selectedTab) {
    if (this.activeTab.platform === selectedTab.platform) {
      return;
    }
    if (selectedTab.sub_tabs?.length) {
      this.activeSubTab = selectedTab.sub_tabs[0];
      this.req.updateOverviewPlatform(this.activeSubTab.platform);
    } else {
      this.req.updateOverviewPlatform(selectedTab.platform);
      selectedTab;
    }
    this.activeTab = selectedTab;
  }

  handleSubTabSelection(selectedSubTab) {
    if (this.activeSubTab.platform === selectedSubTab.platform) {
      return;
    }
    this.activeSubTab = selectedSubTab;
    this.req.updateOverviewPlatform(this.activeSubTab.platform);
  }

  onSelectionChange(event) {
    this.timeFrameSelectedOption = event.value;
    this.req.updateOverviewTimeFrame(this.timeFrameSelectedOption);
  }

  openRequestPdfDialog() {
    // Amplitude event - Request Engagement PDF Clicked
    this.req.ampTrack('Request Engagement PDF Clicked');
    // Client Success event - Request Engagement PDF Clicked
    this.req.csTrack('Request Engagement PDF Clicked');

    this.dialog.open(RequestPdfModalComponent, {
      width: '460px',
      height: '500px',
      panelClass: 'no-scroll-modal',
      data: {
        currentSegment: this.currentSegment,
        timeFrame: this.timeFrameSelectedOption,
        requestType: 'overview',
        requestPdfReport: this.requestPdfReport.bind(this),
        dismiss: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  updateSegment(event) {
    this.currentSegment = event;
    this.req.updateOverviewCommunitySegment(this.currentSegment);
  }

  requestPdfReport(body) {
    this.req.postDashboardRequestPdf(body).subscribe({
      next: data => {
        this.setNotifAlertRequestPdf();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  setNotifAlertFinishSetup() {
    this.notifAlertMessage1 = this.copyGlobal.finish_setup_toast.toast_text;
    this.notifAlertButtonText1 = this.copyGlobal.finish_setup_toast.button_text;
    this.notifAlertButtonFunc1 = this.goToSettings.bind(this);
    this.notifAlertStatus1 = 'pending';
    this.isNotifAlertEnabled1 = true;
  }

  setNotifAlertRequestPdf() {
    this.notifAlertMessage2 =
      'Your overview report has been sent to your email! It may take up to 15 minutes.';
    this.notifAlertButtonText2 = null;
    this.notifAlertButtonFunc2 = null;
    this.notifAlertStatus2 = 'success';
    this.isNotifAlertEnabled2 = true;
  }

  dismissNotifAlert1() {
    this.isNotifAlertEnabled1 = false;
  }

  dismissNotifAlert2() {
    this.isNotifAlertEnabled2 = false;
  }

  goToSettings() {
    this.router.navigate(['/my-account']);
  }

  getViewOnlyLink() {
    this.req.getDashboardShareableWidgetLink().subscribe({
      next: data => {
        const url = data.url + '?page=engagement';
        this.copyToClipboard(url);
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Amplitude event - Engagement Share Link Copied
        this.req.ampTrack('Engagement Share Link Copied');
        this.req.openSnackBar('View-only share link copied', 'Okay');
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }
}
