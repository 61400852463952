import { Component, OnInit } from '@angular/core';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from '../requests.service';
import { ErrorDialogComponent } from '../components/dialog/error.component';
import { MatDialog } from '@angular/material/dialog';

declare const Calendly: any;

interface UploadedFile {
  file_name: string;
  file_size: number;
  file_contents: string;
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  copy: any;
  userData: any;
  fileUrl: any;
  emptyFileUrl: boolean = true;
  isSubmitDisabled: boolean = true;
  userMessage: any;
  supportReasonId: any;
  supportReasons: any;
  filesArray: any;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Amplitude event - Contact Us Viewed
    this.req.ampTrack('Contact Us Viewed');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.copy = this.appCopy.getContactUsPage();
    this.req.setContactUsPageMode('menu');
    // makes sure that the calendly-inline-widget is in the dom before calling
    setTimeout(() => {
      Calendly.initInlineWidget({
        url: 'https://calendly.com/d/dyh-8xb-5fq/zogo-cx-office-hours',
        parentElement: document.querySelector('.calendly-inline-widget'),
        prefill: {
          name:
            this.userData.institution_user.first_name +
            ' ' +
            this.userData.institution_user.last_name,
          email: this.userData.institution_user.email,
        },
        utm: {},
      });
    }, 100);

    window.addEventListener('message', this.handledPostMessageEvent.bind(this));
    this.filesArray = [];
  }

  handledPostMessageEvent(e) {
    console.log(e);
    if (
      e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event.indexOf('calendly.') === 0
    ) {
      if (e.data.event === 'calendly.event_scheduled') {
        // Amplitude event - Office Hours Scheduled
        this.req.ampTrack('Office Hours Scheduled');
      }
    }
  }

  goBack() {
    this.req.setContactUsPageMode('menu');
  }

  scheduleMeeting() {
    this.req.setContactUsPageMode('schedule-meeting');
  }

  goToSupportForm() {
    this.req.getDashboardSupportReasons().subscribe({
      next: data => {
        this.supportReasons = data;
        this.req.setContactUsPageMode('support-form');
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.errors[0].message,
          'Okay'
        );
        console.log('errors', err.error.errors);
      },
    });
  }

  contactCx() {
    // Amplitude event - Send an Email Clicked
    this.req.ampTrack('Send an Email Clicked');
    window.open(
      `mailto:${this.userData.institution.zogo_contact}?subject=Partner Portal Inquiry - ${this.userData.institution.short_name}`
    );
  }

  validateForSubmit() {
    if (this.userMessage?.length > 0 && this.supportReasonId) {
      return (this.isSubmitDisabled = false);
    } else {
      return (this.isSubmitDisabled = true);
    }
  }

  async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  handleDroppedFile(event: DragEvent) {
    event.preventDefault();
    const files: FileList = event.dataTransfer?.files;
    if (files) {
      this.handleFileSelect(files);
    }
  }

  handleFileSelect(files: FileList) {
    if (files.length + this.filesArray.length > 5) {
      this.req.openSnackBar(
        'Up to a maximum of 5 files can be uploaded',
        'Okay'
      );
      return false;
    }

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (files) {
        if (Math.round(file.size / 1024) > 2000) {
          this.req.openSnackBar('File size exceeds 2MB', 'Okay');
          return false;
        }
        const uploadedFile: UploadedFile = {
          file_name: file.name,
          file_size: file.size,
          file_contents: '',
        };

        this.readFileAsDataURL(file, uploadedFile).then(() => {
          this.filesArray.push(uploadedFile);
        });
      }
    }
    return true;
  }

  readFileAsDataURL(file: File, uploadedFile: UploadedFile): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onloadend = () => {
        const base64Url: string = reader.result as string;
        uploadedFile.file_contents = base64Url;
        resolve();
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  removeFile(index: number) {
    this.filesArray.splice(index, 1)[0];
  }

  submitSupportRequest() {
    const request = {
      user_message: this.userMessage,
      support_reason_id: this.supportReasonId,
      attachments: this.filesArray,
    };

    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      disableClose: true,
      data: {
        primaryText: 'Submitting your request...',
        secondaryText: '',
      },
    });

    this.req.postDashboardSupportTicket(request).subscribe({
      next: data => {
        // Amplitude event - Zogo Support Form Submitted
        this.req.ampTrack('Zogo Support Form Submitted');
        this.req.setContactUsPageMode('support-ticket-success');
        dialogRef.close();
        this.supportReasonId = '';
        this.userMessage = '';
        this.filesArray = [];
        this.isSubmitDisabled = true;
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
        console.log('errors', err.error);
        dialogRef.close();
      },
    });
  }
}
