import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async showToast(message: string, duration: number = 3000, buttons?: any[]) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      buttons: buttons,
      cssClass: [
        'text-color-neutral-white',
        'body-medium',
        'padding-x-32',
        'padding-y-16',
      ],
    });
    toast.present();
  }
}
