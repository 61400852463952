import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { RequestsService } from 'src/app/requests.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-account-settings',
  templateUrl: './onboarding-account-settings.component.html',
  styleUrls: ['./onboarding-account-settings.component.css'],
})
export class OnboardingAccountSettingsComponent implements OnInit {
  userData: any;
  accountFormData = {
    fullInstName: '',
    preferredAbbr: '',
    institutionType: '',
    websiteUrl: '',
    accessCode: '',
  };
  needsAccessCode: boolean = false;
  isSidebarOpen: boolean = false;
  institutionTypeOptions = [
    'U.S.-based Bank',
    'International Bank',
    'Credit Union',
    'Community Bank',
    'Fintech',
    'Other',
  ];

  constructor(
    private onboarding: OnboardingService,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.needsAccessCode = this.userData.institution.platform_access_types.some(
      type => type.platform === 'native'
    );
    this.initializeInputs();
  }

  onWebsiteUrlChange(url: string) {
    this.accountFormData.websiteUrl = url.replace(/^https?:\/\//, '');
  }

  initializeInputs() {
    this.accountFormData.fullInstName = this.userData.institution.long_name;
    this.accountFormData.preferredAbbr = this.userData.institution.short_name;
    this.accountFormData.websiteUrl = this.userData.institution.website_url;
    this.accountFormData.accessCode = this.userData.institution.code;
  }

  specialSearch(str) {
    return /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str);
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  onSubmitForm() {
    const request = {
      page: 'account-settings',
      long_name: this.accountFormData.fullInstName,
      short_name: this.accountFormData.preferredAbbr,
      institution_type: this.accountFormData.institutionType,
      website_url: `https://${this.accountFormData.websiteUrl}`,
      code: this.needsAccessCode ? this.accountFormData.accessCode : '',
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Institution Information Completed
        this.req.ampTrack('Institution Information Completed');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.req
          .postDashboardOnboarding({ page: 'account-settings' })
          .subscribe({
            next: data => {
              this.onboarding.handleContinue();
            },
            error: err => {
              this.toastService.showToast(
                'There was an error: ' + err.error.message,
                6000
              );
            },
          });
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }
}
