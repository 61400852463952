import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ZogoDecoratedLinkComponent } from '../zogo-decorated-link/zogo-decorated-link.component';

@Component({
  selector: 'zogo-card',
  templateUrl: './zogo-card.component.html',
  styleUrls: ['./zogo-card.component.scss'],
  standalone: true,
  imports: [CommonModule, ZogoDecoratedLinkComponent],
})
export class ZogoCardComponent {
  @Input() ctaLabel: string = '';
  @Input() ctaIcon: string = '';
  @Input() title: string = '';
  @Input() imageUrl: string = '';
  @Input() navigateUrl: string = '';
  @Input() cardType: 'default' | 'small' = 'default';
  @Input() iconLocation: 'leading' | 'tracking' = 'tracking';

  constructor(private router: Router) {}

  onCardClick() {
    if (this.navigateUrl) {
      this.router.navigate([this.navigateUrl]);
    }
  }
}
