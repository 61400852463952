import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-invite-confirm',
  templateUrl: './invite-confirm.component.html',
  styleUrls: ['./invite-confirm.component.css'],
})
export class InviteConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appCopy: CopyService,
    private req: RequestsService,
    private dialog: MatDialog
  ) {}

  copyToClipboard() {
    navigator.clipboard
      .writeText(this.data.shareLink)
      .then(() => {
        // Amplitude event - Teacher Invitation Link Copied
        this.req.ampTrack('Teacher Invitation Link Copied');
        this.req.openSnackBar('Copied to clipboard', 'Okay');
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }

  dismiss() {
    this.dialog.closeAll();
  }
}
