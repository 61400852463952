import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-social-calendar-event',
  templateUrl: './social-calendar-event.component.html',
  styleUrls: ['./social-calendar-event.component.css'],
})
export class SocialCalendarEventComponent implements AfterViewInit {
  @Input() eventData: any;
  @Input() isLast: boolean;
  @ViewChild('eventCaption', { static: false }) eventCaptionRef: ElementRef;
  isExpanded: boolean = false;

  constructor(private req: RequestsService) {}

  ngAfterViewInit(): void {
    setTimeout(this.isTextEllipsed.bind(this), 1);
  }

  downloadImage() {
    // Amplitude event - Social Calendar Image Downloaded
    this.req.ampTrack('Social Calendar Image Downloaded');
    fetch(this.eventData.image)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.eventData.title.replaceAll(' ', '-')}-image.jpg`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading image:', error));
  }

  isTextEllipsed() {
    if (this.eventCaptionRef) {
      const textContainer = this.eventCaptionRef.nativeElement;
      return textContainer.scrollHeight > textContainer.clientHeight;
    }
    return false;
  }

  readMore() {
    this.isExpanded = true;
  }

  readLess() {
    this.isExpanded = false;
  }
}
