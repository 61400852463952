<div [class]="'d-flex-row gap-8 ion-align-items-center ' + customClass">
  <div
    class="radio-wrapper"
    (click)="toggleCheck()"
    (keydown.enter)="toggleCheck()"
    (keydown.space)="toggleCheck()"
    tabindex="0"
    tappable
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ng-container *ngIf="isSelected && !isDisabled">
        <g clip-path="url(#clip0_203_15715)">
          <circle cx="12" cy="12" r="9" stroke="#1C64F2" stroke-width="2" />
          <circle cx="12" cy="12" r="6" fill="#1C64F2" />
        </g>
        <defs>
          <clipPath id="clip0_203_15715">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </ng-container>
      <ng-container *ngIf="isSelected && isDisabled">
        <g clip-path="url(#clip0_528_2659)">
          <circle cx="12" cy="12" r="9" stroke="#ACACAC" stroke-width="2" />
          <circle cx="12" cy="12" r="6" fill="#ACACAC" />
        </g>
        <defs>
          <clipPath id="clip0_528_2659">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </ng-container>
      <ng-container *ngIf="!isSelected && isDisabled">
        <g clip-path="url(#clip0_203_15720)">
          <circle cx="12" cy="12" r="9" stroke="#DCDCDC" stroke-width="2" />
        </g>
        <defs>
          <clipPath id="clip0_203_15720">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </ng-container>
      <ng-container *ngIf="!isSelected && !isDisabled">
        <g clip-path="url(#clip0_203_15712)">
          <circle cx="12" cy="12" r="9" stroke="#8C8C8C" stroke-width="2" />
        </g>
        <defs>
          <clipPath id="clip0_203_15712">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </ng-container>
    </svg>
  </div>
  <p class="caption-book d-flex-row gap-4">
    <zogo-icon
      *ngIf="iconName && iconLocation === 'leading'"
      size="small"
      class="ion-align-self-center"
      [iconName]="iconName"
    >
    </zogo-icon
    >{{ label }}
    <zogo-icon
      *ngIf="iconName && iconLocation === 'tracking'"
      size="small"
      class="ion-align-self-center"
      [iconName]="iconName"
    ></zogo-icon>
  </p>
</div>
