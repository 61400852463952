import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/requests.service';
import { ModalController } from '@ionic/angular';
import { BillingContactModalComponent } from './billing-contact-modal/billing-contact-modal.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  userData: any;
  hasContactInfo: boolean;
  contactName: string;
  contactEmail: string;
  contactPhone: string;

  constructor(
    public req: RequestsService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    // Amplitude event - Payments Viewed
    this.req.ampTrack('Payments Viewed');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.initializeInputs();
  }

  initializeInputs() {
    this.contactName = this.userData?.institution?.contact_name;
    this.contactEmail = this.userData?.institution?.contact_email;
    this.contactPhone = this.userData?.institution?.contact_phone;

    if (this.contactEmail || this.contactName || this.contactPhone) {
      this.hasContactInfo = true;
    } else {
      this.hasContactInfo = false;
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber) {
      const value =
        phoneNumber.substring(0, 2) +
        ' ' +
        phoneNumber.substring(2, 5) +
        '-' +
        phoneNumber.substring(5, 8) +
        '-' +
        phoneNumber.substring(8, 12);
      return value;
    }
    return '-';
  }

  async addContact() {
    const componentProps = {
      headerText: 'Add Billing Contact',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      toastMessage: 'Contact added.',
    };

    const modal = await this.modalController.create({
      component: BillingContactModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    const { data, role } = await modal.onDidDismiss();

    if (role === 'save' && data) {
      this.contactName = data.contactName;
      this.contactEmail = data.contactEmail;
      this.contactPhone = data.contactPhone;
      this.hasContactInfo = true;
    }
  }

  async editContact() {
    const componentProps = {
      headerText: 'Edit Billing Contact',
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      toastMessage: 'Changes saved.',
    };

    const modal = await this.modalController.create({
      component: BillingContactModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    const { data, role } = await modal.onDidDismiss();

    if (role === 'save' && data) {
      this.contactName = data.contactName;
      this.contactEmail = data.contactEmail;
      this.contactPhone = data.contactPhone;
    }
  }

  getInitials(fullName: string) {
    const names = fullName.trim().split(' ');
    if (names.length === 1) {
      return names[0][0].toUpperCase();
    }

    const firstInitial = names[0][0].toUpperCase();
    const lastInitial = names[names.length - 1][0].toUpperCase();

    return firstInitial + lastInitial;
  }
}
