<div class="d-flex-col gap-24">
  <zogo-btn
    class="ion-align-self-end"
    iconName="plus"
    label="Add Member"
    (clickFunction)="addTeamMember()"
  ></zogo-btn>

  <ion-spinner
    *ngIf="isLoading"
    class="ion-align-self-center"
    name="circular"
  ></ion-spinner>

  <!-- Empty state when no members exists -->
  <div *ngIf="!members && !isLoading" class="member-upload-wrapper">
    <zogo-icon
      iconName="user-plus"
      size="xlarge"
      class="text-color-neutral-600"
    ></zogo-icon>
    <p class="h4-medium text-color-neutral-600">Add your teammates</p>
    <zogo-btn
      label="Add Member"
      buttonStyle="secondary"
      (clickFunction)="addTeamMember()"
    ></zogo-btn>
  </div>

  <ng-container *ngIf="members && !isLoading" class="d-flex-col gap-16">
    <table>
      <thead>
        <tr class="d-flex-row">
          <th class="name-col body-bold text-align-left" scope="col">Name</th>
          <th class="email-col body-bold text-align-left" scope="col">
            Email Address
          </th>
          <th class="access-col body-bold text-align-left" scope="col">
            Access Level
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="data-row d-flex-row"
          *ngFor="let member of this.members; index as i"
        >
          <td class="name-col body-medium d-flex-row gap-16">
            {{ getName(member?.first_name, member?.last_name) }}
          </td>
          <td class="email-col body-book">
            {{ member?.email }}
          </td>
          <td class="access-col body-bold pos-rel">
            <zogo-badge
              [label]="member?.is_admin ? 'Admin' : 'View-only'"
              [type]="member?.is_admin ? 'admin' : 'notice'"
            ></zogo-badge>
            <div tappable class="member-action-cta" (click)="toggleDropdown(i)">
              <zogo-icon
                class="text-color-neutral-600"
                iconName="more-horizontal"
              ></zogo-icon>
            </div>
            <ul *ngIf="dropdownIndex === i" class="dropdown-list margin-top-2">
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="editTeamMember(member)"
              >
                <zogo-icon iconName="edit"></zogo-icon>
                <p>Edit</p>
              </li>
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="resendInvite(member)"
              >
                <zogo-icon iconName="mail"></zogo-icon>
                <p>Resend Invite</p>
              </li>
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="showConfirmationAlert(member)"
              >
                <zogo-icon iconName="trash-2"></zogo-icon>
                <p>Remove</p>
              </li>
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="resetPassword(member.email)"
              >
                <zogo-icon iconName="mail"></zogo-icon>
                <p>Password Reset</p>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
