<div class="dashboard-view">
  <div *ngIf="mode === 'main'">
    <app-notif-alert
      *ngIf="isAccessCodeNotifAlertEnabled"
      [status]="accessCodeNotifAlertStatus"
      [message]="accessCodeNotifAlertMessage"
      [dismiss]="dismissAccessCodeNotifAlert.bind(this)"
      [buttonText]="accessCodeNotifAlertButtonText"
      [buttonFunc]="accessCodeNotifAlertButtonFunc"
      [@inOutAnimation]
    ></app-notif-alert>
    <app-notif-alert
      *ngIf="isLimitTypeNotifAlertEnabled"
      [status]="limitTypeNotifAlertStatus"
      [message]="limitTypeNotifAlertMessage"
      [dismiss]="dismissLimitTypeNotifAlert.bind(this)"
      [buttonText]="limitTypeNotifAlertButtonText"
      [buttonFunc]="limitTypeNotifAlertButtonFunc"
      [@inOutAnimation]
    ></app-notif-alert>
    <div class="page-header-flex-container">
      <h1 class="h3-bold">{{ copy?.header }}</h1>
      <div class="btn-container">
        <button
          [disabled]="
            accessCodesData?.access_codes?.length ===
              userData?.institution?.max_access_codes + 1 ||
            userData?.institution?.limit_type_change_request?.status ===
              'pending' ||
            !isAdmin
          "
          (click)="switchModes('create')"
          class="blue"
          mat-button
        >
          {{ copy?.new_access_code_btn }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="!accessCodesData">
      <mat-spinner class="center-vert" [diameter]="34"></mat-spinner>
    </ng-container>
    <div *ngIf="accessCodesData" class="available-access-codes-section">
      <div class="created">
        {{ accessCodesData?.access_codes?.length - 1 }} {{ copy?.created }}
      </div>
      <div class="available">
        {{
          userData?.institution?.max_access_codes -
            accessCodesData?.access_codes?.length +
            1
        }}
        {{ copy?.available }}
      </div>
    </div>
    <div *ngIf="accessCodesData" class="card">
      <markdown class="access-instructions-markdown">
        {{ replacePlaceholder(copy?.access_code_input_info) }}
      </markdown>
    </div>

    <div
      class="analytics-expansion"
      *ngIf="
        accessCodesData && !userData.institution.community_segments_enabled
      "
    >
      <mat-accordion>
        <mat-expansion-panel class="expansion mat-elevation-z0 card-frame">
          <mat-expansion-panel-header class="access-code">
            <mat-panel-title class="dropdown-title">
              {{ copy?.access_and_zip_code_settings?.header }}
            </mat-panel-title>
            <div
              *ngIf="
                userData?.institution?.limit_type_change_request?.status ===
                'pending'
              "
              class="pending-changes-label limit-type"
            >
              {{ copy?.pending_changes }}
            </div>
            <div
              *ngIf="
                userData?.institution?.limit_type_change_request?.status ===
                'approved'
              "
              class="approved-changes-label limit-type"
            >
              {{ copy?.approved }}
            </div>
            <div
              *ngIf="
                userData?.institution?.limit_type_change_request?.status ===
                'rejected'
              "
              class="rejected-changes-label limit-type"
            >
              Rejected
            </div>
          </mat-expansion-panel-header>
          <div class="expanded-section">
            <div class="sub-header">
              {{ copy?.access_and_zip_code_settings?.sub_header }}
            </div>
            <div
              [ngClass]="
                userData?.institution?.access_code_change_request?.status ===
                  'pending' ||
                userData?.institution?.limit_type_change_request?.status ===
                  'pending' ||
                !isAdmin
                  ? 'disabled'
                  : ''
              "
              class="radio-btn-container access-code"
            >
              <mat-radio-button
                value="access_code"
                (click)="onRadioChange('access_code')"
                [checked]="selectedLimitType === 'access_code'"
                >{{
                  copy?.access_and_zip_code_settings?.access_code_header
                }}</mat-radio-button
              >
              <div class="radio-btn-sub-header">
                {{ copy?.access_and_zip_code_settings?.access_code_sub_header }}
              </div>
            </div>
            <div
              [ngClass]="
                userData?.institution?.access_code_change_request?.status ===
                  'pending' ||
                userData?.institution?.limit_type_change_request?.status ===
                  'pending' ||
                !isAdmin
                  ? 'disabled'
                  : ''
              "
              class="radio-btn-container"
            >
              <div class="group">
                <mat-radio-button
                  value="zip_code"
                  (click)="onRadioChange('zip_code')"
                  [checked]="selectedLimitType === 'zip_code'"
                  >{{
                    copy?.access_and_zip_code_settings?.zip_code_header
                  }}</mat-radio-button
                >
                <img
                  class="geolock-icon"
                  src="../../../assets/imgs/geolock.svg"
                  alt=""
                />
              </div>
              <div class="radio-btn-sub-header">
                {{
                  replaceInstitutionText(
                    copy?.access_and_zip_code_settings?.zip_code_sub_header
                  )
                }}
              </div>
            </div>
            <button
              [disabled]="
                selectedLimitType === userData?.institution?.limit_type ||
                userData?.institution?.limit_type_change_request?.status ===
                  'pending'
              "
              (click)="showSubmitForApprovalModal()"
              class="blue save-btn"
              mat-button
            >
              {{ copy?.save_button_text }}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <input
      *ngIf="accessCodesData"
      class="input-container search-input"
      matInput
      [(ngModel)]="searchTerm"
      (ngModelChange)="filterAccessCodes()"
      placeholder="Search name?"
    />

    <div *ngIf="accessCodesData" class="access-codes-section">
      <table>
        <tr class="column-titles">
          <th class="access-code">{{ copy?.access_code }}</th>
          <th class="description">{{ copy?.description }}</th>
          <th class="use-count">{{ copy?.use_count }}</th>
          <th class="centered qr-code">{{ copy?.qr_code }}</th>
          <th class="centered actions">{{ copy?.actions }}</th>
        </tr>
        <tr *ngFor="let data of filteredCodes">
          <td class="access-code-name">
            <img
              *ngIf="
                !data?.zip_code_override &&
                userData?.institution?.limit_type === 'zip_code'
              "
              src="../../../assets/imgs/geolock.svg"
              alt=""
            />
            <img
              *ngIf="
                data?.zip_code_override &&
                userData?.institution?.limit_type === 'zip_code'
              "
              src="../../../assets/imgs/open-geolock.svg"
              alt=""
            />
            <div
              *ngIf="data?.zip_code_override"
              matTooltip="Join with this access code and ALL zip codes."
              [matTooltipDisabled]="
                checkTooltip(userData?.institution?.limit_type)
              "
              class="header-h5-bold-rounded text-color-header-1"
            >
              {{ data?.code }}
            </div>
            <div
              *ngIf="!data?.zip_code_override"
              matTooltip="Join with this access code and sponsored zip codes."
              [matTooltipDisabled]="
                checkTooltip(userData?.institution?.limit_type)
              "
              class="header-h5-bold-rounded text-color-header-1"
            >
              {{ data?.code }}
            </div>
          </td>
          <td class="description">
            <span *ngIf="data?.primary" class="primary-label">{{
              copy?.primary
            }}</span>
            <span
              *ngIf="
                userData?.institution?.access_code_change_request?.status ===
                  'pending' && data?.primary
              "
              class="pending-changes-label"
              >{{ copy?.pending_changes }}</span
            >
            <span
              *ngIf="!data?.primary"
              class="header-h5-bold-rounded text-color-header-1"
              >{{ data.description }}</span
            >
          </td>
          <td class="use-count">
            <span class="header-h5-bold-rounded text-color-header-1">{{
              data.use_count !== 0 ? data.use_count : '-'
            }}</span>
          </td>
          <td class="centered">
            <img
              class="qr-code-icon"
              (click)="downloadQRCode(data?.qr_code, data?.code)"
              src="../../../assets/imgs/download.svg"
              alt=""
            />
          </td>
          <td class="centered actions-cell">
            <img
              *ngIf="
                data?.primary &&
                userData?.institution?.access_code_change_request?.status !==
                  'pending' &&
                isAdmin &&
                data?.primary &&
                userData?.institution?.limit_type_change_request?.status !==
                  'pending' &&
                isAdmin
              "
              class="actions-icon"
              (click)="switchModes('edit')"
              src="../../../assets/imgs/actions.svg"
              alt=""
            />
            <img
              *ngIf="
                (data?.primary &&
                  userData?.institution?.access_code_change_request?.status ===
                    'pending') ||
                (data?.primary &&
                  userData?.institution?.limit_type_change_request?.status ===
                    'pending') ||
                (data?.primary && !isAdmin)
              "
              class="actions-icon disabled disabled-cursor"
              src="../../../assets/imgs/locked-edit-icon.svg"
              alt=""
            />
            <img
              *ngIf="!data?.primary && isAdmin"
              [ngClass]="!isAdmin ? 'disabled' : ''"
              class="actions-icon"
              (click)="editSecondary(data)"
              src="../../../assets/imgs/actions.svg"
              alt=""
            />
            <img
              *ngIf="!data?.primary && isAdmin"
              [ngClass]="!isAdmin ? 'disabled' : ''"
              class="actions-icon trash-icon"
              (click)="openDeleteAccessCodeModal(data?.id)"
              src="../../../assets/imgs/trash.svg"
              alt=""
            />
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!-- CREATE ACCESS CODE MODE -->
  <div *ngIf="mode === 'create'">
    <div class="page-header-flex-container">
      <div class="create-header-container">
        <img
          class="create-back-arrow"
          (click)="cancelCreateMode()"
          src="../../../assets/imgs/arrow-left.svg"
          alt=""
        />
        <h1 class="h3-bold">{{ copy?.create_access_code.header }}</h1>
      </div>
      <div class="btn-container">
        <div class="cancel-btn" (click)="cancelCreateMode()" mat-button>
          {{ copy?.cancel_button_text }}
        </div>
        <button
          [disabled]="isSecondarySubmitDisabled"
          (click)="createNewAccessCode()"
          class="blue"
          mat-button
        >
          Create
        </button>
      </div>
    </div>
    <div class="card">
      <markdown
        class="access-instructions-markdown"
        [data]="copy?.create_access_code?.sub_header"
      ></markdown>
      <div class="input-label text-color-header-1 header-h5-bold-rounded">
        Access code
      </div>
      <div class="create-access-code-input-section">
        <img
          *ngIf="userData?.institution?.limit_type === 'zip_code'"
          class="geolock-icon-input"
          src="../../../assets/imgs/geolock.svg"
          alt=""
        />
        <input
          class="input-container capitalize"
          matInput
          (blur)="accessCodeBlurred()"
          [(ngModel)]="accessCode"
          (ngModelChange)="validateSecondaryAccessCode()"
          placeholder="Rocket"
          [ngClass]="{
            'extra-left-padding':
              userData?.institution?.limit_type === 'zip_code'
          }"
        />
      </div>
      <p
        class="error-text"
        *ngIf="!this.validSecondaryAccessCode && hasAccessCodeBlurred"
      >
        {{ this.accessCodeErrorMessage }}
      </p>
      <div class="input-label text-color-header-1 header-h5-bold-rounded">
        Description name (optional)
      </div>
      <div class="create-access-code-input-section">
        <input
          class="input-container"
          matInput
          [(ngModel)]="description"
          placeholder="e.g. Rocket Bank, Best Buy, School name"
        />
      </div>
      <mat-checkbox
        *ngIf="userData?.institution?.limit_type === 'zip_code'"
        (change)="showAllowAllZipCodesCreateModal($event)"
        [(ngModel)]="allowAllZipCodesCheckedCreate"
        class="zip-code-checkbox"
        >{{ copy?.create_access_code?.allow_all_zip_codes_text }}</mat-checkbox
      >
    </div>
  </div>

  <!-- EDIT PRIMARY ACCESS CODE MODE -->
  <div *ngIf="mode === 'edit'">
    <div class="page-header-flex-container">
      <div class="create-header-container">
        <img
          class="create-back-arrow"
          (click)="cancelEditMode()"
          src="../../../assets/imgs/arrow-left.svg"
          alt=""
        />
        <h1 class="h3-bold">Edit access code</h1>
      </div>
      <div class="btn-container">
        <div class="cancel-btn" (click)="cancelEditMode()" mat-button>
          {{ copy?.cancel_button_text }}
        </div>
        <button
          [disabled]="isPrimarySubmitDisabled"
          (click)="editPrimaryAccessCode()"
          class="blue"
          mat-button
        >
          Save changes
        </button>
      </div>
    </div>
    <div class="card">
      <markdown
        class="access-instructions-markdown"
        [data]="copy?.create_access_code?.sub_header"
      ></markdown>
      <div class="create-access-code-input-section">
        <img
          *ngIf="userData?.institution?.limit_type === 'zip_code'"
          class="geolock-icon-input"
          src="../../../assets/imgs/geolock.svg"
          alt=""
        />
        <input
          class="input-container"
          [ngClass]="{
            'extra-left-padding':
              userData?.institution?.limit_type === 'zip_code'
          }"
          matInput
          (blur)="accessCodeBlurred()"
          [(ngModel)]="accessCodeInput"
          (ngModelChange)="validatePrimaryAccessCode()"
        />
      </div>
      <p
        class="error-text"
        *ngIf="!this.validPrimaryAccessCode && hasAccessCodeBlurred"
      >
        {{ this.accessCodeErrorMessage }}
      </p>
      <mat-checkbox
        *ngIf="userData?.institution?.limit_type === 'zip_code'"
        (change)="allowAllZipCodesEditChecked($event)"
        [(ngModel)]="allowAllZipCodesCheckedEdit"
        class="zip-code-checkbox"
        >{{ copy?.create_access_code?.allow_all_zip_codes_text }}</mat-checkbox
      >
    </div>
  </div>

  <!-- EDIT SECONDARY ACCESS CODE MODE -->
  <div *ngIf="mode === 'edit-secondary'">
    <div class="page-header-flex-container">
      <div class="create-header-container">
        <img
          class="create-back-arrow"
          (click)="cancelCreateMode()"
          src="../../../assets/imgs/arrow-left.svg"
          alt=""
        />
        <h1 class="h3-bold">Edit access code description</h1>
      </div>
      <div class="btn-container">
        <div class="cancel-btn" (click)="cancelCreateMode()" mat-button>
          {{ copy?.cancel_button_text }}
        </div>
        <button (click)="updateSecondary()" class="blue" mat-button>
          Save Changes
        </button>
      </div>
    </div>
    <div class="card">
      <markdown
        class="access-instructions-markdown"
        [data]="copy?.create_access_code?.sub_header"
      ></markdown>
      <div class="input-label text-color-header-1 header-h5-bold-rounded">
        Access code
      </div>
      <div class="create-access-code-input-section">
        <img
          *ngIf="userData?.institution?.limit_type === 'zip_code'"
          class="geolock-icon-input"
          src="../../../assets/imgs/geolock.svg"
          alt=""
        />
        <input
          class="input-container capitalize"
          matInput
          [(ngModel)]="accessCode"
          placeholder="Rocket"
          disabled="true"
          [ngClass]="{
            'extra-left-padding':
              userData?.institution?.limit_type === 'zip_code'
          }"
        />
      </div>
      <div class="input-label text-color-header-1 header-h5-bold-rounded">
        Description name (optional)
      </div>
      <div class="create-access-code-input-section">
        <input
          class="input-container"
          matInput
          [(ngModel)]="description"
          placeholder="e.g. Rocket Bank, Best Buy, School name"
        />
      </div>
    </div>
  </div>
</div>
