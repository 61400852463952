<span
  class="overline-medium zogo-chip"
  [class]="isSelected ? 'selected' : 'unselected'"
  [attr.aria-label]="ariaLabel"
  (click)="onSelectionChange()"
  (keydown.enter)="onSelectionChange()"
  (keydown.space)="onSelectionChange()"
  role="button"
  tappable
  tabindex="0"
  [ariaPressed]="isSelected"
>
  {{ label }}
</span>
