import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RequestsService } from 'src/app/requests.service';
import { Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-user-data-reports',
  templateUrl: './user-data-reports.component.html',
  styleUrls: ['./user-data-reports.component.scss'],
})
export class UserDataReportsComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  userDataReportsData: any;
  contactStartTime: any = null;
  contactEndTime: any = null;
  public expanded: boolean = true;

  constructor(public req: RequestsService) {}

  ngOnInit() {
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateUserDataReportsData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateUserDataReportsData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateUserDataReportsData();
      },
      error: err => {},
    });

    this.updateUserDataReportsData();
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateUserDataReportsData(shouldClearData = true) {
    if (shouldClearData) {
      this.userDataReportsData = null;
    }

    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.userDataReportsData = data;
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  requestReport() {
    this.req
      .getAnalyticsContact(
        null,
        this.contactStartTime,
        this.contactEndTime,
        this.platform
      )
      .subscribe({
        next: data => {
          // Amplitude event - User Data CSV Requested
          this.req.ampTrack('User Data CSV Requested', {
            platform_type: this.platform,
          });
          this.userDataReportsData.user_data_reports.unshift(data.data_report);
          this.req.openSnackBar(
            'You will receive an email when your user data report is ready',
            'Okay'
          );
        },
        error: err => {
          this.req.openSnackBar(
            `There was an error: ${err.error.message}`,
            'Okay'
          );
          console.log('Could not fetch contacts', err);
        },
      });
  }

  getReport(report_id) {
    this.req.getAnalyticsReport(report_id).subscribe({
      next: data => {
        console.log('should download report');
        // Amplitude event - User Data CSV Downloaded
        this.req.ampTrack('User Data CSV Downloaded', {
          platform_type: this.platform,
        });
        this.req.openURL(data.download_link);
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  setDate(timeframe, e) {
    if (timeframe === 'start') {
      if (e.value === null) {
        this.contactStartTime = null;
      } else {
        this.contactStartTime = new Date(e.value).toISOString().split('T')[0];
      }
    } else if (timeframe === 'end') {
      if (e.value === null) {
        this.contactEndTime = null;
      } else {
        this.contactEndTime = new Date(e.value).toISOString().split('T')[0];
      }
    }
    // this.updateTooltip();
    console.log(this.contactStartTime + ' - ' + this.contactEndTime);
  }

  toggleAccordian() {
    this.expanded = !this.expanded;
  }
}
