<!-- member display mode -->
<div class="dashboard-view d-flex-col gap-80">
  <h1 class="h3-bold">
    {{ copy?.leaderboard_header }}
  </h1>

  <div class="d-flex-col">
    <ion-spinner
      *ngIf="showSpinner"
      class="leaderboard-spinner"
      name="circular"
    ></ion-spinner>

    <div *ngIf="!showSpinner" class="d-flex-col gap-24">
      <!-- Community Leaderboard Section -->
      <ng-container
        *ngIf="leaderboards?.institution_leaderboard.length !== 0"
        class="d-flex-col gap-16"
      >
        <div
          tappable
          tabindex="0"
          class="table-header d-flex-row padding-y-8"
          [class.collapsed]="!expandCommunityLeaderboard"
          (click)="toggleLeaderboardDropdown('community')"
          (keydown.enter)="toggleLeaderboardDropdown('community')"
          (keydown.space)="toggleLeaderboardDropdown('community')"
        >
          <h2 class="h5-bold">
            {{ leaderboards?.institution_leaderboard.name }}
          </h2>
          <zogo-icon
            *ngIf="!expandCommunityLeaderboard"
            iconName="chevron-down"
          ></zogo-icon>
          <zogo-icon
            *ngIf="expandCommunityLeaderboard"
            iconName="chevron-up"
          ></zogo-icon>
        </div>

        <table *ngIf="expandCommunityLeaderboard">
          <thead>
            <tr>
              <th
                class="inst-name-col overline-bold text-align-left"
                scope="col"
              >
                {{ copy?.column_headers?.name }}
              </th>
              <th
                class="inst-modules-col overline-bold text-align-right"
                scope="col"
              >
                {{ copy?.column_headers?.modules_completed }}
              </th>
              <th
                class="inst-xp-col overline-bold text-align-right"
                scope="col"
              >
                {{ copy?.column_headers?.xp }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="data-row"
              *ngFor="
                let user of leaderboards?.institution_leaderboard.users
                  | slice : 0 : 3
              "
            >
              <td class="caption-bold d-flex-row gap-16">
                <div
                  class="rank-display background-brand-primary text-color-neutral-white"
                >
                  {{ user?.rank }}
                </div>
                {{ user?.name }}
              </td>
              <td class="caption-bold text-align-right">
                {{ user?.modules_completed }}
              </td>
              <td class="xp-count-col caption-bold">
                {{ user?.xp_count }}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                    fill="currentColor"
                  />
                </svg>
              </td>
            </tr>
            <tr
              class="data-row"
              *ngFor="
                let user of leaderboards?.institution_leaderboard.users
                  | slice : 3 : 10
              "
            >
              <td class="caption-bold d-flex-row gap-16">
                <div
                  class="rank-display background-neutral-400 text-color-neutral-white"
                >
                  {{ user?.rank }}
                </div>
                {{ user?.name }}
              </td>
              <td class="caption-bold text-align-right">
                {{ user?.modules_completed }}
              </td>
              <td class="xp-count-col caption-bold">
                {{ user?.xp_count }}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                    fill="currentColor"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>

        <zogo-btn
          *ngIf="
            leaderboards?.institution_leaderboard.num_of_users !== 0 &&
            expandCommunityLeaderboard
          "
          class="download-button"
          iconName="download"
          label="Download"
          buttonStyle="secondary"
          (clickFunction)="getInstitutionLeaderboardDownload()"
        ></zogo-btn>
      </ng-container>

      <div
        *ngIf="leaderboards?.institution_leaderboard.length === 0"
        class="empty-state background-neutral-100 d-flex-col gap-8 border-radius-12"
      >
        <zogo-icon
          class="text-color-neutral-600"
          iconName="bar-chart"
          size="large"
        ></zogo-icon>
        <div class="text-color-neutral-600 d-flex-col gap-4">
          <h3 class="h4-medium">No data yet</h3>
          <p class="body-large-book">
            Data for leaderboards will appear once there's activity.
          </p>
        </div>
      </div>

      <!-- Custom Competition Section -->
      <div
        tappable
        tabindex="0"
        class="table-header d-flex-row padding-y-8"
        [class.collapsed]="!expandCustomCompetitions"
        (click)="toggleLeaderboardDropdown('custom')"
        (keydown.enter)="toggleLeaderboardDropdown('custom')"
        (keydown.space)="toggleLeaderboardDropdown('custom')"
      >
        <h2 class="h5-bold">{{ copy?.custom_competitions_header }}</h2>
        <zogo-icon
          *ngIf="!expandCustomCompetitions"
          iconName="chevron-down"
        ></zogo-icon>
        <zogo-icon
          *ngIf="expandCustomCompetitions"
          iconName="chevron-up"
        ></zogo-icon>
      </div>

      <table
        *ngIf="
          leaderboards?.custom_leaderboards.length !== 0 &&
          expandCustomCompetitions
        "
      >
        <thead>
          <tr>
            <th
              class="competition-name-col body-bold text-align-left"
              scope="col"
            >
              {{ copy?.column_headers?.name }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.num_of_users }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.status }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.duration }}
            </th>
            <td class="competition-expansion-col" scope="col"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let leaderboard of leaderboards?.custom_leaderboards;
              let i = index
            "
          >
            <tr
              tappable
              tabindex="0"
              class="data-row"
              (click)="toggleCurrentLeaderboardRow(i)"
              (keydown.enter)="toggleCurrentLeaderboardRow(i)"
              (keydown.space)="toggleCurrentLeaderboardRow(i)"
            >
              <td
                class="body-medium"
                [class.row-expanded]="expandedRowIndex === i"
              >
                {{ leaderboard?.name }}
              </td>
              <td
                class="body-book"
                [class.row-expanded]="expandedRowIndex === i"
              >
                {{ leaderboard?.num_of_users }}
              </td>
              <td [class.row-expanded]="expandedRowIndex === i">
                <zogo-badge
                  [label]="leaderboard?.status"
                  [type]="
                    leaderboard?.status === 'active' ? 'success' : 'notice'
                  "
                ></zogo-badge>
              </td>
              <td
                class="duration-col caption-medium"
                [class.row-expanded]="expandedRowIndex === i"
              >
                <zogo-icon
                  iconName="calendar"
                  size="xsmall"
                  class="text-color-neutral-600"
                ></zogo-icon>
                {{ leaderboard?.duration }}
              </td>
              <td [class.row-expanded]="expandedRowIndex === i">
                <zogo-icon
                  *ngIf="expandedRowIndex !== i"
                  iconName="chevron-down"
                ></zogo-icon>
                <zogo-icon
                  *ngIf="expandedRowIndex === i"
                  iconName="chevron-up"
                ></zogo-icon>
              </td>
            </tr>

            <!-- Expanded Competition Data -->
            <tr *ngIf="expandedRowIndex === i">
              <td class="expanded-competition-data-container" colspan="5">
                <table class="expanded-competition-data">
                  <thead>
                    <tr>
                      <th
                        class="inst-name-col overline-bold text-align-left"
                        scope="col"
                      >
                        {{ copy?.column_headers?.name }}
                      </th>
                      <th
                        class="inst-modules-col overline-bold text-align-right"
                        scope="col"
                      >
                        {{ copy?.column_headers?.modules_completed }}
                      </th>
                      <th
                        class="inst-xp-col overline-bold text-align-right"
                        scope="col"
                      >
                        {{ copy?.column_headers?.xp }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-row"
                      *ngFor="let user of leaderboard?.users | slice : 0 : 3"
                    >
                      <td class="caption-bold d-flex-row gap-16">
                        <div
                          class="rank-display background-brand-primary text-color-neutral-white"
                        >
                          {{ user?.rank }}
                        </div>
                        {{ user?.name }}
                      </td>
                      <td class="caption-bold text-align-right">
                        {{ user?.modules_completed }}
                      </td>
                      <td class="xp-count-col caption-bold">
                        {{ user?.xp }}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                            fill="currentColor"
                          />
                        </svg>
                      </td>
                    </tr>
                    <tr
                      class="data-row"
                      *ngFor="let user of leaderboard?.users | slice : 3 : 10"
                    >
                      <td class="caption-bold d-flex-row gap-16">
                        <div
                          class="rank-display background-neutral-400 text-color-neutral-white"
                        >
                          {{ user?.rank }}
                        </div>
                        {{ user?.name }}
                      </td>
                      <td class="caption-bold text-align-right">
                        {{ user?.modules_completed }}
                      </td>
                      <td class="xp-count-col caption-bold">
                        {{ user?.xp }}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                            fill="currentColor"
                          />
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="expanded-download-button-container">
                  <zogo-btn
                    *ngIf="leaderboard?.num_of_users !== 0"
                    class="download-button"
                    iconName="download"
                    label="Download"
                    buttonStyle="secondary"
                    (clickFunction)="
                      getCustomLeaderboardDownload(leaderboard.id)
                    "
                  ></zogo-btn>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div
        *ngIf="
          leaderboards?.custom_leaderboards.length === 0 &&
          expandCustomCompetitions
        "
        class="empty-state background-neutral-100 d-flex-col gap-8 border-radius-12"
      >
        <zogo-icon
          class="text-color-neutral-600"
          iconName="bar-chart"
          size="large"
        ></zogo-icon>
        <div class="text-color-neutral-600 d-flex-col gap-4">
          <h3 class="h4-medium">No data yet</h3>
          <p class="body-large-book">
            Data for competitions will appear once there's activity.
          </p>
        </div>
      </div>

      <!-- Past Competitions Section -->
      <div
        tappable
        tabindex="0"
        class="table-header d-flex-row padding-y-8"
        [class.collapsed]="!expandPastCustomCompetitions"
        (click)="toggleLeaderboardDropdown('past')"
        (keydown.enter)="toggleLeaderboardDropdown('past')"
        (keydown.space)="toggleLeaderboardDropdown('past')"
      >
        <h2 class="h5-bold">{{ copy?.past_competitions_header }}</h2>
        <zogo-icon
          *ngIf="!expandPastCustomCompetitions"
          iconName="chevron-down"
        ></zogo-icon>
        <zogo-icon
          *ngIf="expandPastCustomCompetitions"
          iconName="chevron-up"
        ></zogo-icon>
      </div>

      <table
        *ngIf="
          leaderboards?.past_custom_leaderboards.length !== 0 &&
          expandPastCustomCompetitions
        "
      >
        <thead>
          <tr>
            <th
              class="competition-name-col body-bold text-align-left"
              scope="col"
            >
              {{ copy?.column_headers?.name }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.num_of_users }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.status }}
            </th>
            <th class="competition-col body-bold text-align-left" scope="col">
              {{ copy?.column_headers?.duration }}
            </th>
            <td class="competition-expansion-col" scope="col"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let leaderboard of leaderboards?.past_custom_leaderboards;
              let i = index
            "
          >
            <tr
              tappable
              tabindex="0"
              class="data-row"
              (click)="togglePastLeaderboardRow(i)"
              (keydown.enter)="toggleCurrentLeaderboardRow(i)"
              (keydown.space)="toggleCurrentLeaderboardRow(i)"
            >
              <td
                class="body-medium"
                [class.row-expanded]="pastExpandedRowIndex === i"
              >
                {{ leaderboard?.name }}
              </td>
              <td
                class="body-book"
                [class.row-expanded]="pastExpandedRowIndex === i"
              >
                {{ leaderboard?.num_of_users }}
              </td>
              <td [class.row-expanded]="pastExpandedRowIndex === i">
                <zogo-badge
                  [label]="leaderboard?.status"
                  type="neutral"
                ></zogo-badge>
              </td>
              <td
                class="duration-col caption-medium"
                [class.row-expanded]="pastExpandedRowIndex === i"
              >
                <zogo-icon
                  iconName="calendar"
                  size="xsmall"
                  class="text-color-neutral-600"
                ></zogo-icon>
                {{ leaderboard?.duration }}
              </td>
              <td [class.row-expanded]="pastExpandedRowIndex === i">
                <zogo-icon
                  *ngIf="pastExpandedRowIndex !== i"
                  iconName="chevron-down"
                ></zogo-icon>
                <zogo-icon
                  *ngIf="pastExpandedRowIndex === i"
                  iconName="chevron-up"
                ></zogo-icon>
              </td>
            </tr>

            <!-- Expanded Competition Data -->
            <tr *ngIf="pastExpandedRowIndex === i">
              <td class="expanded-competition-data-container" colspan="5">
                <table class="expanded-competition-data">
                  <thead>
                    <tr>
                      <th
                        class="inst-name-col overline-bold text-align-left"
                        scope="col"
                      >
                        {{ copy?.column_headers?.name }}
                      </th>
                      <th
                        class="inst-modules-col overline-bold text-align-right"
                        scope="col"
                      >
                        {{ copy?.column_headers?.modules_completed }}
                      </th>
                      <th
                        class="inst-xp-col overline-bold text-align-right"
                        scope="col"
                      >
                        {{ copy?.column_headers?.xp }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-row"
                      *ngFor="let user of leaderboard?.users | slice : 0 : 3"
                    >
                      <td class="caption-bold d-flex-row gap-16">
                        <div
                          class="rank-display background-brand-primary text-color-neutral-white"
                        >
                          {{ user?.rank }}
                        </div>
                        {{ user?.name }}
                      </td>
                      <td class="caption-bold text-align-right">
                        {{ user?.modules_completed }}
                      </td>
                      <td class="xp-count-col caption-bold">
                        {{ user?.xp }}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                            fill="currentColor"
                          />
                        </svg>
                      </td>
                    </tr>
                    <tr
                      class="data-row"
                      *ngFor="let user of leaderboard?.users | slice : 3 : 10"
                    >
                      <td class="caption-bold d-flex-row gap-16">
                        <div
                          class="rank-display background-neutral-400 text-color-neutral-white"
                        >
                          {{ user?.rank }}
                        </div>
                        {{ user?.name }}
                      </td>
                      <td class="caption-bold text-align-right">
                        {{ user?.modules_completed }}
                      </td>
                      <td class="xp-count-col caption-bold">
                        {{ user?.xp }}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2162 6.36796V8.04065H11.1433C11.7284 8.04065 12.0704 7.6811 12.0704 7.21212V7.19649C12.0704 6.65716 11.7056 6.36796 11.1205 6.36796H10.2162Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.88867 0.888916C2.67953 0.888916 0.888672 2.67978 0.888672 4.88892V11.1111C0.888672 13.3203 2.67954 15.1111 4.88867 15.1111H11.1109C13.32 15.1111 15.1109 13.3203 15.1109 11.1111V4.88891C15.1109 2.67978 13.32 0.888916 11.1109 0.888916L4.88867 0.888916ZM3.89408 10.7998C3.63571 10.7998 3.36214 10.5888 3.36214 10.2449C3.36214 10.0729 3.42293 9.9322 3.53692 9.78369L4.97315 7.9703L3.62051 6.27416C3.52932 6.15692 3.44573 6.00059 3.44573 5.82863C3.44573 5.49253 3.6889 5.23459 4.02326 5.23459C4.27403 5.23459 4.41842 5.3362 4.5628 5.53161L5.72546 7.12614L6.88052 5.56288C7.0401 5.35184 7.19208 5.23459 7.45045 5.23459C7.70882 5.23459 7.98239 5.44563 7.98239 5.78955C7.98239 5.96151 7.9216 6.1022 7.80761 6.25071L6.45497 7.95467L7.8836 9.76024C7.97479 9.87748 8.05838 10.0338 8.05838 10.2058C8.05838 10.5419 7.81521 10.7998 7.48085 10.7998C7.23008 10.7998 7.0857 10.6982 6.94131 10.5028L5.69506 8.82228L4.46401 10.4715C4.30443 10.6826 4.15245 10.7998 3.89408 10.7998ZM9.63109 10.7998C9.30433 10.7998 9.04597 10.5341 9.04597 10.198V5.88335C9.04597 5.54724 9.30433 5.28149 9.63109 5.28149H11.2193C12.4884 5.28149 13.2559 6.05531 13.2559 7.17304V7.18867C13.2559 8.45492 12.2984 9.11149 11.1053 9.11149H10.2162V10.198C10.2162 10.5341 9.95786 10.7998 9.63109 10.7998Z"
                            fill="currentColor"
                          />
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="expanded-download-button-container">
                  <zogo-btn
                    *ngIf="leaderboard?.num_of_users !== 0"
                    class="download-button"
                    iconName="download"
                    label="Download"
                    buttonStyle="secondary"
                    (clickFunction)="
                      getCustomLeaderboardDownload(leaderboard.id)
                    "
                  ></zogo-btn>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div
        *ngIf="
          leaderboards?.past_custom_leaderboards.length === 0 &&
          expandPastCustomCompetitions
        "
        class="empty-state background-neutral-100 d-flex-col gap-8 border-radius-12"
      >
        <zogo-icon
          class="text-color-neutral-600"
          iconName="bar-chart"
          size="large"
        ></zogo-icon>
        <div class="text-color-neutral-600 d-flex-col gap-4">
          <h3 class="h4-medium">No data yet</h3>
          <p class="body-large-book">
            Data for competitions will appear once there's activity.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
