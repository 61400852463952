<div *ngIf="!progressBarChartData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>

<div *ngIf="progressBarChartData" class="progress-bar-wrapper">
  <h4 class="h5-bold text-color-neutral-800">
    {{ this.widgetData.widget_display_name }}
  </h4>
  <div class="chart-container">
    <div
      class="chart-bar"
      *ngFor="let data of progressBarChartData.progress_bar_data; let i = index"
    >
      <div class="caption-medium text-color-neutral-700 bar-label">
        {{ data.item_name }}
      </div>
      <div class="bar-value-wrapper">
        <div class="bar-value-max"></div>

        <div
          class="bar-value"
          [ngClass]="{ 'has-popup': data.popup_header }"
          [style.width.%]="calculatePercentage(data.value)"
          (mouseenter)="showTooltip = i"
          (mouseleave)="showTooltip = null"
        ></div>
      </div>
      <div
        *ngIf="data.popup_header"
        class="tooltip"
        [ngClass]="{ hidden: showTooltip !== i }"
      >
        <div class="overline-bold tooltip-header">{{ data.popup_header }}</div>
        <ul>
          <li
            class="overline-medium"
            *ngFor="let popupItem of data.popup_items"
          >
            <span style="background-color: var(--neutral-500)"></span>

            {{ popupItem }}
          </li>
        </ul>
      </div>
      <div class="caption-medium text-color-neutral-800 bar-percentage">
        {{ data.value
        }}<span *ngIf="progressBarChartData.value_display_type === 'percent'"
          >%</span
        >
      </div>
    </div>
  </div>
  <div
    class="data-platform overline-medium text-color-neutral-600"
    *ngIf="progressBarChartData.data_platform_text"
  >
    {{ progressBarChartData.data_platform_text }}
  </div>
</div>
