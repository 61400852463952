import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-metric-table',
  templateUrl: './metric-table.component.html',
  styleUrls: ['./metric-table.component.css'],
})
export class MetricTableComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  tableData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  errorLoading: boolean = false;

  constructor(private req: RequestsService) {}
  ngOnInit() {
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateTableData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateTableData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateTableData();
      },
      error: err => {},
    });

    this.updateTableData();
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateTableData() {
    this.tableData = null;
    this.errorLoading = false;
    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.tableData = data;
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }
}
