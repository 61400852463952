import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';

@Component({
  selector: 'app-custom-module-modal',
  templateUrl: './custom-module-modal.component.html',
  styleUrls: ['./custom-module-modal.component.css'],
})
export class CustomModuleModalComponent implements OnInit {
  headerText: string;
  bodyText: string;
  cancelBtnText: string;
  confirmBtnText: string;
  dropdownPlaceholderText: string;
  availableCustomModules: any;
  confirm: any;
  cancel: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.headerText = this.data.headerText;
    this.bodyText = this.data.bodyText;
    this.cancelBtnText = this.data.cancelBtnText;
    this.confirmBtnText = this.data.confirmBtnText;
    this.dropdownPlaceholderText = this.data.dropdownPlaceholderText;
    this.confirm = this.data.confirmFunct;
    this.cancel = this.data.cancelFunct;
    this.availableCustomModules = this.data.availableCustomModules;
  }
}
