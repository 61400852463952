<div
  class="widget-group-wrapper"
  *ngFor="let widgetGroup of tabData.widget_groups"
>
  <div *ngIf="widgetGroup.source === 'number-cards'">
    <div *ngIf="!metricCardData && !errorLoading">
      <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
    </div>
    <div *ngIf="metricCardData" class="number-cards-wrapper">
      <app-metric-card
        *ngFor="let widget of metricCardData.widgets"
        [metricCardData]="widget"
        class="number-card"
      >
      </app-metric-card>
    </div>
  </div>
  <div
    *ngIf="hasWidgetTypes(widgetGroup, ['user_demographics', 'goals'])"
    class="demographics-goals-wrapper"
  >
    <app-user-demographics-chart
      *ngIf="hasWidgetTypes(widgetGroup, ['user_demographics'])"
      [widgetData]="findWidget(widgetGroup, 'user_demographics')"
    ></app-user-demographics-chart>
    <app-goals-created-chart
      *ngIf="hasWidgetTypes(widgetGroup, ['goals'])"
      [widgetData]="findWidget(widgetGroup, 'goals')"
    ></app-goals-created-chart>
  </div>
  <div
    class="progress-bar-chart-wrapper"
    *ngIf="hasWidgetTypes(widgetGroup, ['progress_bars'])"
  >
    <app-progress-bar-chart
      *ngFor="let widget of widgetGroup.widgets; let i = index"
      [widgetData]="widget"
    ></app-progress-bar-chart>
  </div>
  <div
    *ngIf="hasWidgetTypes(widgetGroup, ['table'])"
    class="metric-table-wrapper"
  >
    <app-metric-table
      *ngFor="let widget of widgetGroup.widgets"
      [widgetData]="widget"
    ></app-metric-table>
  </div>
  <div
    *ngIf="hasWidgetTypes(widgetGroup, ['link_display'])"
    class="link-display-wrapper"
  >
    <app-link-display
      *ngFor="let widget of widgetGroup.widgets"
      [linkDisplayData]="widget"
    ></app-link-display>
  </div>
  <div
    *ngIf="hasWidgetTypes(widgetGroup, ['survey_results'])"
    class="survey-display-wrapper"
  >
    <app-survey-display
      *ngFor="let widget of widgetGroup.widgets"
      [widgetData]="widget"
    ></app-survey-display>
  </div>
  <div
    *ngIf="
      !req.isShareView && hasWidgetTypes(widgetGroup, ['user_data_reports'])
    "
    class="user-data-reports-wrapper"
  >
    <app-user-data-reports
      *ngFor="let widget of widgetGroup.widgets"
      [widgetData]="widget"
    ></app-user-data-reports>
  </div>
  <div
    *ngIf="!req.isShareView && hasWidgetTypes(widgetGroup, ['user_messages'])"
    class="user-messages-wrapper"
  >
    <app-user-messages
      *ngFor="let widget of widgetGroup.widgets"
      [widgetData]="widget"
    ></app-user-messages>
  </div>
  <div
    *ngIf="hasWidgetTypes(widgetGroup, ['geckoboard'])"
    class="geckoboard-wrapper"
  >
    <app-geckoboard-display
      *ngFor="let widget of widgetGroup.widgets"
      [geckoboardDisplayData]="widget"
    ></app-geckoboard-display>
  </div>
</div>
