import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.css'],
})
export class MetricCardComponent implements OnInit {
  @Input() metricCardData: any;

  constructor() {}

  ngOnInit(): void {}
}
