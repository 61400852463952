import { CanActivateFn, Route } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const allowedNav = userData.institution_user?.nav_bar;

  // provides the route configuration options.
  const { routeConfig } = route;

  // provides the path of the route.
  const { path } = routeConfig as Route;

  if (path?.includes('overview') && !allowedNav.includes('overview')) {
    return false;
  }

  if (path?.includes('analytics') && !allowedNav.includes('product_usage')) {
    return false;
  }

  if (
    path?.includes('leaderboard-download') &&
    !allowedNav.includes('leaderboards')
  ) {
    return false;
  }

  if (path?.includes('modules') && !allowedNav.includes('custom_modules')) {
    return false;
  }

  if (path?.includes('custom-color') && !allowedNav.includes('custom_color')) {
    return false;
  }

  if (
    path?.includes('content-library') &&
    !allowedNav.includes('content_library')
  ) {
    return false;
  }

  if (path?.includes('resources') && !allowedNav.includes('resources')) {
    return false;
  }

  if (path?.includes('my-account') && !allowedNav.includes('my_account')) {
    return false;
  }

  if (path?.includes('payments') && !allowedNav.includes('payments')) {
    return false;
  }

  if (path?.includes('team-members') && !allowedNav.includes('team_members')) {
    return false;
  }

  if (path?.includes('access-codes') && !allowedNav.includes('access_codes')) {
    return false;
  }

  if (path?.includes('contact-us') && !allowedNav.includes('contact_us')) {
    return false;
  }

  if (
    path?.includes('marketing-assets') &&
    !allowedNav.includes('marketing_assets')
  ) {
    return false;
  }

  if (path?.includes('overview') && !allowedNav.includes('overview')) {
    return false;
  }

  if (
    path?.includes('pre-post-test') &&
    !allowedNav.includes('pre_post_test')
  ) {
    return false;
  }

  if (
    path?.includes('teacher-admins') &&
    !allowedNav.includes('teacher_admins')
  ) {
    return false;
  }

  if (
    path?.includes('web-customizations') &&
    !allowedNav.includes('zogo_web_customizations')
  ) {
    return false;
  }

  return true;
};
