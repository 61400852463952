<div class="d-flex-col gap-4">
  <span class="caption-medium">{{ label }}</span>
  <div
    tappable
    tabindex="0"
    class="dropdown-container"
    [class.focused]="focused"
    [ngClass]="{
      shadow: helper.isMobile()
    }"
    (click)="toggleDropdown()"
    (keydown.enter)="toggleDropdown()"
    (keydown.space)="toggleDropdown()"
  >
    <div class="dropdown-header">
      <span
        class="body-book text-color-neutral-500"
        [ngClass]="{ 'text-color-neutral-800': selectedOption }"
        >{{ selectedOption || placeholder }}</span
      >
      <zogo-icon
        *ngIf="!isOpen"
        class="dropdown-icon text-color-neutral-800"
        size="small"
        iconName="chevron-down"
      ></zogo-icon>
      <zogo-icon
        *ngIf="isOpen"
        class="dropdown-icon text-color-neutral-800"
        size="small"
        iconName="chevron-up"
      ></zogo-icon>
    </div>
    <ul class="dropdown-list margin-top-2" *ngIf="isOpen">
      <li
        class="caption-medium"
        *ngFor="let option of options"
        (click)="selectOption(option)"
        [class.selected]="option === selectedOption"
      >
        {{ option }}
      </li>
    </ul>
  </div>

  <zogo-text-input
    *ngIf="showOtherInput"
    [(ngModel)]="otherValue"
    name="otherValue"
    inputId="other-input"
    inputLabel="Other (Please Specify)"
    formType="inlineInput"
    placeholder="Please Specify"
    [currentValue]="otherValue"
    (valueChange)="updateOtherValue()"
    (blurEvent)="handleBlur()"
  ></zogo-text-input>
</div>
