import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RequestsService } from '../../../../requests.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class QuestionsComponent implements OnInit {
  changed: any = {};
  question: any = {};
  ansOption: string = '';
  is_survey: boolean = false;
  userData: any;
  language: string; // english | spanish

  constructor(
    public req: RequestsService,
    public dialogRef: MatDialogRef<QuestionsComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.language = this.data.language;
    // Get the mat-dialog-container element
    const dialogContainer = document.querySelector(
      '.mat-dialog-container'
    ) as HTMLElement;

    // Check if the mat-dialog-container element exists
    if (dialogContainer) {
      // Set the padding to 0px
      dialogContainer.style.padding = '0px';
    }
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.data) {
      this.changed = JSON.parse(JSON.stringify(this.data.question));
      this.question = this.data.question;
      if (!this.changed.answers) {
        this.changed.answers = [];
      }

      if (this.changed.answers) {
        for (let i = 0; i < this.changed.answers.length; i++) {
          if (this.changed.answers[i].is_correct === true) {
            this.ansOption = this.changed.answers[i].answer;
          }
        }
      }
      if (this.changed.content_type_value === 'Survey') {
        this.is_survey = true;
      }
    }
  }

  dropQuestion(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.changed.answers,
      event.previousIndex,
      event.currentIndex
    );
  }

  handleCloseButton() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        closeWithoutSaving: this.close.bind(this),
      },
    });
  }

  close() {
    this.question.cancel = true;
    this.dialogRef.close({
      question: this.question,
      cancel: true,
      isNew: this.data.isNew,
    });
  }

  save() {
    this.dialogRef.close({ question: this.changed });
  }

  addOption() {
    if (this.changed.answers.length > 5) {
      this.req.openSnackBar('Questions can only have 6 options', 'Okay');
      return;
    }

    const option = {
      is_correct: false,
      answer: {
        'en-US':
          'Answer choice #' + parseInt(this.changed?.answers?.length + 1),
        'es-US': null,
      },
    };

    this.changed.answers.push(option);
  }

  setNewAns(ans) {
    for (let i = 0; i < this.changed?.answers.length; i++) {
      if (this.changed.answers[i].is_correct) {
        this.changed.answers[i].is_correct = false;
      }
    }
    this.ansOption = ans.answer;
    ans.is_correct = true;
  }

  //Delete individual option
  removeItem(index) {
    this.changed?.answers.splice(index, 1);
  }

  //Delete entire question
  deleteItem() {
    this.dialogRef.close({ question: this.changed, delete: true });
  }

  surveyStatusChanged() {
    this.changed.is_survey = this.is_survey;
    if (this.is_survey) {
      this.changed.content_type_value = 'Survey';
    }
  }

  validateInputs() {
    if (
      this.changed?.content_title?.['en-Us']?.length < 1 ||
      this.changed?.content_title?.['en-Us']?.length > 125
    ) {
      return false;
    }
    if (
      this.changed?.content?.['en-Us']?.length < 1 ||
      this.changed?.content?.['en-Us']?.length > 250
    ) {
      return false;
    }
    if (this.changed?.answers?.length < 2) {
      return false;
    }
    for (const answer of this.changed.answers) {
      if (answer.answer['en-US'].length < 1) {
        return false;
      }
    }
    return true;
  }

  handleQuestionTitleInput(input: string) {
    if (this.language === 'english') {
      this.changed.content_title['en-US'] = input;
    } else {
      this.changed.content_title['es-US'] = input;
    }
  }

  handleQuestionDescriptionInput(input: string) {
    if (this.language === 'english') {
      this.changed.content_description['en-US'] = input;
    } else {
      this.changed.content_description['es-US'] = input;
    }
  }

  handleQuestionExplanationInput(input: string) {
    if (this.language === 'english') {
      this.changed.content['en-US'] = input;
    } else {
      this.changed.content['es-US'] = input;
    }
  }

  handleAnswerInput(input: string, index) {
    if (this.language === 'english') {
      this.changed.answers[index].answer['en-US'] = input;
    } else {
      this.changed.answers[index].answer['es-US'] = input;
    }
  }
}
