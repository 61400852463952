<div class="field-text-container">
  <label
    for="{{ inputId }}"
    class="body-medium text-color-neutral-800 input-title"
  >
    {{ inputTitle }}
    <span class="required-asterisk" *ngIf="isRequired">*</span>
  </label>
  <div
    *ngIf="preview?.length > 0"
    class="overline-medium text-color-neutral-600"
  >
    {{ preview }}
  </div>
  <textarea
    id="{{ inputId }}"
    [name]="inputTitle"
    [placeholder]="placeholder"
    class="field-text body-medium text-color-neutral-600"
    [ngClass]="{
      'field-text-editable': isEditable,
      'field-text-resizable': isResizable
    }"
    [(ngModel)]="currentValue"
    (ngModelChange)="onInput($event)"
    (blur)="onBlur()"
    [maxlength]="disableMaxLength ? 'none' : maxLength"
    [disabled]="!isEditable"
    [required]="isRequired"
    [wrap]="wrap"
    [attr.aria-label]="ariaLabel"
    [rows]="rows"
  ></textarea>
  <div *ngIf="!disableMaxLength" class="character-count">
    <span
      class="caption-bold"
      [ngClass]="{
        'text-color-neutral-400': currentValue.length < maxLength,
        'text-color-shades-orange-1': currentValue.length === maxLength
      }"
      *ngIf="isEditable"
    >
      {{ currentValue.length }} / {{ maxLength }}
    </span>
  </div>
</div>
