import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'zogo-checkbox',
  templateUrl: './zogo-checkbox.component.html',
  styleUrls: ['./zogo-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZogoCheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class ZogoCheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() terms: boolean = false;
  @Input() label: string = '';
  @Input() initialValue: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();

  isChecked: boolean = false;
  isDisabled: boolean = false;

  ngOnInit(): void {
    if (this.initialValue) {
      this.isChecked = this.initialValue;
    }
  }

  private onChange = (checked: boolean) => {
    this.valueChange.emit(checked);
  };
  private onTouched = () => {};

  toggleChecked() {
    if (!this.isDisabled) {
      this.isChecked = !this.isChecked;
      this.onChange(this.isChecked);
      this.onTouched();
    }
  }

  getCheckboxClass() {
    const classes = {
      unchecked: false,
      disabled: false,
    };
    if (!this.isChecked && !this.isDisabled) {
      classes['unchecked'] = true;
    } else if (this.isDisabled) {
      classes['disabled'] = true;
    }
    return classes;
  }

  writeValue(checked: boolean): void {
    this.isChecked = checked;
  }

  registerOnChange(fn: (checked: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
