import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  ValidationErrors,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import { ZogoRadioButtonComponent } from './zogo-radio-button/zogo-radio-button.component';

@Component({
  selector: 'zogo-radio-group',
  templateUrl: './zogo-radio-group.component.html',
  styleUrls: ['./zogo-radio-group.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZogoRadioGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ZogoRadioGroupComponent),
      multi: true,
    },
  ],
})
export class ZogoRadioGroupComponent
  implements AfterContentInit, ControlValueAccessor
{
  @Input() initialValue: string;
  @Output() valueChange = new EventEmitter<string>();
  @ContentChildren(ZogoRadioButtonComponent)
  radios!: QueryList<ZogoRadioButtonComponent>;
  constructor() {}

  private onChange = (value: any) => {};
  private onTouched = () => {};

  ngAfterContentInit() {
    this.radios.forEach(radio => {
      radio.group = this;
      if (radio.value === this.initialValue) {
        radio.isSelected = true;
      }
    });
  }

  updateValue(selectedRadio: ZogoRadioButtonComponent) {
    this.radios.forEach(radio => {
      radio.isSelected = radio === selectedRadio;
      radio.onChange(radio.isSelected ? radio.value : null);
    });
    this.valueChange.emit(selectedRadio.value);
    this.onChange(selectedRadio.value);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.initialValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    if (!this.radios) {
      return null;
    }

    const isSelected = this.radios.some(radio => radio.isSelected);
    return isSelected ? null : { required: true };
  }
}
