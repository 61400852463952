import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CopyService } from 'src/app/copy.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { RequestsService } from 'src/app/requests.service';
import { AddTeacherModalComponent } from './add-teacher-modal/add-teacher-modal.component';
import { AlertModalComponent } from 'src/app/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-teacher-admin',
  templateUrl: './teacher-admin.component.html',
  styleUrls: ['./teacher-admin.component.scss'],
})
export class TeacherAdminComponent implements OnInit {
  userData: any;
  teachers: any;
  hasTeachers: boolean = false;
  isLoading: boolean;
  selectedTitle: any;
  editedTeacher: any;
  dropdownIndex: number | null = null;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    // Amplitude event - Teacher Admins Viewed
    this.req.ampTrack('Teacher Admins Viewed');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.isLoading = true;
    this.req.getDashboardTeachers().subscribe({
      next: data => {
        this.teachers = data;
        if (this.teachers.length > 0) {
          this.hasTeachers = true;
        } else {
          this.hasTeachers = false;
        }
        this.isLoading = false;
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  async addTeacher() {
    const componentProps = {
      mode: 'invite',
      headerText: 'Invite a teacher',
      firstName: '',
      lastName: '',
      email: '',
      title: '',
    };

    const modal = await this.modalController.create({
      component: AddTeacherModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss().then(() => {
      this.isLoading = true;
      this.hasTeachers = true;
      this.req.getDashboardTeachers().subscribe({
        next: data => {
          this.teachers = data;
          this.isLoading = false;
        },
        error: err => {
          console.log(err);
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
        },
      });
    });
  }

  async editTeacher(teacher) {
    const componentProps = {
      mode: 'edit',
      teacherId: teacher.id,
      headerText: 'Edit teacher',
      firstName: teacher.first_name,
      lastName: teacher.last_name,
      email: teacher.email,
      title: teacher.title,
    };

    const modal = await this.modalController.create({
      component: AddTeacherModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss().then(() => {
      this.isLoading = true;
      this.req.getDashboardTeachers().subscribe({
        next: data => {
          this.teachers = data;
          this.isLoading = false;
        },
        error: err => {
          console.log(err);
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
        },
      });
    });
  }

  getName(teacher) {
    if (teacher.title && teacher.first_name && teacher.last_name) {
      return `${teacher.title} ${teacher.first_name} ${teacher.last_name}`;
    }
    return '-';
  }

  resendInvitation(teacher) {
    this.req.postDashboardTeacherInvite(teacher.email).subscribe({
      next: data => {
        // Amplitude event - Teacher Invitation Resent
        this.req.ampTrack('Teacher Invitation Resent');
        this.toastService.showToast('Invitation resent.');
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  async showConfirmationAlert(teacher) {
    const componentProps = {
      header: 'Are you sure?',
      description:
        'Removing a teacher cannot be undone. Any classroom data will be removed as well.',
      confirmationText: 'I understand',
      primaryButtonText: 'Remove Teacher',
      primaryButtonFunc: this.removeTeacher.bind(this, teacher.id),
      secondaryButtonText: "No, don't remove",
      secondaryButtonFunc: () => modal.dismiss(),
    };

    const modal = await this.modalController.create({
      component: AlertModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss().then(() => {
      this.req.getDashboardTeachers().subscribe({
        next: data => {
          this.teachers = data;
        },
        error: err => {
          console.log(err);
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
        },
      });
    });
  }

  removeTeacher(teacherId) {
    this.req.deleteDashboardTeacher(teacherId).subscribe({
      next: data => {
        // Amplitude event - Teacher Deleted
        this.req.ampTrack('Teacher Deleted');
        this.modalController.dismiss();

        this.toastService.showToast('Teacher removed.');
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  resetPassword(user) {
    this.req.postDashboardTeacherPasswordReset(user.id).subscribe({
      next: data => {
        // Amplitude event - Teacher Password Reset Link Sent
        this.req.ampTrack('Teacher Password Reset Link Sent');
        this.toastService.showToast(
          this.appCopy.getTeacherAdmins().teacher_password_reset_success,
          6000
        );
      },
      error: err => {},
    });
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const clickedInside = element.closest('.teacher-action-cta');

    if (!clickedInside) {
      this.dropdownIndex = null;
    }
  }

  toggleDropdown(index: number) {
    this.dropdownIndex = this.dropdownIndex === index ? null : index;
  }
}
