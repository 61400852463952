<div class="login-frame background-neutral-100">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pt-3 pr-3 pb-4">
        <div class="card p-4 mt-5">
          <div class="zogo-logo">
            <img src="global/logos/zogo-blue.svg" alt="" />
          </div>
          <div class="error-text">
            Something went wrong.<br />
            <div class="error-sub-text">Please try again.</div>
          </div>
          <button
            [disabled]=""
            (click)="retryAppInit()"
            class="blue"
            mat-button
          >
            <span>Try Again</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
