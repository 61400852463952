import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as amplitude from '@amplitude/analytics-browser';
import { buildConfig } from './build-config';

// Extend the global Window interface
declare global {
  interface Window {
    csTrack: CSTrack; // Declare the csTrack object on the Window interface
  }
}

// Replace CSTrack with the appropriate type if you have it, or use `any` temporarily
type CSTrack = any;

declare const CSTrack: any;

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  userData: Subject<string> = new Subject<string>();

  port: number = 8081;
  version: number = 93;
  whitelabel: string = 'zogo';
  env: string = buildConfig.environment; //buildConfig.environment; // development|| production
  token: any;
  moduleEditor: any;
  baseURL: string =
    'https://api.zogofinance.com/' + this.env + '/v' + this.version;
  // TEMP Base URL for testing with local backend
  // baseURL: string = 'http://localhost:8082';
  onboardingFlow: any;
  remainingOnboardingFlow: any;
  curOnboardingFlowIndex: number;
  routerURL: string = '';
  contactUsMode: string; // menu || schedule-meeting || support-form || support-ticket-success
  introModuleInfo: any;
  overviewPlatform: Subject<string> = new Subject<string>();
  overviewPlatformStore: string;
  overviewTimeFrame: Subject<string> = new Subject<string>();
  overviewTimeFrameStore: string;
  overviewCommunitySegment: Subject<any> = new Subject<any>();
  overviewCommunitySegmentStore: any;
  isShareView: boolean = false;
  unsubscribeOveriew: Subject<any> = new Subject<any>();
  uniqueIDIncrement: number = 0;

  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public router: Router
  ) {}

  checkToken() {
    this.token = localStorage.getItem('token');
  }

  storeToken(data) {
    localStorage.setItem('token', data);
    this.token = data;
  }

  getProductDisplayName(product) {
    switch (product) {
      case 'native':
        return 'Standalone App';
      case 'web':
        return 'Zogo Classroom';
      case 'sandbox':
        return 'Zogo Integration';
      case 'ncr':
        return 'NCR';
      case 'q2':
        return 'Mobile Banking';
      case 'banno':
        return 'Banno';
      case 'enterprise-web':
        return 'Zogo Web';
      default:
        console.log(`${product} is not a valid product type`);
    }
  }

  getUniqueID() {
    this.uniqueIDIncrement++;
    return Date.now() + ':' + this.uniqueIDIncrement;
  }

  hexToRgbOpacity(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  initializeAnalytics() {
    let standalone_amplitude_api_key = 'fa7d0d249ad8d39d877f0689139bdcff';
    if (this.env !== 'production') {
      standalone_amplitude_api_key = '85578c1b62f535562d2e07696a10e93f';
    }
    //initialize amplitude
    amplitude.init(standalone_amplitude_api_key, {
      defaultTracking: false,
    });
    amplitude.setSessionId(Date.now());
  }

  setUserId(id) {
    if (id) {
      amplitude.setUserId(id);
    }
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData.institution_user.analytics_tracker) {
      this.compileTrackMetric(userData.institution_user.analytics_tracker);
    }

    this.initializeClientSuccessTracking(userData);
  }

  initializeClientSuccessTracking(userData) {
    const CLIENT_SUCCESS_PROJECT_ID = '55bc44392458414b8642bb042a7f1f74';
    const CLIENT_SUCCESS_API_KEY =
      '1435ea93b33a482eb938e06dd70661416782f5f4b62548d79192ec5154bafee12a9b9ef182034fa08912bfb759dcc3f38171e27712fe4e868f53b33fd8ac5a4800c1612c0c5741cc97d7e31815f6d859f57b8a1f21744f17a64fcda33eb061109f0e0057d7cf43ada0ad83654a6790e0';

    window.csTrack = new CSTrack(
      CLIENT_SUCCESS_PROJECT_ID,
      CLIENT_SUCCESS_API_KEY
    );
    // Add identification
    if (userData.institution && userData.institution.id !== 100) {
      window.csTrack.identify({
        organization: {
          id: userData.institution.id, // REQUIRED     A unique identifier for the organization (may be string or number)
          name: userData.institution.short_name, // REQUIRED     A human readable name for the organization.
        },
      });
    }
  }

  csTrack(eventName) {
    // no tracking for share links
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (this.isShareView || userData.institution.id === 100) {
      return;
    }

    if (!userData.institution_user.email.includes('zogofinance.com')) {
      window.csTrack.trackEvent(eventName);
    }
  }

  ampTrack(action, amplitudeProps = null, userProps = []) {
    // no tracking for share links
    if (this.isShareView) {
      return;
    }
    // userProps is an array of objects for each user property [{ name: 'Age', value: 25 }]
    if (userProps.length) {
      const identifyEvent = new amplitude.Identify();
      for (let i = 0; i < userProps.length; i++) {
        if (userProps[i].value) {
          identifyEvent.set(userProps[i].name, userProps[i].value);
        }
      }
      amplitude.identify(identifyEvent);
    }
    if (amplitudeProps) {
      amplitude.track(action, amplitudeProps);
    } else {
      amplitude.track(action);
    }
  }

  compileTrackMetric(data) {
    const analyticsDictionary = {};
    const trackers = data;
    const identifyEvent = new amplitude.Identify();
    for (let i = 0; i < trackers.length; i++) {
      console.log(
        'Analytics tracked: dimension: ' +
          trackers[i].name +
          '; value: ' +
          trackers[i].value
      );
      if (trackers[i].value !== null) {
        identifyEvent.set(trackers[i].name, trackers[i].value);
      }
      analyticsDictionary[trackers[i].name] = trackers[i].value;
    }
    amplitude.identify(identifyEvent);
  }

  dashboardCredential(email, pw): Observable<any> {
    const body = {
      email: email,
      password: pw,
    };
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/credential', body, {
      headers: headers,
    });
  }

  dashboardForgotPassword(email): Observable<any> {
    const body = {
      email: email,
    };
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(
      this.baseURL + '/dashboard/reset-password-invite',
      body,
      {
        headers: headers,
      }
    );
  }

  storeIntroModuleInfo(data) {
    this.introModuleInfo = data;
  }

  getIntroModuleInfo() {
    return this.introModuleInfo;
  }

  updateOverviewPlatform(platform) {
    this.unsubscribeOveriew.next('stop');
    this.overviewPlatformStore = platform;
    this.overviewPlatform.next(platform);
  }

  updateOverviewCommunitySegment(segment) {
    this.unsubscribeOveriew.next('stop');
    this.overviewCommunitySegmentStore = segment;
    this.overviewCommunitySegment.next(segment);
  }

  updateOverviewTimeFrame(timeFrame) {
    this.unsubscribeOveriew.next('stop');
    this.overviewTimeFrameStore = timeFrame;
    this.overviewTimeFrame.next(timeFrame);
  }

  dashboardDemo(): Observable<any> {
    const body = {
      email: 'demo@zogofinance.com',
      password: 'demo',
    };
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/demo', body, {
      headers: headers,
    });
  }

  dashboardPublic(): Observable<any> {
    const body = {
      email: 'demo@zogofinance.com',
      password: 'demo',
    };
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/public', body, {
      headers: headers,
    });
  }

  dashboardLogin(): Observable<any> {
    this.checkToken();
    const body = {};
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/login', body, {
      headers: headers,
    });
  }

  getOverview(timeframe, code = null): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    if (code) {
      return this.http.get(
        this.baseURL +
          `/dashboard/overview?timeframe=${timeframe}&code=${code}`,
        {
          headers: headers,
        }
      );
    }
    return this.http.get(
      this.baseURL + `/dashboard/overview?timeframe=${timeframe}`,
      {
        headers: headers,
      }
    );
  }

  getAnalyticsContact(
    download_param,
    start?: any,
    end?: any,
    platform?: string
  ): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    start = start || '';
    end = end || '';
    platform = platform || 'native';

    let requestUrl = `/dashboard/analytics/contact?platform=${platform}`;
    if (download_param) {
      requestUrl += `&filter=${download_param}`;
    }
    if (start) {
      requestUrl += `&start_date=${start}`;
    }
    if (end) {
      requestUrl += `&end_date=${end}`;
    }
    return this.http.get(this.baseURL + requestUrl, {
      headers: headers,
    });
  }

  getDashboardModule(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/module', {
      headers: headers,
    });
  }

  getDashboardInbox(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/inbox', {
      headers: headers,
    });
  }

  markAsRead(id) {
    const body = {
      id,
      is_read: true,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/message-status', body, {
      headers: headers,
    });
  }

  logout() {
    // Amplitude event - Logged Out
    this.ampTrack('Logged Out');
    localStorage.removeItem('token');
    localStorage.clear();
    this.token = null;
    window.location.href = '/';
  }

  logOutNoRedirect() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.token = null;
  }

  navigate(link) {
    this.router.navigate([link]);
  }

  moduleToEdit(element) {
    this.moduleEditor = element;
  }

  getModuleToEdit() {
    return this.moduleEditor;
  }

  setContactUsPageMode(mode) {
    this.contactUsMode = mode;
  }

  getContactUsPageMode() {
    return this.contactUsMode;
  }

  putDashboardModuleContent(content, spanish_enabled): Observable<any> {
    const body = {
      content_id: content.content_id || null,
      index: content.index || 1,
      content_type_value: content.content_type_value || null,
      content_title: content.content_title || 'Untitled content',
      content: content.content || null,
      content_description: content.content_description || null,
      answers: content.answers || null,
      spanish_enabled,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/module/content', body, {
      headers: headers,
    });
  }

  putDashboardModuleIntroOrder(order): Observable<any> {
    const body = {
      module_ids: order,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/module/intro-order', body, {
      headers: headers,
    });
  }

  putDashboardContentLibraryAutoEnableNewContent(newState): Observable<any> {
    const body = {
      auto_enable_new_content: newState,
    };
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(
      this.baseURL + '/dashboard/content-library/auto-enable-new-content',
      body,
      {
        headers: headers,
      }
    );
  }

  postDashboardModuleContent(
    module_id,
    content_type_value,
    spanish_enabled
  ): Observable<any> {
    const body = {
      module_id: module_id,
      content_type_value: content_type_value,
      spanish_enabled,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/module/content', body, {
      headers: headers,
    });
  }

  postDashboardModule(): Observable<any> {
    const body = {};
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/module', body, {
      headers: headers,
    });
  }

  putDashboardModule(mod, spanish_enabled): Observable<any> {
    const body = {
      module_id: mod.module.module_id,
      image_id: mod.module.image_id,
      module_name: mod.module.module_name,
      status: mod.module.status.value,
      age_group: mod.module.age_group.value,
      content_ids: mod.module.content_ids,
      is_intro_module: mod.module.is_intro_module,
      placed_status: mod.module.placed_status,
      placed_platforms: mod.module.placed_platforms,
      spanish_enabled,
    };
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/module', body, {
      headers: headers,
    });
  }

  openSnackBar(message: string, action: string, duration = 3000) {
    this._snackBar.open(message, action, {
      duration,
      verticalPosition: 'top',
    });
  }

  openURL(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  updateUserDataSub(data) {
    this.userData.next(data);
  }

  resetPassword(pw, pw2, tempToken, firstName?, lastName?): Observable<any> {
    const body: {
      password: string;
      confirm_password: string;
      first_name?: string;
      last_name?: string;
    } = {
      password: pw,
      confirm_password: pw2,
    };

    if (firstName && lastName) {
      body.first_name = firstName;
      body.last_name = lastName;
    }

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + tempToken);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/reset-password', body, {
      headers: headers,
    });
  }

  getDashboardMembershipSurvey(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/membership-survey', {
      headers: headers,
    });
  }

  messageThankYou(id) {
    const body = {
      id,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/message-thank-you', body, {
      headers: headers,
    });
  }

  postAnalyticsSearch(input): Observable<any> {
    const body = {
      input: input,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/analytics/search', body, {
      headers: headers,
    });
  }

  isTestingEnvironment() {
    if (this.env === 'production') {
      return false;
    } else {
      return true;
    }
  }

  getDashboardSchoolOverview(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/school/overview', {
      headers: headers,
    });
  }

  getDashboardContentLibrary(platform: string): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + `/dashboard/content-library/${platform}`,
      {
        headers: headers,
      }
    );
  }

  modifyDashboardContentLibrary(
    platform: string,
    type: 'enable' | 'disable',
    moduleIds: number[]
  ): Observable<any> {
    const body = {
      modification_type: type,
      module_list: moduleIds,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(
      this.baseURL + `/dashboard/content-library/${platform}/modify`,
      body,
      {
        headers: headers,
      }
    );
  }

  handleOnboardingNavigation(page: string) {
    switch (page) {
      case 'account-settings':
        this.routerURL = '/onboarding/account-settings';
        this.router.navigate(['/onboarding/account-settings']);
        break;
      case 'payment-settings':
        this.routerURL = '/onboarding/payment-settings';
        this.router.navigate(['/onboarding/payment-settings']);
        break;
      case 'additional-members':
        this.routerURL = '/onboarding/additional-members';
        this.router.navigate(['/onboarding/additional-members']);
        break;
      case 'logo':
        this.routerURL = '/onboarding/logo';
        this.router.navigate(['/onboarding/logo']);
        break;
      case 'zip-code-interest':
        this.routerURL = '/onboarding/zip-code-interest';
        this.router.navigate(['/onboarding/zip-code-interest']);
        break;
      case 'zogo-web':
        this.routerURL = '/onboarding/zogo-web-subdomain';
        this.router.navigate(['/onboarding/zogo-web-subdomain']);
        break;
      default:
        break;
    }
  }

  postDashboardOnboarding(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/onboarding', body, {
      headers: headers,
    });
  }

  getAppCopy(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );

    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL +
        '/copy?platform=dashboard' +
        '&live=' +
        (this.env === 'development').toString(),
      {
        headers: headers,
      }
    );
  }

  patchDashboardZipCodesChangeRequest(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(
      this.baseURL + '/dashboard/zip-codes-change-request',
      body,
      {
        headers: headers,
      }
    );
  }

  patchDashboardAccessCodeChangeRequest(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(
      this.baseURL + '/dashboard/access-code-change-request',
      body,
      {
        headers: headers,
      }
    );
  }

  getAnalyticsReport(report_id): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + '/dashboard/analytics/report?report_id=' + report_id,

      {
        headers: headers,
      }
    );
  }

  getDashboardMembers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + `/dashboard/members`, {
      headers: headers,
    });
  }

  getDashboardInstitution(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + `/dashboard/institution`, {
      headers: headers,
    });
  }

  patchDashboardInstitution(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(this.baseURL + '/dashboard/institution', body, {
      headers: headers,
    });
  }

  postDashboardUserInvite(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/user-invite', body, {
      headers: headers,
    });
  }

  patchDashboardMember(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(this.baseURL + '/dashboard/member', body, {
      headers: headers,
    });
  }

  deleteDashboardMember(userId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(
      this.baseURL + `/dashboard/member?institution_user_id=${userId}`,
      {
        headers: headers,
      }
    );
  }

  patchDashboardAppAccountInstitutionMigration(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(
      this.baseURL + '/dashboard/app-account-institution-migration',
      body,
      {
        headers: headers,
      }
    );
  }

  putDashboardColor(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/color', body, {
      headers: headers,
    });
  }

  getDashboardLeaderboards(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/leaderboards', {
      headers: headers,
    });
  }

  getDashboardCustomLeaderboard(id): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + '/dashboard/leaderboards/custom?id=' + id,
      {
        headers: headers,
      }
    );
  }

  getDashboardInstitutionLeaderboard(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/leaderboards/institution', {
      headers: headers,
    });
  }

  getDashboardModuleImages(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/module-images', {
      headers: headers,
    });
  }

  getDashboardZipCodes(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/zip-codes', {
      headers: headers,
    });
  }

  getDashboardResources(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/resources', {
      headers: headers,
    });
  }

  patchDashboardContentLibraryTrending(platform, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(
      this.baseURL + `/dashboard/content-library/${platform}/trending`,
      body,
      {
        headers: headers,
      }
    );
  }

  postDashboardSupportTicket(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + `/dashboard/support/ticket`, body, {
      headers: headers,
    });
  }

  getDashboardSupportReasons(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/support/reasons', {
      headers: headers,
    });
  }

  getProductHighlights(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/product-highlights', {
      headers: headers,
    });
  }

  getProductAnalytics(timeframe, code = null): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    if (code) {
      return this.http.get(
        this.baseURL +
          `/dashboard/analytics?timeframe=${timeframe}&code=${code}`,
        {
          headers: headers,
        }
      );
    }

    return this.http.get(
      this.baseURL + `/dashboard/analytics?timeframe=${timeframe}`,
      {
        headers: headers,
      }
    );
  }

  getDashboardAccessCode(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/access-code', {
      headers: headers,
    });
  }

  postDashboardAccessCode(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/access-code', body, {
      headers: headers,
    });
  }

  putDashboardAccessCode(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(this.baseURL + '/dashboard/access-code', body, {
      headers: headers,
    });
  }

  deleteDashboardAccessCode(id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(this.baseURL + `/dashboard/access-code?id=${id}`, {
      headers: headers,
    });
  }

  postDashboardLimitTypeChangeRequest(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(
      this.baseURL + '/dashboard/limit-type-change-request',
      body,
      {
        headers: headers,
      }
    );
  }

  postDashboardRequestPdf(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/request-pdf', body, {
      headers: headers,
    });
  }

  postDashboardContentLibraryPlaceCustomModule(
    body,
    platform
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(
      this.baseURL +
        `/dashboard/content-library/${platform}/place-custom-module`,
      body,
      {
        headers: headers,
      }
    );
  }

  putDashboardContentLibraryModifyOrder(platform, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(
      this.baseURL + `/dashboard/content-library/${platform}/modify-order`,
      body,
      {
        headers: headers,
      }
    );
  }

  deleteDashboardContentLibraryCustomModule(
    platform,
    module_id
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(
      this.baseURL +
        `/dashboard/content-library/${platform}/custom-module?module_id=${module_id}`,
      {
        headers: headers,
      }
    );
  }

  deleteDashboardModule(module_id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(
      this.baseURL + `/dashboard/module?module_id=${module_id}`,
      {
        headers: headers,
      }
    );
  }

  getDashboardContentLibraryAvailableCustomModules(platform): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL +
        `/dashboard/content-library/${platform}/available-custom-modules`,
      {
        headers: headers,
      }
    );
  }

  patchDashboardUserInstitution(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(this.baseURL + '/dashboard/user/institution', body, {
      headers: headers,
    });
  }

  getDashboardTeachers(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/teachers', {
      headers: headers,
    });
  }

  getHelpCenterLink(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/help-center', {
      headers: headers,
    });
  }

  postDashboardTeacherInvite(email): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = { email: email };
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(this.baseURL + '/dashboard/teacher-invite', body, {
      headers: headers,
    });
  }

  //deleteDashboardTeacher
  deleteDashboardTeacher(id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(this.baseURL + `/dashboard/teacher?id=${id}`, {
      headers: headers,
    });
  }

  //dashboard/teacher
  patchDashboardTeacher(body): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(this.baseURL + '/dashboard/teacher', body, {
      headers: headers,
    });
  }

  //dashboard/teacher/password-reset
  postDashboardTeacherPasswordReset(id): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = { id: id };
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(
      this.baseURL + '/dashboard/teacher/password-reset',
      body,
      {
        headers: headers,
      }
    );
  }

  getSurveyQuestions(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + '/dashboard/survey-questions', {
      headers: headers,
    });
  }

  createSurveyQuestionOrUpdate(questionData): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(
      this.baseURL + '/dashboard/survey-questions',
      questionData,
      {
        headers: headers,
      }
    );
  }

  deleteSurveyQuestion(id): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete(
      this.baseURL + `/dashboard/survey-questions/${id}`,
      {
        headers: headers,
      }
    );
  }

  putSurveyQuestionOrder(order): Observable<any> {
    const body = {
      survey_ids: order,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(
      this.baseURL + '/dashboard/survey-questions/order',
      body,
      {
        headers: headers,
      }
    );
  }

  getDashboardSocialCalendar(timeFrame): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + `/dashboard/social-calendar?time_frame=${timeFrame}`,
      {
        headers: headers,
      }
    );
  }

  postDashboardSocialCalendarContact(email): Observable<any> {
    const body = {
      email,
    };

    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(
      this.baseURL + '/dashboard/social-calendar/contact',
      body,
      {
        headers: headers,
      }
    );
  }

  getDashboardOverviewInfo(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + `/dashboard/overview/info`, {
      headers: headers,
    });
  }

  getDashboardOverviewData(reqObj): Observable<any> {
    const { source, platform, time_frame, code } = reqObj;
    let queryParams = `?platform=${platform}&time_frame=${time_frame}`;
    if (code) {
      queryParams += `&code=${code}`;
    }
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + `/dashboard/overview/${source}${queryParams}`,
      {
        headers: headers,
      }
    );
  }

  getDashboardSkillResultsInfo(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + `/dashboard/skill-results/info`, {
      headers: headers,
    });
  }

  getDashboardSkillResultsData(reqObj): Observable<any> {
    const { platform, time_frame, code } = reqObj;
    let queryParams = `?platform=${platform}&time_frame=${time_frame}`;
    if (code) {
      queryParams += `&code=${code}`;
    }
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + `/dashboard/skill-results/data${queryParams}`,
      {
        headers: headers,
      }
    );
  }

  getDashboardShareableWidgetLink(): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseURL + `/dashboard/shareable-widget-link`, {
      headers: headers,
    });
  }

  getDashboardViewOnlyToken(code): Observable<any> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Basic ' + btoa('stagingBasicUser:s5RLcpufL7qd9Bjt')
    );
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(
      this.baseURL + `/dashboard/view-only-token?view-only-code=${code}`,
      {
        headers: headers,
      }
    );
  }

  patchDashboardWebCustomizations(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.token);
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(
      this.baseURL + '/dashboard/web-customizations',
      body,
      {
        headers: headers,
      }
    );
  }
}
