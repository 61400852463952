<app-onboarding-header></app-onboarding-header>
<div class="form-container d-flex-col gap-24">
  <div class="d-flex-col gap-8">
    <h1 class="h3-bold">Add Team Members</h1>
    <p class="body-book">
      Give others access to the Partner Portal. You can always do this later,
      too.
    </p>
  </div>
  <form class="d-flex-col gap-24" #teamForm="ngForm" (ngSubmit)="onSubmit()">
    <div
      *ngFor="let member of teamMembers; let i = index"
      class="d-flex-col gap-8"
    >
      <div class="member-row">
        <p class="body-bold">Team Member #{{ i + 1 }}</p>
        <zogo-icon
          tappable
          *ngIf="i >= 1"
          iconName="trash-2"
          size="xsmall"
          class="remove-member text-color-neutral-400"
          (click)="removeMember(i)"
        ></zogo-icon>
      </div>
      <div class="d-flex-col gap-12">
        <div class="d-flex-row gap-12">
          <zogo-text-input
            [(ngModel)]="member.firstName"
            name="firstName{{ i }}"
            class="name-input"
            inputId="first-name{{ i }}"
            inputLabel="First Name"
            ariaLabel="Team Member {{ i + 1 }} First Name"
            [isRequired]="true"
            [currentValue]="member.firstName"
            #firstNameInput="ngModel"
            (valueChange)="onInputValueChange($event, i, 'firstName')"
          ></zogo-text-input>
          <zogo-text-input
            [(ngModel)]="member.lastName"
            name="firstName{{ i }}"
            class="name-input"
            inputId="last-name{{ i }}"
            inputLabel="Last Name"
            ariaLabel="Team Member {{ i + 1 }} Last Name"
            [isRequired]="true"
            [currentValue]="member.lastName"
            #lastNameInput="ngModel"
            (valueChange)="onInputValueChange($event, i, 'lastName')"
          ></zogo-text-input>
        </div>
        <zogo-text-input
          [(ngModel)]="member.email"
          name="email{{ i }}"
          inputId="email{{ i }}"
          inputLabel="Email Address"
          inputType="email"
          autocompleteType="email"
          ariaLabel="Team Member {{ i + 1 }} Last Name"
          [isRequired]="true"
          [currentValue]="member.email"
          appCustomValidation="email"
          #memberEmailInput="ngModel"
          (valueChange)="onInputValueChange($event, i, 'email')"
        ></zogo-text-input>
        <div
          *ngIf="
            memberEmailInput.errors?.invalidEmailFormat &&
            memberEmailInput.touched
          "
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Invalid email format</p>
        </div>
        <div class="d-flex-col gap-4">
          <p class="caption-medium">Permissions</p>
          <zogo-radio-group
            [(ngModel)]="member.accessLevel"
            name="accessLevel{{ i }}"
            required
            class="d-flex-row gap-24"
            (valueChange)="onInputValueChange($event, i, 'accessLevel')"
            #radioGroup="ngModel"
          >
            <zogo-radio-button value="admin" label="Admin"></zogo-radio-button>
            <zogo-radio-button
              value="view_only"
              label="View-only"
            ></zogo-radio-button>
          </zogo-radio-group>
        </div>
        <div
          *ngIf="member.error"
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Error adding member</p>
        </div>
      </div>
    </div>
    <zogo-btn
      iconName="plus"
      label="Add another member"
      buttonStyle="tertiary"
      (clickFunction)="addMember()"
    ></zogo-btn>
    <div class="d-flex-col gap-16">
      <zogo-btn
        [disabled]="teamForm.invalid"
        size="large"
        label="Continue"
        [fullWidth]="true"
        type="submit"
      ></zogo-btn>
      <zogo-decorated-link
        [displayOnly]="true"
        class="skip-cta"
        label="Skip for now"
        (click)="handleSkip()"
      ></zogo-decorated-link>
    </div>
  </form>
</div>
