<div *ngIf="!userDemographicData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div *ngIf="userDemographicData" class="user-demographics">
  <div class="header-section">
    <div>
      <h4 class="h5-bold text-color-neutral-800">
        {{ userDemographicData.tabs[this.tabIndex].tab_header }}
      </h4>
      <div class="caption-medium text-color-neutral-600">
        {{ userDemographicData.tabs[this.tabIndex].tab_sub_header }}
      </div>
    </div>
    <div class="tab-buttons">
      <div
        *ngFor="let tab of userDemographicData.tabs; let i = index"
        class="overline-medium tab-button"
        (click)="selectTab(i)"
        [ngClass]="{ selected: i === tabIndex }"
      >
        {{ tab.tab_name }}
      </div>
    </div>
  </div>

  <div class="chart-container">
    <div
      class="chart-bar"
      *ngFor="
        let item of userDemographicData.tabs[tabIndex].data;
        let i = index
      "
    >
      <div
        class="caption-medium text-color-neutral-700 bar-percentage"
        [style.top.px]="195 - calculateChartScaling(item.value)"
      >
        {{ item.value }}%
      </div>

      <div
        class="bar-value"
        [style.height.px]="calculateChartScaling(item.value)"
        (mouseenter)="showTooltip = i"
        (mouseleave)="showTooltip = null"
      ></div>
      <div class="tooltip" [ngClass]="{ hidden: showTooltip !== i }">
        <div class="overline-bold">{{ item.popup_header }}</div>
        <ul>
          <li
            class="overline-medium"
            *ngFor="let popupItem of item.popup_items"
          >
            <span style="background-color: var(--tints-primary-3)"></span>

            {{ popupItem }}
          </li>
        </ul>
      </div>
      <div class="caption-medium text-color-neutral-700 bar-label">
        {{ item.item_name }}
      </div>
    </div>
  </div>
</div>
