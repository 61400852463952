import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-custom-module-selection-dialog',
  templateUrl: './custom-module-selection-dialog.component.html',
  styleUrls: ['./custom-module-selection-dialog.component.css'],
})
export class CustomModuleSelectionDialogComponent {
  @Input() header: any;
  selectedModule: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public req: RequestsService
  ) {
    this.selectedModule =
      data.customModuleList.available_custom_modules[0].module_id;
  }

  setNewSelectedModule(id) {
    this.selectedModule = id;
  }

  isCurrentSelectedModule(id) {
    return id === this.selectedModule;
  }

  addModule() {
    this.data.confirmFunct(this.selectedModule);
  }
}
