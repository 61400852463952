import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-widget-display',
  templateUrl: './widget-display.component.html',
  styleUrls: ['./widget-display.component.scss'],
})
export class WidgetDisplayComponent implements OnInit, OnDestroy {
  @Input() TEST_DATA;
  @Input() tabData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  metricCardData: any;
  errorLoading: boolean = false;

  constructor(public req: RequestsService) {}

  ngOnInit() {
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateMetricCardData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateMetricCardData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateMetricCardData();
      },
      error: err => {},
    });
    this.updateMetricCardData();
  }

  hasWidgetTypes(arrayOfWidgets, arrayOfTypes) {
    for (const widget of arrayOfWidgets.widgets) {
      if (arrayOfTypes.includes(widget.widget_type)) {
        return true;
      }
    }
    return false;
  }

  findWidget(arrayOfWidgets, type) {
    return arrayOfWidgets.widgets.find(widget => widget.widget_type === type);
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateMetricCardData() {
    this.metricCardData = null;
    this.errorLoading = false;
    const reqObj = {
      source: 'number-cards',
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.metricCardData = data;
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }
}
