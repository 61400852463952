import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';
import { RequestsService } from 'src/app/requests.service';
import previewScreens from './preview-screens/preview-screens';
import desktopPreviews from './desktop-previews/desktop-preview';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperFunctionsService } from 'src/app/design-system/helper-functions.service';

@Component({
  selector: 'app-custom-color',
  templateUrl: './custom-color.component.html',
  styleUrls: ['./custom-color.component.css'],
})
export class CustomColorComponent implements OnInit {
  copy: any;
  userData: any;
  isAdmin: boolean;
  colorHex: string;
  isValidContrast: boolean;
  contrastRatio: number;
  previewIndex: number = 0;
  progressArray: any;
  isNotifAlertEnabled: boolean;
  notifAlertStatus: string;
  notifAlertMessage: string;
  notifAlertButtonText: string;
  notifAlertButtonFunc: any;
  previewType: 'mobile' | 'desktop' = 'mobile';
  platformAccess: string[] = [];

  constructor(
    public appCopy: CopyService,
    private dialog: MatDialog,
    private req: RequestsService,
    private sanitizer: DomSanitizer,
    private helpers: HelperFunctionsService
  ) {}

  ngOnInit(): void {
    // Amplitude event - Custom Color Viewed
    this.req.ampTrack('Custom Color Viewed');

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.copy = this.appCopy.getCustomColorPage();
    this.initializeInputs();

    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    if (this.userData.institution.platform_access_types) {
      this.userData.institution.platform_access_types.forEach(access => {
        this.platformAccess.push(access.display_name);
      });
    }

    if (
      !this.platformAccess.includes('Mobile Banking') &&
      !this.platformAccess.includes('Standalone')
    ) {
      this.previewType = 'desktop';
      this.progressArray = Array(desktopPreviews.length).fill(1);
    } else {
      this.progressArray = Array(previewScreens.length).fill(1);
    }
  }

  abortChanges() {
    this.initializeInputs();
  }

  initializeInputs() {
    if (this.userData.institution.primary_hex) {
      this.colorHex = this.userData.institution.primary_hex;
    } else {
      this.colorHex = '#0050AA';
    }
    this.validateColor();
    if (!this.userData.institution.can_edit_color) {
      this.setNotifAlert();
    }
  }

  saveColor() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: this.copy.confirm_save_modal.header,
        bodyText: this.copy.confirm_save_modal.body,
        zoImage: this.copy.confirm_save_modal.image_global_key,
        cancelBtnText: this.copy.confirm_save_modal.back_button_text,
        confirmBtnText: this.copy.confirm_save_modal.save_button_text,
        confirmFunct: () => {
          this.updateColor();
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  updateColor() {
    const body = { primary_hex: this.colorHex };

    this.req.putDashboardColor(body).subscribe({
      next: data => {
        // Amplitude event - Custom Color Saved
        this.req.ampTrack('Custom Color Saved', {
          primary_hex: body.primary_hex,
        });
        this.userData.institution.primary_hex = data.primary_hex;
        localStorage.setItem('userData', JSON.stringify(this.userData));
      },
      error: err => {
        this.req.openSnackBar(err.error.message, 'Okay');
      },
    });
  }

  validateColor() {
    const calculatedRatio = this.helpers.calculateContrast(this.colorHex);
    if (calculatedRatio >= 4.5) {
      this.isValidContrast = true;
    } else {
      this.isValidContrast = false;
    }
    this.contrastRatio = calculatedRatio;
  }

  handleColorPickerChange() {
    this.validateColor();
  }

  getCurrentScreen() {
    let svgString;
    if (this.previewType === 'mobile') {
      svgString = previewScreens[this.previewIndex];
    } else {
      svgString = desktopPreviews[this.previewIndex];
    }
    const colors = this.helpers.calculateColors(this.colorHex);
    // replaces #1C64F2 in SVG
    svgString = svgString.replace(/{{color}}/g, colors.color);
    // replaces #EBF5FF in SVG
    svgString = svgString.replace(/{{colorTint}}/g, colors.colorTint);
    // replaces #233876 in SVG
    svgString = svgString.replace(/{{colorShade}}/g, colors.colorShade);
    return this.sanitizer.bypassSecurityTrustHtml(svgString);
  }

  handlePreviewSelection(preview: 'mobile' | 'desktop') {
    this.previewType = preview;
    this.previewIndex = 0;

    if (preview === 'mobile') {
      this.progressArray = Array(previewScreens.length).fill(1);
    } else {
      this.progressArray = Array(desktopPreviews.length).fill(1);
    }
  }

  nextScreen() {
    if (this.previewType === 'mobile') {
      if (previewScreens[this.previewIndex + 1]) {
        this.previewIndex = this.previewIndex + 1;
      }
    } else {
      if (desktopPreviews[this.previewIndex + 1]) {
        this.previewIndex = this.previewIndex + 1;
      }
    }
  }

  previousScreen() {
    if (this.previewType === 'mobile') {
      if (previewScreens[this.previewIndex - 1]) {
        this.previewIndex = this.previewIndex - 1;
      }
    } else {
      if (desktopPreviews[this.previewIndex - 1]) {
        this.previewIndex = this.previewIndex - 1;
      }
    }
  }

  dismissNotifAlert() {
    this.isNotifAlertEnabled = false;
  }

  contactCx() {
    window.open(
      `mailto:${this.userData.institution.zogo_contact}?subject=Partner Portal Custom Color Inquiry - ${this.userData.institution.short_name}`
    );
  }

  setNotifAlert() {
    this.notifAlertMessage = this.copy.locked_toast.toast_text;
    this.notifAlertButtonText = this.copy.locked_toast.button_text;
    this.notifAlertButtonFunc = this.contactCx.bind(this);
    this.notifAlertStatus = 'neutral';
    this.isNotifAlertEnabled = true;
  }
}
