import { Component, OnInit } from '@angular/core';
import { CopyService } from '../copy.service';
import { RequestsService } from '../requests.service';
import { ExportToCsv } from 'export-to-csv';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/dialog/error.component';

@Component({
  selector: 'app-leaderboard-download',
  templateUrl: './leaderboard-download.component.html',
  styleUrls: ['./leaderboard-download.component.css'],
})
export class LeaderboardDownloadComponent implements OnInit {
  copy: any;
  leaderboards: any;
  customLeaderboardData: any;
  institutionLeaderboardData: any;
  showSpinner: boolean;
  expandCommunityLeaderboard: boolean = true;
  expandCustomCompetitions: boolean = true;
  expandPastCustomCompetitions: boolean = false;
  expandedRowIndex: number | null = null;
  pastExpandedRowIndex: number | null = null;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Amplitude event - Leaderboards Viewed
    this.req.ampTrack('Leaderboards Viewed');

    this.copy = this.appCopy.getDashboardLeaderboard();
    this.showSpinner = true;
    this.req.getDashboardLeaderboards().subscribe({
      next: data => {
        this.showSpinner = false;
        this.leaderboards = data;
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
        console.log('errors', err);
      },
    });
  }

  // function for converting arrays to csv format
  arrayToCsv(data) {
    const options = {
      useKeysAsHeaders: true,
      filename: 'Leaderboard',
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  getCustomLeaderboardDownload(id) {
    // Amplitude event - Leaderboard Downloaded
    this.req.ampTrack('Leaderboard Downloaded', {
      leaderboard_type: 'custom',
    });
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      disableClose: true,
      data: {
        primaryText: 'Downloading your file...',
        secondaryText: '',
      },
    });
    this.req.getDashboardCustomLeaderboard(id).subscribe({
      next: data => {
        this.customLeaderboardData = data;
        this.arrayToCsv(this.customLeaderboardData.sorted_users);
        dialogRef.close();
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
        console.log('errors', err);
      },
    });
  }

  getInstitutionLeaderboardDownload() {
    // Amplitude event - Leaderboard Downloaded
    this.req.ampTrack('Leaderboard Downloaded', {
      leaderboard_type: 'institution',
    });

    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      disableClose: true,
      data: {
        primaryText: 'Downloading your file...',
        secondaryText: '',
      },
    });
    this.req.getDashboardInstitutionLeaderboard().subscribe({
      next: data => {
        this.institutionLeaderboardData = data;
        this.arrayToCsv(this.institutionLeaderboardData.users);
        dialogRef.close();
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
        console.log('errors', err);
      },
    });
  }

  toggleLeaderboardDropdown(section: string) {
    switch (section) {
      case 'community':
        this.expandCommunityLeaderboard = !this.expandCommunityLeaderboard;
        break;
      case 'custom':
        this.expandCustomCompetitions = !this.expandCustomCompetitions;
        break;
      case 'past':
        this.expandPastCustomCompetitions = !this.expandPastCustomCompetitions;
        break;
      default:
        break;
    }
  }

  toggleCurrentLeaderboardRow(index: number) {
    this.expandedRowIndex = this.expandedRowIndex === index ? null : index;
  }

  togglePastLeaderboardRow(index: number) {
    this.pastExpandedRowIndex =
      this.pastExpandedRowIndex === index ? null : index;
  }
}
