<div class="schools-wrapper">
  <h1 class="h3-bold">Class Overview</h1>
  <div class="classroom-link-wrapper" *ngIf="isZogoWeb()">
    <div class="classroom-link-display-desc">Share Classroom Link</div>
    <div class="classroom-link-display">
      <div class="classroom-link-display-left">
        <div class="classroom-link-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-link"
          >
            <path
              d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
            ></path>
            <path
              d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
            ></path>
          </svg>
        </div>
        <div class="classroom-link">
          {{ calculateClassroomLink() }}
        </div>
      </div>
      <button
        (click)="copyClassroomLink()"
        class="blue copy-classroom-link-btn"
        mat-button
      >
        COPY
      </button>
    </div>
  </div>
  <div class="class-overview-frame">
    <div *ngIf="!classData?.length" class="no-classes">
      <div class="no-classes-container">
        <div class="no-classes-header">
          {{ emptyClassData?.header }}
        </div>
        <div class="no-classes-sub-header">
          {{ emptyClassData?.sub_header }}
        </div>
      </div>
    </div>
    <div *ngIf="classData?.length" class="search-bar-wrapper">
      <div class="search-bar">
        <div class="search-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-search"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
        <input
          class="search-input"
          [(ngModel)]="searchInput"
          (ngModelChange)="filterResults()"
          [placeholder]="'Search for school or class'"
        />
        <!-- <input
          class="search-input"
          [(ngModel)]="searchInput"
          (ngModelChange)="search_results = null"
          [placeholder]="'Search for school or class'"
        /> -->
      </div>
    </div>
    <table *ngIf="classData?.length">
      <tr class="header-row">
        <th>Classroom</th>
        <th>School Name</th>
        <th>School Zip Code</th>
        <th># Students</th>
        <th>Modules Completed</th>
      </tr>
      <tr *ngFor="let class of filteredClassData">
        <td class="class-name-cell">
          <div class="class-icon" [ngStyle]="{ 'background-color': '#F6F6F6' }">
            <div class="class-icon-image-frame">
              <img [src]="class.icon" alt="" />
            </div>
          </div>
          <span class="class-name">
            {{ class.name }}
          </span>
        </td>
        <td>
          {{ class.school }}
        </td>
        <td>
          {{ class.school_zip_code }}
        </td>
        <td>
          {{ class.student_count }}
        </td>
        <td>
          {{ class.total_module_count }}
        </td>
      </tr>
    </table>
  </div>
</div>
