import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import {
  ZogoBtnComponent,
  ZogoRadioButtonComponent,
  ZogoRadioGroupComponent,
  ZogoTextInputComponent,
} from 'src/app/design-system/web-components';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { RequestsService } from 'src/app/requests.service';
import { CustomValidationDirective } from 'src/app/directives/custom-validation.directive';

@Component({
  selector: 'app-add-member-modal',
  templateUrl: './add-member-modal.component.html',
  styleUrls: ['./add-member-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZogoBtnComponent,
    ZogoTextInputComponent,
    ZogoIconComponent,
    ZogoRadioGroupComponent,
    ZogoRadioButtonComponent,
    CustomValidationDirective,
  ],
})
export class AddMemberModalComponent implements OnInit {
  @Input() mode: 'invite' | 'edit' = 'invite';
  @Input() headerText: string = '';
  @Input() memberId: number;
  @Input() firstName: string = '';
  @Input() lastName: string = '';
  @Input() email: string = '';
  @Input() accessLevel: string = '';
  hasEmailInputError: boolean = false;

  constructor(
    private req: RequestsService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  async dismiss() {
    await this.modalController.dismiss(null, 'cancel');
  }

  async saveChanges() {
    await this.onSubmitForm();

    await this.modalController.dismiss(null, 'save');
  }

  onAccessLevelChange(access) {
    this.accessLevel = access;
  }

  onSubmitForm() {
    if (this.mode === 'invite') {
      const request = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        institution_user_type: this.accessLevel,
      };

      this.req.postDashboardUserInvite(request).subscribe({
        next: data => {
          // Amplitude event - Institution Information Changes Saved
          this.req.ampTrack('New Member Invitation Sent');
          this.toastService.showToast('Changes saved.', 6000);
        },
        error: err => {
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
          console.log('errors', err.error);
        },
      });
    } else {
      const request = {
        institution_user_id: this.memberId,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        access_level: this.accessLevel,
      };

      this.req.patchDashboardMember(request).subscribe({
        next: data => {
          // Amplitude event - Institution Information Changes Saved
          this.req.ampTrack('Member Information Edited');
          this.toastService.showToast('Changes saved.', 6000);
        },
        error: err => {
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
          console.log('errors', err.error);
        },
      });
    }
  }
}
