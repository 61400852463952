import { Component, OnInit, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { SnippetsComponent } from './snippets/snippets.component';
import { QuestionsComponent } from './questions/questions.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RequestsService } from '../../../requests.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/guards/dialog.service';
import { Observable } from 'rxjs';
import { IconSelectComponent } from './icon-select/icon-select.component';
import { ModuleConfirmationDialogComponent } from '../module-confirmation-dialog/module-confirmation-dialog.component';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
})
export class EditorComponent implements OnInit {
  module: any;
  emoji_select_position: any;
  moduleData: any = {};
  hasUnsavedChanges: boolean = false;
  haveSnippetsChanged: boolean = false;
  haveQuestionsChanged: boolean = false;
  iconList: any = {};
  activeModuleIcon: any;
  originalModule: any;
  introStatus: any = null;
  moduleEditorView: string; // english | spanish
  userData: any;

  constructor(
    public dialog: MatDialog,
    public req: RequestsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    public dialogService: DialogService
  ) {}

  ngOnInit() {
    this.moduleEditorView = 'english';

    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.req.getDashboardModuleImages().subscribe({
      next: data => {
        this.iconList = data;
      },
      error: err => {
        this.req.openSnackBar(
          'Error fetching module image list. Please try again later.',
          'Okay'
        );
      },
    });

    const module_data = this.req.getModuleToEdit();
    if (module_data) {
      this.req.moduleToEdit(null);
      this.moduleData = module_data;
      this.originalModule = JSON.parse(JSON.stringify(this.moduleData));
      this.introStatus = this.req.getIntroModuleInfo();
      this.activeModuleIcon = {
        image_id: module_data?.module?.image_id,
        image_url: module_data?.module?.image,
      };
    } else {
      this.router.navigate(['modules']);
    }
  }

  setUnsavedChange() {
    this.hasUnsavedChanges = true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (
      this.hasUnsavedChanges ||
      this.moduleData.module.module_name['en-US'] !==
        this.originalModule.module.module_name['en-US'] ||
      this.haveSnippetsChanged ||
      this.haveQuestionsChanged
    ) {
      $event.returnValue = true;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.moduleData.module) {
      return;
    }
    if (
      this.hasUnsavedChanges ||
      this.moduleData.module.is_intro_module !==
        this.originalModule.module.is_intro_module ||
      this.moduleData.module.module_name['en-US'] !==
        this.originalModule.module.module_name['en-US'] ||
      this.haveSnippetsChanged ||
      this.haveQuestionsChanged
    ) {
      return this.dialogService.confirm(
        'You have unsaved changes! Are you sure you want to leave?'
      );
    }
    return true;
  }

  dropSnippet(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.moduleData.module.content.snippets.values,
      event.previousIndex,
      event.currentIndex
    );
    this.setUnsavedChange();
    this.haveSnippetsChanged = true;
  }

  dropQuestion(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.moduleData.module.content.questions.values,
      event.previousIndex,
      event.currentIndex
    );
    this.setUnsavedChange();
    this.haveQuestionsChanged = true;
  }

  openIconSelect(): void {
    this.dialog.open(IconSelectComponent, {
      width: '400px',
      height: '450px',
      data: {
        iconList: this.iconList,
        activeIcon: this.activeModuleIcon,
        moduleName: this.moduleData?.module.module_name,
        confirmFunct: image => {
          this.activeModuleIcon = image;
          this.moduleData.module.image_id = image.image_id;
          this.setUnsavedChange();
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  openSnippets(data, index, language) {
    if (data) {
      data.index = index;
      this.openSnippetEditor(data, false, language);
    } else {
      this.req
        .postDashboardModuleContent(
          this.moduleData.module.module_id,
          'Snippet',
          this.hasSpanishContent()
        )
        .subscribe({
          next: returnData => {
            data = returnData;
            this.openSnippetEditor(data.content, true, language);
          },
          error: err => {
            console.log(err);
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          },
        });
    }
  }

  openSnippetEditor(data, isNew = false, language) {
    const dialogRef = this.dialog.open(SnippetsComponent, {
      disableClose: true,
      panelClass: 'highlights-modal-component',
      data: { snippet: data, isNew, language },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.cancel === true && !result.isNew) {
          return;
        }
        if (result.delete === true) {
          this.setUnsavedChange();
          this.haveSnippetsChanged = true;
          this.moduleData.module.content.snippets.values.splice(
            result.snippet.index,
            1
          );
          return;
        }
        this.req
          .putDashboardModuleContent(
            result.snippet,
            this.hasSpanishContent(result.snippet)
          )
          .subscribe({
            next: data => {
              if (result.snippet.index != null) {
                this.moduleData.module.content.snippets.values[
                  result.snippet.index
                ] = data.content;
              } else {
                this.moduleData.module.content.snippets.values.push(
                  data.content
                );
              }
              this.handleSaveModuleAfterContentUpdate();
            },
            error: err => {
              console.log(err);
              this.req.openSnackBar(
                'The changes to your content were not saved: ' +
                  err.error.message,
                'Okay'
              );
            },
          });
      }
    });
  }

  openQuestions(data, index, language) {
    if (data) {
      data.index = index;
      this.openQuestionEditor(data, false, language);
    } else {
      this.req
        .postDashboardModuleContent(
          this.moduleData.module.module_id,
          'Multiple Choice',
          this.hasSpanishContent()
        )
        .subscribe({
          next: returnData => {
            data = returnData;
            this.openQuestionEditor(data.content, true, language);
          },
          error: err => {
            console.log(err);
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          },
        });
    }
  }

  openQuestionEditor(data, isNew = false, language) {
    const dialogRef = this.dialog.open(QuestionsComponent, {
      panelClass: 'question-add-dialog',
      disableClose: true,
      data: { question: data, isNew, language },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.cancel === true && !result.isNew) {
          return;
        }
        if (result.delete === true) {
          this.setUnsavedChange();
          this.haveQuestionsChanged = true;
          this.moduleData.module.content.questions.values.splice(
            result.question.index,
            1
          );
          return;
        }
        this.req
          .putDashboardModuleContent(
            result.question,
            this.hasSpanishContent(result.question)
          )
          .subscribe({
            next: data => {
              if (result.question.index != null) {
                this.moduleData.module.content.questions.values[
                  result.question.index
                ] = data.content;
              } else {
                this.moduleData.module.content.questions.values.push(
                  data.content
                );
              }
              this.handleSaveModuleAfterContentUpdate();
            },
            error: err => {
              console.log(err);
              this.req.openSnackBar(
                'The changes to your content were not saved: ' +
                  err.error.message,
                'Okay'
              );
            },
          });
      }
    });
  }

  saveModule(shouldNavigateToModuleList = true, isSubmitForApproval = false) {
    const content_ids = [];
    for (
      let i = 0;
      i < this.moduleData.module.content.snippets.values.length;
      i++
    ) {
      content_ids.push(
        this.moduleData.module.content.snippets.values[i].content_id
      );
    }
    for (
      let i = 0;
      i < this.moduleData.module.content.questions.values.length;
      i++
    ) {
      content_ids.push(
        this.moduleData.module.content.questions.values[i].content_id
      );
    }
    this.moduleData.module.content_ids = content_ids;
    const moduleDataToSubmit = JSON.parse(JSON.stringify(this.moduleData));
    if (isSubmitForApproval) {
      moduleDataToSubmit.module.status.value = 'Pending Approval';
    } else {
      moduleDataToSubmit.module.status.value = 'Inactive';
    }
    this.req
      .putDashboardModule(moduleDataToSubmit, this.hasSpanishContent())
      .subscribe({
        next: data => {
          this.req.openSnackBar('Saved successfully.', '👍');
          if (isSubmitForApproval) {
            this.moduleData.module.status.value = 'Pending Approval';
          } else {
            this.moduleData.module.status.value = 'Inactive';
          }
          this.hasUnsavedChanges = false;
          this.haveQuestionsChanged = false;
          this.haveSnippetsChanged = false;
          this.originalModule = JSON.parse(JSON.stringify(this.moduleData));
          if (shouldNavigateToModuleList) {
            this.router.navigate(['modules']);
          }
        },
        error: err => {
          this.req.openSnackBar(
            'There was a problem saving: ' + err.error.message,
            'Okay',
            6000
          );
        },
      });
  }

  saveAsDraft() {
    if (this.moduleData.module.module_name.length === 0) {
      this.req.openSnackBar(
        'Modules must have a name to save as a draft.',
        'Okay'
      );
      return;
    }
    if (this.moduleData.module.placed_status === 'placed') {
      // sets module status to pending if the current status is Active
      this.dialog.open(ModuleConfirmationDialogComponent, {
        width: '488px',
        height: '273px',
        data: {
          header: 'Save module as draft? ',
          body: 'This action will overwrite your custom module and remove its placement from the Content Library.',
          confirmBtnText: 'Save as draft',
          cancelBtnText: 'Back to Edit',
          confirmFunct: data => {
            if (
              this.moduleData.module.status.value === 'Active' ||
              this.moduleData.module.status.value === 'Pending Approval'
            ) {
              this.moduleData.module.status.value = 'Inactive';
            }

            this.saveModule();
            // Amplitude event - Custom Module Saved as Draft
            this.req.ampTrack('Custom Module Saved as Draft', {
              custom_module_id: this.moduleData.module.module_id,
            });
            this.dialog.closeAll();
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      this.saveModule();
      // Amplitude event - Custom Module Saved as Draft
      this.req.ampTrack('Custom Module Saved as Draft', {
        custom_module_id: this.moduleData.module.module_id,
      });
    }
  }

  handleSaveModuleAfterContentUpdate() {
    this.saveModule(false);
  }

  submitForApproval() {
    if (this.moduleData.module.placed_status === 'placed') {
      this.dialog.open(ModuleConfirmationDialogComponent, {
        width: '488px',
        height: '303px',
        data: {
          header: 'Submit module for approval?',
          body: 'Custom module is currently enabled in Content Library. Submitting for approval will temporarily hide custom module from platforms until re-approved.',
          confirmBtnText: 'Submit',
          cancelBtnText: 'Back to Edit',
          confirmFunct: data => {
            // Amplitude event - Custom Module Submitted for Approval
            this.req.ampTrack('Custom Module Submitted for Approval', {
              custom_module_id: this.moduleData.module.module_id,
            });
            this.saveModule(true, true);

            this.dialog.closeAll();
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      // Amplitude event - Custom Module Submitted for Approval
      this.req.ampTrack('Custom Module Submitted for Approval', {
        custom_module_id: this.moduleData.module.module_id,
      });
      this.saveModule(true, true);
    }
  }

  navigateToModuleList() {
    this.router.navigate(['modules']);
  }

  deleteModule() {
    this.dialog.open(ModuleConfirmationDialogComponent, {
      width: '488px',
      height: '273px',
      data: {
        header: 'Delete Custom module?',
        body: 'This custom module will be permanently deleted from your list. If placed, it will be deleted from all platforms.',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Back to Edit',
        confirmFunct: data => {
          this.req
            .deleteDashboardModule(this.moduleData.module.module_id)
            .subscribe({
              next: data => {
                // Amplitude event - Custom Module Deleted
                this.req.ampTrack('Custom Module Deleted', {
                  custom_module_id: this.moduleData.module.module_id,
                });
                this.router.navigate(['modules']);
                this.dialog.closeAll();
              },
              error: err => {
                this.dialog.closeAll();
              },
            });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  isModuleValid() {
    return (
      this.areQuestionsValid() &&
      this.areSnippetsValid() &&
      this.moduleData.module.module_name['en-US'].length > 0
    );
  }

  areQuestionsValid() {
    if (
      this.moduleData.module.content.questions.values.length >
      this.moduleData.module.content.questions.max_count
    ) {
      return false;
    }
    return true;
  }

  areSnippetsValid() {
    if (
      this.moduleData.module.content.snippets.values.length < 3 ||
      this.moduleData.module.content.snippets.values.length >
        this.moduleData.module.content.snippets.max_count
    ) {
      return false;
    }
    return true;
  }

  isApprovalRequired() {
    return (
      this.haveQuestionsChanged ||
      this.haveSnippetsChanged ||
      this.moduleData.module.module_name !==
        this.originalModule.module.module_name ||
      this.originalModule.module.status.value === 'Inactive'
    );
  }

  handleLanguageChange(language) {
    this.moduleEditorView = language;
  }

  hasSpanishContent(content = null) {
    // checks to see if the module has any spanish content set
    // first iterates through passed in content and checks to see if there is any spanish content
    if (content) {
      if (
        content.content?.['es-US'] ||
        content.content_title?.['es-US'] ||
        content.content_description?.['es-US']
      ) {
        return true;
      }
      if (content.answers) {
        for (const answer of content.answers) {
          if (answer.answer?.['es-US'] || answer.explanation?.['es-US']) {
            return true;
          }
        }
      }
    }

    // next iterates through the entire module to see if there is any spanish content
    const module = this.moduleData.module;
    if (module.module_name?.['es-US']) {
      return true;
    }
    if (module.content.questions) {
      for (const question of module.content.questions.values) {
        if (
          question.content?.['es-US'] ||
          question.content_title?.['es-US'] ||
          question.content_description?.['es-US']
        ) {
          return true;
        }
        if (question.answers) {
          for (const answer of question.answers) {
            if (answer.answer?.['es-US'] || answer.explanation?.['es-US']) {
              return true;
            }
          }
        }
      }
    }

    if (module.content.snippets) {
      for (const snippet of module.content.snippets.values) {
        if (
          snippet.content?.['es-US'] ||
          snippet.content_title?.['es-US'] ||
          snippet.content_description?.['es-US']
        ) {
          return true;
        }
      }
    }
    return false;
  }

  hasSpanishSupport() {
    const PLATFORMS_WITH_SPANISH_SUPPORT = [
      'native',
      'enterprise-web',
      'sandbox',
    ];

    return this.userData.institution.platform_access_types.some(type =>
      PLATFORMS_WITH_SPANISH_SUPPORT.includes(type.platform)
    );
  }

  doesSnippetNeedTranslation(snippet) {
    if (!this.hasSpanishContent()) {
      return false;
    }
    if (!snippet.content?.['es-US']) {
      return true;
    }
    if (!snippet.content_title?.['es-US']) {
      return true;
    }
    return false;
  }

  doesQuestionNeedTranslation(question) {
    if (!this.hasSpanishContent()) {
      return false;
    }
    if (!question.content?.['es-US']) {
      return true;
    }
    if (!question.content_title?.['es-US']) {
      return true;
    }

    for (const answer of question.answers) {
      if (!answer.answer?.['es-US']) {
        return true;
      }
    }

    return false;
  }

  doesModuleNeedTranslation() {
    if (!this.hasSpanishContent()) {
      return false;
    }

    if (!this.moduleData.module.module_name?.['es-US']) {
      return true;
    }

    for (const snippet of this.moduleData.module.content.snippets.values) {
      if (this.doesSnippetNeedTranslation(snippet)) {
        return true;
      }
    }

    for (const question of this.moduleData.module.content.questions.values) {
      if (this.doesQuestionNeedTranslation(question)) {
        return true;
      }
    }

    return false;
  }
}
