<app-onboarding-header></app-onboarding-header>
<div class="form-container d-flex-col gap-24">
  <div class="d-flex-col gap-8">
    <h1 class="h3-bold">Zip Code Sponsorship</h1>
    <p class="body-book">
      Are you interested in sponsoring zip codes to drive community engagement
      with your Zogo app?
    </p>
  </div>
  <form
    class="d-flex-col gap-24"
    #form="ngForm"
    (ngSubmit)="onSubmitZipCodeInterest()"
  >
    <zogo-radio-group
      [(ngModel)]="isInterested"
      name="isInterestedZip"
      class="d-flex-col gap-8"
      required
      #radioGroup="ngModel"
    >
      <zogo-radio-button
        [value]="true"
        label="Yes, I'm interested"
      ></zogo-radio-button>
      <zogo-radio-button
        [value]="false"
        label="No, only users with institution's access code can gain entry"
      ></zogo-radio-button>
    </zogo-radio-group>
    <zogo-btn
      [disabled]="form.invalid"
      size="large"
      label="Continue"
      [fullWidth]="true"
      type="submit"
    ></zogo-btn>
  </form>
</div>
