import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-email-reminder-modal',
  templateUrl: './email-reminder-modal.component.html',
  styleUrls: ['./email-reminder-modal.component.css'],
})
export class EmailReminderModalComponent {
  email: string = this.data.email;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private req: RequestsService
  ) {}

  dismiss() {
    this.dialog.closeAll();
  }

  handleEmailInput(event) {
    this.email = event;
  }

  submit() {
    this.req.postDashboardSocialCalendarContact(this.email).subscribe({
      next: data => {
        // Amplitude event - Social Calendar Email Reminders Enrolled
        this.req.ampTrack('Social Calendar Email Reminders Enrolled');
        this.req.openSnackBar('You’re signed up for email reminders!', 'Okay');
        this.dismiss();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  isValidEmail() {
    if (this.email.length === 0 || this.email.indexOf('@') === -1) {
      return false;
    }
    return true;
  }
}
