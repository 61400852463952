<mat-select
  #select="matSelect"
  [ngModel]="selectedOption"
  (selectionChange)="onSelectionChange($event)"
  class="input-container"
  placeholder="Temp"
>
  <mat-select-trigger>
    <div class="caption-medium text-color-neutral-800">
      {{ selectedOption?.display_value }}
    </div>
  </mat-select-trigger>
  <div class="search-container">
    <svg
      class="margin-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
        fill="#AEAEAE"
      />
    </svg>

    <input
      [(ngModel)]="searchTerm"
      matInput
      (ngModelChange)="filterSegments()"
      class="search-input caption-medium text-color-neutral-800"
      placeholder="Search"
    />
  </div>
  <mat-option
    class="caption-medium text-color-neutral-800"
    [value]="ALL_ACCESS_CODE_OPTION"
  >
    {{ ALL_ACCESS_CODE_OPTION.display_value }}
  </mat-option>
  <mat-option
    class="caption-medium text-color-neutral-800"
    [value]="UNASSIGNED_OPTION"
  >
    {{ UNASSIGNED_OPTION.display_value }}
  </mat-option>
  <div class="column-headers caption-medium uppercase text-color-neutral-600">
    <div class="column-header">access codes</div>
    <div class="column-header">description</div>
  </div>
  <div class="segment-container">
    <mat-option
      *ngFor="let segment of filteredSegments"
      class="segment-select-option"
      [value]="{ value: segment.code, display_value: segment.code }"
    >
      <div class="row-container caption-medium text-color-neutral-800">
        <div class="row-segment">
          {{ segment.code }}
        </div>
        <div class="row-segment">
          {{ segment.description }}
        </div>
      </div>
    </mat-option>
  </div>
</mat-select>
