import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { PrePostTestComponent } from './pre-post-test/pre-post-test.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ModulesComponent } from './customization/modules/modules.component';
import { ContentLibraryComponent } from './customization/content-library/content-library.component';
import { EditorComponent } from './customization/modules/editor/editor.component';
import { SurveyQuestionComponent } from './customization/survey-questions/survey-questions.component';
import { QuestionEditorComponent } from './customization/survey-questions/question-editor/question-editor.component';
import { DemoComponent } from './demo/demo.component';
import { PublicComponent } from './public/public.component';
import { SchoolsComponent } from './schools/schools.component';
import { CanDeactivateGuard } from './guards/can-deactivate-guard.service';
import { ErrorComponent } from './error/error.component';
import { OnboardingAccountSettingsComponent } from './onboarding/onboarding-account-settings/onboarding-account-settings.component';
import { OnboardingPaymentSettingsComponent } from './onboarding/onboarding-payment-settings/onboarding-payment-settings.component';
import { OnboardingTeamMembersComponent } from './onboarding/onboarding-team-members/onboarding-team-members.component';
import { OnboardingLogoUploadComponent } from './onboarding/onboarding-logo-upload/onboarding-logo-upload.component';
import { OnboardingZipCodesComponent } from './onboarding/onboarding-zip-codes/onboarding-zip-codes.component';
import { OnboardingWebSubdomainComponent } from './onboarding/onboarding-web-subdomain/onboarding-web-subdomain.component';
import { AccessCodesComponent } from './settings/access-codes/access-codes.component';
import { NewUserOnboardingComponent } from './onboarding/new-user-onboarding/new-user-onboarding.component';
import { CustomColorComponent } from './customization/custom-color/custom-color.component';
import { LeaderboardDownloadComponent } from './leaderboard-download/leaderboard-download.component';
import { MarketingKitComponent } from './resources/marketing-kit/marketing-kit.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SocialCalendarComponent } from './resources/social-calendar/social-calendar.component';
import { MarketingAssetsComponent } from './resources/marketing-assets/marketing-assets.component';
import { authGuard } from './auth.guard';
import { ZogoWebCustomizationsComponent } from './zogo-web-customizations/zogo-web-customizations.component';
import { LogoCustomizationComponent } from './customization/logo-customization/logo-customization.component';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'overview', component: OverviewComponent, canActivate: [authGuard] },
  {
    path: 'pre-post-test',
    component: PrePostTestComponent,
    canActivate: [authGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ErrorComponent },
  {
    path: 'modules',
    component: ModulesComponent,
    canActivate: [authGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'content-library',
    component: ContentLibraryComponent,
    canActivate: [authGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'custom-color',
    component: CustomColorComponent,
    canActivate: [authGuard],
  },
  {
    path: 'custom-logos',
    component: LogoCustomizationComponent,
    canActivate: [authGuard],
  },
  {
    path: 'zogo-web-customizations',
    component: ZogoWebCustomizationsComponent,
    canActivate: [authGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'editor',
    component: EditorComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  { path: 'demo', component: DemoComponent },
  { path: 'public', component: PublicComponent },
  { path: 'schools', component: SchoolsComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'onboarding/account-settings',
    component: OnboardingAccountSettingsComponent,
  },
  {
    path: 'onboarding/payment-settings',
    component: OnboardingPaymentSettingsComponent,
  },
  {
    path: 'onboarding/additional-members',
    component: OnboardingTeamMembersComponent,
  },
  { path: 'onboarding/logo', component: OnboardingLogoUploadComponent },
  {
    path: 'onboarding/zip-code-interest',
    component: OnboardingZipCodesComponent,
  },
  {
    path: 'onboarding/zogo-web-subdomain',
    component: OnboardingWebSubdomainComponent,
  },
  { path: 'new-user-onboarding', component: NewUserOnboardingComponent },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'access-codes',
    component: AccessCodesComponent,
    canActivate: [authGuard],
  },
  {
    path: 'leaderboard-download',
    component: LeaderboardDownloadComponent,
    canActivate: [authGuard],
  },
  {
    path: 'resources',
    component: MarketingKitComponent,
    canActivate: [authGuard],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'custom-surveys',
    component: SurveyQuestionComponent,
    canActivate: [authGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'custom-surveys/question-editor',
    component: QuestionEditorComponent,
    canActivate: [authGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'social-calendar',
    component: SocialCalendarComponent,
    canActivate: [authGuard],
  },
  {
    path: 'marketing-assets',
    component: MarketingAssetsComponent,
    canActivate: [authGuard],
  },
  {
    path: '**',
    component: OverviewComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AppRoutingModule {}
