<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="d-flex-col gap-24">
  <ng-container *ngFor="let group of formGroups">
    <fieldset class="d-flex-col gap-8">
      <legend class="caption-medium margin-bottom-8">{{ group.label }}</legend>
      <div *ngFor="let field of group.fields; index as i">
        <ng-container [ngSwitch]="field.type">
          <!-- Text Inputs -->
          <div *ngSwitchCase="'text'" class="input-row">
            <span *ngIf="field.iconName" class="input-icon">
              <zogo-icon [iconName]="field.iconName || ''"></zogo-icon>
            </span>
            <label [for]="field.controlName" class="visually-hidden">{{
              field.label
            }}</label>
            <input
              [id]="field.controlName"
              class="input-field body-book"
              [ngClass]="{
                'remove-icon-padding': !field.iconName,
                shadow: helper.isMobile()
              }"
              [autocomplete]="field.autocompleteType"
              [formControlName]="field.controlName"
              [ariaLabel]="field.label"
              [placeholder]="field.placeholder"
            />
          </div>

          <!-- Checkbox -->
          <div
            *ngSwitchCase="'checkbox'"
            class="d-flex-row gap-8 ion-align-items-center"
          >
            <zogo-checkbox
              [terms]="field.controlName === 'terms'"
              [formControlName]="field.controlName"
              [label]="field.label"
            ></zogo-checkbox>
          </div>

          <!-- Radio Select -->
          <div
            *ngSwitchCase="'radio'"
            class="d-flex-col gap-8 padding-x-16 padding-y-12 border border-radius-8 border-neutral-200 ion-align-items-center"
          >
            <label class="caption-book">
              {{ field.label }}
            </label>

            <zogo-radio-group class="d-flex-row gap-24 ion-align-self-start">
              <zogo-radio-button
                *ngFor="let option of field.radioOptions"
                [formControlName]="field.controlName"
                [value]="option.value"
                [label]="option.label"
              ></zogo-radio-button>
            </zogo-radio-group>

            <div
              *ngIf="formGroup.contains(field.controlName + '_other')"
              class="ion-align-self-stretch"
            >
              <label class="visually-hidden">
                Please specify which financial institution:
              </label>
              <input
                class="input-field remove-icon-padding body-book"
                [formControlName]="field.controlName + '_other'"
                [placeholder]="'Financial Institution name'"
              />
            </div>
          </div>

          <!-- Dropdowns -->
          <div *ngSwitchCase="'select'" class="dropdown-container">
            <label class="visually-hidden">{{ field.label }}</label>
            <select
              required
              class="select-field body-book"
              [ngClass]="{
                shadow: helper.isMobile()
              }"
              [ariaLabel]="field.label"
              [formControlName]="field.controlName"
            >
              <option value="" disabled>
                {{ field.placeholder }}
              </option>
              <option *ngFor="let option of field.selectOptions">
                {{ option }}
              </option>
            </select>
            <zogo-icon
              class="dropdown-icon text-color-neutral-800"
              size="small"
              iconName="chevron-down"
            ></zogo-icon>
          </div>

          <!-- Error Indicators -->
          <div
            *ngIf="
              formGroup.get(field.controlName)?.invalid &&
              formGroup.get(field.controlName)?.touched
            "
          >
            <div
              *ngIf="formGroup.get(field.controlName)?.errors?.['email']"
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Please enter a valid email address.
            </div>
            <div
              *ngIf="
                formGroup.get(field.controlName)?.hasError('invalidDomain')
              "
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Please make sure you are using a corporate email address.
            </div>
            <!-- TODO: Get proper error message copy -->
            <div
              *ngIf="
                formGroup.get(field.controlName)?.hasError('invalidFullName')
              "
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Invalid name format. Please enter a first name and a last name.
            </div>
            <div
              *ngIf="
                formGroup.get(field.controlName)?.hasError('invalidPhoneNumber')
              "
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Invalid phone number format. Must be 10 digits.
            </div>
            <div
              *ngIf="
                formGroup.get(field.controlName)?.hasError('invalidZipCode')
              "
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Invalid zip code. Must be 5 digits.
            </div>
            <div
              *ngIf="formGroup.get(field.controlName)?.hasError('requiredTrue')"
              class="overline-bold text-color-brand-orange margin-top-8"
            >
              Accepting the terms and conditions is required.
            </div>
          </div>
        </ng-container>
      </div>
    </fieldset>
  </ng-container>

  <zogo-btn
    [ngClass]="{
      'fixed-button': !alignButtonStart,
      'ion-align-self-start': alignButtonStart,
      'ion-align-self-stretch': helper.isMobile()
    }"
    type="submit"
    label="Submit"
    [fullWidth]="helper.isMobile()"
    [disabled]="!formGroup.valid"
  ></zogo-btn>
</form>
