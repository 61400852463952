<div *ngIf="!surveyData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div
  *ngIf="surveyData"
  class="survey-wrapper"
  [ngClass]="{ 'pre-post-skill': mode === 'pre-post-skill' }"
>
  <div class="survey-header-container">
    <h4
      *ngIf="mode === 'overview'"
      class="surveys-header h5-bold text-color-neutral-800"
    >
      Survey Questions
    </h4>
    <h4
      *ngIf="mode !== 'overview'"
      class="surveys-header h5-bold text-color-neutral-800"
    >
      Survey Results
    </h4>
    <mat-select
      *ngIf="
        mode === 'overview' &&
        checkBadgeTextExists('partner') &&
        checkBadgeTextExists('zogo')
      "
      class="input-container survey-select caption-medium text-color-neutral-800"
      placeholder="All"
      [(ngModel)]="selectedSurveyOption"
      (selectionChange)="filterSurveys()"
    >
      <mat-option value="all">All</mat-option>
      <mat-option value="partner">Partner</mat-option>
      <mat-option value="zogo">Zogo</mat-option>
    </mat-select>
  </div>
  <div class="survey-container" #surveyScrollContainer>
    <div
      class="survey"
      *ngFor="let survey of filteredSurveys; let i = index"
      [ngClass]="{ last: i === filteredSurveys.length - 1 }"
    >
      <div class="caption-medium text-color-neutral-800">
        {{ survey?.content }}
      </div>

      <div class="caption-medium text-color-neutral-600">
        {{ survey?.content_description }}
      </div>

      <div [class]="'survey-text caption-medium ' + survey?.badge_text">
        {{ survey?.badge_text }}
      </div>

      <div class="chart-container">
        <div class="chart-bar" *ngFor="let data of survey?.answer_options">
          <div class="bar-group">
            <div class="caption-medium text-color-neutral-800">
              {{ data.answer }}
            </div>
            <div class="caption-medium text-color-neutral-800 bar-percentage">
              {{ data.percentage }}%
            </div>
          </div>
          <div class="bar-value-group">
            <div class="bar-background"></div>

            <div class="bar-value" [style.width.%]="data.percentage"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <zogo-pagination-arrow
    *ngIf="filteredSurveys?.length > 1"
    class="survey-back-arrow"
    type="bold"
    icon="left"
    [disabled]="disableScrollLeft"
    (pageChange)="scrollBackSurvey()"
  >
  </zogo-pagination-arrow>
  <zogo-pagination-arrow
    *ngIf="filteredSurveys?.length > 1"
    class="survey-next-arrow"
    type="bold"
    icon="right"
    [disabled]="disableScrollRight"
    (pageChange)="scrollNextSurvey()"
  >
  </zogo-pagination-arrow>
</div>
