import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-logo-customization',
  templateUrl: './logo-customization.component.html',
  styleUrls: ['./logo-customization.component.scss'],
})
export class LogoCustomizationComponent implements OnInit {
  userData: any;
  institutionName: string;
  logoUrl: any;
  whiteLogoUrl: any;
  isEditState: boolean = false;
  isLoading: boolean;

  constructor(
    public req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    // Amplitude event - Logos Viewed
    this.req.ampTrack('Logos Viewed');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.institutionName = this.userData.institution.long_name;

    this.isLoading = true;
    this.initializeInputs();
  }

  async initializeInputs() {
    if (this.userData.institution.image) {
      this.logoUrl = this.userData.institution.image;
      this.logoUrl = await this.getBase64FromUrl(
        this.userData.institution.image + '?cacheblock=true'
      );
    }
    if (this.userData.institution.snippet_logo_image) {
      this.whiteLogoUrl = await this.getBase64FromUrl(
        this.userData.institution.snippet_logo_image + '?cacheblock=true'
      );
    }
    this.isLoading = false;
  }

  onImageUpload(event, logoType: 'primary' | 'white') {
    if (logoType === 'primary') {
      this.logoUrl = event.croppedImage;
    } else {
      this.whiteLogoUrl = event.croppedImage;
    }
  }

  handleCancel() {
    this.toggleEditState();
    this.initializeInputs();
  }

  toggleEditState() {
    this.isEditState = !this.isEditState;
  }

  async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  hasValidLogos() {
    if (this.logoUrl && this.whiteLogoUrl) {
      return true;
    }
    return false;
  }

  onSubmitLogos() {
    const request = {
      page: 'logo',
      logo_image: this.logoUrl,
      logo_image_white: this.whiteLogoUrl,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));
        this.isEditState = false;
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
