<div [class]="'notif-alert-wrapper ' + status">
  <div class="notif-alert-flex-left">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="status === 'pending'"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.43246 4.45503L1.66829 17.4167C1.34255 17.9808 1.3406 18.6754 1.66318 19.2413C1.98576 19.8073 2.58442 20.1595 3.23579 20.1667H18.7641C19.4155 20.1595 20.0142 19.8073 20.3367 19.2413C20.6593 18.6754 20.6574 17.9808 20.3316 17.4167L12.5675 4.45503C12.2351 3.90712 11.6408 3.57251 11 3.57251C10.3591 3.57251 9.76481 3.90712 9.43246 4.45503Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 9.16669V12.8334"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="10.9999"
        cy="16.5"
        rx="0.916667"
        ry="0.916667"
        fill="currentColor"
      />
    </svg>

    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="status === 'success'"
    >
      <path
        d="M20.2499 11.0641V11.9166C20.2476 15.9763 17.5753 19.5508 13.6823 20.7019C9.78927 21.8529 5.60283 20.3063 3.39326 16.9006C1.1837 13.495 1.47735 9.04165 4.11497 5.95563C6.7526 2.86961 11.1059 1.88604 14.8141 3.53831"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1666 3.66669L11.0833 13.75L8.33325 11"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="status === 'error'"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.20504 1.83331H14.795L20.1667 7.20498V14.795L14.795 20.1666H7.20504L1.83337 14.795V7.20498L7.20504 1.83331V1.83331Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 7.33331V11"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="11"
        cy="14.6667"
        rx="0.916667"
        ry="0.916667"
        fill="currentColor"
      />
    </svg>

    <p class="body-medium">
      {{ message }}
    </p>
  </div>
  <div class="notif-alert-flex-right">
    <div *ngIf="buttonText" class="notif-alert-btn" (click)="buttonFunc()">
      {{ buttonText }}
    </div>
    <zogo-icon
      class="notif-alert-dismiss-btn"
      iconName="x"
      (click)="dismiss()"
    ></zogo-icon>
  </div>
</div>
