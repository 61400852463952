import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../../requests.service';
import { OnboardingService } from '../onboarding.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-payment-settings',
  templateUrl: './onboarding-payment-settings.component.html',
  styleUrls: ['./onboarding-payment-settings.component.css'],
})
export class OnboardingPaymentSettingsComponent implements OnInit {
  userData: any;
  billingFormData = {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  };
  hasPhoneInputError: boolean = false;

  constructor(
    private req: RequestsService,
    private onboarding: OnboardingService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.initializeInputs();
  }

  initializeInputs() {
    this.billingFormData.contactName = this.userData.institution.contact_name;
    this.billingFormData.contactEmail = this.userData.institution.contact_email;
    this.billingFormData.contactPhone = this.userData.institution.contact_name;
  }

  onPhoneChange(number: string) {
    let phoneNumber = number;
    if (!number.startsWith('+1')) {
      phoneNumber = '+1' + number;
    }

    const phoneRegex = /^(?:\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (number && phoneRegex.test(phoneNumber)) {
      this.hasPhoneInputError = false;
      this.billingFormData.contactPhone = phoneNumber;
    } else {
      this.hasPhoneInputError = true;
    }
  }

  onSubmitForm() {
    const request = {
      page: 'payment-settings',
      contact_name: this.billingFormData.contactName,
      contact_email: this.billingFormData.contactEmail,
      contact_phone: this.billingFormData.contactPhone,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Billing Information Completed
        this.req.ampTrack('Billing Information Completed');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.req
          .postDashboardOnboarding({ page: 'payment-settings' })
          .subscribe({
            next: data => {
              this.onboarding.handleContinue();
            },
            error: err => {
              this.toastService.showToast(
                'There was an error: ' + err.error.message,
                6000
              );
            },
          });
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  handleSkip() {
    this.req.postDashboardOnboarding({ page: 'payment-settings' }).subscribe({
      next: data => {
        // Amplitude event - Billing Information Skipped
        this.req.ampTrack('Billing Information Skipped');
        this.onboarding.handleContinue();
        this.onboarding.markPromptAsSeen('payment-settings');
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }
}
