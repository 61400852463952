<form
  [formGroup]="formGroup"
  (ngSubmit)="onSearch()"
  class="container d-flex-col gap-24"
>
  <fieldset class="d-flex-col gap-8">
    <div class="input-row">
      <span class="input-icon">
        <zogo-icon iconName="search"></zogo-icon>
      </span>
      <label class="visually-hidden">{{ label }}</label>
      <input
        class="input-field body-medium"
        [ngClass]="{
          border: borderStyle === 'border',
          shadow: helper.isMobile()
        }"
        formControlName="search"
        autocomplete="off"
        [placeholder]="label"
      />
    </div>
  </fieldset>
</form>
