import { Injectable } from '@angular/core';
import { RequestsService } from '../requests.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  currentGroupIndex: number = 0;
  currentPageIndex: number = 0;
  promptIndex: number = 0;

  constructor(private req: RequestsService) {}

  hasCompletedEveryPage(pageGroup) {
    return pageGroup.pages.every(page => page.is_complete);
  }

  getFirstOnboardingPage(onboardingFlow) {
    for (let groupIndex = 0; groupIndex < onboardingFlow.length; groupIndex++) {
      const group = onboardingFlow[groupIndex];
      for (let pageIndex = 0; pageIndex < group.pages.length; pageIndex++) {
        const page = group.pages[pageIndex];
        if (!page.is_complete) {
          this.currentGroupIndex = groupIndex;
          this.currentPageIndex = pageIndex;
          return page.page;
        }
      }
    }
    this.currentGroupIndex = 0;
    this.currentPageIndex = 0;
    return null;
  }

  handleContinue() {
    const currentGroup = this.req.onboardingFlow[this.currentGroupIndex];

    if (this.currentPageIndex < currentGroup.pages.length - 1) {
      this.req.onboardingFlow[this.currentGroupIndex].pages[
        this.currentPageIndex
      ].is_complete = true;
      this.currentPageIndex++;
    } else {
      this.req.onboardingFlow[this.currentGroupIndex].pages[
        this.currentPageIndex
      ].is_complete = true;

      this.currentPageIndex = 0;
      this.currentGroupIndex++;

      if (this.currentGroupIndex >= this.req.onboardingFlow.length) {
        console.log('Onboarding complete!');
        window.location.href = '/';
        return;
      }
    }

    const nextGroup = this.req.onboardingFlow[this.currentGroupIndex];
    const nextPage = nextGroup.pages[this.currentPageIndex];

    this.req.handleOnboardingNavigation(nextPage.page);
  }

  calculateProgress(groupType: string) {
    const pageGroupIndex = this.req.onboardingFlow.findIndex(
      group => group.page_group_type === groupType
    );

    if (pageGroupIndex >= 0) {
      if (this.hasCompletedEveryPage(this.req.onboardingFlow[pageGroupIndex])) {
        return 1;
      }

      const currentGroup = this.req.onboardingFlow[pageGroupIndex];
      const currentPageCount =
        this.currentGroupIndex === pageGroupIndex
          ? this.currentPageIndex + 1
          : 0;

      return currentPageCount / currentGroup.pages.length;
    }
    return 0;
  }

  getNextPrompt(): string | undefined {
    if (this.req.remainingOnboardingFlow) {
      if (
        this.hasSeenPrompt(
          this.req.remainingOnboardingFlow[this.promptIndex]?.prompt
        )
      ) {
        this.promptIndex++;
      }
      return this.req.remainingOnboardingFlow[this.promptIndex]?.prompt;
    }
    return undefined;
  }

  markPromptAsSeen(prompt: string) {
    sessionStorage.setItem(`seen_${prompt}_prompt`, 'true');
  }

  hasSeenPrompt(prompt: string): boolean {
    return sessionStorage.getItem(`seen_${prompt}_prompt`) === 'true';
  }
}
