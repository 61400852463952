import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

@Component({
  selector: 'zogo-help-button',
  templateUrl: './zogo-help-button.component.html',
  styleUrls: ['./zogo-help-button.component.scss'],
  standalone: true,
  imports: [ZogoIconComponent],
})
export class ZogoHelpButtonComponent implements OnInit {
  @Input() label: string = '';
  @Output() clickFunction = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  handleClick() {
    this.clickFunction.emit();
  }
}
