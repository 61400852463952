<div class="marketing-card-frame">
  <div
    class="marketing-icon-frame"
    [ngClass]="{
      download: marketingCardData.action === 'download',
      link: marketingCardData.action === 'link'
    }"
  >
    <img [src]="marketingCardData.icon" alt="" />
  </div>

  <div class="marketing-card-content">
    <section class="d-flex-col gap-8">
      <div class="h4-bold">{{ marketingCardData.header }}</div>
      <markdown
        class="marketing-card-description body-book d-flex-col gap-8"
        [data]="marketingCardData.description_markdown"
      ></markdown>
    </section>
    <zogo-btn
      *ngIf="marketingCardData.action === 'download'"
      buttonStyle="secondary"
      label="Download Zip"
      iconName="download-cloud"
      (click)="downloadFile()"
    ></zogo-btn>
    <div *ngIf="marketingCardData.action === 'link'" class="d-flex-row gap-8">
      <zogo-btn
        buttonStyle="secondary"
        label="View"
        iconName="external-link"
        (click)="openLink()"
      ></zogo-btn>
      <zogo-btn
        buttonStyle="secondary"
        iconName="copy"
        (click)="copyLink()"
      ></zogo-btn>
    </div>
  </div>
</div>
