import { Component, Input } from '@angular/core';
import { CopyService } from '../../../copy.service';
import { RequestsService } from '../../../requests.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-marketing-kit-card',
  templateUrl: './marketing-kit-card.component.html',
  styleUrls: ['./marketing-kit-card.component.css'],
})
export class MarketingKitCardComponent {
  @Input() marketingCardData: any;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    private clipboardService: ClipboardService
  ) {}

  downloadFile() {
    // Amplitude event - Resource Folder Downloaded
    this.req.ampTrack('Resource Folder Downloaded', {
      resource_group_name: this.marketingCardData.header,
    });
    this.req.openURL(this.marketingCardData.url);
  }

  openLink() {
    // Amplitude event - Resource Link Opened
    this.req.ampTrack('Resource Link Opened', {
      resource_group_name: this.marketingCardData.header,
    });
    this.req.openURL(this.marketingCardData.url);
  }

  copyLink() {
    // Amplitude event - Resource Link Copied
    this.req.ampTrack('Resource Link Copied', {
      resource_group_name: this.marketingCardData.header,
    });
    this.clipboardService.copyFromContent(this.marketingCardData.url);
    this.req.openSnackBar('Link copied to clipboard', 'Okay');
  }
}
