import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import { ZogoBtnComponent } from 'src/app/design-system/web-components';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
  standalone: true,
  imports: [ImageCropperComponent, ZogoIconComponent, ZogoBtnComponent],
})
export class ImageCropperModalComponent implements OnInit {
  @Input() imageFile!: File;
  @Input() showDarkBackground: boolean = false;
  @Output() loadImageFailed = new EventEmitter<void>();
  imageChangedEvent: any;
  croppedImage: any;
  fileName: string;
  fileSize: number;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async saveCroppedImage() {
    await this.modalController.dismiss(
      {
        croppedImage: this.croppedImage,
        fileName: this.fileName,
        fileSize: this.fileSize,
      },
      'save'
    );
  }

  async dismiss() {
    await this.modalController.dismiss(null, 'cancel');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.objectUrl || event.base64 || '';
    this.fileName = this.imageFile.name;
    this.fileSize = this.imageFile.size;
  }
}
