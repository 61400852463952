import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { RequestsService } from 'src/app/requests.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-zip-codes',
  templateUrl: './onboarding-zip-codes.component.html',
  styleUrls: ['./onboarding-zip-codes.component.css'],
})
export class OnboardingZipCodesComponent implements OnInit {
  userData: any;
  isInterested: boolean = false;

  constructor(
    private onboarding: OnboardingService,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  onSubmitZipCodeInterest() {
    const request = {
      page: 'zip-code-interest',
      is_interested_zip: this.isInterested,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Sponsorship Interest Confirmed
        this.req.ampTrack('Sponsorship Interest Confirmed');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.req
          .postDashboardOnboarding({ page: 'zip-code-interest' })
          .subscribe({
            next: data => {
              this.onboarding.handleContinue();
              this.onboarding.markPromptAsSeen('zip-code');
            },
            error: err => {
              this.toastService.showToast(
                'There was an error: ' + err.error.message,
                6000
              );
              console.log('errors', err.error);
            },
          });
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
