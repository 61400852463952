import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'zogo-icon',
  templateUrl: './zogo-icon.component.html',
  styleUrls: ['./zogo-icon.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ZogoIconComponent {
  @Input() iconName: string = '';
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' = 'medium';
  @Input() role: string = 'presentation';

  constructor() {}
}
