<div *ngIf="mode === 'login'" class="login-container pos-rel">
  <img
    class="branded-pattern"
    src="assets/imgs/branded-pattern-login.png"
    alt=""
  />

  <div class="login-wrapper d-flex-row">
    <div class="form-container d-flex-col gap-24">
      <img class="zogo-logo" src="global/logos/zogo-blue.svg" alt="" />

      <h1 class="h1-bold">
        {{ forgotPassword ? 'Password Recovery' : 'Partner Portal Login' }}
      </h1>

      <form #form="ngForm" (ngSubmit)="login()">
        <div class="d-flex-col gap-12 margin-bottom-24">
          <zogo-text-input
            [(ngModel)]="loginForm.email"
            name="email"
            inputId="email-address"
            inputLabel="Email Address"
            inputType="email"
            autocompleteType="email"
            ariaLabel="Email Address"
            [isRequired]="true"
          ></zogo-text-input>
          <zogo-text-input
            [(ngModel)]="loginForm.password"
            *ngIf="!forgotPassword"
            name="password"
            inputId="password"
            inputLabel="Password"
            inputType="password"
            autocompleteType="current-password"
            ariaLabel="password"
            [isRequired]="true"
          ></zogo-text-input>

          <zogo-decorated-link
            tappable
            *ngIf="!forgotPassword"
            label="Forgot your password?"
            [displayOnly]="true"
            (click)="forgotPassword = !forgotPassword"
          ></zogo-decorated-link>
          <zogo-decorated-link
            tappable
            *ngIf="forgotPassword"
            iconName="arrow-left"
            label="Go back to login"
            iconLocation="leading"
            [displayOnly]="true"
            (click)="forgotPassword = !forgotPassword"
          ></zogo-decorated-link>
        </div>

        <zogo-btn
          [disabled]="form.invalid"
          type="submit"
          size="large"
          [label]="forgotPassword ? 'Send password reset' : 'Log In'"
        ></zogo-btn>
      </form>
      <p class="overline-book text-color-neutral-600">
        By accessing and registering to use Zogo's services, you acknowledge and
        agree to comply with our
        <a href="https://zogo.com/terms-of-service" target="_blank">
          Terms of Service</a
        >. If you are not in agreement with these terms and conditions, you
        should refrain from further use of our services.
      </p>
    </div>
  </div>
</div>
<div *ngIf="mode === 'institution-select'" class="select-institution-wrapper">
  <app-select-institution
    [institutionOptions]="userData?.institution_user.institution_options"
  >
  </app-select-institution>
</div>
