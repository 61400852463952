<div *ngIf="req.isTestingEnvironment()" class="testing-env">
  Testing Environment
</div>

<div
  class="sidenav-container"
  *ngIf="
    userData &&
    req.routerURL !== '/login' &&
    req.routerURL !== '/reset-password' &&
    req.routerURL !== '/new-user-onboarding' &&
    req.routerURL !== '/demo' &&
    req.routerURL !== '/public' &&
    req.routerURL !== '/error' &&
    !req.routerURL.includes('/onboarding')
  "
>
  <div class="sidenav" *ngIf="userData && !req.isShareView">
    <side></side>
  </div>
  <div class="content">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>

<div
  class="login-frame background-neutral-white pos-rel"
  *ngIf="
    req.routerURL === '/login' ||
    req.routerURL === '/demo' ||
    req.routerURL === '/public' ||
    req.routerURL === '/error'
  "
>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<div class="reset-password" *ngIf="req.routerURL === '/reset-password'">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<div class="onboarding" *ngIf="req.routerURL.includes('/onboarding')">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<div class="onboarding" *ngIf="req.routerURL === '/new-user-onboarding'">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<ng-template #template>
  <router-outlet> </router-outlet>
</ng-template>
