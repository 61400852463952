import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperFunctionsService {
  constructor() {}

  /**
   * Checks if the device is an iPhone or Android
   *
   * @returns { Boolean } True if the current device is a phone
   */
  public isMobile(): boolean {
    if (
      navigator.userAgent.includes('iPhone') ||
      navigator.userAgent.includes('Android')
    ) {
      return true;
    }
    return false;
  }

  public calculateContrast(hex) {
    const color1rgb = this.hexToRGB(hex);
    const color2rgb = this.hexToRGB('#fff');
    const color1luminance = this.luminance(
      color1rgb?.r,
      color1rgb?.g,
      color1rgb?.b
    );
    const color2luminance = this.luminance(
      color2rgb?.r,
      color2rgb?.g,
      color2rgb?.b
    );
    const ratio =
      color1luminance > color2luminance
        ? (color2luminance + 0.05) / (color1luminance + 0.05)
        : (color1luminance + 0.05) / (color2luminance + 0.05);
    return 1 / ratio;
  }

  hexToRGB(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  luminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  public calculateColors(colorHex) {
    const LIGHT_PERC = 0.15;
    const DARK_PERC = -20;
    return {
      color: colorHex,
      colorTint: this.hexToRgbSolidOpacity(colorHex, LIGHT_PERC),
      colorShade: this.adjust(colorHex, DARK_PERC),
    };
  }

  adjust(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor((R * (100 + percent)) / 100);
    G = Math.floor((G * (100 + percent)) / 100);
    B = Math.floor((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    const GG =
      G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    const BB =
      B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }

  hexToRgbSolidOpacity(hex: string, alpha: number) {
    const r = (1 - alpha) * 255 + alpha * parseInt(hex.slice(1, 3), 16);
    const g = (1 - alpha) * 255 + alpha * parseInt(hex.slice(3, 5), 16);
    const b = (1 - alpha) * 255 + alpha * parseInt(hex.slice(5, 7), 16);
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }

  contrastRatio(l1: number, l2: number): number {
    return (l1 + 0.05) / (l2 + 0.05);
  }

  rgbToHex(r: number, g: number, b: number): string {
    return (
      '#' +
      [r, g, b]
        .map(x => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } {
    let r = 0,
      g = 0,
      b = 0;

    // 3 digits hex
    if (hex.length == 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits hex
    else if (hex.length == 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return { r, g, b };
  }

  public getColorFromClassName(className: string | null): string {
    // Create a temporary element with the provided class name
    const tempElement = document.createElement('div');

    if (className) {
      tempElement.className = className;
      document.body.appendChild(tempElement);

      // Get the computed style of the element
      const computedStyle = window.getComputedStyle(tempElement);
      const rgbColor = computedStyle.color;

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Convert RGB to HEX
      const hexColor = this.rgbToHexString(rgbColor || '#000000'); // Ensure a string is passed

      return hexColor;
    } else {
      return '#000000'; // Fallback color
    }
  }

  private rgbToHexString(rgb: string): string {
    // Extract the RGB values from the rgb string
    const result = rgb.match(/\d+/g);
    if (result) {
      // Convert each RGB component to hex
      const r = parseInt(result[0]).toString(16).padStart(2, '0');
      const g = parseInt(result[1]).toString(16).padStart(2, '0');
      const b = parseInt(result[2]).toString(16).padStart(2, '0');
      return `#${r}${g}${b}`.toUpperCase();
    }
    return '#000000'; // Fallback HEX color if parsing fails
  }

  darkenColor(
    r: number,
    g: number,
    b: number,
    factor: number
  ): { r: number; g: number; b: number } {
    // Darken by the minimum factor first
    r = Math.max(0, r - factor);
    g = Math.max(0, g - factor);
    b = Math.max(0, b - factor);
    return { r, g, b };
  }

  getContrastCompliantDarkenedColor(hex: string, factor: number): string {
    let { r, g, b } = this.hexToRgb(hex);
    const whiteLuminance = this.luminance(255, 255, 255);

    // Darken the color by the minimum factor before checking contrast
    ({ r, g, b } = this.darkenColor(r, g, b, factor));

    // Continue adjusting the color until the contrast ratio with white is >= 4.5:1
    while (this.contrastRatio(whiteLuminance, this.luminance(r, g, b)) < 4.5) {
      // Keep darkening the color slightly by 1 unit in each channel
      if (r > 0) r -= 1;
      if (g > 0) g -= 1;
      if (b > 0) b -= 1;
    }

    return this.rgbToHex(r, g, b);
  }

  calculateShade(hex) {
    // Function to convert hex to HSL
    function hexToHSL(hex) {
      hex = hex.replace(/^#/, '');

      const r = parseInt(hex.substring(0, 2), 16) / 255;
      const g = parseInt(hex.substring(2, 4), 16) / 255;
      const b = parseInt(hex.substring(4, 6), 16) / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let l = (max + min) / 2;

      let s = 0;
      if (max !== min) {
        s =
          l < 0.5 ? (max - min) / (max + min) : (max - min) / (2.0 - max - min);
      }

      let h = 0;
      if (max === r) {
        h = (g - b) / (max - min);
      } else if (max === g) {
        h = 2.0 + (b - r) / (max - min);
      } else if (max === b) {
        h = 4.0 + (r - g) / (max - min);
      }

      h = Math.round(h * 60);
      if (h < 0) h += 360;

      s = Math.round(s * 100);
      l = Math.round(l * 100);

      return { h, s, l };
    }

    // Function to convert HSL back to hex
    function HSLToHex(h, s, l) {
      s /= 100;
      l /= 100;

      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
      const m = l - c / 2;

      let r = 0,
        g = 0,
        b = 0;
      if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
      }

      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    // Main logic to adjust saturation and lightness
    const { h } = hexToHSL(hex);
    let { s, l } = hexToHSL(hex);

    // Apply the transformations
    if (l > 40) {
      l = l - 15;
    } else {
      l = Math.round(l / 2);
    }

    s = s + Math.round((100 - s) / 2);

    // Convert back to hex
    return HSLToHex(h, s, l);
  }
}
