<div class="dashboard-view">
  <div class="header">
    <h1 class="h3-bold">Social Calendar</h1>
    <mat-select
      [(ngModel)]="timeFrame"
      (selectionChange)="onSelectionChange()"
      class="input-container h5-medium"
    >
      <mat-option class="h5-medium" value="upcoming">Upcoming</mat-option>
      <mat-option class="h5-medium" value="archived">Archived</mat-option>
    </mat-select>
  </div>
  <div class="sub-header">
    <div class="body-medium text-color-neutral-600">
      Use this calendar as a guide to build your social media presence. Download
      images and captions from any of the templates below.
    </div>
    <zogo-btn
      label="Get Email Reminders"
      [fullWidth]="false"
      size="medium"
      buttonStyle="primary"
      (clickFunction)="handleGetEmailReminders()"
    >
    </zogo-btn>
  </div>
  <div *ngIf="!socialCalendarData" class="text-align-center">
    <ion-spinner name="circular"></ion-spinner>
  </div>
  <div *ngIf="socialCalendarData" class="calendar">
    <div
      *ngFor="let month of socialCalendarData.months; index as i"
      class="month"
    >
      <button
        class="accordion"
        (click)="togglePanel(i)"
        [ngClass]="{
          active: activePanels.has(i),
          first: i === 0,
          last: i === socialCalendarData.months.length - 1,
          empty: month.events.length === 0
        }"
      >
        <span class="h5-bold"
          >{{ month.month_name }}
          <span *ngIf="month.events.length"
            >({{ month.events.length }} posts)</span
          >
        </span>
        <span class="accordion-icon">
          <svg
            *ngIf="activePanels.has(i)"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 15L12 9L6 15"
              stroke="#6F6F6F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            *ngIf="!activePanels.has(i)"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#6F6F6F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
      <div class="panel" [ngClass]="{ active: activePanels.has(i) }">
        <div class="panel-content">
          <div *ngIf="month.events.length === 0" class="empty-panel-content">
            <svg
              class="empty-icon"
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.31299 12C1.31299 5.92487 6.23786 1 12.313 1H52.6866C58.7618 1 63.6866 5.92487 63.6866 12V52.3736C63.6866 58.4488 58.7618 63.3736 52.6866 63.3736H12.313C6.23786 63.3736 1.31299 58.4488 1.31299 52.3736V12Z"
                fill="#E8EDF4"
                stroke="white"
                stroke-width="2"
              />
              <g opacity="0.5">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.5737 18.2698C14.5737 16.0557 16.3686 14.2607 18.5828 14.2607H46.646C48.8601 14.2607 50.655 16.0556 50.655 18.2698V46.333C50.655 48.5472 48.8601 50.3421 46.646 50.3421H18.5828C16.3686 50.3421 14.5737 48.5472 14.5737 46.333V18.2698Z"
                  stroke="#7C9FD7"
                  stroke-width="4.00904"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.5986 28.2924C27.2592 28.2924 28.6053 26.9462 28.6053 25.2856C28.6053 23.625 27.2592 22.2788 25.5986 22.2788C23.938 22.2788 22.5918 23.625 22.5918 25.2856C22.5918 26.9462 23.938 28.2924 25.5986 28.2924Z"
                  stroke="#7C9FD7"
                  stroke-width="4.00904"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M50.6548 38.3151L40.6322 28.2925L18.5825 50.3422"
                  stroke="#7C9FD7"
                  stroke-width="4.00904"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>

            <div class="empty-header h4-medium text-color-neutral-800">
              Content coming soon!
            </div>
            <div class="body-book text-color-neutral-600">
              Social posts for this month will be updated.
            </div>
          </div>
          <app-social-calendar-event
            *ngFor="let event of month.events; index as i"
            [eventData]="event"
            [isLast]="i === month.events.length - 1"
          >
          </app-social-calendar-event>
        </div>
      </div>
    </div>
  </div>
</div>
