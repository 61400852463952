<div class="dashboard-view">
  <div class="container-fluid">
    <div
      *ngIf="availableCustomModules?.unplaced_module_count > 0"
      class="placeable-module-cta-area-edit"
    >
      <div class="placeable-module-message body-b2-medium">
        <div class="placable-module-header">
          <div class="placable-module-header-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_6504_164080)">
                <path
                  d="M18.3346 9.2355V10.0022C18.3336 11.7992 17.7517 13.5477 16.6757 14.987C15.5998 16.4263 14.0874 17.4792 12.3641 17.9888C10.6408 18.4983 8.79902 18.4371 7.11336 17.8143C5.4277 17.1916 3.98851 16.0406 3.01044 14.5331C2.03236 13.0255 1.56779 11.2422 1.68603 9.44909C1.80427 7.65597 2.49897 5.9491 3.66654 4.58306C4.8341 3.21702 6.41196 2.26498 8.16479 1.86895C9.91763 1.47292 11.7515 1.65411 13.393 2.3855"
                  stroke="#777DEE"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3333 3.32812L10 11.6698L7.5 9.16979"
                  stroke="#777DEE"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6504_164080">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>
              You have
              {{ availableCustomModules?.unplaced_module_count }}
              {{
                availableCustomModules?.unplaced_module_count > 1
                  ? 'modules'
                  : 'module'
              }}
              ready to be placed!
            </div>
          </div>
          <svg
            class="minimize-arrow"
            (click)="invertPlacementCTA()"
            [ngClass]="{
              'rotate-arrow': placementCTADisplay,
              'unrotate-arrow': !placementCTADisplay
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#5B62EA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div *ngIf="placementCTADisplay" class="placeable-steps-area">
          To place a custom module:

          <ol type="1" class="placement-list">
            <li>
              Select the Zogo platform in which you want the module to appear.
            </li>
            <li>Click on “Edit Mode”.</li>

            <li>
              Using the drop-downs, select the skill in which you’d like to
              place your custom module.
            </li>
            <li>Click on “+” to place custom module in your desired skill.</li>
            <li>Select the module to enable it.</li>
          </ol>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'display'">
      <div class="content-library-header">
        <h1 class="h3-bold">{{ copy?.header }}</h1>
        <div class="body-b2-medium sub-header-color">
          {{ copy?.sub_header }}
        </div>
      </div>
      <div class="auto-enable-toggle-header" *ngIf="isAbletoEnableAutoEnable()">
        <div class="header-h5-bold-rounded text-color-header-1">
          Auto-Enable New Skills
        </div>
        <svg
          class="tappable"
          (click)="autoEnableExplanationModal()"
          matTooltipPosition="above"
          matTooltip="This function only applies to platforms where you can enable/disable Zogo content."
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5143_99737)">
            <path
              d="M8.00065 14.6654C11.6825 14.6654 14.6673 11.6806 14.6673 7.9987C14.6673 4.3168 11.6825 1.33203 8.00065 1.33203C4.31875 1.33203 1.33398 4.3168 1.33398 7.9987C1.33398 11.6806 4.31875 14.6654 8.00065 14.6654Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 10.6667V8"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 5.33203H8.00833"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_5143_99737">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <mat-slide-toggle
          (click)="toggleAutoEnable($event)"
          [(ngModel)]="autoEnableActive"
        ></mat-slide-toggle>
      </div>
      <div class="platform-tabs-edit-section">
        <div class="platform-tabs-section">
          <div
            *ngFor="let tabItem of userData.institution.platform_access_types"
            (click)="selectTab(tabItem.platform)"
            [ngClass]="tab === tabItem.platform ? 'selected' : ''"
            class="h5-medium"
          >
            {{ tabItem.display_name }}
          </div>
        </div>

        <div class="apply-button-container">
          <zogo-btn
            *ngIf="contentLibraryData?.show_trending_lessons"
            ariaLabel="Button that displays a tooltip when focused or hovered over"
            matTooltip="Editing is disabled for “Read-only” users"
            [matTooltipDisabled]="isAdmin"
            iconName="bookmark"
            label="Trending Skills"
            [disabled]="!isAdmin"
            (clickFunction)="isAdmin && changeMode('trending')"
          ></zogo-btn>
          <zogo-btn
            ariaLabel="Button that displays a tooltip when focused or hovered over"
            matTooltip="Editing is disabled for 'Read-only' users"
            [matTooltipDisabled]="isAdmin"
            iconName="edit-2"
            [disabled]="!isAdmin"
            [label]="copy?.edit_btn"
            (clickFunction)="isAdmin && changeMode('status')"
          ></zogo-btn>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'trending'" class="edit-container">
      <div class="edit-left-wrapper">
        <div class="edit-header">Select The Trending Skills?</div>
        <div class="edit-description">
          Please select 3 trending skills to pin on top of Zogo Web
        </div>
        <div
          *ngIf="getTrendingSkillCount() !== 3"
          class="edit-description warning"
        >
          {{
            'You currently have of ' +
              getTrendingSkillCount() +
              ' of 3 skills selected.  Please make sure you have exactly 3 selected before saving.'
          }}
        </div>
      </div>
      <div class="edit-right-wrapper">
        <div class="apply-button-container">
          <button
            class="edit-btn trending"
            mat-button
            (click)="handleTrendingCancel()"
          >
            CANCEL
          </button>
          <button
            class="edit-btn trending"
            mat-button
            [ngClass]="{
              'disabled-btn': trendingSkillList.size !== 3
            }"
            (click)="trendingSkillList.size === 3 && handleTrendingSave()"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'status'" class="edit-container">
      <div class="edit-left-wrapper">
        <div class="edit-header">{{ copy?.edit_mode_modal?.header }}</div>
        <div class="edit-description">
          {{ copy?.edit_mode_modal?.sub_header }}
        </div>
      </div>
      <div class="edit-right-wrapper">
        <div class="apply-button-container">
          <button class="view-mode-btn" mat-button (click)="handleStatusDone()">
            <img src="../../../assets/imgs/corner-up-left.svg" alt="" />
            {{ copy?.edit_mode_modal?.view_mode_btn }}
          </button>
        </div>
      </div>
    </div>

    <div class="content-area">
      <div *ngIf="!isLoadingContentLibrary">
        <mat-accordion *ngFor="let category of contentLibraryData?.categories">
          <mat-expansion-panel class="expansion mat-elevation-z0 card-frame">
            <mat-expansion-panel-header class="access-code">
              <mat-panel-title>
                <div class="dropdown-title">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6504_164011)">
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="#8C8C8C"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6504_164011">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  {{ category?.category_name }}
                </div>
                <div class="module-skill-count">
                  {{ category?.skill_count }} Skills /
                  {{ category?.module_count }} Modules
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="table">
              <ng-container
                *ngIf="contentLibraryData && !isLoadingContentLibrary"
              >
                <ng-container
                  *ngFor="let skill of category?.skills; let i = index"
                >
                  <div class="table-header" *ngIf="i === 0">
                    <div class="table-cell first-col">
                      <div
                        class="cell-content cell-header label-l2-medium first-col-header-margin"
                      >
                        {{ copy?.skill }}
                      </div>
                    </div>

                    <div class="table-cell second-col center">
                      <div class="cell-content cell-header label-l2-medium">
                        {{ copy?.enabled_modules }}
                      </div>
                    </div>

                    <div class="table-cell third-col center">
                      <div class="cell-content cell-header label-l2-medium">
                        Content
                      </div>
                    </div>
                    <div
                      class="table-cell fourth-col"
                      [ngClass]="
                        !contentLibraryData?.show_trending_lessons
                          ? 'last-cell'
                          : ''
                      "
                    >
                      <div class="cell-content cell-header label-l2-medium">
                        {{ copy?.id }}
                      </div>
                    </div>
                    <div
                      *ngIf="contentLibraryData?.show_trending_lessons"
                      class="table-cell fifth-col last-cell"
                    >
                      <div
                        class="cell-content cell-header label-l2-medium"
                      ></div>
                    </div>
                  </div>
                  <div (click)="showModulesForSkill(skill)" class="table-row">
                    <div class="table-cell first-col">
                      <div class="cell-content cell-label">
                        <div class="table-check-box-container">
                          <mat-checkbox
                            *ngIf="
                              mode === 'status' && areAllSkillsDisablable(skill)
                            "
                            [checked]="allSkillModulesSelected(skill)"
                            (click)="
                              selectAllModulesForSkill(
                                skill,
                                skill.levels,
                                $event
                              )
                            "
                            [disabled]="mode !== 'status'"
                          ></mat-checkbox>
                        </div>
                        <svg
                          *ngIf="
                            !selectedModuleIdsBySkill[
                              getSkillInSelectedIndex(skill)
                            ].expanded
                          "
                          class="arrow-icon"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 7L7 1L13 7"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <svg
                          *ngIf="
                            selectedModuleIdsBySkill[
                              getSkillInSelectedIndex(skill)
                            ].expanded
                          "
                          class="arrow-icon"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 1L7 7L1 0.999999"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div class="skill-title-wrapper">
                          <div>{{ skill.skill_title }}</div>

                          <div
                            matTooltip="Skill available in Spanish"
                            *ngIf="skill.is_translated"
                            class="spanish-skill-icon"
                          >
                            <img
                              src="https://images.ctfassets.net/o1xn7flb83yr/6wZ1lDAiB4mkbLqjRs5p2E/1c26eb3e7139b9ffb9bc06b5afd7ea4f/spain.png"
                              alt="spanish-flag"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-cell second-col center">
                      <div
                        *ngIf="contentLibraryData?.show_trending_lessons"
                        class="table-cell fifth-col"
                      >
                        <div class="cell-content cell-label">
                          <div
                            *ngIf="
                              contentLibraryData?.show_trending_lessons &&
                              isTrendingSkill(skill)
                            "
                            class="trending-skill-selected"
                            (click)="
                              mode === 'trending' &&
                                removeTrendingSkill($event, skill)
                            "
                          >
                            <svg
                              width="16"
                              height="20"
                              viewBox="0 0 16 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15 19L8 14L1 19V3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V19Z"
                                fill="#2D9D93"
                                stroke="#2D9D93"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div
                            *ngIf="
                              contentLibraryData?.show_trending_lessons &&
                              !isTrendingSkill(skill)
                            "
                            class="trending-skill-unselected"
                            (click)="
                              mode === 'trending' &&
                                addTrendingSkill($event, skill)
                            "
                          >
                            <svg
                              width="16"
                              height="20"
                              viewBox="0 0 16 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15 19L8 14L1 19V3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V19Z"
                                stroke="#8C8C8C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        [ngClass]="{
                          enabled: skill?.active_module_count > 0,
                          disabled: skill?.active_module_count === 0
                        }"
                        class="cell-content cell-label"
                      >
                        {{ getActiveModuleCount(skill) }} OF
                        {{ getTotalModuleCount(skill) }}
                      </div>
                    </div>
                    <div class="table-cell third-col center">
                      <div class="cell-content cell-label">
                        <img
                          *ngIf="!skill?.is_custom"
                          src="../../../assets/imgs/zogo-content.svg"
                          alt=""
                        />
                        <img
                          *ngIf="
                            !skill?.is_custom &&
                            doesSkillHaveCustomContent(skill)
                          "
                          src="../../../assets/imgs/custom-content.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="table-cell fourth-col last-cell">
                      <div class="cell-content cell-label">
                        {{ skill.skill_id }}
                      </div>
                      <div class="add-module-icon">
                        <svg
                          *ngIf="
                            availableCustomModules.available_custom_modules
                              .length > 0 && mode === 'status'
                          "
                          (click)="openModuleSelectionDialog($event, skill)"
                          [matTooltip]="'Add custom module'"
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                        >
                          <path
                            d="M10.5013 18.8346C15.1037 18.8346 18.8346 15.1037 18.8346 10.5013C18.8346 5.89893 15.1037 2.16797 10.5013 2.16797C5.89893 2.16797 2.16797 5.89893 2.16797 10.5013C2.16797 15.1037 5.89893 18.8346 10.5013 18.8346Z"
                            stroke="#005ABE"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 7.16797V13.8346"
                            stroke="#005ABE"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.16797 10.5H13.8346"
                            stroke="#005ABE"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    class="table-module-toggle-header"
                    *ngIf="getModuleListFromSkill(skill).length !== 0"
                  >
                    <div class="label-medium text-color-body-4">
                      {{
                        getModuleListFromSkill(skill).length +
                          (getModuleListFromSkill(skill).length > 1
                            ? ' modules selected'
                            : ' module selected')
                      }}
                    </div>
                    <div class="table-module-toggle-header-right">
                      <div class="caption-medium text-color-body-4">
                        Module Status
                      </div>
                      <div
                        (click)="handleStatusSave(skill, 'disable')"
                        class="button-b2-uppercase primary-dark-blue tappable"
                      >
                        disable
                      </div>
                      <div
                        (click)="handleStatusSave(skill, 'enable')"
                        class="button-b2-uppercase primary-dark-blue tappable"
                      >
                        enable
                      </div>
                    </div>
                  </div>
                  <ng-container
                    cdkDropListGroup
                    *ngIf="
                      selectedModuleIdsBySkill[getSkillInSelectedIndex(skill)]
                        .expanded
                    "
                  >
                    <div class="sub-table-header">
                      <div class="table-cell first-col">
                        <div
                          class="cell-content cell-header label-l2-medium first-col-header-margin"
                        >
                          {{ copy?.module }}
                        </div>
                      </div>
                      <div class="table-cell second-col center">
                        <div class="cell-content cell-header label-l2-medium">
                          {{ copy?.status }}
                        </div>
                      </div>
                      <div class="table-cell third-col center">
                        <div class="cell-content cell-header label-l2-medium">
                          Content
                        </div>
                      </div>
                      <div class="table-cell fourth-col">
                        <div class="cell-content cell-header label-l2-medium">
                          {{ copy?.id }}
                        </div>
                      </div>
                      <div class="table-cell fifth-col">
                        <div
                          class="cell-content cell-header label-l2-medium"
                        ></div>
                      </div>
                    </div>
                    <div
                      *ngIf="mode === 'status' && movedModules.length > 0"
                      class="save-position-change-header"
                    >
                      <div class="position-order">Position Order:</div>
                      <button
                        (click)="savePositionOrder(skill)"
                        class="save-order-btn"
                        mat-button
                      >
                        Save Order
                      </button>
                    </div>
                    <div
                      class="level-table-row"
                      *ngFor="let level of skill?.levels"
                      cdkDropList
                      [cdkDropListData]="level?.module_list"
                      (cdkDropListDropped)="onItemDrop($event, level, skill)"
                    >
                      <div class="dotted-line skill-expansion-module-list">
                        <span class="level-name">{{ level?.name }}</span>
                      </div>
                      <div
                        *ngFor="let module of level?.module_list; let i = index"
                        class="sub-table-row skill-expansion-module-list"
                        cdkDrag
                        [ngClass]="{
                          'custom-module-contianer':
                            module.is_custom && mode === 'status'
                        }"
                        [cdkDragData]="module"
                        [cdkDragDisabled]="
                          !module.is_custom || mode !== 'status'
                        "
                      >
                        <div class="table-cell first-col">
                          <div class="cell-content cell-label">
                            <div
                              *ngIf="module.is_custom && mode === 'status'"
                              class="dragable-color-highlight-container-indicator"
                            ></div>
                            <img
                              class="drag-image tappable"
                              matTooltip="Drag and drop to reorder"
                              *ngIf="module.is_custom && mode === 'status'"
                              src="../../../assets/imgs/draganddrop.svg"
                              alt=""
                            />

                            <div
                              class="table-check-box-container sub-table-check-box-container"
                              [ngClass]="{
                                'checkbox-spacing-fix':
                                  !module.is_custom && mode === 'status'
                              }"
                            >
                              <mat-checkbox
                                *ngIf="
                                  mode === 'status' && module.can_be_disabled
                                "
                                (click)="
                                  mode === 'status' &&
                                    selectModule(
                                      skill,
                                      module.module_id,
                                      $event
                                    )
                                "
                                [checked]="
                                  isModuleInSelectedList(
                                    skill.skill_id,
                                    module.module_id
                                  ) !== -1
                                "
                                [disabled]="mode !== 'status'"
                              ></mat-checkbox>
                            </div>
                            <div>
                              <div class="module-title">
                                <img
                                  src="../../../assets/imgs/file-text.svg"
                                  alt=""
                                />

                                {{ module.module_title }}

                                <img
                                  matTooltip="Module available in Spanish"
                                  *ngIf="module.is_translated"
                                  class="spanish-skill-icon-module"
                                  src="https://images.ctfassets.net/o1xn7flb83yr/6wZ1lDAiB4mkbLqjRs5p2E/1c26eb3e7139b9ffb9bc06b5afd7ea4f/spain.png"
                                  alt="spanish-flag"
                                />
                              </div>

                              <markdown
                                class="cell-description markdown-cell-description"
                                [data]="module.module_description"
                              >
                              </markdown>
                            </div>
                          </div>
                        </div>
                        <div class="table-cell second-col center">
                          <div class="cell-content">
                            <div
                              [ngClass]="{
                                enabled: module.module_status === 'enabled',
                                disabled: module.module_status === 'disabled'
                              }"
                              class="cell-content cell-label"
                            >
                              {{ module.module_status }}
                            </div>
                          </div>
                        </div>
                        <div class="table-cell third-col center">
                          <img
                            *ngIf="!module.is_custom"
                            src="../../../assets/imgs/zogo-content.svg"
                            alt=""
                          />
                          <img
                            matTooltip="Custom content"
                            *ngIf="module.is_custom"
                            src="../../../assets/imgs/custom-content.svg"
                            alt=""
                          />
                        </div>
                        <div class="table-cell fourth-col">
                          <div class="cell-content cell-label">
                            {{ module.module_id }}
                          </div>
                          <img
                            matTooltip="Remove module"
                            (click)="
                              removeCustomModule(module.module_id, skill)
                            "
                            *ngIf="module.is_custom && mode === 'status'"
                            class="remove-icon tappable"
                            src="../../../assets/imgs/remove.svg"
                            alt=""
                          />
                        </div>
                        <div
                          class="pending-module tag-t1-bold tappable"
                          *ngIf="module.is_custom && module.is_pending_approval"
                          matTooltipPosition="above"
                          [matTooltip]="
                            'Module won’t be shown in product until approved'
                          "
                          [ngStyle]="{
                            color: module.is_pending_approval
                              ? '#EF9006'
                              : '#00695F',
                            backgroundColor: this.req.hexToRgbOpacity(
                              module.is_pending_approval
                                ? '#EF9006'
                                : '#00695F',
                              0.15
                            )
                          }"
                        >
                          Pending approval
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div
        *ngIf="!contentLibraryData || isLoadingContentLibrary"
        class="card card-frame"
      >
        <div class="text-align-center">
          <ion-spinner name="circular"></ion-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
