import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CopyService } from '../copy.service';
import { OnboardingService } from '../onboarding/onboarding.service';

@Component({
  selector: 'app-cx-email-snippet',
  templateUrl: './cx-email-snippet.component.html',
  styleUrls: ['./cx-email-snippet.component.css']
})
export class CxEmailSnippetComponent implements OnInit {

  dashboardGlobalCopy: any;
  
  constructor  (  private route: ActivatedRoute,
  public onboarding: OnboardingService,
  public appCopy: CopyService
  ) {}

  ngOnInit(): void {

    this.dashboardGlobalCopy = this.appCopy.getDashboardGlobalCopy();
    console.log('dashboard global copy', this.dashboardGlobalCopy);
  }

}
