<div *ngIf="!loading" class="select-institution-section">
  <div class="header-section">
    <div class="header-h1-bold-rounded text-color-header-1">
      {{ appCopy.getInstitutionSelectionPage().header }}
    </div>
    <div class="body-b2-medium text-color-body-1">
      {{ appCopy.getInstitutionSelectionPage().sub_header }}
    </div>
  </div>

  <div class="institution-list">
    <div
      *ngFor="let institution of institutionOptions; index as i"
      class="institution-card"
      [ngClass]="{ selected: selectedIndex === i }"
      (click)="handleSelection(i)"
    >
      <div class="body-b2-medium text-color-header-1">
        {{ institution.short_name }}
      </div>
      <div
        *ngIf="institution.is_all_institution"
        class="limited-access-view-wrapper label-medium text-color-body-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <g clip-path="url(#clip0_4870_66745)">
            <path
              d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1818 14.6663 8.49992C14.6663 4.81802 11.6816 1.83325 7.99967 1.83325C4.31778 1.83325 1.33301 4.81802 1.33301 8.49992C1.33301 12.1818 4.31778 15.1666 7.99967 15.1666Z"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 5.83325V8.49992"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 11.1667H8.00667"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4870_66745">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs></svg
        ><span class="limited-access-view-text">
          {{ appCopy.getInstitutionSelectionPage().limited_access_view }}</span
        >
      </div>
      <img
        *ngIf="institution.image"
        [src]="institution.image"
        class="institution-image"
        [alt]="institution.short_name"
      />
    </div>
  </div>
  <div class="bottom-section">
    <button
      class="blue"
      [disabled]="selectedIndex === null"
      (click)="selectedIndex !== null && selectInstitution()"
      mat-button
    >
      {{ appCopy.getInstitutionSelectionPage().continue_btn }}
    </button>
  </div>
</div>

<div *ngIf="loading" class="text-align-center">
  <ion-spinner name="circular"></ion-spinner>
</div>
