import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  appCopyObject: any;
  constructor() {}

  setAppCopy(data) {
    this.appCopyObject = data['en-US'];
  }

  getOnboardingZipCodeUpload() {
    return this.appCopyObject.onboarding_zip_code_upload_page;
  }

  getOnboardingLogoUpload() {
    return this.appCopyObject.onboarding_logo_upload_page;
  }

  getOnboardingPaymentUpload() {
    return this.appCopyObject.onboarding_payment_settings_page;
  }

  getOnboardingAccountSettings() {
    return this.appCopyObject.onboarding_account_settings_page;
  }

  getOnboardingWelcomePage() {
    return this.appCopyObject.welcome_page;
  }

  getDashboardGlobalCopy() {
    return this.appCopyObject.dashboard_global;
  }

  getSignUpPage() {
    return this.appCopyObject.sign_up_page;
  }

  getMyAccount() {
    return this.appCopyObject.my_account_page;
  }

  getAccessCodes() {
    return this.appCopyObject.access_code_page;
  }

  getPaymentPage() {
    return this.appCopyObject.payment_settings_page;
  }

  getTeamMembersPage() {
    return this.appCopyObject.team_members_page;
  }

  getDashboardGlobal() {
    return this.appCopyObject.dashboard_global;
  }

  getCustomColorPage() {
    return this.appCopyObject.custom_color_page;
  }

  getDashboardLeaderboard() {
    return this.appCopyObject.leaderboard_display;
  }

  getMarketingKitPage() {
    return this.appCopyObject.marketing_kit_page;
  }

  getContactUsPage() {
    return this.appCopyObject.contact_us_page;
  }

  getOverviewPage() {
    return this.appCopyObject.overview_page;
  }

  getProductAnalyticsPage() {
    return this.appCopyObject.product_analytics;
  }

  getContentLibraryPage() {
    return this.appCopyObject.content_library;
  }
  
  getInstitutionSelectionPage() {
    return this.appCopyObject.institution_selection_page;
  }

  getTeacherAdmins() {
    return this.appCopyObject.teacher_admins;
  }
}
