import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { CustomValidationService } from '../custom-validation.service';

@Directive({
  selector: '[appCustomValidation]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomValidationDirective,
      multi: true,
    },
  ],
})
export class CustomValidationDirective {
  @Input('appCustomValidation') inputName = '';

  constructor(private customValidation: CustomValidationService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    switch (this.inputName) {
      case 'email':
        return this.customValidation.emailValidator()(control);
      case 'website':
        return this.customValidation.websiteUrlValidator()(control);
      case 'phone':
        return this.customValidation.phoneNumberValidator()(control);
      case 'subdomain':
        return this.customValidation.subdomainValidator()(control);
      case 'accessCode':
        return this.customValidation.accessCodeValidator()(control);
      default:
        return null;
    }
  }
}
