import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/requests.service';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { ModalController } from '@ionic/angular';
import { AccountModalComponent } from './account-modal/account-modal.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
})
export class MyAccountComponent implements OnInit {
  userData: any;
  accountFormData = {
    fullInstName: '',
    preferredAbbr: '',
    institutionType: '',
    websiteUrl: '',
    accessCode: '',
  };
  needsAccessCode: boolean;
  isAdmin: boolean;

  constructor(
    public onboarding: OnboardingService,

    public req: RequestsService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    // Amplitude event - My Account Viewed
    this.req.ampTrack('My Account Viewed');

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.needsAccessCode = this.userData.institution.platform_access_types.some(
      type => type.platform === 'native'
    );
    this.initializeInputs();
    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  async editAccount() {
    const componentProps = {
      fullInstName: this.accountFormData.fullInstName,
      preferredAbbr: this.accountFormData.preferredAbbr,
      institutionType: this.accountFormData.institutionType,
      websiteUrl: this.accountFormData.websiteUrl.replace(/^https?:\/\//, ''),
      accessCode: this.accountFormData.accessCode,
      needsAccessCode: this.needsAccessCode,
    };


    const modal = await this.modalController.create({
      component: AccountModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    const { data, role } = await modal.onDidDismiss();

    if (role === 'save' && data) {
      this.accountFormData.fullInstName = data.fullInstName;
      this.accountFormData.preferredAbbr = data.preferredAbbr;
      this.accountFormData.institutionType = data.institutionType;
      this.accountFormData.websiteUrl = data.websiteUrl;
    }
  }

  async initializeInputs() {
    this.accountFormData.fullInstName = this.userData.institution.long_name;
    this.accountFormData.preferredAbbr = this.userData.institution.short_name;
    this.accountFormData.institutionType =
      this.userData.institution.institution_type;
    this.accountFormData.websiteUrl = this.userData.institution.website_url;
    this.accountFormData.accessCode = this.userData.institution.code;
  }
}
