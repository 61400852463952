<div class="dashboard-view remove-bottom-padding">
  <div class="page-header-flex-container margin-bottom-64">
    <h1 class="h3-bold">Zogo Web</h1>
    <div class="d-flex-row gap-16">
      <zogo-btn
        *ngIf="pageMode === 'view'"
        label="Edit"
        [disabled]="!isAdmin"
        (clickFunction)="enableEditMode()"
      ></zogo-btn>
      <zogo-btn
        *ngIf="pageMode === 'edit'"
        label="Cancel"
        buttonStyle="tertiary"
        [disabled]="!isAdmin"
        (clickFunction)="handleDiscardChanges()"
      ></zogo-btn>
      <zogo-btn
        *ngIf="pageMode === 'edit'"
        label="Save Changes"
        [disabled]="!isAdmin"
        (clickFunction)="handleSaveChangesModal()"
      ></zogo-btn>
    </div>
  </div>
  <div class="customization-preview-wrapper d-flex-row gap-24">
    <!-- View Mode -->
    <div
      *ngIf="pageMode === 'view'"
      class="customization-form-wrapper background-neutral-white border-radius-8"
    >
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-8">
        Banner Background
      </h4>
      <p class="body-book text-color-neutral-800">
        {{ background === 'color' ? 'Primary Color' : 'Upload Image' }}
      </p>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-8">Typeface</h4>
      <div class="body-book text-color-neutral-800">
        <p *ngIf="config.font === 'gotham'">Gotham</p>
        <p class="noto-sans-font" *ngIf="config.font === 'notosans'">
          Noto Sans
        </p>
        <p class="raleway-font" *ngIf="config.font === 'raleway'">Raleway</p>
        <p class="merriweather-font" *ngIf="config.font === 'merriweather'">
          Merriweather
        </p>
      </div>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-8">
        Icon Style
      </h4>
      <div class="body-book text-color-neutral-800">
        <p class="d-flex-row gap-4" *ngIf="config.icon_pack === 'classic'">
          <zogo-icon iconName="classic-icon"></zogo-icon> Classic
        </p>
        <p class="d-flex-row gap-4" *ngIf="config.icon_pack === 'playful'">
          <zogo-icon iconName="playful-icon"></zogo-icon> Playful
        </p>
        <p class="d-flex-row gap-4" *ngIf="config.icon_pack === 'clean'">
          <zogo-icon iconName="clean-icon"></zogo-icon> Clean
        </p>
      </div>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-16">
        Welcome Message
      </h4>
      <div
        *ngIf="userData?.institution.enable_classroom_for_web"
        class="d-flex-row gap-12 margin-bottom-24"
      >
        <zogo-chip
          [shouldManageSelect]="false"
          label="Zogo Web"
          [isSelected]="previewType === 'zogo-web'"
          (selectedChange)="handlePreviewSelection('zogo-web')"
        ></zogo-chip>
        <zogo-chip
          [shouldManageSelect]="false"
          label="Classroom"
          [isSelected]="previewType === 'classroom'"
          (selectedChange)="handlePreviewSelection('classroom')"
        ></zogo-chip>
      </div>

      <ng-container
        *ngIf="previewType === 'zogo-web' && webMessageType === 'default'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          English
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.english_header) }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.english_sub_header) }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.english_description) }}
        </p>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          Spanish
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-booktext-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.spanish_header) }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.spanish_sub_header) }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ substituteInstitutionName(defaultText.spanish_description) }}
        </p>
      </ng-container>
      <ng-container
        *ngIf="previewType === 'zogo-web' && webMessageType === 'custom'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          English
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.english_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.english_sub_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.english_description }}
        </p>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          Spanish
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.spanish_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.spanish_sub_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.spanish_description }}
        </p>
      </ng-container>
      <ng-container
        *ngIf="
          previewType === 'classroom' && classroomMessageType === 'default'
        "
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          English
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_english_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_english_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_english_description }}
        </p>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          Spanish
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_spanish_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_spanish_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ defaultText.classroom_spanish_description }}
        </p>
      </ng-container>
      <ng-container
        *ngIf="previewType === 'classroom' && classroomMessageType === 'custom'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          English
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_english_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_english_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_english_description }}
        </p>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-8 margin-top-32"
        >
          Spanish
        </h5>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_spanish_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Sub-title
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_spanish_header }}
        </p>
        <p class="caption-medium text-color-neutral-800 margin-bottom-4">
          Description
        </p>
        <p class="body-book text-color-neutral-800 margin-bottom-16">
          {{ customText.classroom_spanish_description }}
        </p>
      </ng-container>
    </div>
    <!-- Edit mode -->
    <div
      *ngIf="pageMode === 'edit'"
      class="customization-form-wrapper background-neutral-white border-radius-8"
    >
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-16">
        Banner Background
      </h4>
      <zogo-radio-group
        class="body-book text-color-neutral-800"
        (valueChange)="onBackgroundChange($event)"
        [initialValue]="background"
      >
        <zogo-radio-button
          value="color"
          label="Primary Color"
        ></zogo-radio-button>
        <zogo-radio-button
          value="image"
          label="Upload Image"
        ></zogo-radio-button>
      </zogo-radio-group>
      <div *ngIf="background === 'image'" class="image-upload">
        <p class="caption-book text-color-neutral-800 margin-bottom-8">
          The ideal size for a full-screen image is at least 1,280 pixels wide,
          landscape, and max file size of 5mb.
        </p>
        <input
          #fileInput
          (change)="handleUploadImage($event)"
          accept="image/png, image/jpeg"
          type="file"
          style="display: none"
        />
        <zogo-btn
          *ngIf="!config.image"
          label="Select an Image"
          buttonStyle="secondary"
          iconName="upload"
          (clickFunction)="fileInput.click()"
        ></zogo-btn>
        <div
          *ngIf="config.image"
          class="image-frame caption-medium text-color-neutral-800"
        >
          <div class="image-frame-left">
            <zogo-icon iconName="image"></zogo-icon>
            {{ imageName }}
          </div>
          <zogo-icon
            tappable
            (click)="handleDeleteImage()"
            iconName="x"
          ></zogo-icon>
        </div>
      </div>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-16">
        Typeface
      </h4>
      <zogo-radio-group
        class="body-book text-color-neutral-800"
        (valueChange)="onTypefaceChange($event)"
        [initialValue]="config.font"
      >
        <zogo-radio-button value="gotham" label="Gotham"></zogo-radio-button>
        <zogo-radio-button
          customClass="noto-sans-font"
          value="notosans"
          label="Noto Sans"
        ></zogo-radio-button>
        <zogo-radio-button
          customClass="raleway-font"
          value="raleway"
          label="Raleway"
        ></zogo-radio-button>
        <zogo-radio-button
          customClass="merriweather-font"
          value="merriweather"
          label="Merriweather"
        ></zogo-radio-button>
      </zogo-radio-group>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-16">
        Icon Style
      </h4>
      <zogo-radio-group
        class="body-book text-color-neutral-800"
        (valueChange)="onIconStyleChange($event)"
        [initialValue]="config.icon_pack"
      >
        <zogo-radio-button
          value="classic"
          label="Classic"
          iconName="classic-icon"
        ></zogo-radio-button>
        <zogo-radio-button
          iconName="playful-icon"
          value="playful"
          label="Playful"
        ></zogo-radio-button>
        <zogo-radio-button
          iconName="clean-icon"
          value="clean"
          label="Clean"
        ></zogo-radio-button>
      </zogo-radio-group>
      <div class="form-divider"></div>
      <h4 class="h5-medium text-color-neutral-800 margin-bottom-16">
        Welcome Message
      </h4>
      <div
        *ngIf="userData?.institution.enable_classroom_for_web"
        class="d-flex-row gap-12 margin-bottom-24"
      >
        <zogo-chip
          [shouldManageSelect]="false"
          label="Zogo Web"
          [isSelected]="previewType === 'zogo-web'"
          (selectedChange)="handlePreviewSelection('zogo-web')"
        ></zogo-chip>
        <zogo-chip
          [shouldManageSelect]="false"
          label="Classroom"
          [isSelected]="previewType === 'classroom'"
          (selectedChange)="handlePreviewSelection('classroom')"
        ></zogo-chip>
      </div>
      <zogo-radio-group
        *ngIf="previewType === 'zogo-web'"
        class="body-book text-color-neutral-800"
        (valueChange)="onWebMessageTypeChange($event)"
        [initialValue]="webMessageType"
      >
        <zogo-radio-button value="default" label="Default"></zogo-radio-button>
        <zogo-radio-button value="custom" label="Custom"></zogo-radio-button>
      </zogo-radio-group>
      <zogo-radio-group
        *ngIf="previewType === 'classroom'"
        class="body-book text-color-neutral-800"
        (valueChange)="onClassroomMessageTypeChange($event)"
        [initialValue]="classroomMessageType"
      >
        <zogo-radio-button value="default" label="Default"></zogo-radio-button>
        <zogo-radio-button value="custom" label="Custom"></zogo-radio-button>
      </zogo-radio-group>
      <ng-container
        *ngIf="previewType === 'zogo-web' && webMessageType === 'default'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          English
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_header_default"
          inputLabel="Title"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="substituteInstitutionName(defaultText.english_header)"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_sub_header_default"
          inputLabel="Sub-title (Optional)"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="
            substituteInstitutionName(defaultText.english_sub_header)
          "
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_description_default"
          inputLabel="Description"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="
            substituteInstitutionName(defaultText.english_description)
          "
        ></zogo-text-input>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          Spanish
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_header_default"
          inputLabel="Title"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="substituteInstitutionName(defaultText.spanish_header)"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_sub_header_default"
          inputLabel="Sub-title (Optional)"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="
            substituteInstitutionName(defaultText.spanish_sub_header)
          "
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_description_default"
          inputLabel="Description"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="
            substituteInstitutionName(defaultText.spanish_description)
          "
        ></zogo-text-input>
      </ng-container>
      <ng-container
        *ngIf="previewType === 'zogo-web' && webMessageType === 'custom'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          English
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_header"
          inputLabel="Title"
          (valueChange)="onTextChange($event, 'english_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.english_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_sub_header"
          inputLabel="Sub-title (Optional)"
          (valueChange)="onTextChange($event, 'english_sub_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.english_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="english_description"
          inputLabel="Description"
          (valueChange)="onTextChange($event, 'english_description')"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="customText.english_description"
        ></zogo-text-input>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          Spanish
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_header"
          inputLabel="Title"
          (valueChange)="onTextChange($event, 'spanish_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.spanish_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_sub_header"
          inputLabel="Sub-title (Optional)"
          (valueChange)="onTextChange($event, 'spanish_sub_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.spanish_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="spanish_description"
          inputLabel="Description"
          (valueChange)="onTextChange($event, 'spanish_description')"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="customText.spanish_description"
        ></zogo-text-input>
      </ng-container>
      <ng-container
        *ngIf="
          previewType === 'classroom' && classroomMessageType === 'default'
        "
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          English
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_header_default"
          inputLabel="Title"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="defaultText.classroom_english_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_sub_header_default"
          inputLabel="Sub-title (Optional)"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="defaultText.classroom_english_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_description_default"
          inputLabel="Description"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="defaultText.classroom_english_description"
        ></zogo-text-input>
        <button
          class="help-btn-classroom-syntax caption-medium text-color-brand-primary"
          (click)="openClassroomSyntaxHelpModal()"
        >
          <zogo-icon iconName="help-circle" size="xsmall"></zogo-icon> Use
          syntax for classroom name
        </button>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          Spanish
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_header_default"
          inputLabel="Title"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="defaultText.classroom_spanish_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_sub_header_default"
          inputLabel="Sub-title (Optional)"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="defaultText.classroom_spanish_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_description_default"
          inputLabel="Description"
          isDisabled="true"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="defaultText.classroom_spanish_description"
        ></zogo-text-input>
      </ng-container>
      <ng-container
        *ngIf="previewType === 'classroom' && classroomMessageType === 'custom'"
      >
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          English
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_header"
          inputLabel="Title"
          (valueChange)="onTextChange($event, 'classroom_english_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [value]="'initialValue'"
          [currentValue]="customText.classroom_english_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_sub_header"
          inputLabel="Sub-title (Optional)"
          (valueChange)="onTextChange($event, 'classroom_english_sub_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.classroom_english_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_english_description"
          inputLabel="Description"
          (valueChange)="onTextChange($event, 'classroom_english_description')"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="customText.classroom_english_description"
        ></zogo-text-input>
        <h5
          class="body-bold text-color-neutral-800 margin-bottom-16 margin-top-32"
        >
          Spanish
        </h5>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_header"
          inputLabel="Title"
          (valueChange)="onTextChange($event, 'classroom_spanish_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.classroom_spanish_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_sub_header"
          inputLabel="Sub-title (Optional)"
          (valueChange)="onTextChange($event, 'classroom_spanish_sub_header')"
          formType="textArea"
          wrap="soft"
          [rows]="2"
          [currentValue]="customText.classroom_spanish_sub_header"
        ></zogo-text-input>
        <zogo-text-input
          class="margin-bottom-16 d-block"
          inputId="classroom_spanish_description"
          inputLabel="Description"
          (valueChange)="onTextChange($event, 'classroom_spanish_description')"
          formType="textArea"
          wrap="soft"
          [rows]="3"
          [currentValue]="customText.classroom_spanish_description"
        ></zogo-text-input>
      </ng-container>
    </div>
    <!-- preview column -->
    <div class="preview-wrapper">
      <div class="preview-text background-neutral-white padding-y-12">
        <p class="body-medium">Preview</p>
      </div>
      <div
        *ngIf="userData?.institution.enable_classroom_for_web"
        class="platform-selection-wrapper d-flex-row gap-12 margin-bottom-24"
      >
        <zogo-chip
          [shouldManageSelect]="false"
          label="Zogo Web"
          [isSelected]="previewType === 'zogo-web'"
          (selectedChange)="handlePreviewSelection('zogo-web')"
        ></zogo-chip>
        <zogo-chip
          [shouldManageSelect]="false"
          label="Classroom"
          [isSelected]="previewType === 'classroom'"
          (selectedChange)="handlePreviewSelection('classroom')"
        ></zogo-chip>
      </div>
      <ng-container>
        <div class="panzoom-parent">
          <div id="panzoom-element">
            <app-zogo-web-preview [config]="config"> </app-zogo-web-preview>
          </div>
        </div>
      </ng-container>
      <div class="preview-image-buttons-wrapper margin-bottom-24">
        <div class="reset-btn-background">
          <zogo-btn
            size="small"
            buttonStyle="tertiary"
            label="Reset"
            (clickFunction)="reset()"
          >
          </zogo-btn>
        </div>
        <div class="zoom-btn-group">
          <button class="zoom-in-btn" (click)="zoomIn()">
            <zogo-icon iconName="plus"></zogo-icon>
          </button>
          <div class="zoom-btn-divider"></div>
          <button class="zoom-out-btn" (click)="zoomOut()">
            <zogo-icon iconName="minus"></zogo-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
