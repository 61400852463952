import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';
@Component({
  selector: 'app-user-demographics-chart',
  templateUrl: './user-demographics-chart.component.html',
  styleUrls: ['./user-demographics-chart.component.css'],
})
export class UserDemographicsChartComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  userDemographicData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  copy: any;
  data: any;
  tabSelected: boolean;
  tabIndex: number;
  showTooltip = false;
  calculatedNum: any;
  errorLoading: boolean = false;

  constructor(public appCopy: CopyService, private req: RequestsService) {}

  ngOnInit() {
    this.copy = this.appCopy.getOverviewPage();
    this.tabIndex = 0;
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateUserDemographicData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateUserDemographicData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateUserDemographicData();
      },
      error: err => {},
    });
    this.updateUserDemographicData();
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateUserDemographicData() {
    this.userDemographicData = null;
    this.errorLoading = false;
    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.userDemographicData = data;
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }

  calculateValuePercentage(value) {
    const max = Math.max(...this.data.map(item => item.value));
    return (value / max) * 100;
  }

  generateTooltipContent(title, list) {
    let tooltipContent = title;
    tooltipContent += list.map(item => `- ${item}`).join('');
    return tooltipContent;
  }

  selectTab(index) {
    this.tabIndex = index;
  }

  calculateChartScaling(num) {
    const highestValue = Math.max(
      ...this.userDemographicData.tabs[this.tabIndex].data.map(
        item => item.value
      )
    );

    if (highestValue <= 50) {
      return (num / 100) * 300;
    } else if (highestValue > 50) {
      return (num / 100) * 200;
    }
  }
}
