import { Component, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { RequestsService } from 'src/app/requests.service';
import { OnboardingService } from '../onboarding.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-team-members',
  templateUrl: './onboarding-team-members.component.html',
  styleUrls: ['./onboarding-team-members.component.scss'],
})
export class OnboardingTeamMembersComponent implements OnInit {
  userData: any;
  teamMembers = [
    {
      firstName: '',
      lastName: '',
      email: '',
      accessLevel: '',
      error: false,
    },
  ];

  constructor(
    public onboarding: OnboardingService,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  addMember() {
    this.teamMembers.push({
      firstName: '',
      lastName: '',
      email: '',
      accessLevel: '',
      error: false,
    });
  }

  removeMember(index: number) {
    this.teamMembers.splice(index, 1);
  }

  onInputValueChange(
    event,
    index: number,
    property: 'firstName' | 'lastName' | 'email' | 'accessLevel'
  ) {
    this.teamMembers[index][property] = event;
  }

  async onSubmit() {
    const requests = this.teamMembers.map(member => {
      return this.req
        .postDashboardUserInvite({
          first_name: member.firstName,
          last_name: member.lastName,
          email: member.email,
          institution_user_type: member.accessLevel,
        })
        .pipe(
          map(() => ({ success: true, member })),
          catchError(err => {
            this.toastService.showToast(
              'There was an error: ' + err.error.message,
              6000
            );
            return of({ success: false, member });
          })
        );
    });

    forkJoin(requests).subscribe(results => {
      const failedMembers = results
        .filter(result => !result.success) // Keep only failed requests
        .map(result => ({ ...result.member, error: true })); // Mark failed entries

      if (failedMembers.length === 0) {
        this.req
          .postDashboardOnboarding({ page: 'additional-members' })
          .subscribe({
            next: data => {
              // Amplitude event - Add Team Members Completed
              this.req.ampTrack('Add Team Members Completed');
              this.onboarding.handleContinue();
            },
            error: err => {
              this.toastService.showToast(
                'There was an error: ' + err.error.message,
                6000
              );

              console.log('errors', err.error);
            },
          });
      } else {
        this.teamMembers = failedMembers; // Retain failed entries for retry
      }
    });
  }

  handleSkip() {
    this.req.postDashboardOnboarding({ page: 'additional-members' }).subscribe({
      next: data => {
        // Amplitude event - Add Team Members Skipped
        this.req.ampTrack('Add Team Members Skipped');
        this.onboarding.handleContinue();
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );

        console.log('errors', err.error);
      },
    });
  }
}
