<div class="snippet-app-frame">
  <div class="header header-area h4-bold text-color-neutral-800">
    Add a Snippet
    <div (click)="handleCloseButton()" class="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
  </div>
  <div class="snippet-input-area">
    <app-field-text-input
      inputId="snippet-title"
      inputTitle="Snippet Title"
      [maxLength]="50"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'english'"
      [value]="changed.content_title['en-US']"
      rows="1"
      (valueChange)="handleSnippetTitleInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="snippet-title"
      inputTitle="Snippet Title"
      [maxLength]="50"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'spanish'"
      [preview]="changed.content_title['en-US']"
      [value]="changed.content_title['es-US']"
      rows="1"
      (valueChange)="handleSnippetTitleInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="snippet-content"
      inputTitle="Snippet Content"
      [maxLength]="500"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'english'"
      [value]="changed.content['en-US']"
      rows="3"
      isResizable="true"
      (valueChange)="handleSnippetContentInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="snipet-content"
      inputTitle="Snippet Content"
      [maxLength]="500"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'spanish'"
      [preview]="this.changed.content['en-US']"
      [value]="changed.content['es-US']"
      rows="3"
      isResizable="true"
      (valueChange)="handleSnippetContentInput($event)"
    ></app-field-text-input>
  </div>
  <div class="footer-area">
    <zogo-btn
      *ngIf="language === 'english'"
      label="Delete"
      [fullWidth]="false"
      size="medium"
      buttonStyle="secondary"
      (clickFunction)="deleteItem()"
    >
    </zogo-btn>
    <div *ngIf="language !== 'english'" class="btn-placeholder"></div>
    <zogo-btn
      [disabled]="!validateInputs()"
      label="Save"
      [fullWidth]="false"
      size="medium"
      buttonStyle="primary"
      (clickFunction)="save()"
    >
    </zogo-btn>
  </div>
</div>
