<div *ngIf="metricCardData?.data" class="card-wrapper">
  <div class="h2-bold text-color-neutral-800">
    {{ metricCardData.data.value }}
  </div>
  <div class="h5-medium text-color-neutral-800">
    {{ metricCardData.widget_display_name }}
  </div>
  <div class="change-wrapper" *ngIf="metricCardData.data.percent_change">
    <div
      class="percent-change-wrapper caption-medium"
      [ngClass]="{
        positive: metricCardData.data.change_direction === 'positive',
        negative: metricCardData.data.change_direction === 'negative'
      }"
    >
      <svg
        *ngIf="metricCardData.data.change_direction === 'positive'"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.7803 4.11445C18.0732 4.40734 18.0732 4.88221 17.7803 5.17511L10.6553 12.3001C10.3624 12.593 9.88756 12.593 9.59467 12.3001L6.375 9.08044L1.28033 14.1751C0.987437 14.468 0.512563 14.468 0.21967 14.1751C-0.0732233 13.8822 -0.0732233 13.4073 0.21967 13.1144L5.84467 7.48945C6.13756 7.19655 6.61244 7.19655 6.90533 7.48945L10.125 10.7091L16.7197 4.11445C17.0126 3.82155 17.4874 3.82155 17.7803 4.11445Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 4.64478C12 4.23056 12.3358 3.89478 12.75 3.89478H17.25C17.6642 3.89478 18 4.23056 18 4.64478V9.14478C18 9.55899 17.6642 9.89478 17.25 9.89478C16.8358 9.89478 16.5 9.55899 16.5 9.14478V5.39478H12.75C12.3358 5.39478 12 5.05899 12 4.64478Z"
          fill="currentColor"
        />
      </svg>
      <svg
        *ngIf="metricCardData.data.change_direction === 'negative'"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2976_49467)">
          <path
            d="M17.75 13.5L10.625 6.375L6.875 10.125L1.25 4.5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.25 13.5H17.75V9"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2976_49467">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <span>{{ metricCardData.data.percent_change }}</span>
    </div>
    <div class="caption-medium text-color-neutral-800">
      {{ metricCardData.data.value_change_text }}
    </div>
  </div>
  <div
    *ngIf="metricCardData.data.percent_change"
    class="time-frame overline-medium text-color-neutral-600"
  >
    {{ metricCardData.data.time_frame_text }}
  </div>
  <div
    *ngIf="metricCardData.data.data_platform_text"
    class="data-platform overline-medium text-color-neutral-600"
  >
    {{ metricCardData.data.data_platform_text }}
  </div>
</div>
