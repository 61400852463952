import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests.service';
import { ToastService } from '../design-system/web-components/toast.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm = {
    email: '',
    password: '',
  };
  showSpinner: boolean = false;
  forgotPassword: boolean = false;
  userData: any;
  mode: string = 'login'; // login | institution-select

  constructor(
    public req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  hasValidInputs(): boolean {
    if (this.forgotPassword) {
      if (this.loginForm.email.length > 0) {
        return true;
      }
    } else {
      if (
        this.loginForm.email.length > 0 &&
        this.loginForm.password.length > 0
      ) {
        return true;
      }
    }
    return false;
  }

  login() {
    if (this.forgotPassword) {
      this.showSpinner = true;

      this.req.dashboardForgotPassword(this.loginForm.email).subscribe({
        next: data => {
          this.showSpinner = false;
          this.toastService.showToast(
            'We sent an email with instructions to change your password.',
            6000
          );
        },
        error: err => {
          console.error(err);
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            5000
          );
          this.showSpinner = false;
        },
      });
      return;
    }
    this.showSpinner = true;
    this.req
      .dashboardCredential(this.loginForm.email, this.loginForm.password)
      .subscribe({
        next: data => {
          console.log('response from dashboard/credential', data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userData', JSON.stringify(data));
          this.userData = data;
          this.req.checkToken();
          if (this.userData.institution_user.institution_options) {
            this.mode = 'institution-select';
            this.showSpinner = false;
          } else {
            window.location.href = '/';
            this.showSpinner = false;
          }
        },
        error: err => {
          console.log(err);
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            5000
          );
          this.showSpinner = false;
        },
      });
  }
}
