<div class="accordion">
  <div class="accordion-item" *ngFor="let item of items; index as i">
    <h3>
      <button
        [attr.aria-controls]="'item ' + i"
        class="accordion-header"
        (click)="toggleItem(i)"
        (keydown.enter)="handleKeydown($event, i)"
        (keydown.space)="handleKeydown($event, i)"
        [attr.aria-expanded]="item.expanded"
      >
        <p class="h5-medium ion-text-start">{{ item.title }}</p>

        <zogo-icon
          *ngIf="item.expanded"
          class="text-color-neutral-800"
          iconName="chevron-up"
          ariaTitle="collapse accordion"
        ></zogo-icon>

        <zogo-icon
          *ngIf="!item.expanded"
          class="text-color-neutral-800"
          iconName="chevron-down"
          ariaTitle="expand accordion"
        ></zogo-icon>
      </button>
    </h3>

    <section
      [id]="'item ' + i"
      class="accordion-content"
      role="region"
      *ngIf="item.expanded"
    >
      <markdown
        class="markdown-content body-book d-flex-col gap-16"
        [data]="item.content"
      ></markdown>
    </section>
  </div>
</div>
