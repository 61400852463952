<div class="onboarding-container pos-rel">
  <img
    class="branded-pattern"
    src="assets/imgs/branded-pattern-onboarding.png"
    alt=""
  />
  <div class="onboarding-wrapper d-flex-row">
    <div class="form-container d-flex-col gap-24">
      <img class="zogo-logo" src="global/logos/zogo-blue.svg" alt="" />

      <div class="d-flex-col gap-8">
        <h1 class="h1-bold">Welcome to the Partner Portal!</h1>
        <p class="body-large-book">
          Manage your zogo experience, track learner engagement, and grow
          financial literacy in your community.
        </p>
      </div>

      <form #form="ngForm" (ngSubmit)="onSubmitPassword()">
        <div class="d-flex-col gap-12 margin-bottom-24">
          <h2 class="h4-bold">Create your password</h2>
          <zogo-text-input
            [(ngModel)]="passwordData.password"
            name="password"
            inputId="password"
            inputLabel="Password"
            inputType="password"
            autocompleteType="new-password"
            ariaLabel="New Password"
            placeholder="Enter Password"
            (valueChange)="onPasswordChange($event)"
            #passwordInput="ngModel"
          ></zogo-text-input>
          <zogo-text-input
            [(ngModel)]="passwordData.confirmPassword"
            name="confirmPassword"
            inputId="reenter-password"
            inputType="password"
            autocompleteType="new-password"
            ariaLabel="Confirm Password"
            placeholder="Re-enter Password"
            (valueChange)="onConfirmPasswordChange($event)"
            #confirmPasswordInput="ngModel"
          ></zogo-text-input>
          <div
            *ngIf="!passwordsMatch && confirmPasswordInput.touched"
            class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
          >
            <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
            <p>Passwords do not match</p>
          </div>
          <div class="d-flex-col gap-4">
            <p class="overline-medium">Password Requirements</p>
            <div class="d-flex-col gap-2">
              <div
                *ngIf="hasAtLeast8Chars"
                class="overline-book text-color-shades-green-1 d-flex-row gap-2"
              >
                <zogo-icon iconName="check" size="xsmall"></zogo-icon>
                <p>At least 8 characters</p>
              </div>
              <div
                *ngIf="hasUppercase"
                class="overline-book text-color-shades-green-1 d-flex-row gap-2"
              >
                <zogo-icon iconName="check" size="xsmall"></zogo-icon>
                <p>At least 1 uppercase character</p>
              </div>
              <div
                *ngIf="hasSpecial"
                class="overline-book text-color-shades-green-1 d-flex-row gap-2"
              >
                <zogo-icon iconName="check" size="xsmall"></zogo-icon>
                <p>At least 1 special character</p>
              </div>
              <div
                *ngIf="hasNumber"
                class="overline-book text-color-shades-green-1 d-flex-row gap-2"
              >
                <zogo-icon iconName="check" size="xsmall"></zogo-icon>
                <p>At least 1 number</p>
              </div>
              <ul class="overline-book margin-left-16">
                <li *ngIf="!hasAtLeast8Chars">At least 8 characters</li>
                <li *ngIf="!hasUppercase">At least 1 uppercase character</li>
                <li *ngIf="!hasSpecial">At least 1 special character</li>
                <li *ngIf="!hasNumber">At least 1 number</li>
              </ul>
            </div>
          </div>
        </div>
        <zogo-btn
          type="submit"
          size="large"
          label="Continue"
          [disabled]="!enableSubmit()"
        ></zogo-btn>
      </form>

      <p class="overline-book text-color-neutral-600">
        By accessing and registering to use Zogo's services, you acknowledge and
        agree to comply with our
        <a href="https://zogo.com/terms-of-service" target="_blank">
          Terms of Service</a
        >. If you are not in agreement with these terms and conditions, you
        should refrain from further use of our services.
      </p>
    </div>
  </div>
</div>
