<ng-container [ngSwitch]="cardType">
  <div
    *ngSwitchCase="'small'"
    tappable
    (click)="onCardClick()"
    (keydown.enter)="onCardClick()"
    (keydown.space)="onCardClick()"
    role="button"
    tabindex="0"
    class="small-card"
  >
    <img class="small-card-image" [src]="imageUrl" alt="" />
    <h3 class="small-card-title body-large-medium">
      {{ title }}
    </h3>
    <zogo-decorated-link
      [displayOnly]="true"
      [iconName]="ctaIcon"
      [label]="ctaLabel"
      [iconLocation]="iconLocation"
      [destinationSrc]="navigateUrl"
    ></zogo-decorated-link>
  </div>

  <div
    *ngSwitchDefault
    tappable
    (click)="onCardClick()"
    (keydown.enter)="onCardClick()"
    (keydown.space)="onCardClick()"
    role="button"
    tabindex="0"
    class="card"
  >
    <div class="card-image">
      <img [src]="imageUrl" alt="" />
    </div>
    <div class="card-content">
      <h3 class="card-title h4-medium">{{ title }}</h3>
      <zogo-decorated-link
        [displayOnly]="true"
        [iconName]="ctaIcon"
        [label]="ctaLabel"
        [iconLocation]="iconLocation"
        [destinationSrc]="navigateUrl"
      ></zogo-decorated-link>
    </div>
  </div>
</ng-container>
