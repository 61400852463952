<div class="dashboard-view">
  <app-notif-alert
    *ngIf="isNotifAlertEnabled1"
    [status]="notifAlertStatus1"
    [message]="notifAlertMessage1"
    [dismiss]="dismissNotifAlert1.bind(this)"
    [buttonText]="notifAlertButtonText1"
    [buttonFunc]="notifAlertButtonFunc1"
  ></app-notif-alert>
  <div class="pre-post-test-header-section">
    <div class="header-wrapper">
      <img *ngIf="req.isShareView" src="global/logos/zogo-blue.svg" alt="" />
      <h2 class="h3-bold text-color-neutral-800">Pre/Post Test Results</h2>
    </div>
    <div class="date-select-section">
      <mat-select
        class="input-container caption-medium text-color-neutral-800"
        [placeholder]="copy?.timeframes?.all_time"
        [(ngModel)]="timeFrameSelectedOption"
        (selectionChange)="onSelectionChange($event)"
      >
        <mat-option value="all">{{ copy?.timeframes?.all_time }}</mat-option>
        <mat-option value="thirty">{{
          copy?.timeframes?.thirty_days
        }}</mat-option>
        <mat-option value="ninety">{{
          copy?.timeframes?.ninety_days
        }}</mat-option>
      </mat-select>
      <app-community-segment-select
        class="community-segment-input"
        *ngIf="
          userData.institution.community_segments_enabled &&
          this.activeTab?.platform === 'native'
        "
        (segmentSelected)="updateSegment($event)"
      ></app-community-segment-select>
      <zogo-btn
        *ngIf="
          !req.isShareView && userData?.institution?.can_request_pdf_reports
        "
        label="Send PDF"
        [fullWidth]="false"
        size="medium"
        buttonStyle="primary"
        (clickFunction)="openRequestPdfDialog()"
      >
      </zogo-btn>
    </div>
  </div>
  <div
    *ngIf="
      !req.isShareView &&
      userData.institution.enable_portal_share_link &&
      userData.institution.id !== 100
    "
    class="get-share-link-btn-wrapper"
  >
    <button
      class="get-share-link-btn overline-medium text-color-brand-primary margin-top-16"
      tappable
      (click)="getViewOnlyLink()"
    >
      <zogo-icon size="small" iconName="link-2"></zogo-icon> Share view-only
      link
    </button>
  </div>
  <div *ngIf="!skillResultsInfoData">
    <ion-spinner class="overview-loader" name="circular"></ion-spinner>
  </div>
  <div *ngIf="skillResultsInfoData" class="platform-tabs-section margin-top-64">
    <div
      *ngFor="let tab of skillResultsInfoData.tabs"
      [ngClass]="
        activeTab.platform === tab.platform
          ? 'selected h5-bold text-color-brand-primary'
          : 'h5-medium text-color-neutral-600'
      "
      (click)="selectTab(tab)"
    >
      {{ tab.tab_name }}
    </div>
  </div>
  <div *ngIf="skillResultsInfoData" class="sub-tabs-category-filter-wrapper">
    <div *ngIf="activeTab.sub_tabs?.length" class="sub-tabs-section">
      <zogo-chip
        *ngFor="let subTab of activeTab.sub_tabs"
        class="margin-bottom-8"
        [label]="subTab.sub_tab_name"
        [isSelected]="subTab.platform === activeSubTab.platform"
        [shouldManageSelect]="false"
        (selectedChange)="handleSubTabSelection(subTab)"
      ></zogo-chip>
    </div>
    <div *ngIf="!activeTab.sub_tabs?.length" class="sub-tabs-placeholder"></div>
    <div *ngIf="skillData" class="category-filter-wrapper">
      <div class="caption-medium text-color-neutral-600">Filter by</div>
      <mat-select
        class="input-container category-select caption-medium text-color-neutral-800"
        [(ngModel)]="selectedCategory"
        (selectionChange)="onCategorySelectionChange($event)"
      >
        <mat-option value="all">All Categories</mat-option>
        <mat-option
          *ngFor="let category of skillData.category_list"
          [value]="category.category_id"
          >{{ category.category_name }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div *ngIf="!filteredSkillData">
    <ion-spinner class="pre-post-test-loader" name="circular"></ion-spinner>
  </div>
  <div
    *ngIf="filteredSkillData && !filteredSkillData.skill_results?.length"
    class="no-data-wrapper"
  >
    <svg
      width="175"
      height="122"
      viewBox="0 0 175 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="9.48926"
        width="89.0435"
        height="111.724"
        rx="1.68007"
        transform="rotate(-2.0906 0 9.48926)"
        fill="#DCDCDC"
      />
      <rect
        x="7.34234"
        y="2.97804"
        width="88.1966"
        height="110.313"
        rx="0.623104"
        transform="rotate(-1.57025 7.34234 2.97804)"
        fill="#FBFBFB"
      />
      <rect
        x="7.34234"
        y="2.97804"
        width="88.1966"
        height="110.313"
        rx="0.623104"
        transform="rotate(-1.57025 7.34234 2.97804)"
        stroke="#E8E8E8"
        stroke-width="0.571569"
      />
      <rect
        x="12.1434"
        y="11.6528"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 12.1434 11.6528)"
        fill="#AAAAAA"
      />
      <rect
        x="12.2679"
        y="16.1956"
        width="79.0733"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 12.2679 16.1956)"
        fill="#DCDCDC"
      />
      <rect
        x="12.5918"
        y="28.0066"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 12.5918 28.0066)"
        fill="#AAAAAA"
      />
      <rect
        x="12.7162"
        y="32.5496"
        width="64.8341"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 12.7162 32.5496)"
        fill="#DCDCDC"
      />
      <rect
        x="13.04"
        y="44.3606"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 13.04 44.3606)"
        fill="#AAAAAA"
      />
      <rect
        x="13.1646"
        y="48.9033"
        width="53.0185"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 13.1646 48.9033)"
        fill="#DCDCDC"
      />
      <rect
        x="13.4884"
        y="60.7144"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 13.4884 60.7144)"
        fill="#AAAAAA"
      />
      <rect
        x="13.6129"
        y="65.2571"
        width="43.0207"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 13.6129 65.2571)"
        fill="#DCDCDC"
      />
      <rect
        x="13.9366"
        y="77.0684"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 13.9366 77.0684)"
        fill="#AAAAAA"
      />
      <rect
        x="14.0612"
        y="81.6111"
        width="33.3259"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 14.0612 81.6111)"
        fill="#DCDCDC"
      />
      <rect
        x="14.3848"
        y="93.4219"
        width="19.0867"
        height="3.33259"
        rx="0.302963"
        transform="rotate(-1.57025 14.3848 93.4219)"
        fill="#AAAAAA"
      />
      <rect
        x="14.5093"
        y="97.9646"
        width="30.5993"
        height="6.36222"
        rx="0.605926"
        transform="rotate(-1.57025 14.5093 97.9646)"
        fill="#DCDCDC"
      />
      <rect
        x="101.551"
        y="14.5586"
        width="74.2579"
        height="75.5716"
        rx="1.68007"
        transform="rotate(11.2156 101.551 14.5586)"
        fill="#D9D9D9"
      />
      <rect
        x="101.689"
        y="14.0964"
        width="71.4086"
        height="72.731"
        rx="1.65297"
        transform="rotate(11.176 101.689 14.0964)"
        fill="#FBFBFB"
      />
      <rect
        x="101.689"
        y="14.0964"
        width="71.4086"
        height="72.731"
        rx="1.65297"
        transform="rotate(11.176 101.689 14.0964)"
        stroke="#E8E8E8"
        stroke-width="0.661189"
      />
      <path
        d="M156.587 62.0111C153.65 76.878 139.217 86.5489 124.35 83.6117C109.483 80.6744 99.8117 66.2412 102.749 51.3743C105.686 36.5074 120.119 26.8364 134.986 29.7737C149.853 32.711 159.524 47.1441 156.587 62.0111ZM108.666 52.5434C106.375 64.1422 113.92 75.4026 125.519 77.6942C137.118 79.9858 148.378 72.4408 150.669 60.842C152.961 49.2432 145.416 37.9828 133.817 35.6912C122.218 33.3996 110.958 40.9446 108.666 52.5434Z"
        fill="#EAEAEA"
      />
      <path
        d="M115.484 76.5444C114.508 77.9112 112.595 78.2394 111.344 77.1177C107.128 73.3353 104.165 68.3209 102.905 62.7479C101.644 57.1749 102.159 51.3736 104.336 46.1446C104.982 44.5939 106.85 44.0665 108.32 44.8798C109.79 45.6931 110.299 47.5392 109.703 49.1095C108.22 53.0132 107.906 57.2894 108.838 61.4055C109.769 65.5216 111.893 69.2463 114.912 72.1315C116.126 73.2921 116.461 75.1776 115.484 76.5444Z"
        fill="#DCDCDC"
      />
      <path
        d="M134.39 32.7884C134.719 31.1234 136.343 30.0237 137.961 30.5367C141.106 31.5338 144.053 33.0926 146.657 35.1456C150.067 37.8346 152.791 41.2948 154.603 45.2416C156.416 49.1883 157.265 53.5089 157.082 57.8481C156.9 62.1873 155.689 66.4211 153.551 70.2013C151.413 73.9816 148.408 77.2003 144.784 79.5929C141.159 81.9854 137.018 83.4834 132.702 83.9637C128.385 84.4439 124.017 83.8928 119.955 82.3555C116.854 81.1818 113.999 79.4589 111.524 77.2776C110.251 76.1554 110.357 74.1969 111.614 73.0571C112.872 71.9173 114.803 72.0331 116.112 73.1135C117.907 74.5959 119.94 75.7783 122.13 76.6074C125.282 77.8003 128.673 78.228 132.022 77.8553C135.372 77.4826 138.585 76.3202 141.398 74.4635C144.21 72.6069 146.542 70.1091 148.202 67.1756C149.861 64.242 150.8 60.9566 150.942 57.5893C151.084 54.222 150.424 50.8692 149.018 47.8064C147.611 44.7437 145.498 42.0586 142.851 39.9718C141.012 38.5215 138.952 37.389 136.756 36.6139C135.156 36.0489 134.061 34.4534 134.39 32.7884Z"
        fill="#AAAAAA"
      />
      <rect
        x="123.425"
        y="49.0566"
        width="15.8685"
        height="7.93426"
        rx="1.32238"
        transform="rotate(11.176 123.425 49.0566)"
        fill="#AAAAAA"
      />
      <rect
        x="118.78"
        y="58.9219"
        width="21.158"
        height="2.64475"
        rx="1.32238"
        transform="rotate(11.176 118.78 58.9219)"
        fill="#DCDCDC"
      />
    </svg>
    <div class="h3-medium text-color-neutral-800 margin-top-16">
      Data Coming Soon
    </div>
    <div class="body-medium text-color-neutral-600 margin-top-16">
      Our system is busy gathering reliable data. Please check back later for
      updates.
    </div>
  </div>
  <app-category-highlights
    *ngIf="filteredSkillData"
    [categoryHighlightsData]="filteredSkillData.category_overviews"
  ></app-category-highlights>
  <div
    *ngIf="filteredSkillData?.skill_results?.length"
    class="skill-display-wrapper"
  >
    <div class="skill-display-header">
      <div class="h5-bold text-color-neutral-800 skill-header-cell">Skill</div>
      <div class="accuracy-header-cell">
        <div class="h5-bold text-color-neutral-800">Accuracy</div>
        <div class="overline-book text-color-neutral-800 margin-top-8">
          Percentage of correct responses to knowledge-based questions before
          and after completing a skill.
        </div>
        <div class="key-wrapper margin-top-8">
          <div class="pre-test-circle"></div>
          <div class="overline-book text-color-neutral-800">Pre-test</div>
          <div class="post-test-circle"></div>
          <div class="overline-book text-color-neutral-800">Post-test</div>
        </div>
      </div>
      <div class="confidence-header-cell">
        <div class="h5-bold text-color-neutral-800">Confidence</div>
        <div class="overline-book text-color-neutral-800 margin-top-8">
          Percentage of users who report increased confidence on this topic
          after completing the skill.
        </div>
      </div>
      <div class="spacer-header-cell"></div>
    </div>
    <app-skill-display
      *ngFor="let skill of filteredSkillData.skill_results"
      [skillResultsData]="skill"
    >
    </app-skill-display>
  </div>
</div>
