import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

@Component({
  selector: 'zogo-decorated-link',
  templateUrl: './zogo-decorated-link.component.html',
  styleUrls: ['./zogo-decorated-link.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, ZogoIconComponent],
})
export class ZogoDecoratedLinkComponent {
  @Input() iconName: string = '';
  @Input() destinationSrc: string = '';
  @Input() label: string = '';
  @Input() displayOnly: boolean = false;
  @Input() background: 'light' | 'dark' | 'highContrast' = 'light';
  @Input() iconLocation: 'leading' | 'tracking' = 'tracking';

  constructor() {}
}
