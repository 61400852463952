import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { RequestsService } from 'src/app/requests.service';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-zip-codes',
  templateUrl: './zip-codes.component.html',
  styleUrls: ['./zip-codes.component.scss'],
})
export class ZipCodesComponent implements OnInit {
  userData: any;
  isLoading: boolean = false;
  hasExistingCsvData: boolean;
  file: any;
  approvalStatus: string = '';
  statusType: string = '';
  statusMessage: string = '';
  isSidebarOpen: boolean = false;
  fileName: string;
  fileSize: number;
  lastModified: string;
  zipCodes: any;

  constructor(
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    const zipCodeChangeRequestObject =
      this.userData.institution.zip_code_change_request;
    if (zipCodeChangeRequestObject && zipCodeChangeRequestObject.file_name) {
      this.hasExistingCsvData = true;
      this.file = zipCodeChangeRequestObject;
      this.fileName = zipCodeChangeRequestObject.file_name;
      this.fileSize = zipCodeChangeRequestObject.file_size;
      this.lastModified = this.convertToDate(
        zipCodeChangeRequestObject.request_timestamp
      );

      this.setStatus(zipCodeChangeRequestObject.status);
    } else {
      this.hasExistingCsvData = false;
    }
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  convertSizetoKb(size: number) {
    return Math.ceil(size / 1024);
  }

  convertToDate(timestamp) {
    const date = new Date(timestamp);

    // Convert to "MMM d, yyyy" format (e.g., "Nov 4, 2024")
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return formattedDate;
  }

  onCsvUpload(csvInfo) {
    this.file = csvInfo;
    this.fileName = csvInfo.fileName;
    this.fileSize = this.convertSizetoKb(csvInfo.fileSize);
    this.lastModified = this.convertToDate(csvInfo.lastModified);
    this.zipCodes = csvInfo.zipCodes;
    this.hasExistingCsvData = false;
  }

  setStatus(status) {
    switch (status) {
      case 'pending':
        this.approvalStatus = 'Pending Approval';
        this.statusType = 'notice';
        this.statusMessage =
          'We are reviewing your request and will respond shortly.';
        break;
      case 'approved':
        this.approvalStatus = 'Approved';
        this.statusType = 'success';
        break;
      case 'rejected':
        this.approvalStatus = 'Rejected';
        this.statusType = 'warning';
        break;
      default:
        this.approvalStatus = 'initial';
        this.statusType = 'neutral';
        break;
    }
  }

  arrayToCsv(data) {
    this.fileName = this.fileName.toLowerCase().replace('.csv', '');
    const options = {
      filename: this.fileName,
      quoteStrings: '',
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  downloadDashboardZipCodes() {
    this.req.getDashboardZipCodes().subscribe({
      next: data => {
        // Amplitude event - Zip Codes Downloaded
        this.req.ampTrack('Zip Codes Downloaded');
        if (!data.zip_codes.length) {
          this.toastService.showToast('Your Zip Codes list is currently empty');
        } else {
          this.arrayToCsv(data.zip_codes);
        }
      },
      error: err => {
        this.toastService.showToast('There was an error:' + err.message, 6000);
        console.log('errors', err);
      },
    });
  }

  onSubmitCsvRequest() {
    const request = {
      file_name: this.fileName,
      file_size: this.fileSize,
      zip_codes: this.zipCodes,
    };

    this.isLoading = true;
    this.req.patchDashboardZipCodesChangeRequest(request).subscribe({
      next: data => {
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution.zip_code_change_request = data;
        localStorage.setItem('userData', JSON.stringify(currentUserData));
        this.setStatus(data.status);
        this.zipCodes = '';
        this.isLoading = false;
        this.toastService.showToast('Zip codes uploaded.');
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
