<div class="header-frame">
  <mat-select
    *ngIf="isInstitutionGroup"
    [ngModel]="selectedOption"
    (selectionChange)="onSelectionChange($event)"
    class="input-container institution-select"
    placeholder="testing"
    #institutionSelect
  >
    <mat-select-trigger>
      <div
        *ngIf="selectedOption?.is_all_institution"
        class="header-h5-bold-rounded text-color-header-1"
      >
        {{ selectedOption?.short_name }}
      </div>
      <img
        *ngIf="!selectedOption?.is_all_institution"
        class="institution-option-image"
        [src]="selectedOption?.image"
    /></mat-select-trigger>
    <mat-option
      *ngFor="let institution of userData.institution_user.institution_options"
      class="institution-select-option"
      [value]="institution"
    >
      <div class="caption-medium text-color-body-1">
        {{ institution.short_name }}
      </div>
      <div
        *ngIf="institution.is_all_institution"
        class="limited-access-view-wrapper label-medium text-color-body-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <g clip-path="url(#clip0_4870_66745)">
            <path
              d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1818 14.6663 8.49992C14.6663 4.81802 11.6816 1.83325 7.99967 1.83325C4.31778 1.83325 1.33301 4.81802 1.33301 8.49992C1.33301 12.1818 4.31778 15.1666 7.99967 15.1666Z"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 5.83325V8.49992"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 11.1667H8.00667"
              stroke="#696B6D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4870_66745">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs></svg
        ><span class="limited-access-view-text">
          {{ appCopy.getInstitutionSelectionPage().limited_access_view }}</span
        >
      </div>
      <img
        *ngIf="!institution.is_all_institution"
        class="institution-option-image"
        [src]="institution.image"
      />
    </mat-option>
  </mat-select>
  <div
    class="readonly-text"
    matTooltip="Editing is disabled for “Read-only” users"
    aria-label="Button that displays a tooltip when focused or hovered over"
    *ngIf="!isAdmin"
  >
    Read-only
  </div>
  <div class="admin-text" *ngIf="isAdmin">Admin</div>

  <div *ngIf="!isInstitutionGroup" class="image">
    <img
      *ngIf="userData?.institution.image"
      [src]="userData?.institution.image"
    />
    <svg
      *ngIf="!userData?.institution.image"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#EDF5FF" />
      <path
        d="M21.9996 19.1685V23.6059H9.58398V22.8636L15.0927 13.5248H10.0779V9.12109H21.9494V9.93096L16.516 19.1685H21.9996Z"
        fill="#0078FF"
      />
    </svg>
  </div>
  <div class="userInfo">
    <div class="name">
      {{ userData?.institution_user.first_name }}
      {{ userData?.institution_user.last_name }}
    </div>
    <div class="institution">
      {{ userData?.institution.short_name }}
    </div>
  </div>
</div>
