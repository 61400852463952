import { Component, HostListener, OnInit } from '@angular/core';
import { RequestsService } from '../../requests.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';
import { CopyService } from 'src/app/copy.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CustomModuleModalComponent } from 'src/app/dialogs/custom-module-modal/custom-module-modal.component';
import { CustomModuleSelectionDialogComponent } from './custom-module-selection-dialog/custom-module-selection-dialog.component';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/guards/dialog.service';

@Component({
  selector: 'app-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.css'],
})
export class ContentLibraryComponent implements OnInit {
  contentLibraryData: any = null;
  totalModuleCount = 0;
  expandedSkillId: number | null = null;
  selectedModuleIdsBySkill: any = [];
  userData: any;
  isAdmin: boolean;
  trendingSkillList: Set<number> = new Set<number>();
  mode: 'display' | 'trending' | 'status';
  tab: any; // The platform sourced from the platform_access_types array !!
  copy: any;
  moduleMoved: boolean = false;
  movedModules = [];
  placementCTADisplay: boolean = true;
  isLoadingContentLibrary: boolean = true;
  availableCustomModules: any;
  autoEnableActive: boolean = false;

  constructor(
    public req: RequestsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public appCopy: CopyService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    // Amplitude event - Content Library Viewed
    this.req.ampTrack('Content Library Viewed');

    this.isLoadingContentLibrary = true;
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.autoEnableActive = this.userData.institution.auto_enable_new_content;

    this.copy = this.appCopy.getContentLibraryPage();
    this.tab = this.findFirstTab();

    this.selectTab(this.tab);

    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.mode = 'display';
  }

  findFirstTab() {
    if (this.userData.institution.platform_access_types.length > 0) {
      return this.userData.institution.platform_access_types[0].platform;
    }
    return 'error';
  }

  handleStatusDone() {
    let isChangedOrder = false;
    for (let i = 0; i < this.selectedModuleIdsBySkill.length; i++) {
      if (this.selectedModuleIdsBySkill[i].has_changes === true) {
        isChangedOrder = true;
        break;
      }
    }

    if (isChangedOrder) {
      this.dialog.open(ConfirmationModalComponent, {
        width: '460px',
        height: '250px',
        data: {
          headerText: 'You have unsaved changes.',
          bodyText:
            'You have not saved the order of modules. Do you want to continue and discard these changes?',
          cancelBtnText: 'Cancel',
          confirmBtnText: 'Continue',
          confirmFunct: () => {
            for (let i = 0; i < this.selectedModuleIdsBySkill.length; i++) {
              if (this.selectedModuleIdsBySkill[i].has_changes == true) {
                this.contentLibraryData.categories.forEach(category => {
                  category.skills.forEach(skill => {
                    if (
                      skill.skill_id ===
                      this.selectedModuleIdsBySkill[i].skill_id
                    ) {
                      skill.levels = JSON.parse(
                        this.selectedModuleIdsBySkill[i].original_structure
                      );
                    }
                  });
                });
              }
            }
            for (let i = 0; i < this.selectedModuleIdsBySkill.length; i++) {
              this.selectedModuleIdsBySkill[i].module_ids = [];
            }
            this.mode = 'display';
            //reset the order of skills
            //change view mode
            this.dialog.closeAll();
          },
          cancelFunct: () => {
            // just cancel, do nothing
            this.dialog.closeAll();
            return;
          },
        },
      });
    } else {
      for (let i = 0; i < this.selectedModuleIdsBySkill.length; i++) {
        this.selectedModuleIdsBySkill[i].module_ids = [];
      }
      this.mode = 'display';
    }
  }

  countTotalModules(contentLibraryData: any) {
    let moduleCount = 0;

    contentLibraryData.categories.forEach(skill => {
      moduleCount = moduleCount + skill.total_module_count;
    });

    return moduleCount;
  }

  allSkillModulesSelected(skill) {
    const moduleIds = [];
    let selectedIDs = [];
    skill.levels.forEach(item => {
      item.module_list.forEach(module => {
        moduleIds.push(module.module_id);
      });
    });
    selectedIDs = this.selectedModuleIdsBySkill.find(
      obj => obj.skill_id === skill.skill_id
    ).module_ids;
    moduleIds.sort();
    selectedIDs.sort();

    if (selectedIDs.length !== moduleIds.length) {
      return false;
    }
    for (let i = 0; i < selectedIDs.length; i++) {
      if (selectedIDs[i] !== moduleIds[i]) return false;
    }
    return true;
  }

  selectAllModulesForSkill(skill, levels: any, event) {
    event?.preventDefault();
    event?.stopPropagation();
    const moduleIds = [];

    levels.forEach(item => {
      item.module_list.forEach(module => {
        moduleIds.push(module.module_id);
      });
    });

    if (this.allSkillModulesSelected(skill)) {
      this.selectedModuleIdsBySkill[
        this.getSkillInSelectedIndex(skill)
      ].module_ids = [];
    } else {
      this.selectedModuleIdsBySkill[
        this.getSkillInSelectedIndex(skill)
      ].module_ids = moduleIds;
    }
  }

  getSkillInSelectedIndex(skill) {
    const skillID = skill.skill_id;
    const index = this.selectedModuleIdsBySkill.findIndex(localSkill => {
      return localSkill.skill_id === skillID;
    });
    return index;
  }

  selectModule(skill, moduleId: number, event) {
    event?.preventDefault();
    const skillIndex = this.getSkillInSelectedIndex(skill);

    if (
      this.selectedModuleIdsBySkill[skillIndex].module_ids.includes(moduleId)
    ) {
      this.selectedModuleIdsBySkill[skillIndex].module_ids =
        this.selectedModuleIdsBySkill[skillIndex].module_ids.filter(
          item => item !== moduleId
        );
    } else {
      this.selectedModuleIdsBySkill[skillIndex].module_ids.push(moduleId);
    }
  }

  showModulesForSkill(skill, forceTrue?) {
    if (
      this.selectedModuleIdsBySkill[this.getSkillInSelectedIndex(skill)]
        .expanded === true &&
      !forceTrue
    ) {
      this.selectedModuleIdsBySkill[
        this.getSkillInSelectedIndex(skill)
      ].expanded = false;
    } else {
      this.selectedModuleIdsBySkill[
        this.getSkillInSelectedIndex(skill)
      ].expanded = true;
    }
  }

  countEnabledModules(skill: any) {
    return skill.module_list.filter(m => m.module_status === 'enabled').length;
  }

  getModuleListFromSkill(skill) {
    if (
      this.selectedModuleIdsBySkill[this.getSkillInSelectedIndex(skill)]
        .module_ids
    ) {
      return this.selectedModuleIdsBySkill[this.getSkillInSelectedIndex(skill)]
        .module_ids;
    }
    return [];
  }

  deselectAllModules(skill) {
    this.selectedModuleIdsBySkill[
      this.getSkillInSelectedIndex(skill)
    ].module_ids = [];
  }

  onApplySelections(skill, type: 'enable' | 'disable') {
    const moduleList = this.getModuleListFromSkill(skill);
    this.req
      .modifyDashboardContentLibrary(this.tab, type, moduleList)
      .subscribe({
        next: data => {
          this.req.openSnackBar(`Modules successfully ${type}d!`, 'Okay');
          if (type === 'enable') {
            // Amplitude event - Module(s) Enabled
            this.req.ampTrack('Module(s) Enabled');
          } else {
            // Amplitude event - Module(s) Disabled
            this.req.ampTrack('Module(s) Disabled');
          }

          this.contentLibraryData.categories.forEach(category => {
            category.skills.forEach(skill => {
              skill.levels.forEach(level => {
                level.module_list.forEach(module => {
                  if (moduleList.includes(module.module_id)) {
                    module.module_status = `${type}d`;
                  }
                });
              });
            });
          });
          this.dialog.closeAll();
          this.deselectAllModules(skill);
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  isTrendingSkill(skill) {
    return skill.is_trending;
  }

  addTrendingSkill(ev, skill) {
    ev.preventDefault();
    ev.stopPropagation();
    skill.is_trending = true;
    this.trendingSkillList.add(skill.skill_id);
  }

  removeTrendingSkill(ev, skill) {
    ev.preventDefault();
    ev.stopPropagation();
    skill.is_trending = false;
    this.trendingSkillList.delete(skill.skill_id);
  }

  getTrendingSkillCount() {
    return this.trendingSkillList.size;
  }

  changeMode(mode) {
    this.mode = mode;
  }

  handleTrendingCancel() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: 'Changes may not be saved',
        bodyText:
          'If you leave now, all the changes may not be saved.  Are you sure?',
        cancelBtnText: 'Back To Edit',
        confirmBtnText: 'Leave Without Saving',
        confirmFunct: () => {
          this.selectTab(this.tab);
          this.changeMode('display');
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  handleTrendingSave() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: 'Are you sure?',
        bodyText:
          "All changes will be published instantly. Please make sure you've confirmed all changes.",
        cancelBtnText: 'Back To Edit',
        confirmBtnText: 'Confirm Changes',
        confirmFunct: () => {
          this.updateTrendingSave();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  updateTrendingSave() {
    this.req
      .patchDashboardContentLibraryTrending(this.tab, [
        ...this.trendingSkillList,
      ])
      .subscribe({
        next: data => {
          this.req.openSnackBar(
            `Trending skills successfully updated!`,
            'Okay'
          );
          this.contentLibraryData.categories.forEach(skill => {
            if (this.trendingSkillList.has(skill.skill_id)) {
              skill.is_trending = true;
            }
          });
          this.dialog.closeAll();
          this.changeMode('display');
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  handleStatusSave(skill, actionType) {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: 'Are you sure?',
        bodyText:
          "All changes will be published instantly. Please make sure you've confirmed all changes.",
        cancelBtnText: 'Back To Edit',
        confirmBtnText: 'Confirm Changes',
        confirmFunct: () => {
          this.onApplySelections(skill, actionType);
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  selectTab(tab) {
    this.isLoadingContentLibrary = true;
    this.tab = tab;

    this.req
      .getDashboardContentLibraryAvailableCustomModules(this.tab)
      .subscribe({
        next: data => {
          this.availableCustomModules = data;
          this.req.getDashboardContentLibrary(this.tab).subscribe({
            next: data => {
              this.contentLibraryData = data;
              this.totalModuleCount = this.countTotalModules(data);
              this.selectedModuleIdsBySkill = [];
              const tempArray = [];

              data.categories.forEach(category => {
                category.skills.forEach(skill => {
                  const clone = {
                    skill_id: skill.skill_id,
                    module_ids: [],
                    original_structure: JSON.stringify(skill.levels),
                    has_changes: false,
                    expanded: false,
                  };
                  tempArray.push(clone);
                });
              });
              this.selectedModuleIdsBySkill = [...tempArray];
              if (this.contentLibraryData.show_trending_lessons) {
                this.contentLibraryData.categories.forEach(category => {
                  category.skills.forEach(skill => {
                    if (skill.is_trending) {
                      this.trendingSkillList.add(skill.skill_id);
                    }
                  });
                });
              }
              this.isLoadingContentLibrary = false;
            },
            error: err => {
              console.log(err);
              this.req.openSnackBar(
                'There was an error: ' + err.error.message,
                'Okay'
              );
            },
          });
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  isModuleInSelectedList(skill_id, module_id) {
    const index = this.selectedModuleIdsBySkill.findIndex(
      skill => skill.skill_id === skill_id
    );

    if (index !== -1) {
      if (this.selectedModuleIdsBySkill[index].module_ids.includes(module_id)) {
        return index;
      }
    }

    return -1;
  }

  onItemDrop(event: CdkDragDrop<any[]>, targetLevel, skill): void {
    const existingIndex = this.movedModules.findIndex(
      module => module.module_id === event.item.data.module_id
    );
    if (existingIndex !== -1) {
      this.movedModules[existingIndex].display_order = event.currentIndex;
      this.movedModules[existingIndex].level_id = targetLevel.id;
      if (
        this.movedModules[existingIndex].display_order ===
        this.movedModules[existingIndex].original_index
      ) {
        this.movedModules.splice(existingIndex, 1);
      }
    } else {
      const movedModule = {
        module_id: event.item.data.module_id,
        display_order: event.currentIndex,
        level_id: targetLevel.id,
        original_index: event.previousIndex,
      };
      this.movedModules.push(movedModule);
    }

    this.selectedModuleIdsBySkill[
      this.getSkillInSelectedIndex(skill)
    ].has_changes = true;

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
  }

  savePositionOrder(skill) {
    this.req
      .putDashboardContentLibraryModifyOrder(this.tab, {
        modules: this.movedModules,
      })
      .subscribe({
        next: data => {
          this.req.openSnackBar('Custom module order saved!', 'Okay');
          this.movedModules = [];
          //update saved structure to new saved structure
          this.selectedModuleIdsBySkill[
            this.getSkillInSelectedIndex(skill)
          ].original_structure = JSON.stringify(skill.levels);
          this.selectedModuleIdsBySkill[
            this.getSkillInSelectedIndex(skill)
          ].has_changes = false;

          this.dialog.closeAll();
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  getActiveModuleCount(skill) {
    let currentCount = 0;
    for (let i = 0; i < skill.levels.length; i++) {
      for (let x = 0; x < skill.levels[i].module_list.length; x++) {
        if (skill.levels[i].module_list[x].module_status !== 'disabled') {
          currentCount++;
        }
      }
    }
    return currentCount;
  }

  getTotalModuleCount(skill) {
    let currentCount = 0;
    for (let i = 0; i < skill.levels.length; i++) {
      for (let x = 0; x < skill.levels[i].module_list.length; x++) {
        currentCount++;
      }
    }
    return currentCount;
  }

  removeCustomModule(module_id, skill) {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: 'Remove custom module?',
        bodyText:
          'Custom module will be removed from your Content Library, but you can still view module in your Custom Module Editor.',
        cancelBtnText: 'Back To Edit',
        confirmBtnText: 'Remove',
        confirmFunct: () => {
          this.req
            .deleteDashboardContentLibraryCustomModule(this.tab, module_id)
            .subscribe({
              next: data => {
                this.req.getDashboardContentLibrary(this.tab).subscribe({
                  next: data => {
                    const localContentLibrary = data;
                    localContentLibrary.categories.forEach(category => {
                      category.skills.forEach(localSkill => {
                        if (localSkill.skill_id === skill.skill_id) {
                          skill.levels = JSON.parse(
                            JSON.stringify(localSkill.levels)
                          );
                          this.selectedModuleIdsBySkill[
                            this.getSkillInSelectedIndex(skill)
                          ].original_structure = JSON.parse(
                            JSON.stringify(localSkill.levels)
                          );
                          this.selectedModuleIdsBySkill[
                            this.getSkillInSelectedIndex(skill)
                          ].has_changes = false;
                          const indexToRemove = this.movedModules.findIndex(
                            entry => entry.module_id === module_id
                          );

                          // If the entry is found (index is not -1), remove it from the array
                          if (indexToRemove !== -1) {
                            this.movedModules.splice(indexToRemove, 1);
                          }

                          const moduleIDToRemove =
                            this.selectedModuleIdsBySkill[
                              this.getSkillInSelectedIndex(skill)
                            ].module_ids.findIndex(
                              entry => entry === module_id
                            );
                          if (moduleIDToRemove !== -1) {
                            this.selectedModuleIdsBySkill[
                              this.getSkillInSelectedIndex(skill)
                            ].module_ids.splice(indexToRemove, 1);
                          }
                        }
                      });
                    });
                  },
                  error: err => {
                    console.log(err);
                    this.req.openSnackBar(
                      'There was an error: ' + err.error.message,
                      'Okay'
                    );
                  },
                });
                // for (let i = 0; i < skill.levels.length; i++) {
                //   for (let x = 0; skill.levels[i].module_list.length; x++) {
                //     if (
                //       skill.levels[i].module_list[x].module_id === module_id
                //     ) {
                //       skill.levels[i].module_list.splice(x, 1);
                //       break;
                //     }
                //   }
                this.req
                  .getDashboardContentLibraryAvailableCustomModules(this.tab)
                  .subscribe({
                    next: data => {
                      this.availableCustomModules = data;
                    },
                    error: err => {},
                  });

                this.req.openSnackBar('Custom module removed.', 'Okay');
                this.dialog.closeAll();
              },
              error: err => {
                console.log(err);
                this.req.openSnackBar(
                  'There was an error: ' + err.error.message,
                  'Okay'
                );
              },
            });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  invertPlacementCTA() {
    this.placementCTADisplay = !this.placementCTADisplay;
  }

  doesSkillHaveCustomContent(skill) {
    for (let i = 0; i < skill.levels.length; i++) {
      for (let x = 0; x < skill.levels[i].module_list.length; x++) {
        if (skill.levels[i].module_list[x].is_custom) {
          return true;
        }
      }
    }
    return false;
  }

  openModuleSelectionDialog(event, skill) {
    event.stopPropagation();
    this.dialog.open(CustomModuleSelectionDialogComponent, {
      width: '570px',
      height: '452px',
      panelClass: 'highlights-modal-component',
      data: {
        headerText: 'Add Custom Module',
        subHeaderText:
          'Custom modules will initially default to "disabled" when added.',
        customModuleList: this.availableCustomModules,
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Add',
        confirmFunct: returnedModule => {
          this.req
            .postDashboardContentLibraryPlaceCustomModule(
              { module_id: returnedModule, skill_id: skill.skill_id },
              this.tab
            )
            .subscribe({
              next: data => {
                // Amplitude event - Custom Module Placed
                this.req.ampTrack('Custom Module Placed', {
                  custom_module_id: returnedModule,
                  skill_id: skill.skill_id,
                });
                skill.levels[0].module_list.unshift(data);
                this.showModulesForSkill(skill, true);
                //update saved structure to newly placed module
                this.selectedModuleIdsBySkill[
                  this.getSkillInSelectedIndex(skill)
                ].original_structure = JSON.stringify(skill.levels);
                this.req
                  .getDashboardContentLibraryAvailableCustomModules(this.tab)
                  .subscribe({
                    next: data => {
                      this.availableCustomModules = data;
                    },
                    error: err => {},
                  });
              },
              error: err => {
                console.log(err);
                this.req.openSnackBar(
                  'There was an error: ' + err.error.message,
                  'Okay'
                );
              },
            });

          //save the new module list
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  autoEnableExplanationModal() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '250px',
      data: {
        headerText: 'Auto-Enable New Skills',
        bodyText:
          'This function only applies to platforms where you can enable/disable Zogo content.',

        confirmBtnText: `Got it`,
        confirmFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  toggleAutoEnable(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '280px',
      data: {
        headerText: this.autoEnableActive
          ? 'Turn Off Auto-Enable for New skills?'
          : 'Turn on Auto-Enable for New skills?',
        bodyText: this.autoEnableActive
          ? 'When new Zogo skills are added, they will not be enabled. This function only applies to platforms where you can enable/disable Zogo content.'
          : 'When new Zogo skills are added, they will automatically be enabled. This function only applies to platforms where you can enable/disable Zogo content.',
        cancelBtnText: 'Cancel',
        confirmBtnText: `${!this.autoEnableActive ? 'Enable' : 'Disable'}`,
        confirmFunct: () => {
          this.req
            .putDashboardContentLibraryAutoEnableNewContent(
              !this.autoEnableActive
            )
            .subscribe({
              next: data => {
                this.req.openSnackBar(
                  `Skill Auto-Enable is now ${
                    !this.autoEnableActive ? 'active' : 'off'
                  }.`,
                  'Okay'
                );
                this.autoEnableActive = !this.autoEnableActive;
                this.dialog.closeAll();
                // NOTE: add response data for updated skill
              },
              error: err => {
                console.log(err);
                this.req.openSnackBar(
                  'There was an error: ' + err.error.message,
                  'Okay'
                );
              },
            });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  areAllSkillsDisablable(skill) {
    for (let i = 0; i < skill.levels.length; i++) {
      for (let x = 0; x < skill.levels[i].module_list.length; x++) {
        if (skill.levels[i].module_list[x].can_be_disabled === false) {
          return false;
        }
      }
    }
    return true;
  }

  isAbletoEnableAutoEnable() {
    if (
      this.userData.institution_user.access_level !== 'admin' ||
      (this.userData.institution.platform_access_types.length === 1 &&
        this.userData.institution.platform_access_types[0].platform ===
          'native')
    ) {
      return false;
    }
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    const isSelectionDirty = this.selectedModuleIdsBySkill.some(skill => {
      return skill.module_ids.length !== 0;
    });
    if (
      this.mode === 'status' &&
      (this.movedModules.length > 0 || isSelectionDirty)
    ) {
      return this.dialogService.confirm(
        'You have unsaved changes! Are you sure you want to leave?'
      );
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    const isSelectionDirty = this.selectedModuleIdsBySkill.some(skill => {
      return skill.module_ids.length !== 0;
    });
    if (this.movedModules.length > 0 || isSelectionDirty) {
      $event.returnValue = true;
    }
  }
}
