import { Component, OnInit } from '@angular/core';
import { RequestsService } from './requests.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CopyService } from './copy.service';
import { OnboardingService } from './onboarding/onboarding.service';
import { ModalController } from '@ionic/angular';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { BillingContactModalComponent } from './settings/payments/billing-contact-modal/billing-contact-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'zogo-dashboard';
  userData: any;
  subdomain: string;
  currentPrompt?: string;
  isModalOpen: boolean = false;
  excludedUrls: string[] = [
    '/login',
    '/error',
    '/reset-password',
    '/demo',
    '/public',
    '/schools',
    '/onboarding',
    '/new-user-onboarding',
    '/custom-logos',
  ];

  constructor(
    public req: RequestsService,
    private onboarding: OnboardingService,
    public router: Router,
    private route: ActivatedRoute,
    private appCopy: CopyService,
    private modalController: ModalController
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.showNextPrompt());
  }

  ngOnInit() {
    const urlObject = new URL(window.location.href);
    // Extract the hostname
    const hostname = urlObject.hostname;

    // Extract the subdomain
    const subdomain = hostname.split('.').slice(0, -2).join('.');

    // TEMP
    // let subdomain = 'dev-share-portal';

    if (subdomain === 'share-portal' || subdomain === 'dev-share-portal') {
      this.req.isShareView = true;
      // Extract the route
      const routeCode = urlObject.pathname.substring(1);

      // Extract the URL parameters
      const urlPage = new URLSearchParams(window.location.search).get('page');
      this.req.getAppCopy().subscribe({
        next: data => {
          this.appCopy.setAppCopy(data);
          this.req.getDashboardViewOnlyToken(routeCode).subscribe({
            next: data => {
              const tempToken = data.temp_view_only_token;
              localStorage.setItem('token', tempToken);

              this.req.dashboardLogin().subscribe({
                next: data => {
                  if (data) {
                    localStorage.setItem('userData', JSON.stringify(data));
                    this.userData = data;
                    this.req.routerURL = this.router.url;
                    if (urlPage === 'engagement') {
                      this.req.routerURL = '/overview';
                      this.router.navigate(['/overview']);
                    } else if (urlPage === 'pre-post-test') {
                      this.req.routerURL = '/pre-post-test';
                      this.router.navigate(['/pre-post-test']);
                    } else {
                      this.req.routerURL = '/overview';
                      this.router.navigate(['/overview']);
                    }
                  }
                },
                error: error => {
                  console.log('Error');
                  this.router.navigate(['/error']);
                  //required
                  this.req.routerURL = '/error';
                },
              });
            },
            error: error => {
              console.log('Error');
              this.router.navigate(['/error']);
              //required
              this.req.routerURL = '/error';
            },
          });
        },
        error: error => {
          console.log('Error');
          this.router.navigate(['/error']);
          //required
          this.req.routerURL = '/error';
        },
      });
    } else {
      this.req.getAppCopy().subscribe({
        next: data => {
          this.appCopy.setAppCopy(data);
          this.req.initializeAnalytics();
          this.req.dashboardLogin().subscribe({
            next: data => {
              if (data) {
                localStorage.setItem('userData', JSON.stringify(data));
                this.userData = data;
                this.req.setUserId(
                  this.userData.institution_user.institution_user_id
                );

                this.req.onboardingFlow = data.institution.onboarding_flow;

                this.req.remainingOnboardingFlow =
                  data.institution.remaining_onboarding_steps;

                this.req.routerURL = this.router.url;
                const url = window.location.href;
                if (url.indexOf('/reset-password') > -1) {
                  this.req.logOutNoRedirect();
                  const token = this.getParameterByName('token', url);
                  const inviteSource = this.getParameterByName(
                    'invite_source',
                    url
                  );
                  localStorage.setItem('tempToken', token);
                  console.log('tempToken', token);
                  console.log('inviteSource', inviteSource);
                  if (
                    inviteSource &&
                    (inviteSource.toLowerCase() === 'cx' ||
                      inviteSource.toLowerCase() === 'reset')
                  ) {
                    this.req.routerURL = '/reset-password';
                    this.router.navigate(['/reset-password']);
                  } else if (
                    inviteSource &&
                    inviteSource.toLowerCase() === 'institution'
                  ) {
                    this.req.routerURL = '/new-user-onboarding';
                    this.router.navigate(['/new-user-onboarding']);
                  } else {
                    console.log(
                      `invite source of ${inviteSource} is not set to a valid value`
                    );
                    this.req.routerURL = '/login';
                    this.router.navigate(['/login']);
                  }
                  return;
                } else if (this.req.routerURL === '/public') {
                  this.req.logOutNoRedirect();
                  this.req.routerURL = '/public';
                  this.router.navigate(['/public']);
                  this.presentDemo();
                  return;
                } else if (this.req.routerURL === '/demo') {
                  this.req.logOutNoRedirect();
                  this.req.routerURL = '/demo';
                  this.router.navigate(['/demo']);
                  this.presentDemo();
                  console.log(this.req.routerURL);
                  return;
                } else if (this.userData.institution.onboarding_flow?.length) {
                  this.req.onboardingFlow =
                    this.userData.institution.onboarding_flow;

                  const firstIncompleteOnboardingPage =
                    this.onboarding.getFirstOnboardingPage(
                      this.req.onboardingFlow
                    );

                  this.req.handleOnboardingNavigation(
                    firstIncompleteOnboardingPage
                  );
                  return;
                } else if (
                  this.req.routerURL === '/' ||
                  this.req.routerURL.includes('/onboarding') ||
                  this.req.routerURL.includes('/error')
                ) {
                  this.req.routerURL = '/overview';
                  this.router.navigate(['/overview']);
                }
                this.showNextPrompt();
              }
            },
            error: error => {
              const url = window.location.href;
              if (url.indexOf('/reset-password') > -1) {
                this.req.logOutNoRedirect();
                const token = this.getParameterByName('token', url);
                const inviteSource = this.getParameterByName(
                  'invite_source',
                  url
                );
                localStorage.setItem('tempToken', token);
                console.log('tempToken', token);
                console.log('inviteSource', inviteSource);
                if (
                  inviteSource &&
                  (inviteSource.toLowerCase() === 'cx' ||
                    inviteSource.toLowerCase() === 'reset')
                ) {
                  this.req.routerURL = '/reset-password';
                  this.router.navigate(['/reset-password']);
                } else if (
                  inviteSource &&
                  inviteSource.toLowerCase() === 'institution'
                ) {
                  this.req.routerURL = '/new-user-onboarding';
                  this.router.navigate(['/new-user-onboarding']);
                } else {
                  console.log(
                    `invite source of ${inviteSource} is not set to a valid value`
                  );
                  this.req.routerURL = '/login';
                  this.router.navigate(['/login']);
                }
                return;
              }
              if (url.indexOf('/demo') > -1) {
                this.req.logOutNoRedirect();
                this.req.routerURL = '/demo';
                this.router.navigate(['/demo']);
                this.presentDemo();
                return;
              }
              if (url.indexOf('/public') > -1) {
                this.req.logOutNoRedirect();
                this.req.routerURL = '/public';
                this.router.navigate(['/public']);
                this.presentPublic();
                return;
              } else {
                this.router.navigate(['/login']);
                //required
                this.req.routerURL = '/login';
              }
            },
          });
        },
        error: error => {
          console.log('Error');
          this.router.navigate(['/error']);
          //required
          this.req.routerURL = '/error';
        },
      });
    }
  }

  async showNextPrompt() {
    if (this.excludedUrls.includes(this.router.url) || this.isModalOpen) {
      return;
    }

    this.currentPrompt = this.onboarding.getNextPrompt();

    if (
      this.currentPrompt &&
      !this.onboarding.hasSeenPrompt(this.currentPrompt)
    ) {
      this.isModalOpen = true;
      let header, description, primaryButtonText, componentProps;
      let modal;

      if (this.currentPrompt === 'payment-settings') {
        componentProps = {
          headerText: 'Please add a Billing Contact',
          description:
            'The billing information you provide will allow Zogo to route invoices to the right destination.',
          contactEmail: '',
          contactName: '',
          contactPhone: '',
          showBadge: true,
        };

        modal = await this.modalController.create({
          component: BillingContactModalComponent,
          componentProps,
          cssClass: 'alert-modal',
        });

        // Amplitude event - Billing Information Reminder Delivered
        this.req.ampTrack('Billing Information Reminder Delivered');
      } else if (this.currentPrompt === 'logo') {
        // Amplitude event - Logo Upload Reminder Delivered
        this.req.ampTrack('Logo Upload Reminder Delivered');
        header = 'Brand logos are needed';
        description =
          'Help us to adequately represent your brand by uploading logos now.';
        primaryButtonText = 'Add Logos';

        componentProps = {
          header,
          description,
          primaryButtonText,
          primaryButtonFunc: this.onContinue.bind(this),
          showBadge: true,
        };

        modal = await this.modalController.create({
          component: AlertModalComponent,
          componentProps,
          cssClass: 'alert-modal',
        });
      } else if (this.currentPrompt === 'zip-code') {
        // Amplitude event - Zip Code Upload Reminder Delivered
        this.req.ampTrack('Zip Code Upload Reminder Delivered');
        header = `Ready to upload a list of zip codes eligible for sponsorship with ${this.userData.institution.long_name}`;
        description =
          'You expressed interest in expanding your community reach by sponsoring zip codes.';
        primaryButtonText = 'Add Zip Codes';

        componentProps = {
          header,
          description,
          primaryButtonText,
          primaryButtonFunc: this.onContinue.bind(this),
          secondaryButtonFunc: () => modal.dismiss(),
        };

        modal = await this.modalController.create({
          component: AlertModalComponent,
          componentProps,
          cssClass: 'alert-modal',
        });
      }

      await modal.present();

      // Wait for modal to be dismissed
      modal.onDidDismiss().then(() => {
        this.isModalOpen = false;
        this.onboarding.markPromptAsSeen(this.currentPrompt);

        this.showNextPrompt();
      });
    }
  }

  async onContinue() {
    if (this.currentPrompt) {
      if (this.currentPrompt === 'payment-settings') {
        // Amplitude event - Billing Information Reminder Completed
        this.req.ampTrack('Billing Information Reminder Completed');
        await this.modalController.dismiss();
      } else if (this.currentPrompt === 'logo') {
        // Amplitude event - Logo Upload Reminder Clicked
        this.req.ampTrack('Logo Upload Reminder Clicked');
        this.req.routerURL = '/custom-logos';
        this.router.navigate(['/custom-logos']);
        await this.modalController.dismiss();
      } else if (this.currentPrompt === 'zip-code') {
        // Amplitude event - Zip Code Upload Reminder Clicked
        this.req.ampTrack('Zip Code Upload Reminder Clicked');
        this.req.routerURL = '/settings?tab=zip-codes';
        this.router.navigate(['/settings'], {
          queryParams: { tab: 'zip-codes' },
        });
        await this.modalController.dismiss();
      }
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  presentDemo() {
    this.req.dashboardDemo().subscribe({
      next: data => {
        console.log(data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(data));
        this.req.checkToken();
        window.location.href = '/';
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  presentPublic() {
    this.req.dashboardPublic().subscribe({
      next: data => {
        console.log(data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(data));
        this.req.checkToken();
        window.location.href = '/';
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }
}
