<div class="event-wrapper" [ngClass]="{ last: isLast }">
  <div class="h5-medium text-color-neutral-800">
    {{ eventData.date }}
  </div>
  <div class="event-body">
    <img
      *ngIf="eventData.image"
      class="event-img"
      [src]="eventData.image"
      alt="event image"
    />
    <div *ngIf="!eventData.image" class="event-img-placeholder">
      <div class="event-img-placeholder-content">
        <svg
          class="event-img-placeholder-icon"
          width="73"
          height="72"
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.5 15C6.5 10.0294 10.5294 6 15.5 6H57.5C62.4706 6 66.5 10.0294 66.5 15V57C66.5 61.9706 62.4706 66 57.5 66H15.5C10.5294 66 6.5 61.9706 6.5 57V15ZM15.5 12C13.8431 12 12.5 13.3431 12.5 15V57C12.5 58.6568 13.8431 60 15.5 60H57.5C59.1568 60 60.5 58.6568 60.5 57V15C60.5 13.3431 59.1568 12 57.5 12H15.5Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.5 25.5C18.5 21.3579 21.8579 18 26 18C30.1421 18 33.5 21.3579 33.5 25.5C33.5 29.6421 30.1421 33 26 33C21.8579 33 18.5 29.6421 18.5 25.5ZM26 24C25.1716 24 24.5 24.6716 24.5 25.5C24.5 26.3284 25.1716 27 26 27C26.8284 27 27.5 26.3284 27.5 25.5C27.5 24.6716 26.8284 24 26 24Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M46.3787 27.8787C47.5502 26.7071 49.4497 26.7071 50.6213 27.8787L65.6213 42.8787C66.7929 44.0502 66.7929 45.9497 65.6213 47.1213C64.4497 48.2929 62.5503 48.2929 61.3787 47.1213L48.5 34.2426L17.6213 65.1213C16.4497 66.2929 14.5503 66.2929 13.3787 65.1213C12.2071 63.9497 12.2071 62.0502 13.3787 60.8787L46.3787 27.8787Z"
              fill="currentColor"
            />
          </g>
        </svg>
        <div
          class="overline-medium text-color-neutral-500 event-img-placeholder-text"
        >
          No image for this event
        </div>
      </div>
    </div>
    <div class="event-body-right">
      <div class="event-title h4-medium text-color-neutral-800">
        {{ eventData.title }}
      </div>
      <div
        #eventCaption
        class="event-caption body-book text-color-neutral-800"
        [ngClass]="{ expanded: isExpanded }"
      >
        {{ eventData.caption }}
      </div>
      <button
        class="view-text-button body-book text-color-brand-primary"
        *ngIf="!isExpanded && isTextEllipsed()"
        (click)="readMore()"
      >
        Read more
      </button>
      <button
        class="view-text-button body-book text-color-brand-primary"
        *ngIf="isExpanded"
        (click)="readLess()"
      >
        Read less
      </button>
      <div
        *ngIf="eventData.tags"
        class="event-tags body-bold text-color-neutral-800"
      >
        {{ eventData.tags }}
      </div>
      <zogo-btn
        class="download-btn"
        *ngIf="eventData.image"
        label="Download Image"
        [fullWidth]="false"
        size="medium"
        buttonStyle="primary"
        (clickFunction)="downloadImage()"
      >
      </zogo-btn>
    </div>
  </div>
</div>
