<div class="onboarding-header d-flex-row padding-y-32 padding-x-48">
  <img class="zogo-logo" src="global/logos/zogo-blue.svg" alt="" />
  <div class="d-flex-row gap-12 ion-align-items-center">
    <div *ngIf="hasPageGroupType('account')" class="d-flex-col gap-4">
      <ion-progress-bar
        type="determinate"
        [value]="getGroupProgress('account')"
      ></ion-progress-bar>
      <p
        class="overline-book text-color-neutral-600 ion-align-self-center uppercase"
      >
        account
      </p>
    </div>
    <div *ngIf="hasPageGroupType('branding')" class="d-flex-col gap-4">
      <ion-progress-bar
        type="determinate"
        [value]="getGroupProgress('branding')"
      ></ion-progress-bar>
      <p
        class="overline-book text-color-neutral-600 ion-align-self-center uppercase"
      >
        branding
      </p>
    </div>
    <div *ngIf="hasPageGroupType('product')" class="d-flex-col gap-4">
      <ion-progress-bar
        type="determinate"
        [value]="getGroupProgress('product')"
      ></ion-progress-bar>
      <p
        class="overline-book text-color-neutral-600 ion-align-self-center uppercase"
      >
        product
      </p>
    </div>
  </div>
  <div class="zogo-logo"></div>
</div>
