import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-field-text-input',
  templateUrl: './field-text-input.component.html',
  styleUrls: ['./field-text-input.component.css'],
})
export class FieldTextInputComponent implements OnInit {
  @Input() inputTitle: string = '';
  @Input() inputId: string = '';
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() preview: string = '';
  @Input() ariaLabel: string = '';
  @Input() maxLength: number = 60;
  @Input() disableMaxLength: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() rows: number = 2;
  @Input() isResizable: boolean = false;
  @Input() wrap: 'soft' | 'hard' | 'off' = 'off';
  @Output() valueChange = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter<void>();

  currentValue: string = '';

  ngOnInit(): void {
    if (!this.ariaLabel) {
      this.ariaLabel = this.inputTitle;
    }
    if (this.value) {
      this.currentValue = this.value;
    }
  }

  onInput(event: any) {
    this.currentValue = event;
    this.valueChange.emit(this.currentValue);
  }

  onBlur() {
    this.blurEvent.emit();
  }
}
