<app-onboarding-header></app-onboarding-header>
<div class="form-container d-flex-col gap-24">
  <div class="d-flex-col gap-8">
    <h1 class="h3-bold">Zogo Web Subdomain</h1>
    <p class="body-book">
      This URL will provide your learners access to Zogo Web. Your subdomain can
      <span class="body-bold">not</span> be changed later.
    </p>
  </div>

  <form #form="ngForm" (ngSubmit)="onSubmitSubdomain()">
    <div class="d-flex-col gap-12 margin-bottom-24">
      <div class="d-flex-col gap-4">
        <h2 class="caption-medium">Subdomain</h2>
        <div class="subdomain-input-wrapper d-flex-row gap-8">
          <p class="caption-medium text-color-neutral-600">https://</p>
          <zogo-text-input
            [(ngModel)]="subdomain"
            name="subdomain"
            class="subdomain-input"
            formType="inlineInput"
            inputId="subdomain"
            inputLabel="subdomain"
            ariaLabel="Subdomain"
            [isRequired]="true"
            [currentValue]="subdomain"
            appCustomValidation="subdomain"
            #subdomainInput="ngModel"
          ></zogo-text-input>
          <p class="caption-medium text-color-neutral-600">.zogo.com</p>
        </div>
      </div>
      <div class="d-flex-col gap-2">
        <h2 class="overline-medium">Subdomain requirements</h2>
        <ul class="overline-book margin-left-16">
          <li>Only use letters, numbers and hyphens</li>
          <li>Cannot use hyphens as the first or last character</li>
          <li>Contain no more than 30 characters</li>
        </ul>
      </div>

      <p class="overline-book">
        The URL you are adding will create the Zogo Web educational experience
        specific to your institution. Please make sure the URL you choose
        reflects your institution and is easily recognizable by account holders
        as they will use this unique URL to gain access to your sponsored Zogo
        Web page. Strong examples would include: firstcommunity.zogo.com or
        psfcu.zogo.com.
      </p>
    </div>

    <zogo-btn
      type="submit"
      size="large"
      label="Finish"
      [fullWidth]="true"
      [disabled]="form.invalid"
    ></zogo-btn>
  </form>
</div>
