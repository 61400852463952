<div class="d-flex-col gap-24">
  <zogo-btn
    class="ion-align-self-end"
    iconName="plus"
    label="Add Teacher"
    (clickFunction)="addTeacher()"
  ></zogo-btn>

  <ion-spinner
    *ngIf="isLoading"
    class="ion-align-self-center"
    name="circular"
  ></ion-spinner>

  <!-- Empty state when no members exists -->
  <div *ngIf="!hasTeachers && !isLoading" class="teacher-upload-wrapper">
    <zogo-icon
      iconName="user-plus"
      size="xlarge"
      class="text-color-neutral-600"
    ></zogo-icon>
    <p class="h4-medium text-color-neutral-600">Add teachers to get started</p>
    <zogo-btn
      label="Add Teacher"
      buttonStyle="secondary"
      (clickFunction)="addTeacher()"
    ></zogo-btn>
  </div>

  <ng-container *ngIf="hasTeachers && !isLoading" class="d-flex-col gap-16">
    <table>
      <thead>
        <tr class="d-flex-row">
          <th class="name-col body-bold text-align-left" scope="col">Name</th>
          <th class="email-col body-bold text-align-left" scope="col">
            Email Address
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="data-row d-flex-row"
          *ngFor="let teacher of teachers; index as i"
        >
          <td class="name-col body-medium d-flex-row gap-16">
            {{ getName(teacher) }}
          </td>
          <td class="email-col body-book pos-rel">
            {{ teacher?.email }}
            <div
              tappable
              class="teacher-action-cta"
              (click)="toggleDropdown(i)"
            >
              <zogo-icon
                class="text-color-neutral-600"
                iconName="more-horizontal"
              ></zogo-icon>
            </div>
            <ul *ngIf="dropdownIndex === i" class="dropdown-list margin-top-2">
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="editTeacher(teacher)"
              >
                <zogo-icon iconName="edit"></zogo-icon>
                <p>Edit</p>
              </li>
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="resendInvitation(teacher)"
              >
                <zogo-icon iconName="mail"></zogo-icon>
                <p>Resend Invite</p>
              </li>
              <li
                tappable
                class="caption-medium d-flex-row gap-8 ion-align-items-center"
                (click)="showConfirmationAlert(teacher)"
              >
                <zogo-icon iconName="trash-2"></zogo-icon>
                <p>Remove</p>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
