import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-link-display',
  templateUrl: './link-display.component.html',
  styleUrls: ['./link-display.component.css'],
})
export class LinkDisplayComponent {
  @Input() linkDisplayData: any;

  constructor(
    private clipboardService: ClipboardService,
    public req: RequestsService
  ) {}

  copyLink() {
    this.clipboardService.copyFromContent(this.linkDisplayData.source);
    this.req.openSnackBar('Link copied to clipboard', 'Okay');
  }
}
