import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  forwardRef,
} from '@angular/core';
import {
  FormsModule,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';
import { HelperFunctionsService } from '../../helper-functions.service';
import { ZogoTextInputComponent } from '../zogo-text-input/zogo-text-input.component';

@Component({
  selector: 'zogo-dropdown',
  templateUrl: './zogo-dropdown.component.html',
  styleUrls: ['./zogo-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZogoIconComponent,
    ZogoTextInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZogoDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ZogoDropdownComponent),
      multi: true,
    },
  ],
})
export class ZogoDropdownComponent implements ControlValueAccessor, Validator {
  @Input() label: string = '';
  @Input() options: string[] = [];
  @Input() required: boolean = false;
  @Input() placeholder: string = 'Select an option';
  @Input() selectedOption: string | null = null;
  @Output() optionSelected = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter<void>();

  isOpen = false;
  showOtherInput = false;
  otherValue: string = '';
  focused = false;

  constructor(public helper: HelperFunctionsService) {}

  private onChange = (value: any) => {};
  private onTouched = () => {};

  writeValue(value: any): void {
    this.selectedOption = value;
    this.showOtherInput = value === 'Other';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && !this.selectedOption) {
      return { required: true };
    }
    return null;
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.focused = this.isOpen;
    this.handleBlur();
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.showOtherInput = option === 'Other';
    this.onChange(option === 'Other' ? this.otherValue : option);
    this.isOpen = false;
    this.focused = false;
    this.optionSelected.emit(option === 'Other' ? this.otherValue : option);
    this.handleBlur();
  }

  updateOtherValue() {
    if (this.selectedOption === 'Other') {
      this.onChange(this.otherValue);
      this.optionSelected.emit(this.otherValue);
    }
  }

  handleBlur() {
    this.blurEvent.emit();
    this.onTouched();
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const clickedInside = element.closest('.dropdown-container');

    if (!clickedInside) {
      this.isOpen = false;
      this.focused = false;
    }
  }
}
