<div class="dashboard-view">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-8">
        <h1 class="h3-bold">Custom Modules</h1>
        <p class="body-medium text-color-neutral-600">
          {{ dashboardData?.total_modules_created }}
          created<span class="pl-3"
            >{{ dashboardData?.modules_remaining }} modules available</span
          >
        </p>
      </div>
      <div class="col-md-4 text-right">
        <div class="cta">
          <div
            matTooltip="Editing is disabled for “Read-only” users"
            aria-label="Button that displays a tooltip when focused or hovered over"
            [matTooltipDisabled]="isAdmin"
          >
            <zogo-btn
              label="Create Custom Module"
              [fullWidth]="false"
              size="medium"
              buttonStyle="primary"
              [disabled]="
                !this.isAdmin ||
                (dashboardData?.modules_remaining <= 0 &&
                  dashboardData?.modules_remaining !== null)
              "
              (clickFunction)="createModule()"
            >
            </zogo-btn>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-area">
    <div class="container-fluid p-0" *ngIf="dashboardData">
      <div class="row mt-1">
        <div class="col-md-12">
          <div class="card card-frame">
            <div
              class="header-h5-bold text-color-header-1 page-text-header"
              *ngIf="
                (dashboardData && dashboardData?.intro_modules.length !== 0) ||
                dashboardData?.modules.length !== 0
              "
            >
              <h3
                class="header-h5-bold text-color-header-1 intro-module-header"
              >
                Intro Modules ({{ dashboardData.intro_modules.length }}/{{
                  dashboardData.max_intro_modules
                }})
                <svg
                  class="tappable"
                  (click)="openIntroModuleExplanationDialog()"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5366_182087)">
                    <path
                      d="M8.00065 15.1693C11.6825 15.1693 14.6673 12.1845 14.6673 8.5026C14.6673 4.82071 11.6825 1.83594 8.00065 1.83594C4.31875 1.83594 1.33398 4.82071 1.33398 8.5026C1.33398 12.1845 4.31875 15.1693 8.00065 15.1693Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11.1667V8.5"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 5.83203H8.00833"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5366_182087">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </h3>
              <button
                *ngIf="dashboardData.intro_modules.length > 1"
                [disabled]="!orderChanges"
                (click)="savePositionOrder()"
                class="save-order-btn"
                mat-button
              >
                Save Order
              </button>
            </div>
            <div
              *ngIf="
                dashboardData &&
                dashboardData.intro_modules.length === 0 &&
                dashboardData.modules.length > 0
              "
              class="empty-module-container empty-intro-module-container"
            >
              <div class="h4-bold text-color-neutral-500">
                No intro modules created yet
              </div>
            </div>
            <div
              class="custom-module-table"
              *ngIf="dashboardData && dashboardData.intro_modules.length > 0"
            >
              <table
                *ngIf="dashboardData.intro_modules.length > 0"
                mat-table
                [dataSource]="dashboardData?.intro_modules"
                cdkDropList
                (cdkDropListDropped)="onDragEnded($event)"
              >
                <!-- Position Column -->
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <div class="icon-col">
                      <div class="placement-status">
                        <img
                          class="drag-image tappable"
                          matTooltip="Drag and drop to reorder"
                          src="../../../assets/imgs/draganddrop.svg"
                        />
                      </div>
                      <div class="module-image">
                        <img
                          class="icon"
                          [src]="element?.module?.image"
                          alt=""
                        />
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-template cdkDragPreview></ng-template>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Module name</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="body-bold text-color-neutral-800">
                      {{ element.module.module_name['en-US'] }}
                      <span
                        matTooltip="Module available in Spanish"
                        *ngIf="element.module?.spanish_enabled"
                        class="spanish-skill-icon"
                      >
                        <img
                          src="https://images.ctfassets.net/o1xn7flb83yr/6wZ1lDAiB4mkbLqjRs5p2E/1c26eb3e7139b9ffb9bc06b5afd7ea4f/spain.png"
                          alt="spanish-flag"
                        />
                      </span>
                    </div>
                    <div class="overline-medium text-color-neutral-600">
                      Created {{ element.overview.date_text | date }}
                    </div>
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="element?.module.status.value">
                      <app-zogo-label
                        *ngSwitchCase="'Inactive'"
                        type="neutral"
                        [label]="'Draft'"
                      ></app-zogo-label>
                      <app-zogo-label
                        *ngSwitchCase="'Pending Approval'"
                        type="notice"
                        [label]="'Pending Approval'"
                      ></app-zogo-label>
                      <app-zogo-label
                        *ngSwitchCase="'Active'"
                        type="success"
                        [label]="'Approved'"
                      ></app-zogo-label>
                    </div>
                  </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="completed">
                  <th mat-header-cell *matHeaderCellDef>Completed</th>
                  <td
                    class="body-bold text-color-neutral-800"
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.overview.completed }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="correct">
                  <th mat-header-cell *matHeaderCellDef>ACCURACY(%)</th>
                  <td
                    class="body-bold text-color-neutral-800"
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.overview.correct }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="selection">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td class="numb" mat-cell *matCellDef="let element">
                    <svg
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      class="tappable"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0013 10.8307C10.4615 10.8307 10.8346 10.4576 10.8346 9.9974C10.8346 9.53716 10.4615 9.16406 10.0013 9.16406C9.54106 9.16406 9.16797 9.53716 9.16797 9.9974C9.16797 10.4576 9.54106 10.8307 10.0013 10.8307Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0013 5.0026C10.4615 5.0026 10.8346 4.62951 10.8346 4.16927C10.8346 3.70903 10.4615 3.33594 10.0013 3.33594C9.54106 3.33594 9.16797 3.70903 9.16797 4.16927C9.16797 4.62951 9.54106 5.0026 10.0013 5.0026Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0013 16.6667C10.4615 16.6667 10.8346 16.2936 10.8346 15.8333C10.8346 15.3731 10.4615 15 10.0013 15C9.54106 15 9.16797 15.3731 9.16797 15.8333C9.16797 16.2936 9.54106 16.6667 10.0013 16.6667Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <mat-menu #menu="matMenu">
                      <button
                        *ngIf="
                          element?.module?.placed_status === 'not_placeable' &&
                          canModuleBeSubmitted(element.module)
                        "
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="submitModule($event, element)"
                      >
                        <span class="caption-medium text-color-body-1"
                          >Submit for approval</span
                        >
                      </button>
                      <button
                        *ngIf="
                          element?.module?.placed_status === 'not_placeable' &&
                          !canModuleBeSubmitted(element.module)
                        "
                        class="menu-btn-icon-gap"
                        [matTooltip]="
                          'Module is not complete.
                        Edit the module to enable send for review.'
                        "
                        matTooltipPosition="above"
                        mat-menu-item
                      >
                        <span
                          class="caption-medium text-color-body-1 disabled-option"
                          >Submit for approval</span
                        >
                      </button>
                      <button
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="openModule(element)"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5241_106866)">
                            <path
                              d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.875 1.87224C14.1734 1.57387 14.578 1.40625 15 1.40625C15.422 1.40625 15.8266 1.57387 16.125 1.87224C16.4234 2.17061 16.591 2.57528 16.591 2.99724C16.591 3.4192 16.4234 3.82387 16.125 4.12224L9 11.2472L6 11.9972L6.75 8.99724L13.875 1.87224Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5241_106866">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <span class="caption-medium text-color-body-1"
                          >Edit module</span
                        >
                      </button>
                      <button
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="deleteModule(element)"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 4.5H3.75H15.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 8.25V12.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 8.25V12.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <span class="caption-medium error-text-color"
                          >Delete</span
                        >
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  cdkDrag
                ></tr>
              </table>
            </div>
            <div *ngIf="!dashboardData" class="text-align-center">
              <ion-spinner name="circular"></ion-spinner>
            </div>
            <!-- bottom half -->
            <div
              class="placeable-module-message body-b2-medium"
              *ngIf="getPlacableModuleCount() > 0 && placementCTA"
            >
              <div class="placable-module-header">
                <div class="placable-module-header-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_6504_164080)">
                      <path
                        d="M18.3346 9.2355V10.0022C18.3336 11.7992 17.7517 13.5477 16.6757 14.987C15.5998 16.4263 14.0874 17.4792 12.3641 17.9888C10.6408 18.4983 8.79902 18.4371 7.11336 17.8143C5.4277 17.1916 3.98851 16.0406 3.01044 14.5331C2.03236 13.0255 1.56779 11.2422 1.68603 9.44909C1.80427 7.65597 2.49897 5.9491 3.66654 4.58306C4.8341 3.21702 6.41196 2.26498 8.16479 1.86895C9.91763 1.47292 11.7515 1.65411 13.393 2.3855"
                        stroke="#777DEE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.3333 3.32812L10 11.6698L7.5 9.16979"
                        stroke="#777DEE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6504_164080">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <div>
                    You have {{ getPlacableModuleCount() }}
                    {{ getPlacableModuleCount() > 1 ? 'modules' : 'module' }}
                    ready to be placed!
                  </div>
                </div>
                <div class="placable-module-header-right">
                  <button
                    class="module-place-button"
                    (click)="placeModule()"
                    mat-button
                  >
                    Place Module
                  </button>
                  <svg
                    class="tappable"
                    (click)="dismissPlacementCTA()"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z"
                      fill="#313131"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <h3
              class="header-h5-bold text-color-header-1 page-text-header"
              *ngIf="
                dashboardData.modules.length > 0 ||
                dashboardData.intro_modules.length > 0
              "
            >
              Modules
            </h3>

            <div
              class="icon-guide"
              *ngIf="
                dashboardData.modules.length > 0 ||
                dashboardData.intro_modules.length > 0
              "
            >
              <div class="header-h5-bold text-color-body-1">Icon Guide:</div>
              <div class="icon-guide-item label-medium text-color-header-1">
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5397_186206)">
                    <path
                      d="M2.95508 9C2.95508 5.34112 5.92119 2.375 9.58008 2.375C13.239 2.375 16.2051 5.34112 16.2051 9C16.2051 12.6589 13.239 15.625 9.58008 15.625C5.92119 15.625 2.95508 12.6589 2.95508 9ZM9.58008 0.625C4.9547 0.625 1.20508 4.37462 1.20508 9C1.20508 13.6254 4.9547 17.375 9.58008 17.375C14.2054 17.375 17.9551 13.6254 17.9551 9C17.9551 4.37462 14.2054 0.625 9.58008 0.625Z"
                      fill="#8C8C8C"
                      stroke="#8C8C8C"
                      stroke-width="0.25"
                    />
                    <path
                      d="M1.46136 17.1187L1.54975 17.0303L1.46136 17.1187C1.80307 17.4604 2.35708 17.4604 2.6988 17.1187L2.61041 17.0303L2.6988 17.1187L17.6988 2.11872L17.6104 2.03033L17.6988 2.11872C18.0405 1.77701 18.0405 1.223 17.6988 0.881283L17.6104 0.969668L17.6988 0.881282C17.3571 0.539573 16.8031 0.539573 16.4614 0.88128L1.46136 15.8813C1.11965 16.223 1.11965 16.777 1.46136 17.1187Z"
                      fill="#8C8C8C"
                      stroke="#8C8C8C"
                      stroke-width="0.25"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5397_186206">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.580078)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div>Cannot Be Placed</div>
              </div>
              <div class="icon-guide-item label-medium text-color-header-1">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9147 9.2355V10.0022C18.9137 11.7992 18.3318 13.5477 17.2558 14.987C16.1799 16.4263 14.6675 17.4792 12.9442 17.9888C11.2209 18.4983 9.3791 18.4371 7.69344 17.8143C6.00778 17.1916 4.56859 16.0406 3.59051 14.5331C2.61244 13.0255 2.14787 11.2422 2.26611 9.44909C2.38435 7.65597 3.07905 5.9491 4.24661 4.58306C5.41417 3.21702 6.99204 2.26498 8.74487 1.86895C10.4977 1.47292 12.3316 1.65411 13.9731 2.3855"
                    stroke="#8C8C8C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.9134 3.33203L10.5801 11.6737L8.08008 9.1737"
                    stroke="#8C8C8C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>Ready to be placed</div>
              </div>
              <div class="icon-guide-item label-medium text-color-header-1">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9147 9.2355V10.0022C18.9137 11.7992 18.3318 13.5477 17.2558 14.987C16.1799 16.4263 14.6675 17.4792 12.9442 17.9888C11.2209 18.4983 9.3791 18.4371 7.69344 17.8143C6.00778 17.1916 4.56859 16.0406 3.59051 14.5331C2.61244 13.0255 2.14787 11.2422 2.26611 9.44909C2.38435 7.65597 3.07905 5.9491 4.24661 4.58306C5.41417 3.21702 6.99204 2.26498 8.74487 1.86895C10.4977 1.47292 12.3316 1.65411 13.9731 2.3855"
                    stroke="#0050AA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.9134 3.33203L10.5801 11.6737L8.08008 9.1737"
                    stroke="#0050AA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>Placed</div>
              </div>
            </div>

            <div
              *ngIf="dashboardData && dashboardData.modules.length === 0"
              class="empty-module-container"
            >
              <svg
                width="87"
                height="88"
                viewBox="0 0 87 88"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="43.4999"
                  cy="43.871"
                  r="41.7085"
                  fill="#F6F6F6"
                  stroke="#AAAAAA"
                  stroke-width="3.57501"
                  stroke-dasharray="12 12"
                />
                <path
                  d="M63.7188 35.7497C64.426 35.7497 65 35.1617 65 34.4372V31.8122C65 31.3274 64.7403 30.8829 64.3218 30.6537L45.103 20.1536C44.7255 19.9488 44.2762 19.9488 43.8987 20.1536L24.6799 30.6537C24.2597 30.8829 24 31.3274 24 31.8122V34.4372C24 35.1617 24.574 35.7497 25.2812 35.7497H29.125V56.7499H25.7083C24.7653 56.7499 24 57.534 24 58.5V60.25C24 61.216 24.7653 62 25.7083 62H63.2917C64.2347 62 65 61.216 65 60.25V58.5C65 57.534 64.2347 56.7499 63.2917 56.7499H59.875V35.7497H63.7188ZM56.4583 56.7499H32.5417V35.7497H56.4583V56.7499Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M43.816 47.7743H45.184C45.7996 47.7743 46.3 48.2469 46.3 48.8454C46.3 49.4268 45.7996 49.8995 45.184 49.8995H41.35C40.6048 49.8995 40 50.4707 40 51.1746C40 51.8785 40.6048 52.4497 41.35 52.4497H43.15V53.7249C43.15 54.4287 43.7548 55 44.5 55C45.2452 55 45.85 54.4287 45.85 53.7249V52.3868C47.6356 52.0876 49 50.6153 49 48.8301C49 46.8426 47.2882 45.2257 45.184 45.2257H43.816C43.2004 45.2257 42.7 44.7531 42.7 44.1546C42.7 43.5732 43.2004 43.1005 43.816 43.1005H47.65C48.3952 43.1005 49 42.5293 49 41.8254C49 41.1215 48.3952 40.5503 47.65 40.5503H45.85V39.2751C45.85 38.5713 45.2452 38 44.5 38C43.7548 38 43.15 38.5713 43.15 39.2751V40.6132C41.3644 40.9124 40 42.3847 40 44.1699C40 46.1574 41.7118 47.7743 43.816 47.7743Z"
                  fill="#DCDCDC"
                />
              </svg>

              <div class="h4-bold text-color-neutral-500">
                No custom modules created yet
              </div>
            </div>
            <div
              class="custom-module-table"
              *ngIf="dashboardData.modules.length > 0"
            >
              <table mat-table [dataSource]="dashboardData?.modules">
                <!-- Position Column -->
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <div class="icon-col">
                      <div class="placement-status">
                        <svg
                          *ngIf="
                            element?.module?.placed_status === 'not_placeable'
                          "
                          [matTooltip]="'Cannot be placed'"
                          class="text-color-body-5"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5397_186248)">
                            <path
                              d="M2.95508 9C2.95508 5.34112 5.92119 2.375 9.58008 2.375C13.239 2.375 16.2051 5.34112 16.2051 9C16.2051 12.6589 13.239 15.625 9.58008 15.625C5.92119 15.625 2.95508 12.6589 2.95508 9ZM9.58008 0.625C4.9547 0.625 1.20508 4.37462 1.20508 9C1.20508 13.6254 4.9547 17.375 9.58008 17.375C14.2054 17.375 17.9551 13.6254 17.9551 9C17.9551 4.37462 14.2054 0.625 9.58008 0.625Z"
                              fill="currentColor"
                              stroke="currentColor"
                              stroke-width="0.25"
                            />
                            <path
                              d="M1.46136 17.1187L1.54975 17.0303L1.46136 17.1187C1.80307 17.4604 2.35708 17.4604 2.6988 17.1187L2.61041 17.0303L2.6988 17.1187L17.6988 2.11872L17.6104 2.03033L17.6988 2.11872C18.0405 1.77701 18.0405 1.223 17.6988 0.881283L17.6104 0.969668L17.6988 0.881282C17.3571 0.539573 16.8031 0.539573 16.4614 0.88128L1.46136 15.8813C1.11965 16.223 1.11965 16.777 1.46136 17.1187Z"
                              fill="currentColor"
                              stroke="currentColor"
                              stroke-width="0.25"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5397_186248">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(0.580078)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <svg
                          *ngIf="
                            element?.module?.placed_status !== 'not_placeable'
                          "
                          [ngClass]="{
                            'text-color-body-5':
                              element?.module?.placed_status === 'ready',
                            'primary-dark-blue':
                              element?.module?.placed_status === 'placed'
                          }"
                          [matTooltip]="
                            element?.module?.placed_status === 'ready'
                              ? 'Ready to be placed'
                              : 'Placed in: 
                            ' +
                                getTooltipContent(
                                  element?.module?.placed_platforms
                                )
                          "
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9147 9.2355V10.0022C18.9137 11.7992 18.3318 13.5477 17.2558 14.987C16.1799 16.4263 14.6675 17.4792 12.9442 17.9888C11.2209 18.4983 9.3791 18.4371 7.69344 17.8143C6.00778 17.1916 4.56859 16.0406 3.59051 14.5331C2.61244 13.0255 2.14787 11.2422 2.26611 9.44909C2.38435 7.65597 3.07905 5.9491 4.24661 4.58306C5.41417 3.21702 6.99204 2.26498 8.74487 1.86895C10.4977 1.47292 12.3316 1.65411 13.9731 2.3855"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.9134 3.33203L10.5801 11.6737L8.08008 9.1737"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="module-image">
                        <img
                          class="icon"
                          [src]="element?.module?.image"
                          alt=""
                        />
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Module name</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="body-bold text-color-neutral-800">
                      {{ element.module.module_name['en-US'] }}
                      <span
                        matTooltip="Module available in Spanish"
                        *ngIf="element.module?.spanish_enabled"
                        class="spanish-skill-icon"
                      >
                        <img
                          src="https://images.ctfassets.net/o1xn7flb83yr/6wZ1lDAiB4mkbLqjRs5p2E/1c26eb3e7139b9ffb9bc06b5afd7ea4f/spain.png"
                          alt="spanish-flag"
                        />
                      </span>
                    </div>

                    <div class="overline-medium text-color-neutral-600">
                      Created {{ element.overview.date_text | date }}
                    </div>
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="element?.module.status.value">
                      <app-zogo-label
                        *ngSwitchCase="'Inactive'"
                        type="neutral"
                        [label]="'Draft'"
                      ></app-zogo-label>
                      <app-zogo-label
                        *ngSwitchCase="'Pending Approval'"
                        type="notice"
                        [label]="'Pending Approval'"
                      ></app-zogo-label>
                      <app-zogo-label
                        *ngSwitchCase="'Active'"
                        type="success"
                        [label]="'Approved'"
                      ></app-zogo-label>
                    </div>
                  </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="completed">
                  <th mat-header-cell *matHeaderCellDef>Completed</th>
                  <td
                    class="body-bold text-color-neutral-800"
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.overview.completed }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="correct">
                  <th mat-header-cell *matHeaderCellDef>ACCURACY(%)</th>
                  <td
                    class="body-bold text-color-neutral-800"
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.overview.correct }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="selection">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td class="numb" mat-cell *matCellDef="let element">
                    <svg
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      class="tappable"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0013 10.8307C10.4615 10.8307 10.8346 10.4576 10.8346 9.9974C10.8346 9.53716 10.4615 9.16406 10.0013 9.16406C9.54106 9.16406 9.16797 9.53716 9.16797 9.9974C9.16797 10.4576 9.54106 10.8307 10.0013 10.8307Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0013 5.0026C10.4615 5.0026 10.8346 4.62951 10.8346 4.16927C10.8346 3.70903 10.4615 3.33594 10.0013 3.33594C9.54106 3.33594 9.16797 3.70903 9.16797 4.16927C9.16797 4.62951 9.54106 5.0026 10.0013 5.0026Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0013 16.6667C10.4615 16.6667 10.8346 16.2936 10.8346 15.8333C10.8346 15.3731 10.4615 15 10.0013 15C9.54106 15 9.16797 15.3731 9.16797 15.8333C9.16797 16.2936 9.54106 16.6667 10.0013 16.6667Z"
                        stroke="#484848"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <mat-menu #menu="matMenu">
                      <button
                        *ngIf="
                          element?.module?.placed_status === 'not_placeable' &&
                          canModuleBeSubmitted(element.module)
                        "
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="submitModule($event, element)"
                      >
                        <span class="caption-medium text-color-body-1"
                          >Submit for approval</span
                        >
                      </button>
                      <button
                        *ngIf="
                          element?.module?.placed_status === 'not_placeable' &&
                          !canModuleBeSubmitted(element.module)
                        "
                        class="menu-btn-icon-gap"
                        [matTooltip]="
                          'Module is not complete.
                          
                        Edit the module to enable send for review.'
                        "
                        matTooltipPosition="above"
                        mat-menu-item
                      >
                        <span
                          class="caption-medium text-color-body-1 disabled-option"
                          >Submit for approval</span
                        >
                      </button>
                      <button
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="openModule(element)"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5241_106866)">
                            <path
                              d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.875 1.87224C14.1734 1.57387 14.578 1.40625 15 1.40625C15.422 1.40625 15.8266 1.57387 16.125 1.87224C16.4234 2.17061 16.591 2.57528 16.591 2.99724C16.591 3.4192 16.4234 3.82387 16.125 4.12224L9 11.2472L6 11.9972L6.75 8.99724L13.875 1.87224Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5241_106866">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <span class="caption-medium text-color-body-1"
                          >Edit module</span
                        >
                      </button>
                      <button
                        class="menu-btn-icon-gap"
                        mat-menu-item
                        (click)="deleteModule(element)"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 4.5H3.75H15.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 8.25V12.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 8.25V12.75"
                            stroke="#FF555F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <span class="caption-medium error-text-color"
                          >Delete</span
                        >
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
            <div *ngIf="!dashboardData" class="text-align-center">
              <ion-spinner name="circular"></ion-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!dashboardData" class="text-align-center">
      <ion-spinner name="circular"></ion-spinner>
    </div>
  </div>
</div>
