<div class="account-modal-wrapper">
  <zogo-icon
    class="dismiss-icon text-color-neutral-800"
    iconName="x"
    (click)="dismiss()"
  ></zogo-icon>
  <div class="d-flex-col gap-24">
    <h2 class="h4-bold">Institution Settings</h2>

    <form #form="ngForm" (ngSubmit)="onSubmitForm()">
      <div class="d-flex-col gap-12 margin-bottom-24">
        <!-- Institution Name -->
        <zogo-text-input
          [(ngModel)]="fullInstName"
          name="fullInstName"
          inputId="institution-name"
          inputLabel="Full Institution Name"
          ariaLabel="Full Institution Name"
          [isRequired]="true"
          [currentValue]="fullInstName"
          #fullInstNameInput="ngModel"
        ></zogo-text-input>

        <!-- Institution Abbreviation -->
        <zogo-text-input
          [(ngModel)]="preferredAbbr"
          name="preferredInstAbbr"
          inputId="preferred-institution-abbr"
          inputLabel="Preferred Institution Abbreviation"
          ariaLabel="Preferred Institution Abbreviation"
          [isRequired]="true"
          [currentValue]="preferredAbbr"
          #preferredAbbrInput="ngModel"
        ></zogo-text-input>

        <!-- Institution Type -->
        <zogo-dropdown
          [(ngModel)]="institutionType"
          name="institutionType"
          label="Institution Type"
          placeholder="Choose One"
          required
          [options]="institutionTypeOptions"
          #institutionDropdown="ngModel"
        ></zogo-dropdown>

        <!-- Website Url -->
        <div class="d-flex-col gap-4">
          <p class="caption-medium">Website URL</p>
          <div class="website-input-wrapper d-flex-row gap-8">
            <p class="caption-medium text-color-neutral-600">https://</p>
            <zogo-text-input
              [(ngModel)]="websiteUrl"
              name="websiteUrl"
              class="website-input"
              formType="inlineInput"
              inputId="website"
              inputLabel="Website URL"
              ariaLabel="Website Url"
              [isRequired]="true"
              [currentValue]="websiteUrl"
              appCustomValidation="website"
              (valueChange)="onWebsiteUrlChange($event)"
              #websiteUrlInput="ngModel"
            ></zogo-text-input>
            <div
              *ngIf="
                websiteUrlInput.errors?.invalidUrl && websiteUrlInput.touched
              "
              class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
            >
              <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
              <p>Invalid website url</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex-row gap-8">
        <zogo-btn
          [disabled]="form.invalid"
          type="submit"
          label="Save Changes"
        ></zogo-btn>
        <zogo-btn
          buttonStyle="tertiary"
          label="Cancel"
          (clickFunction)="dismiss()"
        ></zogo-btn>
      </div>
    </form>
  </div>
</div>
