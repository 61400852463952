<div class="container">
  <div class="close">
    <svg
      (click)="close()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-x"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </div>
  <div class="body">
    <ion-spinner *ngIf="secondaryText === ''" name="circular"></ion-spinner>
    <div class="primary-text h5-bold text-color-neutral-500">
      {{ primaryText }}
    </div>
    <div class="secondary-text caption-bold text-color-brand-orange">
      {{ secondaryText }}
    </div>
  </div>
</div>
