<div class="input-container">
  <label
    class="caption-medium text-color-neutral-800"
    *ngIf="formType === 'input' || formType === 'textArea'"
    [for]="inputId"
    >{{ inputLabel }}</label
  >
  <div class="input-row">
    <span class="input-icon">
      <zogo-icon *ngIf="iconName" [iconName]="iconName"></zogo-icon>
    </span>
    <label
      *ngIf="formType !== 'input' && formType !== 'textArea'"
      [for]="inputId"
    ></label>
    <input
      *ngIf="formType !== 'textArea'"
      class="body-book input-field"
      [id]="inputId"
      [name]="inputLabel"
      [autocomplete]="autocompleteType"
      [ngClass]="{
        'remove-icon-padding': !iconName,
        shadow: formType === 'inlineShadow',
        disabled: isDisabled
      }"
      [type]="inputType"
      [(ngModel)]="currentValue"
      (ngModelChange)="onInput($event)"
      (blur)="onBlur()"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [required]="isRequired"
      [attr.aria-label]="ariaLabel"
    />
    <textarea
      *ngIf="formType === 'textArea'"
      class="body-book input-field text-area"
      [id]="inputId"
      [name]="inputLabel"
      [autocomplete]="autocompleteType"
      [ngClass]="{
        'text-area-resizable': isResizable,
        'remove-icon-padding': !iconName,
        disabled: isDisabled
      }"
      [(ngModel)]="currentValue"
      (ngModelChange)="onInput($event)"
      (blur)="onBlur()"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [required]="isRequired"
      [attr.aria-label]="ariaLabel"
      [wrap]="wrap"
      [rows]="rows"
    ></textarea>
  </div>
</div>
