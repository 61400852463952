<!-- <img class="logo-img" src="/assets/imgs/BlueLogo.svg" /> -->

<div class="cx-snippet-container">
  <div class="cx-snippet">
    <img
      class="zogo-pineapple"
      src="{{ this.dashboardGlobalCopy.zogo_pineapple }}"
    />
    <p>
      {{ this.dashboardGlobalCopy.help_text }}
      <a
        className="cx-email"
        href="mailto: {{ this.dashboardGlobalCopy.zogo_email }}"
      >
        {{ this.dashboardGlobalCopy.zogo_email }}</a
      >
    </p>
  </div>
</div>
