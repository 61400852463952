import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';
import { HelperFunctionsService } from '../../helper-functions.service';

@Component({
  selector: 'zogo-searchbar',
  templateUrl: './zogo-searchbar.component.html',
  styleUrls: ['./zogo-searchbar.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ZogoIconComponent],
})
export class ZogoSearchbarComponent {
  @Input() label: string = '';
  @Input() borderStyle: 'border' | 'borderless' = 'borderless';
  @Output() searchOutput: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperFunctionsService
  ) {
    this.formGroup = this.formBuilder.group({
      search: [''],
    });
  }

  onSearch(): void {
    const searchValue = this.formGroup.get('search')?.value;
    this.searchOutput.emit(searchValue);
  }
}
