<div class="d-flex-col gap-24">
  <zogo-btn
    *ngIf="!hasContactInfo"
    class="ion-align-self-end"
    iconName="plus"
    label="Add Contact"
    (clickFunction)="addContact()"
  ></zogo-btn>
  <zogo-btn
    *ngIf="hasContactInfo"
    class="ion-align-self-end"
    label="Edit Contact"
    (clickFunction)="editContact()"
  ></zogo-btn>

  <div *ngIf="!hasContactInfo" class="empty-state">
    <zogo-icon
      class="text-color-neutral-600"
      iconName="user-plus"
      size="xlarge"
    ></zogo-icon>
    <div class="ion-align-items-center">
      <p class="h4-medium text-color-neutral-600">Make payment easy</p>
    </div>
    <zogo-btn
      label="Add Contact"
      buttonStyle="secondary"
      (clickFunction)="addContact()"
    ></zogo-btn>
  </div>

  <div *ngIf="hasContactInfo" class="billing-contact-card d-flex-row gap-24">
    <div class="icon-wrapper">
      <p
        *ngIf="contactName"
        class="initials body-bold text-color-neutral-white"
      >
        {{ getInitials(contactName) }}
      </p>
      <zogo-icon
        *ngIf="!contactName"
        iconName="user"
        class="text-color-neutral-white"
      ></zogo-icon>
    </div>
    <div class="text-wrapper d-flex-col gap-16">
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Full Name</p>
        <p class="body-book text-color-neutral-600">
          {{ contactName || '-' }}
        </p>
      </div>
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Email</p>
        <p class="body-book text-color-neutral-600">
          {{ contactEmail || '-' }}
        </p>
      </div>
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Phone Number</p>
        <p class="body-book text-color-neutral-600">
          {{ formatPhoneNumber(contactPhone) }}
        </p>
      </div>
    </div>
  </div>
</div>
