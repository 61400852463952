<div class="dialog-container">
  <div class="header h4-medium text-color-neutral-800">
    {{ dialogData.header }}
  </div>
  <div class="body body-medium text-color-neutral-600">
    {{ dialogData.body }}
  </div>
  <div
    class="lower-btn-area"
    [ngClass]="{
      'single-lower-btn-area':
        !dialogData.cancelBtnText && dialogData.confirmBtnText
    }"
  >
    <zogo-btn
      *ngIf="dialogData.cancelBtnText"
      [label]="dialogData.cancelBtnText"
      [fullWidth]="false"
      size="medium"
      buttonStyle="secondary"
      (clickFunction)="dialogData.cancelFunct()"
    >
    </zogo-btn>
    <zogo-btn
      *ngIf="dialogData.confirmBtnText"
      [label]="dialogData.confirmBtnText"
      [fullWidth]="false"
      size="medium"
      buttonStyle="primary"
      (clickFunction)="dialogData.confirmFunct()"
    >
    </zogo-btn>
  </div>
</div>
