import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';
import { ZogoBtnComponent } from '../zogo-btn/zogo-btn.component';
import { ZogoCheckboxComponent } from '../zogo-checkbox/zogo-checkbox.component';
import { ZogoRadioButtonComponent } from '../zogo-radio-group/zogo-radio-button/zogo-radio-button.component';
import { ZogoRadioGroupComponent } from '../zogo-radio-group/zogo-radio-group.component';

import { HelperFunctionsService } from '../../helper-functions.service';

export type FormField = {
  label: string;
  controlName: string;
  type: 'text' | 'select' | 'checkbox' | 'radio';
  placeholder?: string;
  autocompleteType?: string;
  iconName?: string;
  group: string;
  groupLabel?: string;
  radioOptions?: {
    value: string;
    label: string;
  }[];
  selectOptions?: string[]; // Options for select type
  validators?: ValidatorFn[]; // Optional validation rules
};

type FormGroupModel = {
  label: string;
  fields: FormField[];
};

@Component({
  selector: 'zogo-form',
  templateUrl: './zogo-form.component.html',
  styleUrls: ['./zogo-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    ZogoIconComponent,
    ZogoBtnComponent,
    ZogoCheckboxComponent,
    ZogoRadioButtonComponent,
    ZogoRadioGroupComponent,
  ],
})
export class ZogoFormComponent implements OnInit {
  @Input() alignButtonStart: boolean = false;
  @Input() formFields: FormField[] = [];
  @Output() formDataOutput: EventEmitter<any> = new EventEmitter<any>();
  formGroup: FormGroup;
  formGroups: FormGroupModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public helper: HelperFunctionsService
  ) {
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.groupFormFields();
    this.createFormControls();
    this.setupRadioFieldChanges();
  }

  groupFormFields() {
    const groupsMap = new Map<string, FormField[]>();
    this.formFields.forEach(field => {
      const groupKey = field.group;
      if (!groupsMap.has(groupKey)) {
        groupsMap.set(groupKey, []);
      }
      groupsMap.get(groupKey)?.push(field);
    });

    groupsMap.forEach((fields, key) => {
      const groupLabel = fields[0].groupLabel || key;
      this.formGroups.push({ label: groupLabel, fields });
    });
  }

  createFormControls() {
    this.formGroups.forEach(group => {
      group.fields.forEach(field => {
        const validators = field.validators || [];
        let initialValue = '';

        if (
          field.type === 'radio' &&
          field.radioOptions &&
          field.radioOptions.length > 0
        ) {
          initialValue = field.radioOptions[0].value;
        }

        this.formGroup.addControl(
          field.controlName,
          this.formBuilder.control(initialValue, validators)
        );
      });
    });
  }

  setupRadioFieldChanges() {
    this.formGroups.forEach(group => {
      group.fields.forEach(field => {
        if (field.type === 'radio') {
          const control = this.formGroup.get(field.controlName);
          if (control) {
            control.valueChanges.subscribe(value => {
              if (value === 'yes') {
                this.addOtherFieldControl(field.controlName);
              } else {
                this.removeOtherFieldControl(field.controlName);
              }
            });
          }
        }
      });
    });
  }

  addOtherFieldControl(parentControlName: string) {
    const controlName = `${parentControlName}_other`;
    if (!this.formGroup.contains(controlName)) {
      this.formGroup.addControl(
        controlName,
        this.formBuilder.control('', [Validators.required])
      );
    }
  }

  removeOtherFieldControl(parentControlName: string) {
    const controlName = `${parentControlName}_other`;
    if (this.formGroup.contains(controlName)) {
      this.formGroup.removeControl(controlName);
    }
  }

  onSubmit() {
    const formData = this.formGroup.value;
    this.formDataOutput.emit(formData);
    this.formGroup.reset();
  }
}
