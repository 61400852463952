import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HighlightsModalComponent } from '../../dialogs/highlights-modal/highlights-modal.component';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-product-highlights',
  templateUrl: './product-highlights.component.html',
  styleUrls: ['./product-highlights.component.scss'],
})
export class ProductHighlightsComponent implements OnInit {
  @Input() productHighlights: any;

  disableScrollLeft: boolean = true;
  disableScrollRight: boolean = true;

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  constructor(public dialog: MatDialog, private req: RequestsService) {}

  ngOnInit() {
    setTimeout(() => {
      this.checkScrollPosition();
    }, 0);
  }

  openHighlightsModal(highlight) {
    // Amplitude event - Highlight Clicked
    this.req.ampTrack('Highlight Clicked', {
      highlight_title: highlight?.title,
    });

    this.dialog.open(HighlightsModalComponent, {
      width: '600px',
      panelClass: 'highlights-modal-component',
      data: {
        highlightTitle: highlight?.title,
        highlightDescription: highlight?.long_description,
        highlightImage: highlight?.img_url,
        websiteUrl: highlight?.article_link,

        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  scrollNext(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 412;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 412;
    const currentPosition = wrapperElement.scrollLeft;
    const nextPosition = currentPosition + calculatedSectionWidth;
    wrapperElement.scrollTo({ left: nextPosition, behavior: 'smooth' });
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  scrollBack(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 412;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 412;
    const currentPosition = wrapperElement.scrollLeft;
    const previousPosition = currentPosition - calculatedSectionWidth;
    wrapperElement.scrollTo({ left: previousPosition, behavior: 'smooth' });
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  checkScrollPosition(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const maxScrollLeft =
      wrapperElement.scrollWidth - wrapperElement.clientWidth;
    this.disableScrollLeft = wrapperElement.scrollLeft === 0;
    this.disableScrollRight = wrapperElement.scrollLeft >= maxScrollLeft;
  }
}
