import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'zogo-chip',
  templateUrl: './zogo-chip.component.html',
  styleUrls: ['./zogo-chip.component.scss'],
  standalone: true,
})
export class ZogoChipComponent implements OnInit {
  @Input() label: string = '';
  @Input() ariaLabel: string = '';
  @Input() isSelected: boolean = false;
  @Input() shouldManageSelect: boolean = true;
  @Output() selectedChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    if (!this.ariaLabel) {
      this.ariaLabel = this.label;
    }
  }

  onSelectionChange() {
    if (this.shouldManageSelect) {
      this.isSelected = !this.isSelected;
    }
    this.selectedChange.emit(this.isSelected);
  }
}
