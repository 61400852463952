import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-module-confirmation-dialog',
  templateUrl: './module-confirmation-dialog.component.html',
  styleUrls: ['./module-confirmation-dialog.component.css'],
})
export class ModuleConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {}
}
