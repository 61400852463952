<div class="dashboard-view" *ngIf="moduleData.module">
  <div class="container-fluid p-0">
    <div class="row mb-4">
      <div>
        <div class="header-left">
          <svg
            class="tappable back-btn"
            (click)="navigateToModuleList()"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.49012e-08 7C1.49012e-08 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 1.49012e-08 7.55228 1.49012e-08 7Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z"
            />
          </svg>
          <h1 class="header h3-bold">Create module</h1>
        </div>
      </div>
      <div class="upper-button-area">
        <zogo-btn
          label="Save as Draft"
          [fullWidth]="false"
          size="medium"
          buttonStyle="secondary"
          (clickFunction)="saveAsDraft()"
        >
        </zogo-btn>
        <zogo-btn
          *ngIf="isApprovalRequired()"
          [matTooltip]="
            'Editing module name, snippets, and questions require approval'
          "
          label="Submit for Approval"
          [fullWidth]="false"
          size="medium"
          buttonStyle="primary"
          [disabled]="!isModuleValid()"
          (clickFunction)="submitForApproval()"
        >
        </zogo-btn>
      </div>
    </div>
  </div>

  <div *ngIf="hasSpanishSupport()" class="mb-4">
    <span
      class="tappable h5-medium text-color-neutral-600"
      [ngClass]="{
        'h5-bold': moduleEditorView === 'english',
        'text-color-brand-primary': moduleEditorView === 'english',
        'selected-underline': moduleEditorView === 'english'
      }"
      (click)="handleLanguageChange('english')"
      >English</span
    >
    <span
      class="tappable ml-3 h5-medium text-color-neutral-600"
      [ngClass]="{
        'h5-bold': moduleEditorView === 'spanish',
        'text-color-brand-primary': moduleEditorView === 'spanish',
        'selected-underline': moduleEditorView === 'spanish'
      }"
      (click)="handleLanguageChange('spanish')"
      >Spanish (Optional)</span
    >
  </div>

  <div
    *ngIf="moduleEditorView === 'spanish' && doesModuleNeedTranslation()"
    class="needs-translation-frame"
  >
    <span class="alert-icon"
      ><svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0618 6.41797C11.5681 6.41797 11.9785 6.82837 11.9785 7.33464V11.0013C11.9785 11.5076 11.5681 11.918 11.0618 11.918C10.5556 11.918 10.1452 11.5076 10.1452 11.0013V7.33464C10.1452 6.82837 10.5556 6.41797 11.0618 6.41797Z"
          fill="currentColor"
        />
        <path
          d="M11.0618 15.5846C11.5681 15.5846 11.9785 15.1742 11.9785 14.668C11.9785 14.1617 11.5681 13.7513 11.0618 13.7513C10.5556 13.7513 10.1452 14.1617 10.1452 14.668C10.1452 15.1742 10.5556 15.5846 11.0618 15.5846Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.978516 11.0013C0.978516 5.43243 5.49298 0.917969 11.0618 0.917969C16.6307 0.917969 21.1452 5.43243 21.1452 11.0013C21.1452 16.5702 16.6307 21.0846 11.0618 21.0846C5.49298 21.0846 0.978516 16.5702 0.978516 11.0013ZM11.0618 2.7513C6.5055 2.7513 2.81185 6.44495 2.81185 11.0013C2.81185 15.5577 6.5055 19.2513 11.0618 19.2513C15.6182 19.2513 19.3119 15.5577 19.3119 11.0013C19.3119 6.44495 15.6182 2.7513 11.0618 2.7513Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <span class="body-medium">
      Complete translation of all required fields to submit the module for
      approval.
    </span>
  </div>

  <div *ngIf="moduleData?.module">
    <div class="container-fluid p-0">
      <div class="row mt-1">
        <div class="col-md-4 card card-frame">
          <div class="container-fluid p-0">
            <div *ngIf="moduleData?.module.module_name">
              <div class="h5-medium text-color-neutral-800">
                Module Name
                <svg
                  *ngIf="
                      moduleData.module.module_name?.['en-US'] !==
                        originalModule.module.module_name?.['en-US'] ||
                      moduleData.module.module_name?.['es-US'] !==
                        originalModule.module.module_name?.['es-US']
                    "
                  class="tappable"
                  [matTooltip]="'Editing module name requires approval'"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5432_187467)">
                    <path
                      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                      stroke="#FFAC30"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 6V9"
                      stroke="#FFAC30"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 12H9.00833"
                      stroke="#FFAC30"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5432_187467">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                *ngIf="moduleEditorView === 'spanish'"
                class="body-medium text-color-neutral-600"
              >
                {{moduleData.module.module_name?.['en-US']}}
              </div>
              <input
                class="name-input-box body-b2-medium"
                *ngIf="moduleEditorView === 'english'"
                [ngClass]="{
                  'name-input-no-lower-margin':
                    moduleData?.module.module_name['en-US'].length === 0
                }"
                [(ngModel)]="moduleData?.module.module_name['en-US']"
                (ngModelChange)="setUnsavedChange()"
                [placeholder]="'New Module'"
              />
              <input
                *ngIf="moduleEditorView === 'spanish'"
                class="name-input-box body-b2-medium"
                [(ngModel)]="moduleData?.module.module_name['es-US']"
                (ngModelChange)="setUnsavedChange()"
                [placeholder]=""
              />
              <div
                class="error-text-color module-name-error label-medium"
                *ngIf="moduleData?.module.module_name['en-US'].length === 0"
              >
                Modules must have a name.
              </div>
              <div
                class="module-icon-selection-title"
                *ngIf="moduleEditorView === 'english'"
              >
                <div class="h5-medium text-color-neutral-800">Module Icon</div>
                <zogo-btn
                  label="Select"
                  [fullWidth]="false"
                  size="small"
                  buttonStyle="primary"
                  (clickFunction)="openIconSelect()"
                >
                </zogo-btn>
              </div>
            </div>
          </div>
          <div class="card-frame text-center module-card-area">
            <div
              class="module-preview-header-area text-color-neutral-500 overline-medium"
            >
              <div>Preview</div>
            </div>

            <div class="module-radius">
              <div class="module-icon-frame">
                <img class="icon" [src]="activeModuleIcon?.image_url" alt="" />
                <svg
                  _ngcontent-gyu-c5=""
                  class="star none"
                  fill="none"
                  height="28"
                  viewBox="0 0 30 28"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  ng-reflect-klass="star"
                  ng-reflect-ng-class="none"
                >
                  <path
                    _ngcontent-gyu-c5=""
                    d="M19.5698 8.21024L18.3287 4.39058C17.281 1.16599 12.719 1.16598 11.6713 4.39057L10.4302 8.21024C10.3633 8.41625 10.1713 8.55573 9.95469 8.55573H5.93846C2.54794 8.55573 1.13821 12.8944 3.88121 14.8873L7.13041 17.248C7.30565 17.3753 7.37898 17.601 7.31205 17.807L6.07096 21.6266C5.02324 24.8512 8.7139 27.5327 11.4569 25.5398L14.7061 23.1791C14.8814 23.0518 15.1186 23.0518 15.2939 23.1791L18.5431 25.5398C21.2861 27.5327 24.9768 24.8512 23.929 21.6266L22.688 17.807C22.621 17.601 22.6943 17.3753 22.8696 17.248L26.1188 14.8873C28.8618 12.8944 27.4521 8.55573 24.0615 8.55573H20.0453C19.8287 8.55573 19.6367 8.41625 19.5698 8.21024Z"
                    fill="#FFD058"
                    stroke="white"
                    stroke-width="3"
                  ></path>
                </svg>
              </div>
            </div>
            <div *ngIf="moduleEditorView === 'english'" class="module-title">
              {{ moduleData?.module.module_name['en-US'] }}
            </div>
            <div *ngIf="moduleEditorView === 'spanish'" class="module-title">
              {{ moduleData?.module.module_name['es-US'] }}
            </div>
          </div>
          <div class="lower-status-label">
            <div class="status-label h5-medium text-color-neutral-800">
              Status:
            </div>
            <div [ngSwitch]="moduleData?.module.status.value">
              <app-zogo-label
                *ngSwitchCase="'Inactive'"
                type="neutral"
                [label]="'Draft'"
              ></app-zogo-label>
              <app-zogo-label
                *ngSwitchCase="'Pending Approval'"
                type="notice"
                [label]="'Pending Approval'"
              ></app-zogo-label>
              <app-zogo-label
                *ngSwitchCase="'Active'"
                type="success"
                [label]="'Approved'"
              ></app-zogo-label>
            </div>
          </div>
          <div
            class="lower-status-label"
            *ngIf="moduleEditorView === 'english'"
          >
            <div class="status-label h5-medium text-color-neutral-800">
              Intro Module
            </div>
            <mat-slide-toggle
              [disabled]="
                introStatus?.num_modules >= introStatus?.max_modules &&
                !this.moduleData.module.is_intro_module
              "
              [(ngModel)]="moduleData.module.is_intro_module"
            ></mat-slide-toggle>
          </div>
          <div
            *ngIf="introStatus?.num_modules >= introStatus?.max_modules"
            class="caption-medium text-color-body-4"
          >
            You’ve reached your {{ introStatus?.max_modules }} intro module
            limit.
          </div>
          <div
            (click)="deleteModule()"
            *ngIf="moduleEditorView === 'english'"
            class="text-color-shades-orange-1 delete-button-area tappable body-large-medium"
          >
            Delete Module
          </div>
        </div>
        <div class="col-md-8">
          <div class="card card-frame second-card">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-md-12">
                  <div
                    [ngClass]="{
                      'extra-bottom-margin':
                        moduleEditorView === 'spanish' &&
                        moduleData?.module?.content.snippets.values.length > 0
                    }"
                    class="editor-header header-h4-bold-rounded snippet-header"
                  >
                    <div>
                      <div
                        class="centered-header-callout h5-medium text-color-neutral-800"
                      >
                        Snippets
                        <svg
                          *ngIf="haveSnippetsChanged"
                          class="tappable"
                          [matTooltip]="'Editing snippets requires approval'"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5432_187467)">
                            <path
                              d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                              stroke="#FFAC30"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 6V9"
                              stroke="#FFAC30"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 12H9.00833"
                              stroke="#FFAC30"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5432_187467">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <a
                      href="https://zogo-files.s3.amazonaws.com/dashboard-resources/Custom+Module+Guide+UPDATED.pdf"
                      download="Zogo Content Formatting Guide.pdf"
                      target="_blank"
                      class="body-medium text-color-brand-primary formatting-guide tappable"
                      *ngIf="moduleEditorView === 'english'"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.83398 8.33594L10.0007 12.5026L14.1673 8.33594"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 12.5V2.5"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Formatting Guide
                    </a>
                  </div>
                  <div
                    class="body-medium text-color-neutral-600 editor-sub-header"
                    *ngIf="moduleEditorView === 'english'"
                  >
                    Add 3-{{ moduleData?.module?.content.snippets.max_count }}
                    snippets
                  </div>
                  <div
                    class="body-medium text-color-neutral-600 editor-sub-header"
                    *ngIf="
                      moduleEditorView === 'spanish' &&
                      moduleData?.module?.content.snippets.values.length === 0
                    "
                  >
                    Add English snippets to translate to Spanish
                  </div>
                  <div class="gray-card">
                    <div
                      class="draggable-list"
                      cdkDropList
                      (cdkDropListDropped)="dropSnippet($event)"
                      *ngIf="moduleEditorView === 'english'"
                    >
                      <div
                        cdkDragPreview
                        class="draggable-item"
                        *ngFor="
                          let snippet of moduleData?.module.content.snippets
                            .values;
                          let i = index
                        "
                        (click)="openSnippets(snippet, i, 'english')"
                        cdkDrag
                      >
                        <div
                          class="draggable-header body-bold text-color-neutral-800"
                        >
                          {{ snippet.content_title?.['en-US'] }}
                        </div>
                        <div
                          class="draggable-desc overline-medium text-color-neutral-600"
                        >
                          {{ snippet.content?.['en-US'] }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="draggable-list"
                      *ngIf="moduleEditorView === 'spanish'"
                    >
                      <div
                        class="body-medium text-color-neutral-600 spanish-empty-state"
                        *ngIf="
                          !moduleData?.module.content.snippets.values.length
                        "
                      >
                        No snippets added yet
                      </div>
                      <div
                        class="draggable-item flex"
                        *ngFor="
                          let snippet of moduleData?.module.content.snippets
                            .values;
                          let i = index
                        "
                        (click)="openSnippets(snippet, i, 'spanish')"
                      >
                        <div class="content-left">
                          <div
                            *ngIf="snippet.content_title?.['es-US']"
                            class="draggable-header body-bold text-color-neutral-800"
                          >
                            {{ snippet.content_title?.['es-US'] }}
                          </div>
                          <div
                            *ngIf="!snippet.content_title?.['es-US']"
                            class="draggable-header body-bold text-color-neutral-800"
                          >
                            Translation required
                          </div>
                          <div
                            *ngIf="snippet.content?.['es-US']"
                            class="draggable-desc overline-medium text-color-neutral-600"
                          >
                            {{ snippet.content?.['es-US'] }}
                          </div>
                          <div
                            *ngIf="!snippet.content?.['es-US']"
                            class="draggable-desc overline-medium text-color-neutral-600"
                          >
                            Translation required
                          </div>
                        </div>
                        <div class="content-right">
                          <div
                            *ngIf="doesSnippetNeedTranslation(snippet)"
                            [matTooltip]="'Spanish translation required'"
                            class="needs-translation-alert"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M12.0625 7C12.6148 7 13.0625 7.44772 13.0625 8V12C13.0625 12.5523 12.6148 13 12.0625 13C11.5102 13 11.0625 12.5523 11.0625 12V8C11.0625 7.44772 11.5102 7 12.0625 7Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12.0625 17C12.6148 17 13.0625 16.5523 13.0625 16C13.0625 15.4477 12.6148 15 12.0625 15C11.5102 15 11.0625 15.4477 11.0625 16C11.0625 16.5523 11.5102 17 12.0625 17Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.0625 12C1.0625 5.92487 5.98737 1 12.0625 1C18.1376 1 23.0625 5.92487 23.0625 12C23.0625 18.0751 18.1376 23 12.0625 23C5.98737 23 1.0625 18.0751 1.0625 12ZM12.0625 3C7.09194 3 3.0625 7.02944 3.0625 12C3.0625 16.9706 7.09194 21 12.0625 21C17.0331 21 21.0625 16.9706 21.0625 12C21.0625 7.02944 17.0331 3 12.0625 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div
                            *ngIf="!doesSnippetNeedTranslation(snippet)"
                            class="needs-translation-alert-placeholder"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        moduleEditorView === 'english' &&
                        moduleData?.module?.content.snippets &&
                        moduleData?.module?.content.snippets.values.length <
                          moduleData?.module?.content.snippets.max_count
                      "
                      (click)="openSnippets(null, null, 'english')"
                      class="add-draggable"
                    >
                      <span class="icon"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line></svg
                      ></span>
                      <span class="body-medium text-color-neutral-600"
                        >Add Snippet</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div
                    [ngClass]="{
                      'extra-bottom-margin':
                        moduleEditorView === 'spanish' &&
                        moduleData?.module?.content.questions.values.length > 0
                    }"
                    class="editor-header h5-medium text-color-neutral-800"
                  >
                    <div class="centered-header-callout">
                      Questions
                      <svg
                        *ngIf="haveQuestionsChanged"
                        class="tappable"
                        [matTooltip]="'Editing questions requires approval'"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5432_187467)">
                          <path
                            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                            stroke="#FFAC30"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 6V9"
                            stroke="#FFAC30"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 12H9.00833"
                            stroke="#FFAC30"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5432_187467">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div
                    class="body-medium text-color-neutral-600 editor-sub-header"
                    *ngIf="moduleEditorView === 'english'"
                  >
                    Add up to
                    {{ moduleData?.module?.content.questions.max_count }}
                    questions
                  </div>
                  <div
                    class="body-medium text-color-neutral-600 editor-sub-header"
                    *ngIf="
                      moduleEditorView === 'spanish' &&
                      moduleData?.module?.content.snippets.values.length === 0
                    "
                  >
                    Add English questions to translate to Spanish
                  </div>
                  <div class="gray-card">
                    <div
                      *ngIf="moduleEditorView === 'english'"
                      class="draggable-list"
                      cdkDropList
                      (cdkDropListDropped)="dropQuestion($event)"
                    >
                      <div
                        class="draggable-item"
                        *ngFor="
                          let question of moduleData?.module.content.questions
                            .values;
                          let i = index
                        "
                        (click)="openQuestions(question, i, 'english')"
                        cdkDrag
                      >
                        <div
                          class="draggable-header body-bold text-color-neutral-800"
                        >
                          {{ question.content_title?.['en-US'] }}
                        </div>
                        <div class="draggable-actionbar">
                          <span
                            matTooltip="Question type"
                            class="type {{ question.content_type_value }}"
                            >{{ question.content_type_value }}</span
                          >
                          <span matTooltip="Answer options" class="options">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-list"
                            >
                              <line x1="8" y1="6" x2="21" y2="6"></line>
                              <line x1="8" y1="12" x2="21" y2="12"></line>
                              <line x1="8" y1="18" x2="21" y2="18"></line>
                              <line x1="3" y1="6" x2="3.01" y2="6"></line>
                              <line x1="3" y1="12" x2="3.01" y2="12"></line>
                              <line x1="3" y1="18" x2="3.01" y2="18"></line>
                            </svg>
                            {{ question.answers.length }}</span
                          >
                          <span
                            matTooltip="Description"
                            *ngIf="question.content_description?.['en-US']"
                            class="desc"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-align-left"
                            >
                              <line x1="17" y1="10" x2="3" y2="10"></line>
                              <line x1="21" y1="6" x2="3" y2="6"></line>
                              <line x1="21" y1="14" x2="3" y2="14"></line>
                              <line x1="17" y1="18" x2="3" y2="18"></line></svg
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="moduleEditorView === 'spanish'"
                      class="draggable-list"
                    >
                      <div
                        class="body-medium text-color-neutral-600 spanish-empty-state"
                        *ngIf="
                          !moduleData?.module.content.questions.values.length
                        "
                      >
                        No questions added yet
                      </div>
                      <div
                        class="draggable-item flex"
                        *ngFor="
                          let question of moduleData?.module.content.questions
                            .values;
                          let i = index
                        "
                        (click)="openQuestions(question, i, 'spanish')"
                      >
                        <div class="content-left">
                          <div
                            class="draggable-header body-bold text-color-neutral-800"
                          >
                            {{ question.content_title?.['es-US'] }}
                          </div>
                          <div
                            *ngIf="!question.content_title?.['es-US']"
                            class="draggable-header body-bold text-color-neutral-800"
                          >
                            Translation required
                          </div>
                          <div class="draggable-actionbar">
                            <span
                              matTooltip="Question type"
                              class="type {{ question.content_type_value }}"
                              >{{ question.content_type_value }}</span
                            >
                            <span matTooltip="Answer options" class="options">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-list"
                              >
                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                <line x1="3" y1="18" x2="3.01" y2="18"></line>
                              </svg>
                              {{ question.answers.length }}</span
                            >
                            <span
                              matTooltip="Description"
                              *ngIf="question.content_description?.['es-US']"
                              class="desc"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-align-left"
                              >
                                <line x1="17" y1="10" x2="3" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line
                                  x1="17"
                                  y1="18"
                                  x2="3"
                                  y2="18"
                                ></line></svg
                            ></span>
                          </div>
                        </div>
                        <div class="content-right">
                          <div
                            *ngIf="doesQuestionNeedTranslation(question)"
                            [matTooltip]="'Spanish translation required'"
                            class="needs-translation-alert"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M12.0625 7C12.6148 7 13.0625 7.44772 13.0625 8V12C13.0625 12.5523 12.6148 13 12.0625 13C11.5102 13 11.0625 12.5523 11.0625 12V8C11.0625 7.44772 11.5102 7 12.0625 7Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12.0625 17C12.6148 17 13.0625 16.5523 13.0625 16C13.0625 15.4477 12.6148 15 12.0625 15C11.5102 15 11.0625 15.4477 11.0625 16C11.0625 16.5523 11.5102 17 12.0625 17Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.0625 12C1.0625 5.92487 5.98737 1 12.0625 1C18.1376 1 23.0625 5.92487 23.0625 12C23.0625 18.0751 18.1376 23 12.0625 23C5.98737 23 1.0625 18.0751 1.0625 12ZM12.0625 3C7.09194 3 3.0625 7.02944 3.0625 12C3.0625 16.9706 7.09194 21 12.0625 21C17.0331 21 21.0625 16.9706 21.0625 12C21.0625 7.02944 17.0331 3 12.0625 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div
                            *ngIf="!doesQuestionNeedTranslation(question)"
                            class="needs-translation-alert-placeholder"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        moduleEditorView === 'english' &&
                        moduleData?.module?.content.questions &&
                        moduleData?.module?.content.questions.values.length <
                          moduleData?.module?.content.questions.max_count
                      "
                      (click)="openQuestions(null, null, 'english')"
                      class="add-draggable"
                    >
                      <span class="icon"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line></svg
                      ></span>
                      <span class="body-medium text-color-neutral-600"
                        >Add Question</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
