<div class="institution-select-dialog-wrapper">
  <div
    class="institution-select-dialog-text header-h3-bold-rounded text-color-header-1"
  >
    {{
      appCopy.getInstitutionSelectionPage().institution_view_popup.description
    }}
  </div>
  <button
    class="institution-select-btn"
    (click)="dismiss()"
    class="blue"
    mat-button
  >
    {{
      appCopy.getInstitutionSelectionPage().institution_view_popup.got_it_btn
    }}
  </button>
</div>
