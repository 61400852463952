<div
  class="upload-container"
  [ngClass]="{ error: state === 'error', 'file-over': isFileOver }"
  (drop)="handleFileDrop($event)"
  (dragover)="handleDragOver($event)"
  (dragleave)="handleDragLeave($event)"
>
  <!-- Empty state before file upload -->
  <div
    *ngIf="!file || state === 'error'"
    class="upload-wrapper"
    tappable
    (click)="fileType === 'image' ? imageInput.click() : fileInput.click()"
  >
    <zogo-icon
      *ngIf="state === 'default'"
      class="text-color-brand-primary"
      [iconName]="fileType === 'image' ? 'image' : 'file-plus'"
    ></zogo-icon>

    <div
      *ngIf="state === 'error'"
      class="error-bar background-utility-red"
    ></div>

    <p
      class="overline-medium text-color-brand-primary"
      [ngClass]="{ 'text-color-utility-red-shade-1': state === 'error' }"
    >
      {{ label }}
    </p>
    <p *ngIf="caption" class="overline-book text-color-neutral-600">
      {{ caption }}
    </p>
  </div>

  <!-- File has been uploaded -->
  <div
    *ngIf="file && state !== 'error'"
    class="file-info-wrapper d-flex-row gap-16"
  >
    <div class="file-info d-flex-col gap-2">
      <p class="overline-medium">{{ fileName }}</p>
      <p *ngIf="lastModified" class="overline-book text-color-neutral-600">
        {{ lastModified }}
      </p>
      <div class="overline-book d-flex-row gap-12">
        <p *ngIf="fileSize" class="text-color-neutral-600">{{ fileSize }} kb</p>
        <p
          class="cta-text"
          tappable
          tabindex="0"
          (click)="
            fileType === 'image' ? imageInput.click() : fileInput.click()
          "
        >
          Replace
        </p>
        <p
          *ngIf="hasExistingData"
          tappable
          tabindex="0"
          class="cta-text"
          (click)="onDownloadClick()"
        >
          Download
        </p>
      </div>
    </div>
    <div
      *ngIf="fileType === 'image'"
      class="cropped-image-wrapper background-neutral-200"
      [class.file-over]="isFileOver"
      [class.background-neutral-800]="shouldConvertLogo"
    >
      <img class="cropped-image" [src]="file" alt="" />
    </div>

    <div *ngIf="fileType === 'csv'" class="csv-image-wrapper">
      <zogo-icon
        iconName="file-text"
        class="text-color-neutral-white"
      ></zogo-icon>
    </div>
  </div>

  <input
    #imageInput
    type="file"
    class="hidden-input"
    accept=".png, .svg"
    (change)="fileChangeEvent($event)"
  />

  <input
    #fileInput
    type="file"
    class="hidden-input"
    accept=".csv"
    (change)="fileChangeEvent($event)"
  />
</div>
