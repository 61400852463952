import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-request-pdf-modal',
  templateUrl: './request-pdf-modal.component.html',
  styleUrls: ['./request-pdf-modal.component.css'],
})
export class RequestPdfModalComponent implements OnInit {
  userData: any;
  dateOption: any;
  startTime: any;
  endTime: any;
  platforms: any = new Set();
  currentSegment: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appCopy: CopyService,
    private req: RequestsService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.dateOption = this.data.timeFrame;
    this.userData.institution.platform_access_types.forEach(platform => {
      this.platforms.add(platform.platform);
    });
    this.currentSegment = this.data.currentSegment;
  }

  setDate(timeframe, e) {
    if (timeframe === 'start') {
      if (e.value === null) {
        this.startTime = null;
      } else {
        this.startTime = new Date(e.value).toISOString().split('T')[0];
      }
    } else if (timeframe === 'end') {
      if (e.value === null) {
        this.endTime = null;
      } else {
        this.endTime = new Date(e.value).toISOString().split('T')[0];
      }
    }
  }

  togglePlatform(platform) {
    if (this.platforms.has(platform)) {
      this.platforms.delete(platform);
    } else {
      this.platforms.add(platform);
    }
  }

  resetDates() {
    this.startTime = null;
    this.endTime = null;
  }

  handleSubmit() {
    const body = {
      page_type: this.data.requestType,
      time_frame: this.dateOption === 'custom' ? null : this.dateOption,
      start_date: this.dateOption === 'custom' ? this.startTime : null,
      end_date: this.dateOption === 'custom' ? this.endTime : null,
      immediate: false,
      code: null,
      platforms: Array.from(this.platforms),
    };

    if (this.userData.institution.community_segments_enabled) {
      body.code = this.currentSegment?.value;
    }

    const amplitude_platforms = Array.from(this.platforms).map(platform => {
      for (const platform_object of this.userData.institution
        .platform_access_types) {
        if (platform_object.platform === platform) {
          return platform_object.display_name;
        }
      }
    });

    if (this.data.requestType === 'overview') {
      // Amplitude event - Request Engagement PDF Submitted
      this.req.ampTrack('Request Engagement PDF Submitted', {
        date: this.dateOption,
        products: amplitude_platforms,
        access_code: this.currentSegment?.value,
        custom_start: this.dateOption === 'custom' ? this.startTime : null,
        custom_end: this.dateOption === 'custom' ? this.endTime : null,
      });
    } else if (this.data.requestType === 'pre-post-test') {
      // Amplitude event - Request Pre/Post Test PDF Submitted
      this.req.ampTrack('Request Pre/Post Test PDF Submitted', {
        date: this.dateOption,
        products: amplitude_platforms,
        access_code: this.currentSegment?.value,
        custom_start: this.dateOption === 'custom' ? this.startTime : null,
        custom_end: this.dateOption === 'custom' ? this.endTime : null,
      });
    }
    this.data.requestPdfReport(body);
    this.data.dismiss();
  }

  hasValidInputs() {
    if (this.platforms.size < 1) {
      return false;
    }
    if (this.dateOption === 'custom' && (!this.startTime || !this.endTime)) {
      return false;
    }
    return true;
  }

  updateSegment(event) {
    this.currentSegment = event;
  }

  onTimeFrameChange(event) {
    this.dateOption = event;
    this.resetDates();
  }
}
