import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CopyService } from 'src/app/copy.service';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import {
  ZogoBtnComponent,
  ZogoDropdownComponent,
  ZogoTextInputComponent,
} from 'src/app/design-system/web-components';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { CustomValidationDirective } from 'src/app/directives/custom-validation.directive';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-add-teacher-modal',
  templateUrl: './add-teacher-modal.component.html',
  styleUrls: ['./add-teacher-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZogoBtnComponent,
    ZogoIconComponent,
    ZogoDropdownComponent,
    ZogoTextInputComponent,
    CustomValidationDirective,
  ],
})
export class AddTeacherModalComponent implements OnInit {
  @Input() mode: 'invite' | 'edit' = 'invite';
  @Input() headerText: string = '';
  @Input() teacherId: number;
  @Input() title: string = '';
  @Input() firstName: string = '';
  @Input() lastName: string = '';
  @Input() email: string = '';
  invited: boolean = false;
  inviteLink: string = '';
  titleOptions = ['Mr.', 'Mrs.', 'Ms.'];
  copyButtonLabel: string = 'Copy';

  constructor(
    public appCopy: CopyService,
    private modalController: ModalController,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  async dismiss() {
    await this.modalController.dismiss(null, 'cancel');
  }

  async saveChanges() {
    await this.modalController.dismiss(null, 'save');
  }

  resendInvitation(email) {
    this.req.postDashboardTeacherInvite(email).subscribe({
      next: data => {
        // Amplitude event - Teacher Invitation Resent
        this.req.ampTrack('Teacher Invitation Resent');
        this.toastService.showToast('Invitation resent.');
        this.inviteLink = data.invite_link;
        this.invited = true;
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  removeTeacher(teacherId) {
    this.req.deleteDashboardTeacher(teacherId).subscribe({
      next: data => {
        // Amplitude event - Teacher Deleted
        this.req.ampTrack('Teacher Deleted');
        this.toastService.showToast('Teacher removed.');

        this.modalController.dismiss(null, 'save');
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  resetPassword(teacherId) {
    this.req.postDashboardTeacherPasswordReset(teacherId).subscribe({
      next: data => {
        // Amplitude event - Teacher Password Reset Link Sent
        this.req.ampTrack('Teacher Password Reset Link Sent');
        this.modalController.dismiss(null, 'save');
        this.toastService.showToast(
          this.appCopy.getTeacherAdmins().teacher_password_reset_success,
          6000
        );
      },
      error: err => {},
    });
  }

  copyText(text: string) {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.copyButtonLabel = 'Copied';
          setTimeout(() => {
            this.copyButtonLabel = 'Copy';
          }, 5000);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  }

  onSubmitForm() {
    if (this.mode === 'invite') {
      this.req.postDashboardTeacherInvite(this.email).subscribe({
        next: data => {
          // Amplitude event - Teacher Invitation Sent
          this.req.ampTrack('Teacher Invitation Sent');
          this.invited = true;
          this.inviteLink = data.invite_link;
          this.toastService.showToast('Invite sent.', 6000);
        },
        error: err => {
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
          console.log('errors', err.error);
        },
      });
    } else {
      const request = {
        id: this.teacherId,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        title: this.title,
      };

      this.req.patchDashboardTeacher(request).subscribe({
        next: data => {
          // Amplitude event - Teacher Information Edited
          this.req.ampTrack('Teacher Information Edited');
          this.toastService.showToast('Changes saved.', 6000);
          this.saveChanges();
        },
        error: err => {
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
          console.log('errors', err.error);
        },
      });
    }
  }
}
