import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import {
  ZogoBtnComponent,
  ZogoDropdownComponent,
  ZogoTextInputComponent,
} from 'src/app/design-system/web-components';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { CustomValidationDirective } from 'src/app/directives/custom-validation.directive';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZogoBtnComponent,
    ZogoTextInputComponent,
    ZogoDropdownComponent,
    ZogoIconComponent,
    CustomValidationDirective,
  ],
})
export class AccountModalComponent implements OnInit {
  @Input() fullInstName: string;
  @Input() preferredAbbr: string;
  @Input() institutionType: string;
  @Input() websiteUrl: string;
  @Input() accessCode: string;
  @Input() needsAccessCode: boolean;
  institutionTypeOptions = [
    'U.S.-based Bank',
    'International Bank',
    'Credit Union',
    'Community Bank',
    'Fintech',
    'Other',
  ];

  constructor(
    private req: RequestsService,
    private modalController: ModalController,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  async dismiss() {
    await this.modalController.dismiss(null, 'cancel');
  }

  onWebsiteUrlChange(url: string) {
    this.websiteUrl = url.replace(/^https?:\/\//, '');
  }

  onSubmitForm() {
    const request = {
      page: 'account-settings',
      long_name: this.fullInstName,
      short_name: this.preferredAbbr,
      institution_type: this.institutionType,
      website_url: `https://${this.websiteUrl}`,
      code: this.needsAccessCode ? this.accessCode : '',
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Institution Information Changes Saved
        this.req.ampTrack('Institution Information Changes Saved');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.req.updateUserDataSub(currentUserData);
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.toastService.showToast('Changes saved.', 6000);

        this.modalController.dismiss(
          {
            fullInstName: data.institution.long_name,
            preferredAbbr: data.institution.short_name,
            institutionType: data.institution.institution_type,
            websiteUrl: data.institution.website_url,
          },
          'save'
        );
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }
}
