import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

@Component({
  selector: 'zogo-text-input',
  templateUrl: './zogo-text-input.component.html',
  styleUrls: ['./zogo-text-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ZogoIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZogoTextInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ZogoTextInputComponent),
      multi: true,
    },
  ],
})
export class ZogoTextInputComponent implements ControlValueAccessor, Validator {
  @Input() inputId: string = '';
  @Input() inputLabel: string = '';
  @Input() inputType: 'text' | 'email' | 'password' | 'search' = 'text';
  @Input() autocompleteType: string = '';
  @Input() iconName: string = '';
  @Input() placeholder: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() formType: 'inlineInput' | 'inlineShadow' | 'input' | 'textArea' =
    'input';
  @Input() value: string = ''; // allows you to set an initial value
  @Input() rows: number = 2; // textArea only
  @Input() isResizable: boolean = false; // textArea only
  @Input() wrap: 'soft' | 'hard' | 'off' = 'off'; // textArea only
  @Input() currentValue: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter<void>();

  private _ariaLabel: string = '';

  constructor() {}

  @Input()
  set ariaLabel(value: string) {
    this._ariaLabel = value;
  }

  get ariaLabel(): string {
    return this._ariaLabel || this.placeholder;
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

  writeValue(value: any): void {
    this.currentValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.isRequired && !this.currentValue) {
      return { required: true };
    }
    return null;
  }

  onInput(value: any) {
    this.currentValue = value;
    this.onChange(value);
    this.valueChange.emit(this.currentValue);
  }

  onBlur() {
    this.onTouched();
    this.blurEvent.emit();
  }
}
