import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';
import { ImageCropperModalComponent } from 'src/app/components/image-cropper-modal/image-cropper-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'zogo-uploader',
  templateUrl: './zogo-uploader.component.html',
  styleUrls: ['./zogo-uploader.component.scss'],
  standalone: true,
  imports: [CommonModule, ZogoIconComponent],
})
export class ZogoUploaderComponent implements OnInit {
  state: 'default' | 'uploaded' | 'error' = 'default';
  @Input() label: string = 'Upload';
  @Input() caption: string = '';
  @Input() fileType: 'image' | 'csv';
  @Input() aspectRatio = 16 / 9;
  @Input() shouldConvertLogo?: boolean = false;
  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileUploadFailed = new EventEmitter<void>();
  @Input() file?: any;
  @Input() fileName?: string = '';
  @Input() fileSize?: number;
  @Input() lastModified?: string;
  @Input() hasExistingData?: boolean;
  @Output() downloadFunction = new EventEmitter<any>();
  isFileOver: boolean = false;
  isUploading: boolean = false;

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {}

  convertSizetoKb(size: number) {
    return Math.ceil(size / 1024);
  }

  convertToDate(timestamp) {
    const date = new Date(timestamp);

    // Convert to "MMM d, yyyy" format (e.g., "Nov 4, 2024")
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return formattedDate;
  }

  validateImageType(file) {
    if (!file.name.includes('.png') && !file.name.includes('.svg')) {
      this.state = 'error';
      this.caption = 'Re-upload';
      this.label = 'File is not of type PNG or SVG.';
      return false;
    }
    return true;
  }

  validateFileSize(file: File, size: number) {
    if (this.convertSizetoKb(file.size) > size) {
      this.state = 'error';
      this.caption = 'Re-upload';
      this.label = `File size exceeds ${Math.ceil(size / 1024)} MB`;
      return false;
    }
    return true;
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    this.isFileOver = true;
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isFileOver = false;
  }

  onDownloadClick() {
    this.downloadFunction.emit();
  }

  async applyWhiteOverlay(file: File): Promise<File> {
    return new Promise<File>(resolve => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const imageDataUrl = e.target?.result as string;
        const img = new Image();
        img.src = imageDataUrl;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (ctx) {
            // Set canvas size to match the image
            canvas.width = img.width;
            canvas.height = img.height;

            ctx.drawImage(img, 0, 0);

            // Set the globalCompositeOperation to 'source-in' to keep the shape of the image
            ctx.globalCompositeOperation = 'source-in';

            // Fill the entire logo with white
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Convert canvas back to a Blob and create a File object
            canvas.toBlob(blob => {
              if (blob) {
                const whiteOverlayedFile = new File([blob], file.name, {
                  type: 'image/png',
                  lastModified: Date.now(),
                });
                resolve(whiteOverlayedFile);
              }
            }, 'image/png');
          }
        };
      };
      reader.readAsDataURL(file);
    });
  }

  async handleFileDrop(event: DragEvent) {
    event.preventDefault();
    this.isFileOver = false;

    if (this.validateFileSize(event.dataTransfer?.files[0], 1000)) {
      if (this.fileType === 'image') {
        this.handleImageUpload(event.dataTransfer?.files[0]);
      } else {
        this.handleCsvUpload(event.dataTransfer?.files[0]);
      }
    }
  }

  async fileChangeEvent(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (this.validateFileSize(file, 1000)) {
        if (this.fileType === 'image') {
          this.handleImageUpload(file);
        } else {
          this.handleCsvUpload(file);
        }
        event.target.value = '';
      }
    }
  }

  async handleImageUpload(file) {
    if (!this.validateImageType(file)) {
      return;
    }

    let processedImageFile;

    if (this.shouldConvertLogo) {
      processedImageFile = await this.applyWhiteOverlay(file);
    } else {
      processedImageFile = file;
    }

    const modal = await this.modalController.create({
      component: ImageCropperModalComponent,
      componentProps: {
        imageFile: processedImageFile,
        showDarkBackground: this.shouldConvertLogo,
      },
      cssClass: 'alert-modal',
    });

    await modal.present();
    const { data, role } = await modal.onDidDismiss();
    if (role === 'save' && data) {
      this.file = data.croppedImage;
      this.state = 'uploaded';
      this.fileName = data.fileName;
      this.fileSize = this.convertSizetoKb(data.fileSize);
      this.fileUploaded.emit(data);
    }
  }

  async handleCsvUpload(file) {
    if (!this.validateZipFileType(file)) {
      return;
    }

    const reader = new FileReader();

    reader.onload = e => {
      const text = e.target.result;
      const array = csvToArray(text);
      if (this.validateZips(array)) {
        const zipCodes = array.filter(zip => zip !== '');
        this.file = file;
        this.fileName = file.name;
        this.fileSize = this.convertSizetoKb(file.size);
        this.lastModified = this.convertToDate(file.lastModified);
        this.state = 'uploaded';
        this.fileUploaded.emit({
          file: file,
          fileName: file.name,
          fileSize: file.size,
          lastModified: file.lastModified,
          zipCodes: zipCodes,
        });
      } else {
        this.state = 'error';
        this.caption = 'Re-upload';
        this.label = 'Not all zip codes in CSV are valid.';
      }
    };

    reader.readAsText(file);

    function csvToArray(str, delimiter = ',') {
      let array = str.split('\n').map(function (line) {
        return line.split(delimiter)[0].trim();
      });

      if (isNaN(Number(array[0]))) {
        array = array.slice(1);
      }

      return array;
    }
  }

  validateZips(array) {
    let areZipsValid = true;
    array.forEach(zip => {
      if (zip !== '' && !this.zipCodeSearch(zip)) {
        areZipsValid = false;
      }
    });
    return areZipsValid;
  }

  validateZipFileType(file) {
    if (!file.name.includes('.csv')) {
      this.state = 'error';
      this.caption = 'Re-upload';
      this.label = 'File is not of type CSV.';
      return false;
    }
    return true;
  }

  zipCodeSearch(str) {
    return /^\d{5}$/.test(str);
  }
}
