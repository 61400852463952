import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.css'],
})
export class ProgressBarChartComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  progressBarChartData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  showTooltip = false;
  errorLoading: boolean = false;

  constructor(private req: RequestsService) {}

  ngOnInit() {
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateProgressBarChartData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateProgressBarChartData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateProgressBarChartData();
      },
      error: err => {},
    });

    this.updateProgressBarChartData();
  }

  ngOnDestroy() {
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }

  updateProgressBarChartData() {
    this.progressBarChartData = null;
    this.errorLoading = false;

    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.progressBarChartData = data;
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }

  calculatePercentage(value) {
    return (value / this.progressBarChartData.max_value) * 100;
  }
}
