import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skill-display',
  templateUrl: './skill-display.component.html',
  styleUrls: ['./skill-display.component.scss'],
})
export class SkillDisplayComponent implements OnInit {
  @Input() skillResultsData: any;

  public expanded: boolean = false;

  constructor() {}

  ngOnInit() {}

  toggleAccordian() {
    this.expanded = !this.expanded;
  }
}
