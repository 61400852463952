import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';
import { DialogService } from 'src/app/guards/dialog.service';
import { RequestsService } from 'src/app/requests.service';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-access-codes',
  templateUrl: './access-codes.component.html',
  styleUrls: ['./access-codes.component.css'],
  // TODO I can't get the height animation to work
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('.65s ease-out', style({ opacity: 1, height: 300 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: 300 }),
        animate('.65s ease-in', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ],
})
export class AccessCodesComponent implements OnInit {
  @ViewChild('textInput', { static: false }) textInput: ElementRef;
  copy: any;
  userData: any;
  editState: boolean = false;
  pendingState: boolean = false;
  accessCodeInput: string;
  errorText: string = null;
  isAdmin: boolean;
  mode: any; // 'main' | 'create' | 'edit' | 'edit-secondary'
  accessCode: any;
  description: any;
  accessCodeBeingEdited: any;
  validPrimaryAccessCode: boolean = true;
  validSecondaryAccessCode: boolean = true;
  hasAccessCodeBlurred: any;
  validEmptyAccessCode: any;
  accessCodesData: any;
  searchTerm: string = '';
  filteredCodes: any;
  isPrimarySubmitDisabled: boolean = true;
  isSecondarySubmitDisabled: boolean = true;
  allowAllZipCodesCheckedEdit: boolean;
  allowAllZipCodesCheckedCreate: boolean;
  selectedLimitType: any;
  accessCodeNotifAlertMessage: string;
  accessCodeNotifAlertStatus: string;
  accessCodeNotifAlertButtonText: string;
  accessCodeNotifAlertButtonFunc: any;
  isAccessCodeNotifAlertEnabled: boolean = false;
  limitTypeNotifAlertMessage: string;
  limitTypeNotifAlertStatus: string;
  limitTypeNotifAlertButtonText: string;
  limitTypeNotifAlertButtonFunc: any;
  isLimitTypeNotifAlertEnabled: boolean = false;
  accessCodeErrorMessage: string =
    'Access codes can only contain numbers and letters — no special characters.';

  constructor(
    public appCopy: CopyService,
    private dialog: MatDialog,
    private req: RequestsService
  ) {}

  ngOnInit(): void {
    // Amplitude event - Access Codes Viewed
    this.req.ampTrack('Access Codes Viewed');
    this.copy = this.appCopy.getAccessCodes();
    this.mode = 'main';
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.selectedLimitType = this.userData?.institution?.limit_type;

    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.req.getDashboardAccessCode().subscribe({
      next: data => {
        this.accessCodesData = data;
        this.filteredCodes = this.accessCodesData.access_codes;
        this.searchTerm = '';
        this.accessCodeInput = this.accessCodesData?.access_codes[0].code;
        this.allowAllZipCodesCheckedEdit =
          this.accessCodesData?.access_codes[0].zip_code_override;
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });

    this.initializeInputs();
  }

  initializeInputs() {
    this.description = null;
    if (this.userData.institution.access_code_change_request) {
      if (
        this.userData.institution.access_code_change_request.status ===
        'pending'
      ) {
        this.setAccessCodeNotifAlertPending();
      } else if (
        this.userData.institution.access_code_change_request.status ===
        'approved'
      ) {
        this.setAccessCodeNotifAlertApproved();
      } else if (
        this.userData.institution.access_code_change_request.status ===
        'rejected'
      ) {
        this.setAccessCodeNotifAlertRejected();
      } else {
        this.isAccessCodeNotifAlertEnabled = false;
        this.accessCodeNotifAlertStatus = null;
      }
    }
    if (this.userData.institution.limit_type_change_request) {
      if (
        this.userData.institution.limit_type_change_request.status === 'pending'
      ) {
        this.setLimitTypeNotifAlertPending();
      } else if (
        this.userData.institution.limit_type_change_request.status ===
        'approved'
      ) {
        this.setLimitTypeNotifAlertApproved();
      } else if (
        this.userData.institution.limit_type_change_request.status ===
        'rejected'
      ) {
        this.setLimitTypeNotifAlertRejected();
      } else {
        this.isLimitTypeNotifAlertEnabled = false;
        this.limitTypeNotifAlertStatus = null;
      }
    }
  }

  contactCx() {
    window.open(
      `mailto:${this.userData.institution.zogo_contact}?subject=Partner Portal Inquiry - ${this.userData.institution.short_name}`
    );
  }

  dismissAccessCodeNotifAlert() {
    this.isAccessCodeNotifAlertEnabled = false;
  }

  dismissLimitTypeNotifAlert() {
    this.isLimitTypeNotifAlertEnabled = false;
  }

  setAccessCodeNotifAlertPending() {
    this.accessCodeNotifAlertMessage =
      this.copy.status_toast.pending_primary_access_code_edit.text;
    this.accessCodeNotifAlertButtonText = null;
    this.accessCodeNotifAlertButtonFunc = null;
    this.accessCodeNotifAlertStatus = 'pending';
    this.isAccessCodeNotifAlertEnabled = true;
  }

  setAccessCodeNotifAlertApproved() {
    this.accessCodeNotifAlertMessage =
      this.copy.status_toast.approved_primary_access_code_edit.text;
    this.accessCodeNotifAlertButtonText = null;
    this.accessCodeNotifAlertButtonFunc = null;
    this.accessCodeNotifAlertStatus = 'success';
    this.isAccessCodeNotifAlertEnabled = true;
  }

  setAccessCodeNotifAlertRejected() {
    this.accessCodeNotifAlertMessage =
      this.copy.status_toast.rejected_primary_access_code_edit.text;
    this.accessCodeNotifAlertButtonText =
      this.copy.status_toast.rejected_primary_access_code_edit.contact_button_text;
    this.accessCodeNotifAlertButtonFunc = this.contactCx.bind(this);
    this.accessCodeNotifAlertStatus = 'error';
    this.isAccessCodeNotifAlertEnabled = true;
  }

  setLimitTypeNotifAlertPending() {
    this.limitTypeNotifAlertMessage =
      this.copy.status_toast.pending_limit_type_request.text;
    this.limitTypeNotifAlertButtonText = null;
    this.limitTypeNotifAlertButtonFunc = null;
    this.limitTypeNotifAlertStatus = 'pending';
    this.isLimitTypeNotifAlertEnabled = true;
  }

  setLimitTypeNotifAlertApproved() {
    this.limitTypeNotifAlertMessage =
      this.copy.status_toast.approved_limit_type_request.text;
    this.limitTypeNotifAlertButtonText = null;
    this.limitTypeNotifAlertButtonFunc = null;
    this.limitTypeNotifAlertStatus = 'success';
    this.isLimitTypeNotifAlertEnabled = true;
  }

  setLimitTypeNotifAlertRejected() {
    this.limitTypeNotifAlertMessage =
      this.copy.status_toast.denied_limit_type_request.text;
    this.limitTypeNotifAlertButtonText =
      this.copy.status_toast.denied_limit_type_request.contact_button_text;
    this.limitTypeNotifAlertButtonFunc = this.contactCx.bind(this);
    this.limitTypeNotifAlertStatus = 'error';
    this.isLimitTypeNotifAlertEnabled = true;
  }

  showSubmitForApprovalModal() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '450px',
      data: {
        headerText: this.copy?.confirm_save_modal?.header,
        bodyText: this.replaceInstitutionText(
          this.copy?.confirm_save_modal?.body_markdown
        ),
        zoImage: this.copy?.confirm_save_modal?.image_global_key,
        cancelBtnText: this.copy?.confirm_save_modal?.back_button_text,
        confirmBtnText: this.copy?.confirm_save_modal?.save_button_text,
        confirmFunct: () => {
          this.editState = false;
          this.initializeInputs();
          this.dialog.closeAll();

          this.req
            .postDashboardLimitTypeChangeRequest({
              type: this.selectedLimitType,
            })
            .subscribe({
              next: data => {
                const currentUserData = JSON.parse(
                  localStorage.getItem('userData')
                );
                currentUserData.institution = data;
                this.userData = currentUserData;
                localStorage.setItem(
                  'userData',
                  JSON.stringify(currentUserData)
                );
                this.initializeInputs();
              },
              error: err => {
                console.log(err);
                this.req.openSnackBar(
                  'There was an error: ' + err.error.message,
                  'Okay'
                );
              },
            });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  switchModes(mode) {
    if (mode === 'main') {
      this.mode = 'main';
    }
    if (mode === 'create') {
      // Amplitude event - New Access Code Clicked
      this.req.ampTrack('New Access Code Clicked');
      this.mode = 'create';
    }

    if (mode === 'edit') {
      this.mode = 'edit';
    }
  }

  cancelEditMode() {
    this.mode = 'main';
    this.accessCodeInput = this.accessCodesData?.access_codes[0].code;
    this.allowAllZipCodesCheckedEdit =
      this.accessCodesData?.access_codes[0].zip_code_override;
    this.validPrimaryAccessCode = true;
  }

  cancelCreateMode() {
    this.mode = 'main';
    this.accessCode = '';
    this.description = null;
    this.allowAllZipCodesCheckedCreate = false;
    this.validSecondaryAccessCode = true;
  }

  allowAllZipCodesEditChecked(event: any) {
    if (
      this.allowAllZipCodesCheckedEdit !==
      this.accessCodesData?.access_codes[0].zip_code_override
    ) {
      this.isPrimarySubmitDisabled = false;
    } else if (
      this.allowAllZipCodesCheckedEdit ===
      this.accessCodesData?.access_codes[0].zip_code_override
    ) {
      this.isPrimarySubmitDisabled = true;
    }

    if (event.checked) {
      this.allowAllZipCodesCheckedEdit = true;
    } else {
      this.allowAllZipCodesCheckedEdit = false;
    }
  }

  showAllowAllZipCodesCreateModal(event: any) {
    if (event.checked) {
      this.allowAllZipCodesCheckedCreate = true;
      this.dialog.open(ConfirmationModalComponent, {
        width: '460px',
        height: '350px',
        data: {
          headerText: this.copy?.allow_users_from_all_zip_codes_modal?.header,
          bodyText: this.copy?.allow_users_from_all_zip_codes_modal?.body,
          confirmBtnText:
            this.copy?.allow_users_from_all_zip_codes_modal
              ?.confirmation_button,
          confirmFunct: () => {
            this.dialog.closeAll();
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      this.allowAllZipCodesCheckedCreate = false;
    }
  }

  openDeleteAccessCodeModal(id) {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '350px',
      data: {
        headerText: this.copy?.delete_access_code_modal?.header,
        bodyText: this.copy?.delete_access_code_modal?.sub_header,
        confirmBtnText: this.copy?.delete_access_code_modal?.delete_button,
        cancelBtnText: this.copy?.delete_access_code_modal?.cancel_button,
        confirmFunct: () => {
          this.dialog.closeAll();
          this.req.deleteDashboardAccessCode(id).subscribe({
            next: data => {
              // Amplitude event - Access Code Deleted
              this.req.ampTrack('Access Code Deleted');
              this.accessCodesData = data;
              this.filteredCodes = this.accessCodesData.access_codes;
              this.searchTerm = '';

              this.req.openSnackBar('Access code has been deleted.', 'Okay');
            },
            error: err => {
              console.log(err);
              this.req.openSnackBar(
                'There was an error: ' + err.error.message,
                'Okay'
              );
            },
          });
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  specialSearch(str) {
    return /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str);
  }

  accessCodeBlurred() {
    this.hasAccessCodeBlurred = true;
  }

  validatePrimaryAccessCode() {
    if (
      !this.specialSearch(this.accessCodeInput) &&
      this.accessCodeInput.length > 3 &&
      this.accessCodeInput.length < 11
    ) {
      this.isPrimarySubmitDisabled = false;
      this.validPrimaryAccessCode = true;
    } else {
      this.hasAccessCodeBlurred = false;
      this.validPrimaryAccessCode = false;
      this.isPrimarySubmitDisabled = true;
      if (this.specialSearch(this.accessCodeInput)) {
        this.accessCodeErrorMessage =
          'Access codes can only contain numbers and letters — no special characters.';
      } else {
        this.accessCodeErrorMessage =
          'Access codes must be between 4 and 10 characters';
      }
    }
  }

  validateSecondaryAccessCode() {
    if (
      !this.specialSearch(this.accessCode) &&
      this.accessCode.length > 3 &&
      this.accessCode.length < 11
    ) {
      this.isSecondarySubmitDisabled = false;
      this.validSecondaryAccessCode = true;
    } else {
      this.hasAccessCodeBlurred = false;
      this.isSecondarySubmitDisabled = true;
      this.validSecondaryAccessCode = false;
      if (this.specialSearch(this.accessCode)) {
        this.accessCodeErrorMessage =
          'Access codes can only contain numbers and letters — no special characters.';
      } else {
        this.accessCodeErrorMessage =
          'Access codes must be between 4 and 10 characters';
      }
    }
  }

  downloadQRCode(url, code) {
    // Amplitude event - QR Code Downloaded
    this.req.ampTrack('QR Code Downloaded', { access_code: code });
    const blob = this.dataURItoBlob(url);
    const imgUrl = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = imgUrl;
    a.download = code + '.png';
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(imgUrl);
    document.body.removeChild(a);
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  createNewAccessCode() {
    this.req
      .postDashboardAccessCode({
        code: this.accessCode,
        zip_code_override: this.allowAllZipCodesCheckedCreate,
        description: this.description,
      })
      .subscribe({
        next: data => {
          // Amplitude event - New Access Code Created
          this.req.ampTrack('New Access Code Created');
          this.accessCodesData = data;
          this.filteredCodes = this.accessCodesData.access_codes;
          this.searchTerm = '';
          this.mode = 'main';
          this.req.openSnackBar('New access code created!', 'Okay');
          this.accessCode = '';
          this.description = '';
          this.allowAllZipCodesCheckedCreate = false;
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  editPrimaryAccessCode() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '460px',
      height: '420px',
      data: {
        headerText:
          this.copy?.allow_users_from_all_zip_codes_primary_modal?.header,
        bodyText: this.replaceInstitutionText(
          this.copy?.allow_users_from_all_zip_codes_primary_modal?.body_markdown
        ),
        confirmBtnText:
          this.copy?.allow_users_from_all_zip_codes_primary_modal
            ?.save_button_text,
        cancelBtnText:
          this.copy?.allow_users_from_all_zip_codes_primary_modal
            ?.back_button_text,

        confirmFunct: () => {
          this.req
            .patchDashboardAccessCodeChangeRequest({
              code: this.accessCodeInput,
              zip_code_override: this.allowAllZipCodesCheckedEdit,
            })
            .subscribe({
              next: data => {
                // Amplitude event - Access Code Edited
                this.req.ampTrack('Access Code Edited');
                this.mode = 'main';
                const currentUserData = JSON.parse(
                  localStorage.getItem('userData')
                );
                currentUserData.institution.access_code_change_request = data;
                this.userData = currentUserData;
                localStorage.setItem(
                  'userData',
                  JSON.stringify(currentUserData)
                );
                this.initializeInputs();
              },
              error: err => {
                console.log(err);
                this.req.openSnackBar(
                  'There was an error: ' + err.error.message,
                  'Okay'
                );
              },
            });
          this.dialog.closeAll();
        },
        cancelFunct: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  replacePlaceholder(text: string) {
    return text
      .replace(/\${institution}/g, this.userData?.institution?.short_name)
      .replace(
        /\${max_access_codes}/g,
        this.userData?.institution?.max_access_codes
      );
  }

  onRadioChange(option) {
    this.selectedLimitType = option;
  }

  replaceInstitutionText(string) {
    const inputString = string;
    const replacementValue = this.userData?.institution?.short_name;
    return inputString.replace(/\${institution}/g, replacementValue);
  }

  checkTooltip(limit_type) {
    if (limit_type === 'zip_code') {
      return false;
    } else {
      return true;
    }
  }

  editSecondary(code) {
    this.accessCodeBeingEdited = code;
    this.description = code.description;
    this.accessCode = code.code;
    this.mode = 'edit-secondary';
  }

  updateSecondary() {
    this.req
      .putDashboardAccessCode({
        id: this.accessCodeBeingEdited.id,
        description: this.description,
      })
      .subscribe({
        next: data => {
          // Amplitude event - Access Code Edited
          this.req.ampTrack('Access Code Edited');
          this.accessCodesData = data;
          this.filteredCodes = this.accessCodesData.access_codes;
          this.searchTerm = '';

          this.mode = 'main';
          this.req.openSnackBar('Access code description updated!', 'Okay');
          this.accessCode = '';
          this.description = null;
        },
        error: err => {
          console.log(err);
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }

  filterAccessCodes() {
    this.filteredCodes = this.accessCodesData.access_codes.filter(segment => {
      if (!this.searchTerm.trim().length) {
        return true;
      }

      if (
        segment.code &&
        segment.code.toLowerCase().includes(this.searchTerm.toLowerCase())
      ) {
        return true;
      }

      if (
        segment.description &&
        segment.description
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  }
}
