import { Component, OnInit, HostListener } from '@angular/core';
import { CopyService } from 'src/app/copy.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { RequestsService } from 'src/app/requests.service';
import { AddMemberModalComponent } from './add-member-modal/add-member-modal.component';
import { ModalController } from '@ionic/angular';
import { AlertModalComponent } from 'src/app/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss'],
})
export class TeamMembersComponent implements OnInit {
  userData: any;
  members: any;
  email: string;
  name: string;
  accessLevel: string;
  currentUser: any;
  isLoading: boolean;
  dropdownIndex: number | null = null;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    // Amplitude event - Team Members Viewed
    this.req.ampTrack('Team Members Viewed');
    this.userData = JSON.parse(localStorage.getItem('userData'));

    // fetch the initial user list
    this.isLoading = true;
    this.req.getDashboardMembers().subscribe({
      next: data => {
        this.isLoading = false;
        this.members = data.members;
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  async addTeamMember() {
    // Amplitude event - Add New Member Clicked
    this.req.ampTrack('Add New Member Clicked');
    const componentProps = {
      mode: 'invite',
      headerText: 'Invite a team member',
      firstName: '',
      lastName: '',
      email: '',
      accessLevel: '',
    };

    const modal = await this.modalController.create({
      component: AddMemberModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss();

    this.isLoading = true;
    this.req.getDashboardMembers().subscribe({
      next: data => {
        this.isLoading = false;
        this.members = data.members;
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  async editTeamMember(member) {
    const componentProps = {
      mode: 'edit',
      memberId: member.id,
      headerText: 'Edit team member',
      firstName: member.first_name,
      lastName: member.last_name,
      email: member.email,
      accessLevel: member.access_level,
    };

    const modal = await this.modalController.create({
      component: AddMemberModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss();

    this.isLoading = true;
    this.req.getDashboardMembers().subscribe({
      next: data => {
        this.isLoading = false;
        this.members = data.members;
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  getName(firstName, lastName) {
    if (!firstName && !lastName) {
      return '-';
    } else {
      return firstName + ' ' + lastName;
    }
  }

  resendInvite(member) {
    this.dropdownIndex = null;
    if (!member.email) {
      return;
    }

    const request = {
      institution_user_id: member.id,
      first_name: member.firstName,
      last_name: member.lastName,
      email: member.email,
      institution_user_type: member.accessLevel,
    };

    this.req.postDashboardUserInvite(request).subscribe({
      next: data => {
        // Amplitude event - New Member Invitation Sent
        this.req.ampTrack('Member Invite Resent');
        this.dropdownIndex = null;
        this.toastService.showToast('Invitation email resent.', 6000);
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  resetPassword(memberEmail) {
    this.req.dashboardForgotPassword(memberEmail).subscribe({
      next: data => {
        // Amplitude event - Password Reset Link Sent
        this.req.ampTrack('Password Reset Link Sent');
        this.dropdownIndex = null;
        this.toastService.showToast('Password reset email sent.', 6000);
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  async showConfirmationAlert(member) {
    const componentProps = {
      header: 'Are you sure?',
      description: 'Removing a team member cannot be undone.',
      confirmationText: 'I understand',
      primaryButtonText: 'Remove Member',
      primaryButtonFunc: this.removeMember.bind(this, member.id),
      secondaryButtonText: "No, don't remove",
      secondaryButtonFunc: () => modal.dismiss(),
    };

    const modal = await this.modalController.create({
      component: AlertModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();

    this.dropdownIndex = null;

    await modal.onDidDismiss();
  }

  removeMember(memberId) {
    this.req.deleteDashboardMember(memberId).subscribe({
      next: data => {
        // Amplitude event - Member Deleted
        this.req.ampTrack('Member Deleted');
        this.members = data.members;
        this.modalController.dismiss();

        this.toastService.showToast('Member removed.');
      },
      error: err => {
        console.log(err);
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
      },
    });
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const clickedInside = element.closest('.member-action-cta');

    if (!clickedInside) {
      this.dropdownIndex = null;
    }
  }

  toggleDropdown(index: number) {
    this.dropdownIndex = this.dropdownIndex === index ? null : index;
  }
}
