import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
  NgZone,
} from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  Asset,
  headers,
  spanishHeaders,
  squarePosts,
  spanishSquarePosts,
  rectanglePosts,
  spanishRectanglePosts,
  stories,
  banners,
  printResources,
  spanishPrintResources,
  spanishStories,
  screenshots,
  mockups,
  spanishBanners,
} from './marketingAssets';
import { RequestsService } from 'src/app/requests.service';
import { HelperFunctionsService } from 'src/app/design-system/helper-functions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

type AccessCode = {
  id: number;
  code: string;
  description: string;
  zip_code_override: string;
  primary: boolean;
  qr_code: string;
  use_count: number;
};

@Component({
  selector: 'app-marketing-assets',
  templateUrl: './marketing-assets.component.html',
  styleUrls: ['./marketing-assets.component.scss'],
})
export class MarketingAssetsComponent implements OnInit, AfterViewInit {
  hasLogoImages: boolean;
  institutionHex: string;
  customColorHex: string;
  institutionLogo: string;
  altInstitutionLogo: string;
  accessCodes: AccessCode[];
  navTabs: any[];
  activeTab: string;
  selectedAccessCode: any;
  selectedLanguage: string = 'English';
  displayAccessCodeDropdown: boolean = false;
  displayLanguageDropdown: boolean = false;
  expandHeaders: boolean = true;
  expandPosts: boolean = false;
  expandStories: boolean = false;
  expandScreenshots: boolean = true;
  expandMockups: boolean = false;

  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

  imageData = {
    socialMedia: {
      isLoading: true,
      headerImages: [],
      spanishHeaderImages: [],
      squarePostImages: [],
      spanishSquarePostImages: [],
      rectanglePostImages: [],
      spanishRectanglePostImages: [],
      spanishStories: [],
      storyImages: [],
    },
    banners: {
      isLoading: true,
      bannerImages: [],
      spanishBannerImages: [],
    },
    printResources: {
      isLoading: true,
      printResourceImages: [],
      spanishPrintResourceImages: [],
    },
    mockups: {
      isLoading: true,
      screenshotImages: [],
      mockupImages: [],
    },
  };
  isDownloadingResources: boolean;

  constructor(
    private helpers: HelperFunctionsService,
    private req: RequestsService,
    private router: Router,
    private route: ActivatedRoute,
    public toastService: ToastService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.req.ampTrack('Marketing Assets Viewed');
    const userData = JSON.parse(localStorage.getItem('userData'));

    this.institutionHex = userData.institution.primary_hex;
    this.institutionLogo = userData.institution.image;
    this.altInstitutionLogo = userData.institution.snippet_logo_image;

    if (!this.institutionLogo || !this.altInstitutionLogo) {
      this.hasLogoImages = false;
    } else {
      this.hasLogoImages = true;
    }

    if (
      userData.institution.platform_access_types.some(
        item => item.platform === 'native'
      )
    ) {
      this.navTabs = [
        {
          label: 'Social Media',
          param: 'social-media',
        },
        { label: 'Banners', param: 'banners' },
        {
          label: 'Print Resources',
          param: 'print-resources',
        },
        { label: 'Mockups', param: 'mockups' },
        {
          label: 'Brand Graphics',
          param: 'brand-graphics',
        },
      ];
    } else {
      this.navTabs = [
        {
          label: 'Brand Graphics',
          param: 'brand-graphics',
        },
      ];
    }

    this.route.queryParams
      .pipe(filter(params => params.tab))
      .subscribe(params => {
        this.activeTab = params.tab;
      });
  }

  ngAfterViewInit() {
    this.req.getDashboardAccessCode().subscribe({
      next: data => {
        this.accessCodes = data.access_codes;
        this.selectedAccessCode = this.accessCodes[0];
        this.renderTabData(this.activeTab);
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  handleAddLogos() {
    this.router.navigate(['/custom-logos']);
  }

  toggleAccessCodeDropdown() {
    this.displayAccessCodeDropdown = !this.displayAccessCodeDropdown;
  }

  selectAccessCode(accessCodeObject: AccessCode) {
    this.selectedAccessCode = accessCodeObject;
    this.selectTab(this.navTabs[0]);
    this.ngZone.run(() => {
      this.toastService.showToast('Updating Resources, please wait.');
    });

    setTimeout(() => {
      this.imageData = {
        socialMedia: {
          isLoading: true,
          headerImages: [],
          spanishHeaderImages: [],
          squarePostImages: [],
          spanishSquarePostImages: [],
          rectanglePostImages: [],
          spanishRectanglePostImages: [],
          spanishStories: [],
          storyImages: [],
        },
        banners: {
          isLoading: true,
          bannerImages: [],
          spanishBannerImages: [],
        },
        printResources: {
          isLoading: true,
          printResourceImages: [],
          spanishPrintResourceImages: [],
        },
        mockups: {
          isLoading: true,
          screenshotImages: [],
          mockupImages: [],
        },
      };
      this.renderTabData(this.activeTab);
      this.displayAccessCodeDropdown = false;
    }, 150);
  }

  toggleLanguageSelectDropdown() {
    this.displayLanguageDropdown = !this.displayLanguageDropdown;
  }

  selectLanguage(language: string) {
    this.selectedLanguage = language;
    if (language === 'Spanish') {
      this.expandPosts = true;
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent) {
    if (this.displayAccessCodeDropdown || this.displayLanguageDropdown) {
      this.displayAccessCodeDropdown = false;
      this.displayLanguageDropdown = false;
      return;
    }
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const clickedInside = element.closest('.dropdown-button');

    if (!clickedInside) {
      this.displayAccessCodeDropdown = false;
      this.displayLanguageDropdown = false;
    }
  }

  toggleSection(section: string) {
    switch (section) {
      case 'headers':
        this.expandHeaders = !this.expandHeaders;
        break;
      case 'posts':
        this.expandPosts = !this.expandPosts;
        break;
      case 'stories':
        this.expandStories = !this.expandStories;
        break;
      case 'screenshots':
        this.expandScreenshots = !this.expandScreenshots;
        break;
      case 'mockups':
        this.expandMockups = !this.expandMockups;
        break;
      default:
        break;
    }
  }

  loadSvgAsText(svgUrl: string): Promise<string> {
    return fetch(svgUrl).then(response => response.text());
  }

  replaceColorInSvg(
    svgText: string,
    colorMap: { targetColor: string; newColor: string }[]
  ): string {
    let modifiedSvgText = svgText;

    colorMap.forEach(({ targetColor, newColor }) => {
      modifiedSvgText = modifiedSvgText.replace(
        new RegExp(targetColor, 'g'),
        newColor
      );
    });

    return modifiedSvgText;
  }

  renderTabData(tabName: string) {
    switch (tabName) {
      case 'social-media':
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          headers,
          this.imageData.socialMedia.headerImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          spanishHeaders,
          this.imageData.socialMedia.spanishHeaderImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          squarePosts,
          this.imageData.socialMedia.squarePostImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          spanishSquarePosts,
          this.imageData.socialMedia.spanishSquarePostImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          rectanglePosts,
          this.imageData.socialMedia.rectanglePostImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          spanishRectanglePosts,
          this.imageData.socialMedia.spanishRectanglePostImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          spanishStories,
          this.imageData.socialMedia.spanishStories
        );

        this.renderImages(
          this.selectedAccessCode,
          'socialMedia',
          stories,
          this.imageData.socialMedia.storyImages
        );
        break;
      case 'banners':
        this.renderImages(
          this.selectedAccessCode,
          'banners',
          banners,
          this.imageData.banners.bannerImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'banners',
          spanishBanners,
          this.imageData.banners.spanishBannerImages
        );

        break;
      case 'print-resources':
        this.renderImages(
          this.selectedAccessCode,
          'printResources',
          printResources,
          this.imageData.printResources.printResourceImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'printResources',
          spanishPrintResources,
          this.imageData.printResources.spanishPrintResourceImages
        );
        break;
      case 'mockups':
        this.renderImages(
          this.selectedAccessCode,
          'mockups',
          screenshots,
          this.imageData.mockups.screenshotImages
        );
        this.renderImages(
          this.selectedAccessCode,
          'mockups',
          mockups,
          this.imageData.mockups.mockupImages
        );
        break;
      default:
        break;
    }
  }

  renderImages(
    accessCodeObject: AccessCode,
    assetName: string,
    assets: Asset[],
    imageArray: string[]
  ) {
    const canvas = this.canvas.nativeElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const institutionColors = this.helpers.calculateColors(this.institutionHex);

    const scaleFactor = 3;

    assets.forEach((asset, index) => {
      const institutionColorReplacements = [
        { targetColor: '#0F663D', newColor: institutionColors.colorShade },
        { targetColor: '#1EC677', newColor: institutionColors.color },
        { targetColor: '#E8F9F1', newColor: institutionColors.colorTint },
      ];

      if (asset.useCustomColor) {
        institutionColorReplacements.push(
          {
            targetColor: '{{colorShade}}',
            newColor: institutionColors.colorShade,
          },
          {
            targetColor: '{{color}}',
            newColor: institutionColors.color,
          },
          {
            targetColor: '{{colorTint}}',
            newColor: institutionColors.colorTint,
          }
        );
      }

      this.loadSvgAsText(asset.url).then(svgText => {
        const newSvgText = this.replaceColorInSvg(
          svgText,
          institutionColorReplacements
        );

        const loadSvgPromise = this.loadImageFromSvg(newSvgText);
        const loadQrCodePromise = this.loadImage(
          accessCodeObject.qr_code,
          false
        );
        const loadLogoPromise = asset.useAltLogo
          ? this.loadImage(this.altInstitutionLogo, true)
          : this.loadImage(this.institutionLogo, true);

        Promise.all([loadSvgPromise, loadQrCodePromise, loadLogoPromise])
          .then(([svgImage, qrCodeImage, logoImage]) => {
            // Set canvas dimensions based on the SVG
            canvas.width = svgImage.width * scaleFactor;
            canvas.height = svgImage.height * scaleFactor;

            ctx.scale(scaleFactor, scaleFactor);

            // Main Canvas
            ctx.drawImage(svgImage, 0, 0, svgImage.width, svgImage.height);
            ctx.imageSmoothingEnabled = false;

            // QR code
            if (asset.coordinates.qrCodeX) {
              const cropAmount = 100;
              const croppedWidth = qrCodeImage.width - 2 * cropAmount;
              const croppedHeight = qrCodeImage.height - 2 * cropAmount;

              ctx.drawImage(
                qrCodeImage,
                cropAmount,
                cropAmount,
                croppedWidth,
                croppedHeight,
                asset.coordinates.qrCodeX,
                asset.coordinates.qrCodeY,
                240 * asset.qrCodeSizeMultiplier,
                240 * asset.qrCodeSizeMultiplier
              );
            }

            // Partner Logo
            if (asset.coordinates.logoX) {
              const modifiedLogoImage = this.modifyLogo(
                logoImage,
                asset.logoSizeMultiplier,
                asset.useAltLogo
              );

              if (asset.isMockup) {
                // Calculate aspect ratio based on the modified dimensions
                const logoAspectRatio =
                  modifiedLogoImage.width / modifiedLogoImage.height;

                const logoAreaWidth =
                  canvas.width * 0.2 * asset.logoSizeMultiplier; // Max logo width (adjust percentage as needed)
                const logoAreaHeight =
                  canvas.height * 0.2 * asset.logoSizeMultiplier; // Max logo height

                let finalLogoWidth, finalLogoHeight;

                if (logoAspectRatio > 1) {
                  // Landscape logo
                  finalLogoWidth = Math.min(
                    logoAreaWidth,
                    modifiedLogoImage.width
                  );
                  finalLogoHeight = finalLogoWidth / logoAspectRatio;
                } else {
                  // Portrait or square logo
                  finalLogoHeight = Math.min(
                    logoAreaHeight,
                    modifiedLogoImage.height
                  );
                  finalLogoWidth = finalLogoHeight * logoAspectRatio;
                }

                // Center the logo in the designated area
                const centeredX =
                  asset.coordinates.logoX +
                  (logoAreaWidth - finalLogoWidth) / 2;
                const centeredY =
                  asset.coordinates.logoY +
                  (logoAreaHeight - finalLogoHeight) / 2;

                ctx.drawImage(
                  modifiedLogoImage,
                  centeredX,
                  centeredY,
                  finalLogoWidth,
                  finalLogoHeight
                );
              } else {
                ctx.drawImage(
                  modifiedLogoImage,
                  asset.coordinates.logoX,
                  asset.coordinates.logoY,
                  modifiedLogoImage.width,
                  modifiedLogoImage.height
                );
              }
            }

            if (asset.coordinates.accessCodeX) {
              this.addText(
                ctx,
                accessCodeObject.code,
                asset.fontSize,
                asset.fontColor,
                asset.coordinates.accessCodeX,
                asset.coordinates.accessCodeY
              );
            }

            // Generate the final image
            const imageDataUrl = canvas.toDataURL('image/png');

            imageArray[index] = imageDataUrl;

            if (imageArray.every(element => element !== undefined)) {
              // All images have finished processing
              this.imageData[assetName].isLoading = false;
            }

            ctx.setTransform(1, 0, 0, 1, 0, 0);
          })
          .catch(error => {
            console.error('Error loading images:', error);
          });
      });
    });
  }

  loadImageFromSvg(svgContent: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' });
      img.crossOrigin = 'anonymous';
      const url = URL.createObjectURL(svgBlob);

      img.src = url;
      img.onload = () => {
        resolve(img);
        URL.revokeObjectURL(url);
      };

      img.onerror = () => reject(new Error('Failed to load SVG'));
    });
  }

  loadImage(
    src: string,
    isInstitutionLogo: boolean
  ): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      if (isInstitutionLogo) {
        img.src = src + '?not-from-cache-please';
      } else {
        img.src = src;
      }

      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error('Failed to load image: ' + src));
    });
  }

  modifyLogo(
    image: HTMLImageElement,
    multiplier: number,
    isAlternate: boolean
  ) {
    const maxLogoHeight = 80 * multiplier;
    let ratio = maxLogoHeight / image.height;
    image.width = image.width * ratio;
    image.height = image.height * ratio;

    const maxLogoWidth = 300 * multiplier;

    if (image.width > maxLogoWidth) {
      // set ratio equal to the max width divided by the image width
      ratio = maxLogoWidth / image.width;
      // set width equal to maxWidth (downscale to 300)
      image.width = image.width * ratio;
      // downscale height to maintain new aspect ratio based on maxWidth
      image.height = image.height * ratio;
    }

    if (isAlternate) {
      const offScreenCanvas = document.createElement('canvas');
      const offCtx = offScreenCanvas.getContext('2d');

      offScreenCanvas.width = image.width;
      offScreenCanvas.height = image.height;

      offCtx.drawImage(image, 0, 0, image.width, image.height);

      // Set the globalCompositeOperation to 'source-in' to keep the shape of the image
      offCtx.globalCompositeOperation = 'source-in';

      // Fill the entire logo with white
      offCtx.fillStyle = 'white';
      offCtx.fillRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);

      return offScreenCanvas;
    }

    return image;
  }

  addText(
    ctx: CanvasRenderingContext2D,
    text: string,
    fontSize: number,
    fontColor: string,
    x: number,
    y: number
  ) {
    ctx.font = `600 ${fontSize}px Gotham`;
    ctx.textAlign = 'center';
    if (fontColor === 'institutionColor') {
      ctx.fillStyle = this.institutionHex;
    } else {
      ctx.fillStyle = fontColor;
    }
    ctx.fillText(text, x, y);
  }

  selectTab(tab: any): void {
    if (this.activeTab === tab.param) {
      return;
    }

    this.selectedLanguage = 'English';
    this.displayAccessCodeDropdown = false;
    this.displayLanguageDropdown = false;
    this.activeTab = tab.param;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tab.param },
    });

    this.renderTabData(tab.param);
  }

  downloadImage(imageUri: string, fileName: string, index: number) {
    // Amplitude event - Individual Asset Downloaded
    this.req.ampTrack('Individual Asset Downloaded', {
      asset_category: this.activeTab,
      access_code: this.selectedAccessCode.code,
      language: this.selectedLanguage,
    });

    const link = document.createElement('a');
    link.href = imageUri;
    link.download = `${fileName}-${index + 1}.png`;
    link.click();
  }

  downloadAll(assetImages: string[], assetName: string) {
    this.isDownloadingResources = true;
    this.ngZone.run(() => {
      this.toastService.showToast('Downloading Assets. Please wait.');
    });
    setTimeout(() => {
      // Amplitude event - All Assets Downloaded
      this.req.ampTrack('All Assets Downloaded', {
        asset_category: this.activeTab,
        access_code: this.selectedAccessCode.code,
        language: this.selectedLanguage,
      });

      const zip = new JSZip();

      if (assetName === 'SocialMedia') {
        const socialMediaAssets = assetImages.concat(
          this.imageData.socialMedia.squarePostImages,
          this.imageData.socialMedia.rectanglePostImages,
          this.imageData.socialMedia.storyImages
        );

        socialMediaAssets.forEach((dataUri, index) => {
          // Convert the data URI to base64 and add it to the zip
          const base64Data = dataUri.split(',')[1];
          zip.file(`${assetName}-${index + 1}.png`, base64Data, {
            base64: true,
          });
        });
      }
      if (assetName === 'SpanishSocialMedia') {
        const socialMediaAssets = assetImages.concat(
          this.imageData.socialMedia.spanishSquarePostImages,
          this.imageData.socialMedia.spanishRectanglePostImages,
          this.imageData.socialMedia.spanishStories
        );

        socialMediaAssets.forEach((dataUri, index) => {
          // Convert the data URI to base64 and add it to the zip
          const base64Data = dataUri.split(',')[1];
          zip.file(`${assetName}-${index + 1}.png`, base64Data, {
            base64: true,
          });
        });
      } else if (assetName === 'Mockups') {
        const mockupAssets = assetImages.concat(
          this.imageData.mockups.mockupImages
        );

        mockupAssets.forEach((dataUri, index) => {
          // Convert the data URI to base64 and add it to the zip
          const base64Data = dataUri.split(',')[1];
          zip.file(`${assetName}-${index + 1}.png`, base64Data, {
            base64: true,
          });
        });
      } else {
        assetImages.forEach((dataUri, index) => {
          // Convert the data URI to base64 and add it to the zip
          const base64Data = dataUri.split(',')[1];
          zip.file(`${assetName}-${index + 1}.png`, base64Data, {
            base64: true,
          });
        });
      }

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `ZogoMarketingAssets_${assetName}.zip`);
      });
      this.isDownloadingResources = false;
    }, 100);
  }

  async downloadLogo(logoUrl: string, color: string) {
    // Fetch the SVG content from the URL
    const response = await fetch(logoUrl);
    if (!response.ok) {
      console.error('Failed to fetch the logo');
      return;
    }

    const svgText = await response.text();

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const img = new Image();
    const logoBlob = new Blob([svgText], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(logoBlob);

    img.onload = () => {
      // Calculate the 2x dimensions for 2x resolution
      const scale = 2;
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      context?.scale(scale, scale);

      context?.drawImage(img, 0, 0);

      // Convert canvas to PNG
      const pngUrl = canvas.toDataURL('image/png');

      // Trigger download
      const link = document.createElement('a');
      link.href = pngUrl;
      link.download = `ZogoLogo_${color}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up
      URL.revokeObjectURL(url);
    };

    img.onerror = () => {
      console.error('Failed to load the logo image');
      URL.revokeObjectURL(url);
    };

    img.src = url;
  }

  downloadIconSet(iconSet: string) {
    const link = document.createElement('a');
    switch (iconSet) {
      case 'feather':
        link.href =
          'https://assets.ctfassets.net/o1xn7flb83yr/6v0GY1eTYnHA71PsovewWu/6464039554140d8c065df1297002e551/FeatherIcons.zip';
        link.download = 'ZogoFeatherIcons.zip';
        link.click();
        break;
      case 'skillAndModule':
        link.href =
          'https://assets.ctfassets.net/o1xn7flb83yr/31LOhdFJBy3WAeSWl8Y1ij/40d40f5ca22bc0b5e675e8ad9c0e272b/SkillAndModuleIcons.zip';
        link.download = 'ZogoSkillAndModuleIcons.zip';
        link.click();
        break;
      case 'skillBadge':
        link.href =
          'https://assets.ctfassets.net/o1xn7flb83yr/CXUwT8pVNyF1taNffA7VU/782fcab01075bf7033382a9d27fa33e1/SkillBadges.zip';
        link.download = 'ZogoSkillBadgeIcons.zip';
        link.click();
        break;
      case 'coreIllustration':
        link.href =
          'https://assets.ctfassets.net/o1xn7flb83yr/4HGoDnmeONCzbEbcYNiSPi/8dcac9aea6ac280a9232a43c6448c3ea/CoreIllustrations.zip';
        link.download = 'ZogoCoreIllustrations.zip';
        link.click();
        break;
      case 'allBrandGraphics':
        link.href =
          'https://assets.ctfassets.net/o1xn7flb83yr/7yuybWu0iVcwyKO05N4EBg/ac2ba64fea2eb2bc976e7e34ff3bf691/ZogoBrandGraphics.zip';
        link.download = 'ZogoBrandGraphics.zip';
        link.click();
        break;
      default:
        break;
    }
  }
}
