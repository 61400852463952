<div class="skill-display-wrapper">
  <div class="skill-display-stats" tappable (click)="toggleAccordian()">
    <div class="skill-cell h5-medium text-color-neutral-800">
      {{ skillResultsData.skill_name }}
    </div>
    <div class="accuracy-cell">
      <div class="accuracy-frame body-bold text-color-neutral-800">
        <zogo-icon
          *ngIf="skillResultsData.accuracy.percent_change !== 0"
          class="arrow-icon"
          size="xsmall"
          [iconName]="
            skillResultsData.accuracy.change_direction === 'positive'
              ? 'arrow-up'
              : 'arrow-down'
          "
        ></zogo-icon
        >{{ skillResultsData.accuracy.percent_change }}%
      </div>
      <div class="progress-bars-wrapper">
        <div class="progress-bar-wrapper">
          <div class="bar-value-wrapper">
            <div class="bar-value-max"></div>

            <div
              class="bar-value-pre-test"
              [style.width.%]="skillResultsData.accuracy.pre_accuracy"
            ></div>
          </div>
          <div class="overline-medium text-color-neutral-600 bar-percentage">
            {{ skillResultsData.accuracy.pre_accuracy }}%
          </div>
        </div>
        <div class="progress-bar-wrapper">
          <div class="bar-value-wrapper">
            <div class="bar-value-max"></div>

            <div
              class="bar-value-post-test"
              [style.width.%]="skillResultsData.accuracy.post_accuracy"
            ></div>
          </div>
          <div class="overline-medium text-color-neutral-600 bar-percentage">
            {{ skillResultsData.accuracy.post_accuracy }}%
          </div>
        </div>
      </div>
    </div>
    <div class="confidence-cell">
      <div class="confidence-frame body-bold text-color-neutral-800">
        {{ skillResultsData.confidence_increase }}%
      </div>
    </div>
    <div class="dropdown-cell">
      <zogo-icon
        *ngIf="expanded"
        class="text-color-neutral-800"
        iconName="chevron-up"
      ></zogo-icon>

      <zogo-icon
        *ngIf="!expanded"
        class="text-color-neutral-800"
        iconName="chevron-down"
      ></zogo-icon>
    </div>
  </div>
  <div *ngIf="expanded" class="skill-survey-display-wrapper">
    <app-survey-display
      mode="pre-post-skill"
      [skillSurveyData]="skillResultsData"
    ></app-survey-display>
  </div>
</div>
