import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from '../../requests.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorDialogComponent implements OnInit {
  primaryText: string;
  secondaryText: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    public req: RequestsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.primaryText = this.data.primaryText;
      this.secondaryText = this.data.secondaryText;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
