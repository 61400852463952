import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { RequestsService } from '../../requests.service';
import { SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-community-segment-select',
  templateUrl: './community-segment-select.component.html',
  styleUrls: ['./community-segment-select.component.css'],
})
export class CommunitySegmentSelectComponent implements OnInit {
  @ViewChild('select', { static: true }) select: any;
  @Output() segmentSelected = new EventEmitter<any>();

  selectedOption: any;
  searchTerm: string = '';
  filteredSegments: any;
  accessCodesData: any;
  ALL_ACCESS_CODE_OPTION: any = {
    value: 'all',
    display_value: 'All Users',
  };
  UNASSIGNED_OPTION: any = {
    value: 'unassigned',
    display_value: '[Standalone] No access codes',
  };

  constructor(public req: RequestsService) {}

  ngOnInit(): void {
    this.selectedOption = this.ALL_ACCESS_CODE_OPTION;
    // special code to stop spacebar from closing mat select
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.disabled) {
        this.select.panelOpen
          ? this.select._handleOpenKeydown(event)
          : this.select._handleClosedKeydown(event);
      }
    };
    this.req.getDashboardAccessCode().subscribe({
      next: data => {
        this.accessCodesData = data.access_codes;
        this.filteredSegments = this.accessCodesData;
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  onSelectionChange(event) {
    this.selectedOption = event.value;
    this.segmentSelected.emit(this.selectedOption);
  }

  filterSegments() {
    this.filteredSegments = this.accessCodesData.filter(segment => {
      if (!this.searchTerm.trim().length) {
        return true;
      }

      if (
        segment.code &&
        segment.code.toLowerCase().includes(this.searchTerm.toLowerCase())
      ) {
        return true;
      }

      if (
        segment.description &&
        segment.description
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  }
}
