<div *ngIf="!userMessagesData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div *ngIf="userMessagesData" class="user-messages-container text-center">
  <div class="card card-frame messages-container">
    <ng-container *ngIf="allMessages">
      <div class="messages-header flex-row">
        <div class="messages-emblem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail"
          >
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
            ></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
        </div>
        <div class="messages-title caption-medium text-color-neutral-800">
          {{ widgetData.widget_display_name }} ({{ getNumberUnread() }} unread)
        </div>
      </div>

      <div
        *ngIf="!messages"
        class="caption-medium text-color-neutral-800 no-messages"
      >
        No messages yet
      </div>

      <div *ngIf="messages" class="messages-body flex-row">
        <div class="messages-list">
          <div class="flex-row filter-container">
            <span class="caption-medium text-color-neutral-800 filter-text"
              >Filter by</span
            >
            <mat-select class="filter" [(value)]="filter">
              <mat-option value="All">All</mat-option>
              <mat-option value="Unread">Unread</mat-option>
              <mat-option value="Read">Read</mat-option>
            </mat-select>
          </div>

          <ng-container *ngFor="let message of messages">
            <div
              (click)="selectedMsg = message"
              [ngClass]="{ read: message.is_read }"
              class="message-preview flex-col"
            >
              <div class="messages-row">
                <span
                  class="caption-medium text-color-neutral-800 truncate messages-name-text"
                  >{{
                    getTruncatedName(message.first_name, message.last_name)
                  }}</span
                >
                <span
                  class="caption-medium text-color-neutral-600 messages-preview-time truncate"
                  >{{ getFormattedTime(message.timestamp) }}</span
                >
              </div>
              <div class="messages-row">
                <span
                  class="caption-medium text-color-neutral-600 messages-preview-text truncate"
                  >{{ message.message }}</span
                >
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="selectedMsg" class="content-view">
          <div class="user-info">
            <div>
              <div
                class="caption-medium text-color-neutral-800 messages-row truncate"
              >
                {{
                  getTruncatedName(
                    selectedMsg.first_name,
                    selectedMsg.last_name
                  )
                }}
              </div>
              <div
                *ngIf="selectedMsg.city"
                class="caption-medium text-color-neutral-600 messages-row messages-location-text truncate"
              >
                {{ selectedMsg.city }}
              </div>
            </div>
            <div *ngIf="selectedMsg.membership_status" class="member-status">
              <zogo-badge
                type="neutral"
                [label]="selectedMsg.membership_status"
              >
              </zogo-badge>
            </div>
          </div>

          <div class="message-container" id="messageContainer">
            <div>
              <div class="flex-row message-content from-us">
                <div class="message-text-container question-container">
                  <span
                    class="caption-medium text-color-neutral-white message-text"
                    >{{ selectedMsg.question_text }}</span
                  >
                </div>

                <img
                  class="zogo-avatar"
                  src="../../assets/imgs/zogo-avatar.png"
                />
              </div>

              <div class="caption-medium text-color-neutral-600 messages-time">
                {{ getFormattedDate(selectedMsg.timestamp) }}
              </div>
            </div>
            <div class="flex-row message-content from-them">
              <div class="user-avatar">
                <span
                  class="user-avatar-text caption-medium text-color-neutral-white"
                  >{{
                    getInitials(selectedMsg.first_name, selectedMsg.last_name)
                  }}</span
                >
              </div>

              <div class="message-text-container">
                <span
                  class="message-text caption-medium text-color-neutral-800"
                  >{{ selectedMsg.message }}</span
                >
              </div>
            </div>
            <div
              *ngIf="selectedMsg.zogo_response_message"
              class="from-us flex-row message-content"
            >
              <div class="message-text-container question-container">
                <span
                  class="message-text caption-medium text-color-neutral-white"
                  >{{ selectedMsg.zogo_response_message }}</span
                >
              </div>

              <img
                class="zogo-avatar"
                src="../../assets/imgs/zogo-avatar.png"
              />
            </div>

            <div class="chat-options">
              <div
                *ngIf="selectedMsg.thank_you_text"
                class="caption-medium text-color-neutral-800 bubble"
                (click)="sendUserResponse(selectedMsg.id)"
              >
                {{ selectedMsg.thank_you_text }}
              </div>
              <div
                *ngIf="selectedMsg.email"
                class="caption-medium text-color-neutral-800 bubble"
                (click)="copyUserEmail(selectedMsg.email.to)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-copy"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path
                    d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                  ></path>
                </svg>
                {{ selectedMsg.email.copy_email_text }}
              </div>
              <div
                *ngIf="selectedMsg.email"
                class="bubble"
                (click)="
                  sendUserEmail(
                    selectedMsg.email.to,
                    selectedMsg.email.subject,
                    selectedMsg.email.body
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-mail"
                >
                  <path
                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                  ></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                {{ selectedMsg.email.send_email_text }}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!selectedMsg" class="content-view">
          <div class="caption-medium text-color-neutral-800 no-selected">
            Click on a message to view it!
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
