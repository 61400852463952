import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  userData: any;
  navTabs: any[] = [];
  activeTab: string;

  constructor(
    public req: RequestsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    const navBarItems = [
      {
        key: 'team_members',
        tabInfo: { label: 'Team Members', param: 'team-members' },
      },
      {
        key: 'teacher_admins',
        tabInfo: { label: 'Teacher Admins', param: 'teacher-admins' },
      },
      {
        key: 'my_account',
        tabInfo: { label: 'Account Settings', param: 'account-settings' },
      },
      {
        key: 'payments',
        tabInfo: { label: 'Billing Contact', param: 'billing-contact' },
      },
      {
        key: 'zip_codes',
        tabInfo: { label: 'Zip Codes', param: 'zip-codes' },
      },
    ];

    navBarItems.forEach(option => {
      if (this.userData.institution_user.nav_bar.includes(option.key)) {
        this.navTabs.push(option.tabInfo);
      }
    });

    this.route.queryParams
      .pipe(filter(params => params.tab))
      .subscribe(params => {
        this.activeTab = params.tab;
      });
  }

  selectTab(tab: string): void {
    if (this.activeTab === tab) {
      return;
    }

    this.activeTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tab },
    });
  }
}
