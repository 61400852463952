<div mat-dialog-title>
  <svg
    class="dismiss-cross"
    (click)="data.cancelFunct()"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>

<div class="highlight-section">
  <img class="highlight-img" [src]="highlightImage" />

  <div class="highlight-header">{{ highlightTitle }}</div>
  <markdown
    class="highlight-description"
    [data]="highlightDescription"
  ></markdown>

  <zogo-btn
    *ngIf="websiteUrl"
    label="Open Article"
    [fullWidth]="false"
    size="medium"
    buttonStyle="primary"
    (clickFunction)="openArticleLink()"
  >
  </zogo-btn>
</div>
