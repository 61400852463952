export type Asset = {
  title: string;
  url: string;
  fontSize: number;
  fontColor?: string;
  useAltLogo: boolean;
  useCustomColor?: boolean;
  isMockup?: boolean;
  logoSizeMultiplier: number;
  qrCodeSizeMultiplier: number;
  coordinates: {
    logoX?: number;
    logoY?: number;
    accessCodeX?: number;
    accessCodeY?: number;
    qrCodeX?: number;
    qrCodeY?: number;
  };
};

export const headers: Asset[] = [
  {
    title: 'header-v2-branded',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1tjxqXPMNhqIwk8OYrqG1c/f3ad5425607c09ebd69581eb850c25a7/Header-v2-branded.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 125,
      accessCodeX: 443,
      accessCodeY: 990,
    },
  },
  {
    title: 'header-v1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2bn81GSTolKkecWJU94Vxo/39bcf05e32cd7b3aa10ad46ed3b63b65/Header-v1.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 120,
      accessCodeX: 455,
      accessCodeY: 985,
    },
  },
  {
    title: 'header-v2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/71Z2aTyutpUbSCsttGb2f9/96c798385d1e3d48773c066b8aa8ff27/Header-v2.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 85,
      accessCodeX: 800,
      accessCodeY: 990,
      qrCodeX: 159,
      qrCodeY: 805,
    },
  },
  {
    title: 'header-v3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1xzvS9hy1yoGfixRhNrm4i/5134bb64d773a05ef7db38b208b1e6d0/Header-v3.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 85,
      accessCodeX: 800,
      accessCodeY: 990,
      qrCodeX: 159,
      qrCodeY: 805,
    },
  },
  {
    title: 'header-v4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1oCMXG4VmdtnfgSDUBqcvk/2d49f6b5d229e1d6eaa64ba7676dbb2a/Header-v4.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 95,
      accessCodeX: 800,
      accessCodeY: 990,
      qrCodeX: 159,
      qrCodeY: 805,
    },
  },
  {
    title: 'header-v5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5bwDMmQU1thLWCmZjKxFSs/7048b8789b4c21da067f8efb1b5c29f8/Header-v5.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 85,
      accessCodeX: 800,
      accessCodeY: 990,
      qrCodeX: 159,
      qrCodeY: 805,
    },
  },
];

export const spanishHeaders: Asset[] = [
  {
    title: 'Header-v1-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2bn81GSTolKkecWJU94Vxo/5c739148265cc88510e03e61d293478e/Header-v1-sp.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 80,
      accessCodeX: 455,
      accessCodeY: 1025,
    },
  },
  {
    title: 'Header-v2-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/71Z2aTyutpUbSCsttGb2f9/94298788d5b36beeeb60580cd0913bf3/Header-v2-sp.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 70,
      accessCodeX: 460,
      accessCodeY: 1035,
    },
  },
  {
    title: 'Header-v3-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1xzvS9hy1yoGfixRhNrm4i/4d37590a06776cb97c48623f076e6353/Header-v3-sp.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 95,
      accessCodeX: 800,
      accessCodeY: 975,
      qrCodeX: 155,
      qrCodeY: 785,
    },
  },
  {
    title: 'Header-v4-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1oCMXG4VmdtnfgSDUBqcvk/12835bcaaa60efd5704101c2fdd7df1a/Header-v4-sp.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 95,
      accessCodeX: 800,
      accessCodeY: 990,
      qrCodeX: 160,
      qrCodeY: 795,
    },
  },
  {
    title: 'Header-v5-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5bwDMmQU1thLWCmZjKxFSs/f281cdd035e36bd79a9b79aa6023574c/Header-v5-sp.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 95,
      accessCodeX: 805,
      accessCodeY: 1040,
      qrCodeX: 160,
      qrCodeY: 850,
    },
  },
  {
    title: 'Header-v2-branded-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1tjxqXPMNhqIwk8OYrqG1c/35acc9e7015a6455765ae58c1e9a66cf/Header-v2-branded-sp.svg',
    fontSize: 80,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 635,
      logoY: 70,
      accessCodeX: 455,
      accessCodeY: 1050,
    },
  },
];

export const squarePosts: Asset[] = [
  {
    title: 'post-square-v1-branded',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7MMVGxMaHuXGNYlcAg9UrA/86328284eb740384bffec57c255826f5/post-square-v1-branded.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 155,
      logoY: 1487,
      accessCodeX: 460,
      accessCodeY: 1310,
    },
  },
  {
    title: 'post-square-v1a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6JUCWrwALsD3C1sIeKTg81/9869274649f6d6319bd815f771cd76ff/post-square-v1a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 190,
      logoY: 1500,
      accessCodeX: 490,
      accessCodeY: 1320,
    },
  },
  {
    title: 'post-square-v1b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3Pzgacpy1tp5fAZqSDHl0s/ec51c354d8d9696097907234f63508d4/post-square-v1b.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 190,
      logoY: 1500,
      accessCodeX: 490,
      accessCodeY: 1320,
    },
  },
  {
    title: 'post-square-v2a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6dEZFUOialCpdsloHMfLIU/9c5b0429dd49f0244ed7402ae14d6958/post-square-v2a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 155,
      logoY: 1425,
      accessCodeX: 460,
      accessCodeY: 1230,
    },
  },
  {
    title: 'post-square-v2b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/10igk7GJ4bjyMdcbjdv0Vg/c52dbc7a5488da94f90f38e34c6160a9/post-square-v2b.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 155,
      logoY: 1425,
      accessCodeX: 460,
      accessCodeY: 1230,
    },
  },
  {
    title: 'post-square-v3a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2fxaSFgyAFnQembFlclPQ3/9383da2ac1ea893464e18a728bff2321/post-square-v3a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1090,
      logoY: 95,
      accessCodeX: 550,
      accessCodeY: 1207,
    },
  },
  {
    title: 'post-square-v3b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1ENwcNxNOhZtz8BJ6U3m3k/16ee93f1b5e1f96a1b8443cf124e7978/post-swuare-v3b.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1090,
      logoY: 95,
      accessCodeX: 550,
      accessCodeY: 1170,
    },
  },
  {
    title: 'post-square-v4a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1cGekjgIH6tZIp9xAoJ1uj/80761f3d99e74e84a972b2704cc574dd/post-square-v4a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 191,
      accessCodeX: 425,
      accessCodeY: 1225,
      qrCodeX: 137,
      qrCodeY: 1370,
    },
  },
  {
    title: 'post-square-v4b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2q3ohSELXupNyEKk3TnPGo/eeb1018a6aedcd0b40ca682914a16cf4/post-square-v4b.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 191,
      accessCodeX: 425,
      accessCodeY: 1225,
      qrCodeX: 137,
      qrCodeY: 1370,
    },
  },
];

export const spanishSquarePosts: Asset[] = [
  {
    title: 'post-square-v1a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6JUCWrwALsD3C1sIeKTg81/e8263046954ca719ae83e58d9f3e10eb/post-square-v1a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 190,
      logoY: 1473,
      accessCodeX: 495,
      accessCodeY: 1300,
    },
  },
  {
    title: 'post-square-v1b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3Pzgacpy1tp5fAZqSDHl0s/30c35b1b8c815717a0b762d822d4cca6/post-square-v1b-sp.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 190,
      logoY: 1473,
      accessCodeX: 495,
      accessCodeY: 1300,
    },
  },
  {
    title: 'post-square-v2a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6dEZFUOialCpdsloHMfLIU/e9d6910357fccaed972396bc8d0a214c/post-square-v2a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 155,
      logoY: 1403,
      accessCodeX: 460,
      accessCodeY: 1215,
    },
  },
  {
    title: 'post-square-v2b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/10igk7GJ4bjyMdcbjdv0Vg/11293f4078c8eea91bbf9a386daf5bb9/post-square-v2b-sp.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 155,
      logoY: 1403,
      accessCodeX: 460,
      accessCodeY: 1210,
    },
  },
  {
    title: 'post-square-v3a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2fxaSFgyAFnQembFlclPQ3/cce9f5af1bb7263bf2781e48417a5b0a/post-square-v3a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1090,
      logoY: 120,
      accessCodeX: 550,
      accessCodeY: 1235,
    },
  },
  {
    title: 'post-square-v3b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1ENwcNxNOhZtz8BJ6U3m3k/74d2f9047f199ef31eae749f9ea6093a/post-swuare-v3b-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1090,
      logoY: 120,
      accessCodeX: 550,
      accessCodeY: 1235,
    },
  },
  {
    title: 'post-square-v4a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1cGekjgIH6tZIp9xAoJ1uj/887282d078948d45d95a70d341732c72/post-square-v4a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.9,
    coordinates: {
      logoX: 550,
      logoY: 247,
      accessCodeX: 423,
      accessCodeY: 1165,
      qrCodeX: 150,
      qrCodeY: 1325,
    },
  },
  {
    title: 'post-square-v4b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2q3ohSELXupNyEKk3TnPGo/6531dc1543df993aaaef8ca1998fe5f4/post-square-v4b-sp.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.9,
    coordinates: {
      logoX: 550,
      logoY: 247,
      accessCodeX: 423,
      accessCodeY: 1165,
      qrCodeX: 150,
      qrCodeY: 1325,
    },
  },
  {
    title: 'post-square-v1-branded-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7MMVGxMaHuXGNYlcAg9UrA/0a3b7bf84df027f0311dd0aa534b8118/post-square-v1-branded-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 190,
      logoY: 1473,
      accessCodeX: 490,
      accessCodeY: 1300,
    },
  },
];

export const rectanglePosts: Asset[] = [
  {
    title: 'Post-rectangle-v1a-branded',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4IFj0IH9fH7pqsRB5JN2vn/875b8ee08a6983e36271a10f7fb7767c/Post-rectangle-v1a-branded.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 227,
      logoY: 975,
      accessCodeX: 965,
      accessCodeY: 1087,
      qrCodeX: 1245,
      qrCodeY: 1227,
    },
  },
  {
    title: 'Post-rectangle-v1a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/51CqRslyTFjAloGdMzbSVU/e6ca52e6689dab9352e8f2bb21ecf559/Post-rectangle-v1a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.98,
    coordinates: {
      logoX: 227,
      logoY: 975,
      accessCodeX: 965,
      accessCodeY: 1087,
      qrCodeX: 1245,
      qrCodeY: 1227,
    },
  },

  {
    title: 'Post-rectangle-v1b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6Ni5q6YvSz3J4dy0EifeIw/d5e3edef20a41848a57ec93f6e4a436e/Post-rectangle-v1b.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 227,
      logoY: 975,
      accessCodeX: 965,
      accessCodeY: 1087,
      qrCodeX: 1245,
      qrCodeY: 1227,
    },
  },
  {
    title: 'Post-rectangle-v2a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5HAHRe79ZEAJFy4veJgly6/16281ce4167f38f073c2ae3247f51bec/Post-rectangle-v2a.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 180,
      logoY: 935,
      accessCodeX: 915,
      accessCodeY: 1063,
      qrCodeX: 1190,
      qrCodeY: 1200,
    },
  },
  {
    title: 'Post-rectangle-v2b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2RbJDd9rANmldV9nbBTHkb/2d38cb4a3450ad85bb8a7417d974da3f/Post-rectangle-v2b.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.97,
    coordinates: {
      logoX: 177,
      logoY: 955,
      accessCodeX: 915,
      accessCodeY: 1065,
      qrCodeX: 1190,
      qrCodeY: 1200,
    },
  },
  {
    title: 'Post-rectangle-v3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3tRgh94YEffNZkOpHtyc3B/1170e1752363c94dfebce8dc5e8f7217/Post-rectangle-v3.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1477,
      logoY: 73,
      accessCodeX: 865,
      accessCodeY: 935,
    },
  },
  {
    title: 'Post-rectangle-v4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2JwOFDkQZW5gZsKTUttOf8/1bebff84c271ff0e03dec53dba8b2d56/Post-rectangle-v4.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 580,
      logoY: 143,
      accessCodeX: 460,
      accessCodeY: 1037,
      qrCodeX: 1163,
      qrCodeY: 1150,
    },
  },
];

export const spanishRectanglePosts: Asset[] = [
  {
    title: 'Post-rectangle-v1a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/51CqRslyTFjAloGdMzbSVU/7dd6508c52ade27e7620ab3a9a9e17b3/Post-rectangle-v1a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.97,
    coordinates: {
      logoX: 227,
      logoY: 925,
      accessCodeX: 1020,
      accessCodeY: 1017,
      qrCodeX: 1245,
      qrCodeY: 1161,
    },
  },
  {
    title: 'Post-rectangle-v1a-branded-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4IFj0IH9fH7pqsRB5JN2vn/be23d451ffdf81db21641445246e1f03/Post-rectangle-v1a-branded-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.97,
    coordinates: {
      logoX: 230,
      logoY: 923,
      accessCodeX: 1020,
      accessCodeY: 1017,
      qrCodeX: 263,
      qrCodeY: 1161,
    },
  },
  {
    title: 'Post-rectangle-v1b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6Ni5q6YvSz3J4dy0EifeIw/fac30284a0451f960cb24c20da90265b/Post-rectangle-v1b-sp.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 230,
      logoY: 925,
      accessCodeX: 1020,
      accessCodeY: 1017,
      qrCodeX: 1240,
      qrCodeY: 1161,
    },
  },
  {
    title: 'Post-rectangle-v2a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5HAHRe79ZEAJFy4veJgly6/574452351aea5d3df55328d979b895b5/Post-rectangle-v2a-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 175,
      logoY: 990,
      accessCodeX: 970,
      accessCodeY: 1077,
      qrCodeX: 1188,
      qrCodeY: 1220,
    },
  },
  {
    title: 'Post-rectangle-v2b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2RbJDd9rANmldV9nbBTHkb/177c2df18c3007cc1b5548b6b8d6a870/Post-rectangle-v2b-sp.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 175,
      logoY: 990,
      accessCodeX: 970,
      accessCodeY: 1077,
      qrCodeX: 1188,
      qrCodeY: 1220,
    },
  },
  {
    title: 'Post-rectangle-v3-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3tRgh94YEffNZkOpHtyc3B/1ac80289727ea850b0fc779fc5eaee04/Post-rectangle-v3-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1477,
      logoY: 73,
      accessCodeX: 760,
      accessCodeY: 1015,
    },
  },
  {
    title: 'Post-rectangle-v4-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2JwOFDkQZW5gZsKTUttOf8/5e244f7a336ee1b91bdab9a1be5a7455/Post-rectangle-v4-sp.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 0.95,
    coordinates: {
      logoX: 587,
      logoY: 137,
      accessCodeX: 455,
      accessCodeY: 920,
      qrCodeX: 1167,
      qrCodeY: 1041,
    },
  },
];

export const stories: Asset[] = [
  {
    title: 'Stories-v4-branded',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1ibrTWrSZml7bZQjAnY2PM/d2732c97a3716aaed24eab9e28c35d3f/Stories-v4-branded.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 548,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v1a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7Ke4FktfHRULGgc8X6LGIQ/b8b530a0ebb87a5e9708442c258827a7/Stories-v1a.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 548,
      accessCodeY: 1723,
    },
  },
  {
    title: 'Stories-v1b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/72dy1CS5zzxfSkm968MJIC/e81fb45574011cc6aa6158cebc23422a/Stories-v1b.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v2a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6WIHQsG1u7SP4ISPXwdcui/f5e6be09bd00395c1db3afcb5187a738/Stories-v2a.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v2b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1I33dUt1zQvYjwC0MKOdPo/050cbcbb5e4a3cdf254600fe533b5f11/Stories-v2b.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1728,
    },
  },
  {
    title: 'Stories-v3a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7p6XQrPf1BGBSZvZVkEw5B/b17a6c3b93bd29539d880dfaf3c775af/Stories-v3a.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 443,
      logoY: 177,
      accessCodeX: 345,
      accessCodeY: 1185,
    },
  },
  {
    title: 'Stories-v3b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/24nV43cvzYkw11hZZJ4T0q/3c9291ca7e53592aa7788ec0c7370d77/Stories-v3b.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 350,
      accessCodeY: 1150,
    },
  },
  {
    title: 'Stories-v4a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4iKfnnnxjYNv9c4nDQ7Vn/142792eb96b9d87effcc5bb4df051f12/Stories-v4a.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 350,
      accessCodeY: 1108,
    },
  },
  {
    title: 'Storie-v4b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1lecfx4a2p8UJZkTPnr86b/b8434b9a328ef587faae98d9ca943e04/Storie-v4b.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 320,
      accessCodeY: 1105,
    },
  },

  {
    title: 'Stories-v5a',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6x4gPvx8zJBzDtQQz2cg3f/d8dfc42f6af6827290011d74f535345e/Stories-v5a.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 538,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v5b',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/FiSs4gF9hIZNyx2SBqUkk/ae600c1abcf311c614ec7a974f048625/Stories-v5b.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1725,
    },
  },
];

export const spanishStories: Asset[] = [
  {
    title: 'Stories-v1a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7Ke4FktfHRULGgc8X6LGIQ/da2b8f3e87dd51b255f071053e7dfa19/Stories-v1a-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 548,
      accessCodeY: 1685,
    },
  },
  {
    title: 'Stories-v1b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/72dy1CS5zzxfSkm968MJIC/bbc5f9da09fa693fd46ce9044ca4e3e9/Stories-v1b-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v2a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6WIHQsG1u7SP4ISPXwdcui/4f93c1e4d56b11527c1f2ca144defafc/Stories-v2a-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1703,
    },
  },
  {
    title: 'Stories-v2b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1I33dUt1zQvYjwC0MKOdPo/bc3e399cf5dd265ced4376d46129482f/Stories-v2b-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1715,
    },
  },
  {
    title: 'Stories-v3a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7p6XQrPf1BGBSZvZVkEw5B/2ff6494633f7e62b1bae42c7ef390ed7/Stories-v3a-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 443,
      logoY: 177,
      accessCodeX: 345,
      accessCodeY: 1155,
    },
  },
  {
    title: 'Stories-v3b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/24nV43cvzYkw11hZZJ4T0q/449c7ad9539c48a45e733a0d4f477ce6/Stories-v3b-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 355,
      accessCodeY: 1160,
    },
  },
  {
    title: 'Stories-v4a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4iKfnnnxjYNv9c4nDQ7Vn/b639d8e51a843dc39647b0c006096005/Stories-v4a-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 350,
      accessCodeY: 1100,
    },
  },
  {
    title: 'Storie-v4b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1lecfx4a2p8UJZkTPnr86b/2001dfb003fd6a5cb19718e28af702a9/Stories-v4b-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 450,
      logoY: 177,
      accessCodeX: 355,
      accessCodeY: 1100,
    },
  },
  {
    title: 'Stories-v4-branded-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1ibrTWrSZml7bZQjAnY2PM/b87846d2db5279a52266a9001ceb49a6/Stories-v4-branded-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 548,
      accessCodeY: 1718,
    },
  },
  {
    title: 'Stories-v5a-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6x4gPvx8zJBzDtQQz2cg3f/a1d597c9f2c6c2c8755f8256d5fe2507/Stories-v5a-sp.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1725,
    },
  },
  {
    title: 'Stories-v5b-sp',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/FiSs4gF9hIZNyx2SBqUkk/ce6d42f605274dedc0df1f84883cd58f/Stories-v5b-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 585,
      logoY: 180,
      accessCodeX: 543,
      accessCodeY: 1700,
    },
  },
];

export const banners: Asset[] = [
  {
    title: 'banner-v1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6gVGJzmuz5X6iNtxqZRShi/57dd0d75a86e540a6a4a38dc1a2e55f0/banner-v1.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 940,
      logoY: 20,
      accessCodeX: 1800,
      accessCodeY: 205,
    },
  },
  {
    title: 'banner-v2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6ipKJ5oSJEenG8BNjp37sq/8fff16ba0814742e50db5acf167b5057/banner-v2.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 890,
      logoY: 20,
      accessCodeX: 1800,
      accessCodeY: 205,
    },
  },
  {
    title: 'banner-v3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/72izD0cO26H9eAHM1Ilh62/5966cbc3ca0a2cee3e07040277d8cd0b/banner-v3.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      accessCodeX: 1790,
      accessCodeY: 205,
    },
  },
  {
    title: 'banner-v4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1bD4y8CjGpbCRyCS4Sd3Mq/4e9ddeb59f28e283146c20e89ffacdce/banner-v4.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 0.6,
    coordinates: {
      accessCodeX: 1595,
      accessCodeY: 203,
      qrCodeX: 1855,
      qrCodeY: 77,
    },
  },
];

export const spanishBanners: Asset[] = [
  {
    title: 'banner-v1-es',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6gVGJzmuz5X6iNtxqZRShi/ea3137257783a651ed001a0acc46d1c2/banner-v1-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 940,
      logoY: 25,
      accessCodeX: 1800,
      accessCodeY: 230,
    },
  },
  {
    title: 'banner-v2-es',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6ipKJ5oSJEenG8BNjp37sq/10e3046df620f78728f735a99fc9f515/banner-v2-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.8,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 890,
      logoY: 25,
      accessCodeX: 1800,
      accessCodeY: 228,
    },
  },
  {
    title: 'banner-v3-es',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/72izD0cO26H9eAHM1Ilh62/f29359f608d651e085cb60fb48699bb6/banner-v3-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      accessCodeX: 1795,
      accessCodeY: 228,
    },
  },
  {
    title: 'banner-v4-es',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1bD4y8CjGpbCRyCS4Sd3Mq/ad5f9c03e85c25f94153cdccb024c21f/banner-v4-sp.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 0.6,
    coordinates: {
      accessCodeX: 1590,
      accessCodeY: 228,
      qrCodeX: 1855,
      qrCodeY: 77,
    },
  },
];

export const printResources: Asset[] = [
  {
    title: 'Frame_223',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5KwhbmbeRNb8mC980dqUEi/c2f133fc734e9bff18b86c1d2494a25f/Frame_223.svg',
    fontSize: 48,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 525,
      logoY: 1925,
      accessCodeX: 735,
      accessCodeY: 1718,
      qrCodeX: 615,
      qrCodeY: 1325,
    },
  },
  {
    title: 'MAK_Bi-Fold_Brochure_1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7FIgZSCLcGBWyhCViDgYml/16bb7a60d491a29a2dc9bcdc1b5df526/MAK_Bi-Fold_Brochure_1.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1995,
      logoY: 103,
      accessCodeX: 1915,
      accessCodeY: 1775,
    },
  },
  {
    title: 'banner_1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/10V4x6PX8sUINDhOcWFF2f/1f22897ad16b2e2a4d43cdfbd832a79d/Frame_289394.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 110,
      logoY: 655,
      accessCodeX: 1885,
      accessCodeY: 710,
      qrCodeX: 1762,
      qrCodeY: 225,
    },
  },
  {
    title: 'Frame_223',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1dtq0VeLJK1XrGQOdI7lhk/45527cfe733bc4b8c261213824cb145a/buckslip__usb.svg',
    fontSize: 48,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 525,
      logoY: 1925,
    },
  },
  {
    title: 'MAK_Bi-Fold_Brochure_1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4JlOJeuhyBwYUYF7kNXcb7/fb46eb3eec2f16f6cf8bbe723ed408b2/8.5x11in_with_0.25in_margins__300ppi.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1340,
      logoY: 3220,
      accessCodeX: 435,
      accessCodeY: 2950,
    },
  },
  {
    title: 'banner_2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/17yi570eIwULqjC9sLDS7v/04dcd7e807ff984b9f47eb12af13157f/banner_2.svg',
    fontSize: 48,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.25,
    qrCodeSizeMultiplier: 0.8,
    coordinates: {
      logoX: 110,
      logoY: 975,
      accessCodeX: 315,
      accessCodeY: 807,
      qrCodeX: 115,
      qrCodeY: 453,
    },
  },
  {
    title: 'banner_3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2t4GZxXhAKGNr90VsCJmsi/241fbe1d5185d9db8856514dcf0c1303/banner_3.svg',
    fontSize: 48,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.25,
    qrCodeSizeMultiplier: 0.8,
    coordinates: {
      logoX: 460,
      logoY: 950,
      accessCodeX: 315,
      accessCodeY: 660,
    },
  },
  {
    title: 'banner_4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4t70yECNUSxeGVelZVR7PW/cd1a6f41961da4247171e27f0c97d33c/banner_4.svg',
    fontSize: 48,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 1.25,
    qrCodeSizeMultiplier: 0.8,
    coordinates: {
      logoX: 470,
      logoY: 950,
      accessCodeX: 325,
      accessCodeY: 770,
      qrCodeX: 125,
      qrCodeY: 400,
    },
  },
  {
    title: 'MAK_Bi-Fold_Brochure_1-Branded',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7qbyaplxhaSArS8uiUUUP9/5807dddf161a5308848dc6e5fabd9739/8.5x11in_with_0.25in_margins__300ppi__1_.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1340,
      logoY: 3220,
      accessCodeX: 435,
      accessCodeY: 2950,
    },
  },
];

export const spanishPrintResources: Asset[] = [
  {
    title: 'banner_1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/p6p3MbjLEl2zNmcuuJVSb/529433e190be64e8ff8a8b38d36f9d4e/Frame_289403.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 108,
      logoY: 685,
      accessCodeX: 1885,
      accessCodeY: 710,
      qrCodeX: 1762,
      qrCodeY: 225,
    },
  },
  {
    title: 'SPANISH_8.5x11in_with_0.25in_margins__300ppi',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3EWf3T7qLbZx2aQnIju4Y4/77ef8b331af607f251442279be695803/SPANISH_8.5x11in_with_0.25in_margins__300ppi.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1350,
      logoY: 3220,
      accessCodeX: 500,
      accessCodeY: 2973,
    },
  },
  {
    title: 'Group_289427',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/eUubgbd5Ge5umWpVHNdjA/6265ed51bda90972e2a231431aa78cab/Group_289427.svg',
    fontSize: 60,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 2005,
      logoY: 103,
      accessCodeX: 1920,
      accessCodeY: 1775,
    },
  },
];

export const screenshots: Asset[] = [
  {
    title: 'screenshot-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3XdWYUod0u1UCrCvvVFkAG/ec13c20bbccde32a008ac34678558369/screenshot-1.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    isMockup: true,
    logoSizeMultiplier: 0.35,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 0,
    },
  },
  {
    title: 'screenshot-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3UCsOOmFWirNap3TuCx6Ad/49ee18c11e8c9d0ee10a2953f06229b3/screenshot-2.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'screenshot-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7qKsxz4t9ghRM8dEpKvjTn/75dc987616732e42f6f60fcd3cb1b3fa/screenshot-3.svg',
    fontSize: 80,
    useAltLogo: true,
    useCustomColor: true,
    isMockup: true,
    logoSizeMultiplier: 0.35,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 0,
    },
  },
  {
    title: 'screenshot-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1bfuCsQ1ns34XfANRt48mN/5cc2a5c2de69b276a8ed78a6e07e8a9f/screenshot-4.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'screenshot-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/CGdz33Ckl4Lfp125x9kwY/fac7cc4d7718b0fdb13c11c1281f3ad2/screenshot-5.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'screenshot-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4Igmch0193IY0UWPHb1PEW/762bcd5aa843e87aa3966b560697a4a7/screenshot-6.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
];

export const mockups: Asset[] = [
  {
    title: 'mockup-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2tPXVovzxVBF81tAmXyVfP/3cda8cb76aa467d05855162a6563a7a3/mockup-1.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    isMockup: true,
    logoSizeMultiplier: 0.35,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 170,
      logoY: 15,
    },
  },
  {
    title: 'mockup-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5EGJRTy3GOC6F6URzjYjx1/ed02b1f5a1eb6e891ec31a7bb4814145/mockup-2.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'mockup-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4qATY8akFt8obbtdqcN7LI/bab5e5a26899e8dba4542dfc0e1c1fbf/mockup-3.svg',
    fontSize: 80,
    useAltLogo: true,
    useCustomColor: true,
    isMockup: true,
    logoSizeMultiplier: 0.35,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 170,
      logoY: 15,
    },
  },
  {
    title: 'mockup-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2rpTZz9Y7k7OvhRY27qG0h/e5596805c4abe1ba8a07bfdf95b8279d/mockup-4.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'mockup-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/77yzB3osWgmxvGvOH9KkLD/39d8815b59f539dc0d630db1e1c2bf09/mockup-5.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'mockup-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7LTAujl0l67OBXzXiJh20R/198b94439c17e581f31c23ab51da5b6a/mockup-6.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
];
