<div class="alert-modal-wrapper">
  <zogo-icon
    class="dismiss-icon text-color-neutral-800"
    iconName="x"
    (click)="dismiss()"
  ></zogo-icon>
  <div class="d-flex-col gap-8">
    <zogo-badge *ngIf="showBadge" label="Urgent" type="accent"></zogo-badge>
    <h4 class="h4-bold text-color-neutral-800">{{ header }}</h4>
    <markdown
      class="markdown-content body-book text-color-neutral-800"
      [data]="description"
    ></markdown>

    <zogo-checkbox
      *ngIf="dontShowAgainToken"
      class="overline-book text-color-neutral-800"
      label="Don't show this again"
      [initialValue]="dontShowAgain"
      (valueChange)="toggleDontShowAgain($event)"
    >
    </zogo-checkbox>

    <zogo-checkbox
      *ngIf="confirmationText"
      class="overline-book text-color-neutral-800"
      [label]="confirmationText"
      [initialValue]="hasConfirmed"
      (valueChange)="toggleConfirmation($event)"
    >
    </zogo-checkbox>
  </div>

  <div class="button-wrapper d-flex-row gap-8 margin-top-32">
    <zogo-btn
      *ngIf="primaryButtonText"
      buttonStyle="primary"
      [label]="primaryButtonText"
      [disabled]="confirmationText && !hasConfirmed"
      (clickFunction)="handlePrimaryButtonFunc()"
    ></zogo-btn>
    <zogo-btn
      *ngIf="secondaryButtonText"
      buttonStyle="tertiary"
      [label]="secondaryButtonText"
      (clickFunction)="secondaryButtonFunc()"
    ></zogo-btn>
  </div>
</div>
