import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
})
export class ConfirmationModalComponent implements OnInit {
  headerText: string;
  bodyText: string;
  zoImage: string;
  cancelBtnText: string;
  confirmBtnText: string;
  dashboardGlobal: any;
  confirm: any;
  cancel: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appCopy: CopyService
  ) {}

  ngOnInit(): void {
    this.dashboardGlobal = this.appCopy?.getDashboardGlobal();
    this.headerText = this.data.headerText;
    this.bodyText = this.data.bodyText;
    this.zoImage = this.dashboardGlobal[this.data.zoImage];
    this.cancelBtnText = this.data.cancelBtnText;
    this.confirmBtnText = this.data.confirmBtnText;
    this.confirm = this.data.confirmFunct;
    this.cancel = this.data.cancelFunct;
  }
}
