<div class="side-frame">
  <div class="top-group">
    <img
      [class.pointer-events-disabled]="this.router.url === '/overview'"
      src="global/logos/zogo-blue.svg"
      alt="Go to main page"
      tappable
      (click)="goToOverview()"
      (keydown.enter)="goToOverview()"
      (keydown.space)="goToOverview()"
      tabindex="0"
    />
    <ul class="nav-item-container">
      <li
        class="billing-item caption-book padding-8"
        *ngIf="userData?.institution_user.nav_bar.includes('budget_card')"
        tappable
        (click)="openBillingAlert()"
        (keydown.enter)="openBillingAlert()"
        (keydown.space)="openBillingAlert()"
        tabindex="0"
      >
        {{ userData?.institution.budgeting.current }} Billing
      </li>
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'analytics'"
        *ngIf="
          userData?.institution_user.nav_bar.includes('product_usage') ||
          userData?.institution_user.nav_bar.includes('leaderboards')
        "
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'analytics'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('analytics')"
          (keydown.enter)="toggleDropdown('analytics')"
          (keydown.space)="toggleDropdown('analytics')"
          tabindex="0"
        >
          Analytics
          <zogo-icon
            *ngIf="activeMenuIndex !== 'analytics'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'analytics'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'analytics'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="userData?.institution_user.nav_bar.includes('overview')"
              class="caption-book text-color-shades-primary-1"
              routerLink="overview"
              routerLinkActive="active"
            >
              Engagement
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('pre_post_test')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="pre-post-test"
              routerLinkActive="active"
            >
              Pre/Post Test Results
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('leaderboards')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="leaderboard-download"
              routerLinkActive="active"
            >
              Leaderboards
            </li>
          </ul>
        </section>
      </li>
      <!-- TODO update nav checks -->
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'branding'"
        *ngIf="userData?.institution_user.nav_bar.includes('custom_color')"
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'branding'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('branding')"
          (keydown.enter)="toggleDropdown('branding')"
          (keydown.space)="toggleDropdown('branding')"
          tabindex="0"
        >
          Branding
          <zogo-icon
            *ngIf="activeMenuIndex !== 'branding'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'branding'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'branding'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="
                isAdmin &&
                userData?.institution_user.nav_bar.includes('custom_color')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="custom-color"
              routerLinkActive="active"
            >
              Custom Color
            </li>
            <li
              class="caption-book text-color-shades-primary-1"
              routerLink="custom-logos"
              routerLinkActive="active"
            >
              Logos
            </li>
            <li
              *ngIf="
                isAdmin &&
                userData?.institution_user.nav_bar.includes(
                  'zogo_web_customizations'
                )
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="zogo-web-customizations"
              routerLinkActive="active"
            >
              Zogo Web
            </li>
          </ul>
        </section>
      </li>
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'content'"
        *ngIf="
          userData?.institution_user.nav_bar.includes('content_library') ||
          userData?.institution_user.nav_bar.includes('custom_modules') ||
          userData?.institution_user.nav_bar.includes('custom_surveys')
        "
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'content'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('content')"
          (keydown.enter)="toggleDropdown('content')"
          (keydown.space)="toggleDropdown('content')"
          tabindex="0"
        >
          Content
          <zogo-icon
            *ngIf="activeMenuIndex !== 'content'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'content'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'content'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('content_library')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="content-library"
              routerLinkActive="active"
            >
              Library
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('custom_modules')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="modules"
              routerLinkActive="active"
            >
              Custom Modules
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('custom_surveys')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="custom-surveys"
              routerLinkActive="active"
            >
              Custom Surveys
            </li>
          </ul>
        </section>
      </li>
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'institution'"
        *ngIf="
          userData?.institution_user.nav_bar.includes('my_account') ||
          userData?.institution_user.nav_bar.includes('payments') ||
          userData?.institution_user.nav_bar.includes('team_members') ||
          userData?.institution_user.nav_bar.includes('access_codes')
        "
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'institution'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('institution')"
          (keydown.enter)="toggleDropdown('institution')"
          (keydown.space)="toggleDropdown('institution')"
          tabindex="0"
        >
          My Institution
          <zogo-icon
            *ngIf="activeMenuIndex !== 'institution'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'institution'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'institution'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="userData?.institution_user.nav_bar.includes('my_account')"
              class="caption-book text-color-shades-primary-1"
              routerLink="settings"
              [queryParams]="{ tab: 'team-members' }"
              [class.active]="isActiveMenuLink('settings')"
            >
              Settings
            </li>
            <li
              *ngIf="
                isAdmin &&
                userData?.institution_user.nav_bar.includes('access_codes')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="access-codes"
              routerLinkActive="active"
            >
              Access Codes
            </li>
          </ul>
        </section>
      </li>
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'resources'"
        *ngIf="
          userData?.institution_user.nav_bar.includes('resources') ||
          userData?.institution_user.nav_bar.includes('social_calendar')
        "
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'resources'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('resources')"
          (keydown.enter)="toggleDropdown('resources')"
          (keydown.space)="toggleDropdown('resources')"
          tabindex="0"
        >
          Resources
          <zogo-icon
            *ngIf="activeMenuIndex !== 'resources'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'resources'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'resources'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="userData?.institution_user.nav_bar.includes('resources')"
              class="caption-book text-color-shades-primary-1"
              routerLink="resources"
              routerLinkActive="active"
            >
              Guides
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('social_calendar')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="social-calendar"
              routerLinkActive="active"
            >
              Social Calendar
            </li>
            <li
              *ngIf="
                userData?.institution_user.nav_bar.includes('marketing_assets')
              "
              class="caption-book text-color-shades-primary-1"
              routerLink="marketing-assets"
              [queryParams]="{ tab: marketingAssetPageTab }"
              [class.active]="isActiveMenuLink('marketing-assets')"
            >
              Marketing Assets
            </li>
          </ul>
        </section>
      </li>
      <li
        tappable
        class="nav-item padding-8"
        [class.expanded]="activeMenuIndex === 'support'"
        *ngIf="
          userData?.institution_user.nav_bar.includes('help_center') ||
          userData?.institution_user.nav_bar.includes('contact_us')
        "
      >
        <div
          class="d-flex-row nav-item-header"
          [ngClass]="
            activeMenuIndex === 'support'
              ? 'caption-medium text-color-shades-primary-1'
              : 'caption-book text-color-neutral-800'
          "
          (click)="toggleDropdown('support')"
          (keydown.enter)="toggleDropdown('support')"
          (keydown.space)="toggleDropdown('support')"
          tabindex="0"
        >
          Support
          <zogo-icon
            *ngIf="activeMenuIndex !== 'support'"
            iconName="chevron-down"
            class="text-color-neutral-400"
          ></zogo-icon>
          <zogo-icon
            *ngIf="activeMenuIndex === 'support'"
            iconName="chevron-up"
            class="text-color-shades-primary-1"
          ></zogo-icon>
        </div>
        <section
          *ngIf="activeMenuIndex === 'support'"
          class="d-flex-row gap-12 margin-top-8"
        >
          <div class="left-border"></div>
          <ul class="d-flex-col gap-12">
            <li
              *ngIf="userData?.institution_user.nav_bar.includes('help_center')"
              class="caption-book text-color-shades-primary-1"
              (click)="openHelpCenter()"
              (keydown.enter)="openHelpCenter()"
              (keydown.space)="openHelpCenter()"
            >
              Help Center
            </li>
            <li
              *ngIf="userData?.institution_user.nav_bar.includes('contact_us')"
              class="caption-book text-color-shades-primary-1"
              routerLink="contact-us"
              routerLinkActive="active"
            >
              Contact Us
            </li>
          </ul>
        </section>
      </li>
    </ul>
  </div>
  <div class="bottom-group">
    <div *ngIf="expandInstitutionSelect" class="institution-list">
      <div
        *ngFor="
          let institution of userData.institution_user.institution_options;
          last as isLast
        "
        class="d-flex-col"
        tappable
        (click)="selectInstitution(institution)"
        (keydown.enter)="selectInstitution(institution)"
        (keydown.space)="selectInstitution(institution)"
      >
        <div
          *ngIf="!institution.is_all_institution"
          class="institution-wrapper d-flex-col gap-4 padding-8"
        >
          <div class="d-flex-row institution-justify-between">
            <img
              class="institution-image"
              [src]="institution.image"
              [alt]="institution.short_name"
            />
            <zogo-icon
              *ngIf="
                institution.short_name === userData?.institution.short_name
              "
              class="text-color-neutral-800"
              iconName="check"
              size="xsmall"
            ></zogo-icon>
          </div>
          <p
            class="overline-book text-color-neutral-800"
            [class.overline-medium]="
              institution.short_name === userData?.institution.short_name
            "
          >
            {{ institution.short_name }}
          </p>
        </div>
        <div
          *ngIf="institution.is_all_institution"
          class="institution-wrapper d-flex-row institution-justify-between padding-8"
        >
          <p
            class="overline-book text-color-neutral-800"
            [class.overline-medium]="
              institution.short_name === userData?.institution.short_name
            "
          >
            View all groups
          </p>
          <zogo-icon
            *ngIf="institution.short_name === userData?.institution.short_name"
            class="text-color-neutral-800"
            iconName="check"
            size="xsmall"
          ></zogo-icon>
        </div>
        <div *ngIf="!isLast" class="institution-divider"></div>
      </div>
    </div>
    <div
      *ngIf="isInstitutionGroup"
      class="institution-select institution-justify-between"
      tappable
      (click)="toggleInstitutionSelect()"
      (keydown.enter)="toggleInstitutionSelect()"
      (keydown.space)="toggleInstitutionSelect()"
    >
      <img
        class="institution-image"
        *ngIf="userData?.institution.image"
        [src]="userData?.institution.image"
        [alt]="userData?.institution.short_name"
      />
      <p
        *ngIf="!userData?.institution.image"
        class="overline-book text-color-neutral-800"
      >
        {{ userData?.institution.short_name }}
      </p>
      <zogo-icon
        *ngIf="!expandInstitutionSelect"
        class="dropdown-icon text-color-neutral-500 pointer-events-disabled"
        iconName="list"
        size="small"
      ></zogo-icon>
      <zogo-icon
        *ngIf="expandInstitutionSelect"
        class="dropdown-icon text-color-neutral-500 pointer-events-disabled"
        iconName="x"
        size="small"
      ></zogo-icon>
    </div>
    <div
      *ngIf="!isInstitutionGroup"
      class="institution institution-justify-center"
    >
      <img
        class="institution-image"
        [class.pointer-events-disabled]="!isInstitutionGroup"
        *ngIf="userData?.institution.image"
        [src]="userData?.institution.image"
        [alt]="userData?.institution.short_name"
      />
      <p
        *ngIf="!userData?.institution.image"
        class="overline-book text-color-neutral-800"
      >
        {{ userData?.institution.short_name }}
      </p>
    </div>

    <div class="name-container d-flex-row gap-8">
      <div class="d-flex-col overline-medium">
        <p class="name overline-medium text-color-neutral-800">
          {{ userData?.institution_user.first_name }}
          {{ userData?.institution_user.last_name }}
        </p>
        <p *ngIf="isAdmin" class="text-color-neutral-500">Admin</p>
      </div>
      <div
        tappable
        *ngIf="userData?.institution_user.nav_bar.includes('log_out')"
        class="logout overline-book text-color-neutral-500"
        (click)="req.logout()"
        (keydown.enter)="req.logout()"
        (keydown.space)="req.logout()"
        tabindex="0"
      >
        Log out
      </div>
    </div>
  </div>
</div>
