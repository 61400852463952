import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CopyService } from '../../../copy.service';

@Component({
  selector: 'app-institution-select-dialog',
  templateUrl: './institution-select-dialog.component.html',
  styleUrls: ['./institution-select-dialog.component.css'],
})
export class InstitutionSelectDialogComponent {
  constructor(private dialog: MatDialog, public appCopy: CopyService) {}

  dismiss() {
    this.dialog.closeAll();
  }
}
