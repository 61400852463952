<div class="billing-contact-modal-wrapper">
  <zogo-icon
    class="dismiss-icon text-color-neutral-800"
    iconName="x"
    (click)="dismiss()"
  ></zogo-icon>
  <div class="d-flex-col gap-24">
    <div class="d-flex-col gap-8">
      <zogo-badge *ngIf="showBadge" label="Urgent" type="accent"></zogo-badge>
      <h2 class="h4-bold">{{ headerText }}</h2>
      <p *ngIf="description" class="caption-book">{{ description }}</p>
    </div>
    <form #form="ngForm" (ngSubmit)="onSubmitForm()">
      <div class="d-flex-col gap-12 margin-bottom-24">
        <zogo-text-input
          [(ngModel)]="contactName"
          name="contactName"
          inputId="contact-name"
          inputLabel="Full Name"
          autocompleteType="name"
          ariaLabel="Billing Contact Name"
          [isRequired]="true"
          [currentValue]="contactName"
          #contactNameInput="ngModel"
        ></zogo-text-input>

        <zogo-text-input
          [(ngModel)]="contactEmail"
          name="contactEmail"
          inputId="contact-email"
          inputLabel="Email Address"
          inputType="email"
          autocompleteType="email"
          ariaLabel="Billing Contact Email"
          [isRequired]="true"
          [currentValue]="contactEmail"
          appCustomValidation="email"
          #contactEmailInput="ngModel"
        ></zogo-text-input>
        <div
          *ngIf="
            contactEmailInput.errors?.invalidEmailFormat &&
            contactEmailInput.touched
          "
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Invalid email format</p>
        </div>

        <zogo-text-input
          [(ngModel)]="contactPhone"
          name="contactPhone"
          inputId="contact-phone"
          inputLabel="Phone Number"
          autocompleteType="tel"
          ariaLabel="Billing Contact Phone"
          [isRequired]="true"
          [currentValue]="contactPhone"
          (valueChange)="onPhoneChange($event)"
          #contactPhoneInput="ngModel"
        ></zogo-text-input>
        <div
          *ngIf="hasPhoneInputError && contactPhoneInput.touched"
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Invalid phone number format</p>
        </div>
      </div>
      <div class="d-flex-row gap-8">
        <zogo-btn
          type="submit"
          label="Save Changes"
          [disabled]="form.invalid || hasPhoneInputError"
        ></zogo-btn>
        <zogo-btn
          buttonStyle="tertiary"
          label="Cancel"
          (clickFunction)="dismiss()"
        ></zogo-btn>
      </div>
    </form>
  </div>
</div>
