import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

@Component({
  selector: 'zogo-btn',
  templateUrl: './zogo-btn.component.html',
  styleUrls: ['./zogo-btn.component.scss'],
  standalone: true,
  imports: [CommonModule, ZogoIconComponent],
})
export class ZogoBtnComponent implements OnInit {
  @Input() label: string = '';
  @Input() iconName: string = '';
  @Input() ariaLabel: string = '';
  @Input() fullWidth: boolean = false;
  @Input() disabled: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() type: 'submit' | 'button' = 'button';
  @Input() buttonStyle: 'primary' | 'secondary' | 'tertiary' | 'download' =
    'primary';
  @Input() background: 'light' | 'dark' = 'light';
  @Output() clickFunction = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.ariaLabel) {
      this.ariaLabel = this.label;
    }
  }

  onClick() {
    this.clickFunction.emit();
  }
}
