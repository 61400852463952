import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zogo-badge',
  templateUrl: './zogo-badge.component.html',
  styleUrls: ['./zogo-badge.component.scss'],
  standalone: true,
})
export class ZogoBadgeComponent implements OnInit {
  @Input() label: string = '';
  @Input() ariaLabel: string = '';
  @Input() type:
    | 'success'
    | 'accent'
    | 'warning'
    | 'notice'
    | 'neutral'
    | 'admin' = 'neutral';

  constructor() {}

  ngOnInit() {
    if (!this.ariaLabel) {
      this.ariaLabel = this.label;
    }
  }
}
