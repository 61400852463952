import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/requests.service';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'app-onboarding-header',
  templateUrl: './onboarding-header.component.html',
  styleUrls: ['./onboarding-header.component.scss'],
})
export class OnboardingHeaderComponent implements OnInit {
  currentIndex: number;

  constructor(
    private req: RequestsService,
    public onboarding: OnboardingService
  ) {}

  ngOnInit() {}

  hasPageGroupType(type: string): boolean {
    const pageGroup = this.req.onboardingFlow.find(
      group => group.page_group_type === type
    );
    return pageGroup ? pageGroup.pages.length > 0 : false;
  }

  getGroupProgress(groupType: string): number {
    return this.onboarding.calculateProgress(groupType);
  }
}
