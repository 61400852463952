<div class="skill-card-container" *ngIf="skillCardData">
  <div class="skill-card-header">
    <div class="skill-icon">
      <div
        class="skill-icon-image"
        [attr.role]="'presentation'"
        [attr.alt]="'Decorative skill image'"
        *ngIf="localID"
        [id]="localID"
      ></div>
    </div>
    <div>
      <div class="header-h4-bold primary-text-color">
        {{ skillCardData.topic }}
      </div>
      <div class="body-b2-medium secondary-text-color description-text">
        {{ skillCardData.short_description }}
      </div>
    </div>
  </div>
  <div class="skill-progress">
    <div class="caption-c3-bold primary-app-color">
      {{ skillCardData.modules_completed }}/{{
        skillCardData.modules_total_count
      }}
    </div>
    <div class="progress-width">
      <div class="progress-min"></div>
    </div>
  </div>
  <div class="skill-module-info">
    <div class="body-b2-medium secondary-text-color item-status">
      <svg
        _ngcontent-ng-c1673789492=""
        role="presentation"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          _ngcontent-ng-c1673789492=""
          d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5Z"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M10.5 1.5V6H15"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M12 9.75H6"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M12 12.75H6"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M7.5 6.75H6.75H6"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      {{ skillCardData.modules_total_count }} modules
    </div>
    <div class="body-b2-medium secondary-text-color item-status">
      <svg
        _ngcontent-ng-c1673789492=""
        role="presentation"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          _ngcontent-ng-c1673789492=""
          d="M9.5 16.5C13.6421 16.5 17 13.1421 17 9C17 4.85786 13.6421 1.5 9.5 1.5C5.35786 1.5 2 4.85786 2 9C2 13.1421 5.35786 16.5 9.5 16.5Z"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M9.5 4.5V9L12.5 10.5"
          stroke="#6F6F6F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      {{ skillCardData.estimated_completion_time }} min
    </div>
    <div class="body-b2-medium primary-app-color points-container item-status">
      + {{ skillCardData.reward_value }}
      <svg
        _ngcontent-ng-c1673789492=""
        role="presentation"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="primary-app-color"
      >
        <path
          _ngcontent-ng-c1673789492=""
          d="M0 4.5C0 2.01472 2.01472 0 4.5 0H11.5C13.9853 0 16 2.01472 16 4.5V11.5C16 13.9853 13.9853 16 11.5 16H4.5C2.01472 16 0 13.9853 0 11.5V4.5Z"
          fill="currentColor"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M3.59229 10.1578C3.59229 10.4908 3.85716 10.6951 4.10733 10.6951C4.35749 10.6951 4.50464 10.5816 4.65916 10.3772L5.85111 8.78038L7.05778 10.4075C7.19757 10.5967 7.33737 10.6951 7.58017 10.6951C7.90391 10.6951 8.13936 10.4454 8.13936 10.1199C8.13936 9.95343 8.05843 9.80207 7.97013 9.68854L6.58688 7.94032L7.89656 6.29049C8.00692 6.14669 8.06578 6.01047 8.06578 5.84397C8.06578 5.51098 7.80091 5.30664 7.55074 5.30664C7.30058 5.30664 7.15343 5.42016 6.99891 5.6245L5.88054 7.13811L4.75481 5.59423C4.61501 5.40503 4.47521 5.30664 4.23241 5.30664C3.90867 5.30664 3.67322 5.55639 3.67322 5.88181C3.67322 6.04831 3.75416 6.19967 3.84245 6.31319L5.15212 7.95546L3.76151 9.71125C3.65115 9.85504 3.59229 9.99127 3.59229 10.1578Z"
          fill="white"
        ></path>
        <path
          _ngcontent-ng-c1673789492=""
          d="M9.09558 10.1124C9.09558 10.4378 9.34574 10.6951 9.66212 10.6951C9.97851 10.6951 10.2287 10.4378 10.2287 10.1124V9.0604H11.0895C12.2447 9.0604 13.1718 8.42468 13.1718 7.19865V7.18352C13.1718 6.10129 12.4286 5.35205 11.1999 5.35205H9.66212C9.34574 5.35205 9.09558 5.60936 9.09558 5.93479V10.1124ZM10.2287 8.02357V6.40401H11.1042C11.6708 6.40401 12.024 6.68403 12.024 7.20622V7.22136C12.024 7.67544 11.6929 8.02357 11.1263 8.02357H10.2287Z"
          fill="white"
        ></path>
      </svg>
    </div>
  </div>
</div>
