<app-onboarding-header></app-onboarding-header>
<div class="upload-logo-container d-flex-row gap-24">
  <div class="upload-logo-text-wrapper d-flex-col gap-24">
    <div class="d-flex-col gap-8">
      <h1 class="h3-bold">Upload Logos</h1>
      <p class="body-book">
        Upload 2 versions of your institution's logo: one primary logo with a
        transparent background and one all-white logo with a transparent
        background. We use your logo to promote your institution in our app and
        modules.
      </p>
    </div>

    <div class="d-flex-col gap-8">
      <h2 class="h5-bold">Requirements</h2>
      <ul class="body-book">
        <li>Resolution: minimum height of 1080 px for PNGs</li>
        <li>Orientation: Landscape</li>
        <li>SVG or PNG format, transparent background</li>
        <li>Limit details and small text</li>
        <li>
          If you have multiple orientations to choose from, a 16:9 aspect ratio
          will allow your logo to be displayed most prominently
        </li>
      </ul>
    </div>

    <div class="d-flex-col gap-8">
      <h2 class="h5-bold">Primary</h2>
      <p class="body-book">
        Upload your full-color brand logo without any padding.
      </p>
    </div>
    <zogo-uploader
      label="Upload PNG or SVG"
      caption="Max: 1 MB"
      fileType="image"
      (fileUploaded)="onImageUpload($event, 'primary')"
    ></zogo-uploader>

    <div class="d-flex-col gap-8">
      <h2 class="h5-bold">White</h2>
      <p class="body-book">Upload your white brand logo without any padding.</p>
    </div>
    <zogo-uploader
      label="Upload PNG or SVG"
      caption="Max: 1 MB"
      fileType="image"
      [shouldConvertLogo]="true"
      (fileUploaded)="onImageUpload($event, 'white')"
    ></zogo-uploader>

    <div class="d-flex-row gap-24">
      <zogo-btn
        size="large"
        label="Continue"
        [fullWidth]="true"
        type="submit"
        [disabled]="!hasValidLogos()"
        (clickFunction)="onSubmitLogos()"
      ></zogo-btn>
      <zogo-decorated-link
        [displayOnly]="true"
        class="skip-cta"
        label="Skip for now"
        (click)="handleSkip()"
      ></zogo-decorated-link>
    </div>
  </div>

  <div class="logo-example-container d-flex-col gap-24">
    <div
      class="d-flex-col gap-16 padding-24 background-neutral-100 border-radius-12"
    >
      <h3 class="caption-bold ion-align-items-center">
        Primary & White Example Logos
      </h3>
      <!-- Primary Zogo Logo -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="239"
        height="81"
        viewBox="0 0 239 81"
        fill="none"
      >
        <g clip-path="url(#clip0_2033_14388)">
          <path
            d="M46.871 56.3V39.359H26.611L46.6854 4.0919V1H2.82494V17.8122H21.3527L1 53.4657V56.3H46.871Z"
            fill="#1C64F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.9999 1C62.2168 1 49.4193 13.1248 49.4193 29.9667C49.4193 46.7768 62.2168 58.9333 79.9999 58.9333C97.7196 58.9333 110.581 46.7452 110.581 29.9667C110.581 13.1248 97.7196 1 79.9999 1ZM91.7495 29.9983C91.7495 37.1846 86.8592 42.1232 79.9999 42.1232C73.1407 42.1232 68.2185 37.153 68.2503 29.9983C68.2503 22.8121 73.1407 17.8735 79.9999 17.8735C86.8273 17.8735 91.7495 22.8437 91.7495 29.9983Z"
            fill="#1C64F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M174.29 2.76986V51.8755C174.29 69.2521 162.77 80 144.264 80C128.37 80 117.241 72.0839 114.663 58.9871H133.298C134.637 62.6877 139.434 64.6827 144.101 64.6827C150.563 64.6827 155.753 60.6281 155.753 54.0635V52.4224C151.542 55.061 146.549 56.5736 141.131 56.5736C125.074 56.5736 113.129 44.796 113.129 28.8027C113.129 13.035 125.074 1 141.131 1C147.92 1 154.056 3.41344 158.821 7.40366L161.268 2.76986H174.29ZM144.33 40.9021C151.249 40.9021 156.242 35.8501 156.242 28.8027C156.242 21.82 151.281 16.7678 144.33 16.7678C137.411 16.7678 132.352 21.82 132.352 28.8027C132.352 35.8179 137.346 40.9021 144.33 40.9021Z"
            fill="#1C64F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M208.694 1C191.651 1 179.387 13.1248 179.387 29.9667C179.387 46.7768 191.651 58.9333 208.694 58.9333C225.675 58.9333 238 46.7452 238 29.9667C238 13.1248 225.675 1 208.694 1ZM219.954 29.9983C219.954 37.1846 215.267 42.1232 208.694 42.1232C202.12 42.1232 197.403 37.153 197.433 29.9983C197.433 22.8121 202.12 17.8735 208.694 17.8735C215.237 17.8735 219.954 22.8437 219.954 29.9983Z"
            fill="#1C64F2"
          />
        </g>
        <rect
          x="0.55"
          y="0.55"
          width="237.9"
          height="79.9"
          stroke="#8C8C8C"
          stroke-width="0.9"
          stroke-dasharray="1.8 1.8"
        />
        <defs>
          <clipPath id="clip0_2033_14388">
            <rect x="1" y="1" width="237" height="79" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <!-- White Zogo Logo -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="239"
        height="81"
        viewBox="0 0 239 81"
        fill="none"
      >
        <g clip-path="url(#clip0_2033_14390)">
          <path
            d="M46.871 56.3V39.359H26.611L46.6854 4.0919V1H2.82494V17.8122H21.3527L1 53.4657V56.3H46.871Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.9999 1C62.2168 1 49.4193 13.1248 49.4193 29.9667C49.4193 46.7768 62.2168 58.9333 79.9999 58.9333C97.7196 58.9333 110.581 46.7452 110.581 29.9667C110.581 13.1248 97.7196 1 79.9999 1ZM91.7495 29.9983C91.7495 37.1846 86.8592 42.1232 79.9999 42.1232C73.1407 42.1232 68.2185 37.153 68.2503 29.9983C68.2503 22.8121 73.1407 17.8735 79.9999 17.8735C86.8273 17.8735 91.7495 22.8437 91.7495 29.9983Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M174.29 2.76986V51.8755C174.29 69.2521 162.77 80 144.264 80C128.37 80 117.241 72.0839 114.663 58.9871H133.298C134.637 62.6877 139.434 64.6827 144.101 64.6827C150.563 64.6827 155.753 60.6281 155.753 54.0635V52.4224C151.542 55.061 146.549 56.5736 141.131 56.5736C125.074 56.5736 113.129 44.796 113.129 28.8027C113.129 13.035 125.074 1 141.131 1C147.92 1 154.056 3.41344 158.821 7.40366L161.268 2.76986H174.29ZM144.33 40.9021C151.249 40.9021 156.242 35.8501 156.242 28.8027C156.242 21.82 151.281 16.7678 144.33 16.7678C137.411 16.7678 132.352 21.82 132.352 28.8027C132.352 35.8179 137.346 40.9021 144.33 40.9021Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M208.694 1C191.651 1 179.387 13.1248 179.387 29.9667C179.387 46.7768 191.651 58.9333 208.694 58.9333C225.675 58.9333 238 46.7452 238 29.9667C238 13.1248 225.675 1 208.694 1ZM219.954 29.9983C219.954 37.1846 215.267 42.1232 208.694 42.1232C202.12 42.1232 197.403 37.153 197.433 29.9983C197.433 22.8121 202.12 17.8735 208.694 17.8735C215.237 17.8735 219.954 22.8437 219.954 29.9983Z"
            fill="white"
          />
        </g>
        <rect
          x="0.55"
          y="0.55"
          width="237.9"
          height="79.9"
          stroke="#8C8C8C"
          stroke-width="0.9"
          stroke-dasharray="1.8 1.8"
        />
        <defs>
          <clipPath id="clip0_2033_14390">
            <rect x="1" y="1" width="237" height="79" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div class="d-flex-col gap-8">
        <div class="d-flex-row gap-4">
          <zogo-icon
            class="text-color-shades-green-1"
            iconName="check-circle"
            size="xsmall"
          ></zogo-icon>
          <p class="overline-medium">Landscape</p>
        </div>
        <div class="d-flex-row gap-4">
          <zogo-icon
            class="text-color-shades-green-1"
            iconName="check-circle"
            size="xsmall"
          ></zogo-icon>
          <p class="overline-medium">Trim white space</p>
        </div>
        <div class="d-flex-row gap-4">
          <zogo-icon
            class="text-color-shades-green-1"
            iconName="check-circle"
            size="xsmall"
          ></zogo-icon>
          <p class="overline-medium">Transparent background</p>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col gap-16 padding-24 background-neutral-100 border-radius-12"
    >
      <h3 class="caption-bold ion-align-self-center">Do not use</h3>

      <div class="bad-logo-example-container d-flex-col gap-16">
        <div class="d-flex-col gap-8">
          <div class="logo-background background-neutral-white">
            <img src="global/logos/zogo-blue.svg" alt="" />
          </div>
          <div class="d-flex-row gap-4">
            <zogo-icon
              class="text-color-shades-orange-1"
              iconName="x-circle"
              size="xsmall"
            ></zogo-icon>
            <p class="overline-medium text-color-neutral-600">
              White background
            </p>
          </div>
        </div>
        <div class="d-flex-col gap-8">
          <div class="logo-background background-tints-primary-2">
            <img src="global/logos/zogo-blue.svg" alt="" />
          </div>
          <div class="d-flex-row gap-4">
            <zogo-icon
              class="text-color-shades-orange-1"
              iconName="x-circle"
              size="xsmall"
            ></zogo-icon>
            <p class="overline-medium text-color-neutral-600">
              Colored background
            </p>
          </div>
        </div>
        <div class="d-flex-col gap-8">
          <div class="logo-distorted">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="92"
              height="30"
              viewBox="0 0 92 30"
              fill="none"
            >
              <path
                d="M17.8065 23.25V18.425H9.94184L17.7344 8.3806V7.5H0.708416V12.2883H7.90063L0 22.4428V23.25H17.8065Z"
                fill="#1C64F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.6666 7.5C23.7635 7.5 18.7957 10.9533 18.7957 15.75C18.7957 20.5377 23.7635 24 30.6666 24C37.5452 24 42.5376 20.5287 42.5376 15.75C42.5376 10.9533 37.5452 7.5 30.6666 7.5ZM35.2277 15.759C35.2277 17.8058 33.3293 19.2123 30.6666 19.2123C28.004 19.2123 26.0933 17.7968 26.1056 15.759C26.1056 13.7123 28.004 12.3057 30.6666 12.3057C33.3169 12.3057 35.2277 13.7213 35.2277 15.759Z"
                fill="#1C64F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M67.2688 8.00407V21.9899C67.2688 26.9389 62.7966 30 55.6132 30C49.4434 30 45.1232 27.7454 44.1223 24.0153H51.3564C51.8758 25.0693 53.7382 25.6375 55.5499 25.6375C58.0583 25.6375 60.0727 24.4827 60.0727 22.613V22.1456C58.4384 22.8971 56.5001 23.3279 54.397 23.3279C48.1638 23.3279 43.5269 19.9735 43.5269 15.4185C43.5269 10.9277 48.1638 7.5 54.397 7.5C57.0322 7.5 59.414 8.18737 61.2637 9.32383L62.2139 8.00407H67.2688ZM55.6385 18.8645C58.3244 18.8645 60.2628 17.4256 60.2628 15.4185C60.2628 13.4297 58.3371 11.9908 55.6385 11.9908C52.9527 11.9908 50.989 13.4297 50.989 15.4185C50.989 17.4165 52.9274 18.8645 55.6385 18.8645Z"
                fill="#1C64F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.6236 7.5C74.0082 7.5 69.2473 10.9533 69.2473 15.75C69.2473 20.5377 74.0082 24 80.6236 24C87.2156 24 92 20.5287 92 15.75C92 10.9533 87.2156 7.5 80.6236 7.5ZM84.9946 15.759C84.9946 17.8058 83.1753 19.2123 80.6236 19.2123C78.072 19.2123 76.2409 17.7968 76.2527 15.759C76.2527 13.7123 78.072 12.3057 80.6236 12.3057C83.1635 12.3057 84.9946 13.7213 84.9946 15.759Z"
                fill="#1C64F2"
              />
            </svg>
          </div>
          <div class="d-flex-row gap-4">
            <zogo-icon
              class="text-color-shades-orange-1"
              iconName="x-circle"
              size="xsmall"
            ></zogo-icon>
            <p class="overline-medium text-color-neutral-600">Distorted logo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
