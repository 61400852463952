import { Component, Input } from '@angular/core';
import { RequestsService } from '../requests.service';
import { CopyService } from '../copy.service';

@Component({
  selector: 'app-select-institution',
  templateUrl: './select-institution.component.html',
  styleUrls: ['./select-institution.component.css'],
})
export class SelectInstitutionComponent {
  constructor(public req: RequestsService, public appCopy: CopyService) {}
  @Input() institutionOptions: any;
  selectedIndex: any = null;
  loading: boolean = false;

  handleSelection(index) {
    this.selectedIndex = index;
  }

  selectInstitution() {
    this.loading = true;
    this.req
      .patchDashboardUserInstitution({
        institution_id: this.institutionOptions[this.selectedIndex].id,
      })
      .subscribe({
        next: data => {
          window.location.href = '/';
        },
        error: err => {
          this.loading = false;
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }
}
