import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';
import { Subscription, takeUntil } from 'rxjs';
import { HelperFunctionsService } from 'src/app/design-system/helper-functions.service';

@Component({
  selector: 'app-goals-created-chart',
  templateUrl: './goals-created-chart.component.html',
  styleUrls: ['./goals-created-chart.component.css'],
})
export class GoalsCreatedChartComponent implements OnInit, OnDestroy {
  @Input() widgetData: any;
  goalsCreatedData: any;
  chart: any;
  copy: any;
  data: any;
  goalsCreatedCount: number;
  brandPrimaryHexCode: string;
  brandGreenHexCode: string;
  brandGoldHexCode: string;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  errorLoading: boolean = false;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    private helpers: HelperFunctionsService
  ) {}

  ngOnInit() {
    this.copy = this.appCopy.getOverviewPage();
    this.platform = this.req.overviewPlatformStore;
    this.platformSub = this.req.overviewPlatform.subscribe({
      next: data => {
        this.platform = data;
        this.updateGoalsCreatedData();
      },
      error: err => {},
    });
    this.timeFrame = this.req.overviewTimeFrameStore;
    this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
      next: data => {
        this.timeFrame = data;
        this.updateGoalsCreatedData();
      },
      error: err => {},
    });
    this.communitySegment = this.req.overviewCommunitySegmentStore;
    this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
      next: data => {
        this.communitySegment = data;
        this.updateGoalsCreatedData();
      },
      error: err => {},
    });
    this.updateGoalsCreatedData();
  }

  createChart() {
    this.data = this.goalsCreatedData;
    const root = getComputedStyle(document.documentElement);
    this.brandPrimaryHexCode = root.getPropertyValue('--brand-primary').trim();
    this.brandGreenHexCode = root.getPropertyValue('--brand-green').trim();
    this.brandGoldHexCode = root.getPropertyValue('--brand-gold').trim();

    if (this.chart) {
      this.chart.destroy();
    }
    const goalNames = [];
    const goalData = [];
    this.data.goals.forEach(goal => {
      goalNames.push(goal.goal_name);
      goalData.push(goal.value);
    });

    this.goalsCreatedCount = goalData.reduce((a, b) => a + b, 0);

    this.chart = new Chart('canvas', {
      type: 'doughnut',

      data: {
        labels: goalNames,
        datasets: [
          {
            data: goalData,
            backgroundColor: [
              this.brandPrimaryHexCode,
              this.brandGreenHexCode,
              this.brandGoldHexCode,
            ],
            borderRadius: 5,
            spacing: 2,
            borderColor: '#fff',
            hoverBackgroundColor: [
              this.helpers.adjust(this.brandPrimaryHexCode, -30),
              this.helpers.adjust(this.brandGreenHexCode, -30),
              this.helpers.adjust(this.brandGoldHexCode, -30),
            ],
            hoverBorderColor: '#fff',
          },
        ],
      },
      options: {
        cutout: '80%',
        radius: '55%',
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const dataset = context.dataset;
                const value = dataset.data[context.dataIndex];
                return value.toString() + ' goals created';
              },
              labelColor: function () {
                return {
                  borderColor: '#fff',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  borderRadius: 6,
                };
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }

  updateGoalsCreatedData() {
    this.goalsCreatedData = null;
    this.errorLoading = false;
    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.goalsCreatedData = data;
          setTimeout(() => {
            this.createChart();
          }, 0);
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.platformSub.unsubscribe();
    this.timeFrameSub.unsubscribe();
    this.communitySegmentSub.unsubscribe();
  }
}
