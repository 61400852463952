import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-zogo-label',
  templateUrl: './zogo-label.component.html',
  styleUrls: ['./zogo-label.component.css'],
})
export class ZogoLabelComponent implements OnInit {
  @Input() label: string;
  @Input() ariaLabel: string;
  @Input() type: 'success' | 'warning' | 'notice' | 'neutral' = 'neutral';

  ngOnInit(): void {
    if (!this.ariaLabel) {
      this.ariaLabel = this.label;
    }
  }
}
