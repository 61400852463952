<app-onboarding-header></app-onboarding-header>
<div class="form-container d-flex-col gap-24">
  <h1 class="h3-bold">Your Institution</h1>

  <form #form="ngForm" (ngSubmit)="onSubmitForm()">
    <div class="d-flex-col gap-12 margin-bottom-24">
      <!-- Institution Name -->
      <zogo-text-input
        [(ngModel)]="accountFormData.fullInstName"
        name="fullInstName"
        inputId="institution-name"
        inputLabel="Full Institution Name"
        ariaLabel="Full Institution Name"
        [isRequired]="true"
        [currentValue]="accountFormData.fullInstName"
        #fullInstNameInput="ngModel"
      ></zogo-text-input>
      <div
        *ngIf="fullInstNameInput.invalid && fullInstNameInput.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Fill out this field to continue</p>
      </div>

      <!-- Institution Abbreviation -->
      <zogo-text-input
        [(ngModel)]="accountFormData.preferredAbbr"
        name="preferredInstAbbr"
        inputId="preferred-institution-abbr"
        inputLabel="Preferred Institution Abbreviation"
        ariaLabel="Preferred Institution Abbreviation"
        [isRequired]="true"
        [currentValue]="accountFormData.preferredAbbr"
        #preferredAbbrInput="ngModel"
      ></zogo-text-input>
      <div
        *ngIf="preferredAbbrInput.invalid && preferredAbbrInput.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Fill out this field to continue</p>
      </div>

      <!-- Institution Type -->
      <zogo-dropdown
        [(ngModel)]="accountFormData.institutionType"
        name="institutionType"
        label="Institution Type"
        placeholder="Choose One"
        required
        [options]="institutionTypeOptions"
        #institutionDropdown="ngModel"
      ></zogo-dropdown>

      <div
        *ngIf="institutionDropdown.invalid && institutionDropdown.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Fill out this field to continue</p>
      </div>

      <!-- Website Url -->
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Website URL</p>
        <div class="website-input-wrapper d-flex-row gap-8">
          <p class="caption-medium text-color-neutral-600">https://</p>
          <zogo-text-input
            [(ngModel)]="accountFormData.websiteUrl"
            name="websiteUrl"
            class="website-input"
            formType="inlineInput"
            inputId="website"
            inputLabel="Website URL"
            ariaLabel="Website Url"
            [isRequired]="true"
            [currentValue]="accountFormData.websiteUrl"
            appCustomValidation="website"
            (valueChange)="onWebsiteUrlChange($event)"
            #websiteUrlInput="ngModel"
          ></zogo-text-input>
        </div>
      </div>
      <div
        *ngIf="websiteUrlInput.invalid && websiteUrlInput.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Fill out this field to continue</p>
      </div>
      <div
        *ngIf="websiteUrlInput.errors?.invalidUrl && websiteUrlInput.touched"
        class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
      >
        <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
        <p>Invalid website url</p>
      </div>

      <!-- Access Code (only shown if user has access to Standalone) -->
      <div *ngIf="needsAccessCode" class="d-flex-col gap-8">
        <zogo-text-input
          [(ngModel)]="accountFormData.accessCode"
          name="accessCode"
          inputId="preferred-access-code"
          inputLabel="Preferred Access Code"
          ariaLabel="Preferred Access Code"
          [isRequired]="true"
          [currentValue]="accountFormData.accessCode"
          appCustomValidation="accessCode"
          #accessCodeInput="ngModel"
        ></zogo-text-input>
        <div
          *ngIf="accessCodeInput.invalid && accessCodeInput.touched"
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Fill out this field to continue</p>
        </div>
        <div
          *ngIf="
            accessCodeInput.errors?.invalidAccessCode && accessCodeInput.touched
          "
          class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
        >
          <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
          <p>Invalid access code</p>
        </div>

        <div class="overline-book d-flex-col gap-2">
          <p>Code must be between 4-10 characters.</p>
          <p>Special characters not permitted.</p>
        </div>

        <zogo-help-button
          (clickFunction)="toggleSidebar()"
          label="What is an access code?"
        ></zogo-help-button>
      </div>
    </div>
    <zogo-btn
      type="submit"
      size="large"
      label="Continue"
      [fullWidth]="true"
      [disabled]="form.invalid"
    ></zogo-btn>
  </form>
</div>

<!-- Sidebar -->
<div
  class="sidebar-overlay"
  [class.open]="isSidebarOpen"
  (click)="closeSidebar()"
>
  <div
    class="sidebar"
    [class.open]="isSidebarOpen"
    (click)="$event.stopPropagation()"
  >
    <zogo-icon
      tappable
      class="close-btn"
      iconName="x"
      (click)="closeSidebar()"
    ></zogo-icon>
    <div class="d-flex-col gap-16">
      <h2 class="h3-bold">Access Codes</h2>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">What is an access code?</h3>
        <p class="body-book">
          New users will enter this access code one time during onboarding to
          gain access to your unique sponsored experience.
        </p>
      </div>

      <img
        class="example-access-code"
        src="assets/imgs/example-access-code.png"
        alt="Example Access Code"
      />

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">How should I pick an access code?</h3>
        <p class="body-book">
          We recommend selecting an access code that is concise and clearly
          relates to your institution. The title of your institution is always a
          strong choice.
          <span class="body-medium">For example, “ZOGO” for Zogo Finance.</span>
        </p>
      </div>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">Other guidelines</h3>
        <ul class="body-book margin-left-16">
          <li>
            Access codes can contain letters and numbers, though we recommend
            only using letters.
          </li>
          <li>Access codes can be a maximum of 10 characters.</li>
          <li>
            You will need manual approval from Zogo to change this code later,
            so we advise picking something simple and effective.
          </li>
        </ul>
      </div>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">Example access codes</h3>
        <p class="body-book">
          Rocket Financial:
          <span class="body-medium">“ROCKET”</span>
        </p>
        <p class="body-book">
          Massachusetts Credit Union:
          <span class="body-medium">“MASSCU”</span>
        </p>
        <p class="body-book">
          First Community Bank:
          <span class="body-medium">“FIRST”</span>
        </p>
      </div>
    </div>
  </div>
</div>
