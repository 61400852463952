<div
  tappable
  *ngIf="displayOnly; else anchorBlock"
  class="body-medium d-flex-row gap-4 align-items-center"
  [ngClass]="{
    'dark-link': background === 'light',
    'light-link': background === 'dark' || background === 'highContrast',
    'text-color-brand-primary': background === 'light',
    'text-color-tints-primary-2': background === 'dark',
    'text-color-neutral-white': background === 'highContrast'
  }"
>
  <zogo-icon
    *ngIf="iconLocation === 'leading' && iconName"
    size="small"
    [iconName]="iconName"
  ></zogo-icon>
  {{ label }}
  <zogo-icon
    *ngIf="iconLocation === 'tracking' && iconName"
    size="small"
    [iconName]="iconName"
  ></zogo-icon>
</div>

<ng-template #anchorBlock>
  <a
    [routerLink]="[destinationSrc]"
    role="button"
    class="link body-medium d-flex-row gap-4 ion-align-items-center"
    [ngClass]="{
      'dark-link': background === 'light',
      'light-link': background === 'dark' || background === 'highContrast',
      'text-color-brand-primary': background === 'light',
      'text-color-tints-primary-2': background === 'dark',
      'text-color-neutral-white': background === 'highContrast'
    }"
  >
    <zogo-icon
      *ngIf="iconLocation === 'leading' && iconName"
      size="small"
      [iconName]="iconName"
    ></zogo-icon>
    {{ label }}
    <zogo-icon
      *ngIf="iconLocation === 'tracking' && iconName"
      size="small"
      [iconName]="iconName"
    ></zogo-icon>
  </a>
</ng-template>
