import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { RequestsService } from '../../requests.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-new-user-onboarding',
  templateUrl: './new-user-onboarding.component.html',
  styleUrls: ['./new-user-onboarding.component.css'],
})
export class NewUserOnboardingComponent implements OnInit {
  passwordData = {
    password: '',
    confirmPassword: '',
  };
  hasAtLeast8Chars: boolean = false;
  hasUppercase: boolean = false;
  hasSpecial: boolean = false;
  hasNumber: boolean = false;
  passwordsMatch: boolean = false;
  tempToken: string = '';
  userData: any;

  constructor(
    public onboarding: OnboardingService,
    public req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.tempToken = localStorage.getItem('tempToken');
  }

  onPasswordChange(password: string) {
    if (password.replace(/[^A-Z]/g, '').length > 0) {
      this.hasUppercase = true;
    } else {
      this.hasUppercase = false;
    }

    if (password.length >= 8) {
      this.hasAtLeast8Chars = true;
    } else {
      this.hasAtLeast8Chars = false;
    }

    if (this.specialSearch(password)) {
      this.hasSpecial = true;
    } else {
      this.hasSpecial = false;
    }

    if (this.numberSearch(password)) {
      this.hasNumber = true;
    } else {
      this.hasNumber = false;
    }
  }

  onConfirmPasswordChange(password: string) {
    if (password === this.passwordData.password) {
      this.passwordsMatch = true;
    } else {
      this.passwordsMatch = false;
    }
  }

  enableSubmit() {
    if (
      this.hasUppercase &&
      this.hasAtLeast8Chars &&
      this.hasSpecial &&
      this.hasNumber &&
      this.passwordsMatch
    ) {
      return true;
    }
    return false;
  }

  onSubmitPassword() {
    this.req
      .resetPassword(
        this.passwordData.password,
        this.passwordData.confirmPassword,
        this.tempToken
      )
      .subscribe({
        next: data => {
          // Amplitude event - Initial Password Created
          this.req.ampTrack('Initial Password Created');
          localStorage.setItem('token', data.token);
          localStorage.setItem('userData', JSON.stringify(data));
          this.req.checkToken();
          this.userData = data;
          window.location.href = '/';
        },
        error: err => {
          this.toastService.showToast(
            'There was an error: ' + err.error.message,
            6000
          );
          console.log(err);
        },
      });
  }

  specialSearch(str) {
    return /[~`!@#$%^&*+=()\-_[\]\\';,./{}|\\":<>?]/g.test(str);
  }

  numberSearch(str) {
    return /\d/.test(str);
  }
}
