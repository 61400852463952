<div id="zip-code-container" class="d-flex-row gap-24">
  <div class="text-wrapper d-flex-col gap-24">
    <div class="d-flex-col gap-8">
      <p class="body-book">
        Provide a list of all 5-digit zip codes where you would like the
        co-branded version of your app to appear.
      </p>

      <zogo-help-button
        label="How are zip codes used?"
        (clickFunction)="toggleSidebar()"
      ></zogo-help-button>
    </div>

    <div class="d-flex-col gap-8">
      <h2 class="h5-bold">File Requirements:</h2>
      <ul class="body-book margin-left-16">
        <li>File format must be .csv</li>
        <li>One zip code per cell</li>
        <li>
          Zip codes can <span class="body-medium">not</span> have spaces or
          non-numeric characters
        </li>
      </ul>
    </div>

    <div class="d-flex-col gap-8">
      <div
        *ngIf="approvalStatus !== 'initial'"
        class="d-flex-row gap-8 ion-align-items-center"
      >
        <zogo-badge [label]="approvalStatus" [type]="statusType"></zogo-badge>
        <p
          class="overline-book"
          [ngClass]="{
           'text-color-shades-gold-1': statusType === 'notice',
           'text-color-utility-red-shade-1': statusType === 'warning',
           'text-color-shades-green-1': statusType === 'success',
         }"
        >
          {{ statusMessage }}
        </p>
      </div>
      <zogo-uploader
        label="Upload Zip Code List"
        caption="Max: 1 MB"
        fileType="csv"
        [file]="file"
        [fileName]="fileName"
        [fileSize]="fileSize"
        [hasExistingData]="hasExistingCsvData"
        [lastModified]="lastModified"
        (fileUploaded)="onCsvUpload($event)"
        (downloadFunction)="downloadDashboardZipCodes()"
      ></zogo-uploader>
    </div>

    <ion-spinner *ngIf="isLoading" name="circular"></ion-spinner>
    <zogo-btn
      *ngIf="!isLoading"
      [disabled]="!zipCodes"
      size="large"
      label="Submit"
      (clickFunction)="onSubmitCsvRequest()"
    ></zogo-btn>
  </div>

  <div
    class="d-flex-col gap-16 padding-24 background-neutral-100 border-radius-12"
  >
    <h3 class="caption-bold ion-align-items-center">
      Formatting for CSV Files
    </h3>
    <table>
      <thead>
        <tr>
          <th class="caption-medium" scope="col">Zip Code</th>
        </tr>
      </thead>
      <tbody>
        <tr class="data-row">
          <td class="caption-medium">98023</td>
        </tr>
        <tr class="data-row">
          <td class="caption-medium">98104</td>
        </tr>
        <tr class="data-row">
          <td class="caption-medium">93203</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex-col gap-8">
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-green-1"
          iconName="check-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">
          One zip code per cell
        </p>
      </div>
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-green-1"
          iconName="check-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">No spaces</p>
      </div>
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-green-1"
          iconName="check-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">
          No non-numeric characters
        </p>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th class="caption-medium" scope="col">Zip Code</th>
        </tr>
      </thead>
      <tbody>
        <tr class="data-row">
          <td class="caption-medium">!#90023</td>
        </tr>
        <tr class="data-row">
          <td class="caption-medium">9 3031</td>
        </tr>
        <tr class="data-row">
          <td class="caption-medium">98104, 19340, 83493</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex-col gap-8">
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-orange-1"
          iconName="x-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">
          Non-numeric characters in zip codes
        </p>
      </div>
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-orange-1"
          iconName="x-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">
          Spaces in zip codes
        </p>
      </div>
      <div class="d-flex-row gap-4">
        <zogo-icon
          class="text-color-shades-orange-1"
          iconName="x-circle"
          size="xsmall"
        ></zogo-icon>
        <p class="overline-medium text-color-neutral-700">
          Multiple zip codes per cell
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Sidebar -->
<div
  class="sidebar-overlay"
  [class.open]="isSidebarOpen"
  (click)="closeSidebar()"
>
  <div
    class="sidebar"
    [class.open]="isSidebarOpen"
    (click)="$event.stopPropagation()"
  >
    <zogo-icon
      tappable
      class="close-btn"
      iconName="x"
      (click)="closeSidebar()"
    ></zogo-icon>
    <div class="d-flex-col gap-16">
      <h2 class="h3-bold">Zip Codes</h2>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">What are zip codes used for?</h3>
        <p class="body-book">
          The zip codes you upload are used to determine which users are
          eligible for your institution to sponsor without an access code. When
          you sponsor a user, any rewards that user redeems will be taken out of
          your rewards budget.
        </p>
      </div>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">How many zip codes should I upload?</h3>
        <div class="d-flex-col gap-16">
          <p class="body-book">
            The zip code sponsorship area's you choose can be updated at any
            time. We suggest starting with a list that includes zip codes within
            a five mile radius of any current branch locations.
          </p>
          <p class="body-book">
            Please note: Any zip code area you sponsor will be eligible for in
            app reward redemption.
          </p>
        </div>
      </div>

      <div class="d-flex-col gap-4">
        <h3 class="h5-bold">What if I don’t upload any zip codes?</h3>
        <p class="body-book">
          If you don’t upload any zip codes, users will only be able to access
          your sponsored experience via an access code.
        </p>
      </div>
    </div>
  </div>
</div>
