import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MarkdownComponent } from 'ngx-markdown';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import {
  ZogoBadgeComponent,
  ZogoBtnComponent,
  ZogoCheckboxComponent,
} from 'src/app/design-system/web-components';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ZogoBtnComponent,
    ZogoIconComponent,
    ZogoCheckboxComponent,
    ZogoBadgeComponent,
    MarkdownComponent,
  ],
})
export class AlertModalComponent implements OnInit {
  @Input() header?: string;
  @Input() description?: string;
  @Input() showDismiss?: boolean = true;
  @Input() primaryButtonText?: string;
  @Input() primaryButtonFunc?: any;
  @Input() secondaryButtonText?: string;
  @Input() secondaryButtonFunc?: any;
  @Input() dontShowAgainToken?: any;
  @Input() confirmationText?: any;
  @Input() showBadge?: boolean = false;

  dontShowAgain: boolean = false;
  hasConfirmed: boolean = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }

  handleDontShowThisAgain() {
    localStorage.setItem(this.dontShowAgainToken, 'true');
  }

  toggleDontShowAgain(event) {
    this.dontShowAgain = event;
  }

  toggleConfirmation(confirmed: boolean) {
    this.hasConfirmed = confirmed;
  }

  async handlePrimaryButtonFunc() {
    await this.primaryButtonFunc();
    if (this.dontShowAgain) {
      this.handleDontShowThisAgain();
    }
  }
}
