import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.css'],
})
export class IconSelectComponent implements OnInit {
  previewImage: any;
  constructor(
    private req: RequestsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.previewImage = this.dialogData?.activeIcon;
  }

  addIcon(image: string) {
    this.dialogData.activeIcon = image;
    this.dialogData.confirmFunct(image);
  }

  changePreviewImage(image) {
    this.previewImage = image;
  }
}
