import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CopyService } from 'src/app/copy.service';
import { RequestsService } from 'src/app/requests.service';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-highlights-modal',
  templateUrl: './highlights-modal.component.html',
  styleUrls: ['./highlights-modal.component.css'],
})
export class HighlightsModalComponent implements OnInit {
  highlightTitle: any;
  highlightDescription: any;
  highlightImage: any;
  websiteUrl: any;
  cancel: any;
  copy: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appCopy: CopyService,
    public req: RequestsService
  ) {}

  ngOnInit() {
    this.copy = this.appCopy.getOverviewPage();
    this.highlightTitle = this.data.highlightTitle;
    this.highlightDescription = this.data.highlightDescription;
    this.highlightImage = this.data.highlightImage;
    this.websiteUrl = this.data.websiteUrl;
  }

  openArticleLink() {
    window.open(this.websiteUrl, '_blank');
  }
}
