<div class="dashboard-view">
  <div class="container-fluid p-0">
    <div class="row align-items-center">
      <div class="col-md-7 d-flex align-items-center add-header">
        <svg
          class="tappable back-btn"
          (click)="handleBack()"
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.49012e-08 7C1.49012e-08 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 1.49012e-08 7.55228 1.49012e-08 7Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z"
          />
        </svg>
        <div class="h3-medium text-color-neutral-800">
          <div
            *ngIf="
              this.originalQuestion?.question['en-US'].length > 0;
              then edit;
              else add
            "
          ></div>
          <ng-template #edit>Edit Survey Question</ng-template>
          <ng-template #add>Add Survey Question</ng-template>
        </div>
      </div>
      <div class="col-md-5 text-right">
        <div class="d-flex justify-content-end">
          <zogo-btn
            class="mr-1"
            label="Save as Draft"
            size="medium"
            [fullWidth]="false"
            buttonStyle="secondary"
            (clickFunction)="handleSaveAsDraft()"
          ></zogo-btn>
          <zogo-btn
            class="ml-1"
            label="Submit for Approval"
            size="medium"
            [fullWidth]="false"
            buttonStyle="primary"
            [disabled]="!validateForm()"
            (clickFunction)="handleSubmitForApproval()"
          ></zogo-btn>
        </div>
      </div>
    </div>
  </div>
  <div class="content-area">
    <div class="container-fluid p-0">
      <div class="row mt-1">
        <div class="col-md-8">
          <div class="mb-4">
            <span
              class="tappable h5-medium text-color-neutral-600"
              [ngClass]="{
                'h5-bold': questionEditorView === 'english',
                'text-color-brand-primary': questionEditorView === 'english',
                'selected-underline': questionEditorView === 'english'
              }"
              (click)="handleLanguageChange('english')"
              >English</span
            >
            <span
              class="tappable ml-3 h5-medium text-color-neutral-600"
              [ngClass]="{
                'h5-bold': questionEditorView === 'spanish',
                'text-color-brand-primary': questionEditorView === 'spanish',
                'selected-underline': questionEditorView === 'spanish'
              }"
              (click)="handleLanguageChange('spanish')"
              >Spanish (Optional)</span
            >
          </div>
          <div class="card card-frame">
            <div class="form-content">
              <div class="d-flex align-items-center mb-3">
                <div class="body-medium text-color-neutral-700">Status:</div>
                <div
                  [ngSwitch]="this.originalQuestion?.status.value"
                  class="ml-2"
                >
                  <app-zogo-label
                    *ngSwitchCase="'inactive'"
                    type="neutral"
                    [label]="this.originalQuestion?.status.display"
                  ></app-zogo-label>
                  <app-zogo-label
                    *ngSwitchCase="'pending'"
                    type="notice"
                    [label]="this.originalQuestion?.status.display"
                  ></app-zogo-label>
                  <app-zogo-label
                    *ngSwitchCase="'active'"
                    type="success"
                    [label]="this.originalQuestion?.status.display"
                  ></app-zogo-label>
                </div>
              </div>
              <app-field-text-input
                class="mb-3"
                inputId="question"
                inputTitle="Question"
                [maxLength]="125"
                [isRequired]="true"
                [isEditable]="true"
                wrap="soft"
                *ngIf="this.questionEditorView === 'english'"
                [value]="this.questionData.question['en-US']"
                (valueChange)="handleQuestionInput($event)"
              ></app-field-text-input>
              <app-field-text-input
                class="mb-3"
                inputId="question"
                inputTitle="Question"
                [maxLength]="125"
                [isRequired]="true"
                [isEditable]="true"
                wrap="soft"
                *ngIf="this.questionEditorView === 'spanish'"
                [preview]="this.questionData.question['en-US']"
                [value]="this.questionData.question['es-US']"
                (valueChange)="handleQuestionInput($event)"
              ></app-field-text-input>
              <app-field-text-input
                class="mb-3"
                inputId="description"
                inputTitle="Question Description"
                [maxLength]="125"
                [isRequired]="false"
                [isEditable]="true"
                wrap="soft"
                *ngIf="this.questionEditorView === 'english'"
                [value]="this.questionData.description['en-US']"
                (valueChange)="handleQuestionDescriptionInput($event)"
              ></app-field-text-input>
              <app-field-text-input
                class="mb-3"
                inputId="description"
                inputTitle="Question Description"
                [maxLength]="125"
                [isRequired]="false"
                [isEditable]="true"
                wrap="soft"
                *ngIf="this.questionEditorView === 'spanish'"
                [preview]="this.questionData.description['en-US']"
                [value]="this.questionData.description['es-US']"
                (valueChange)="handleQuestionDescriptionInput($event)"
              ></app-field-text-input>
              <div class="h5-medium text-color-neutral-800">
                Multiple Choice Answers<span class="text-color-shades-orange-1"
                  >*</span
                >
              </div>
              <div class="overline-medium text-color-neutral-800">
                You must create between two and six answers.
              </div>
              <div class="answer-choice-container">
                <div
                  *ngFor="let answer of this.questionData?.answers; index as i"
                >
                  <div class="answer-choice-row">
                    <div class="body-medium text-color-neutral-500">
                      {{ i + 1 }}
                    </div>
                    <app-field-text-input
                      class="answer-choice-field"
                      [inputId]="'answer' + i"
                      inputTitle="Answer"
                      [maxLength]="125"
                      [isRequired]="false"
                      [isEditable]="true"
                      *ngIf="this.questionEditorView === 'english'"
                      [value]="this.questionData?.answers[i]['en-US']"
                      (valueChange)="handleAnswerChoiceInput($event, i)"
                      wrap="soft"
                    ></app-field-text-input>
                    <app-field-text-input
                      class="answer-choice-field"
                      [inputId]="'answer' + i"
                      inputTitle="Answer"
                      [maxLength]="125"
                      [isRequired]="false"
                      [isEditable]="true"
                      [preview]="this.questionData?.answers[i]['en-US']"
                      *ngIf="this.questionEditorView === 'spanish'"
                      [value]="this.questionData?.answers[i]['es-US']"
                      (valueChange)="handleAnswerChoiceInput($event, i)"
                      wrap="soft"
                    ></app-field-text-input>
                    <div class="remove-row-btn">
                      <svg
                        class="tappable delete-icon"
                        matTooltip="Remove"
                        *ngIf="
                          this.questionData?.answers?.length > 2 &&
                          this.questionEditorView === 'english'
                        "
                        (click)="handleDeleteAnswerChoice(i)"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.25 4.5H3.75H15.75"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 2.25C7.08579 2.25 6.75 2.58579 6.75 3V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V3C5.25 1.75736 6.25736 0.75 7.5 0.75H10.5C11.7426 0.75 12.75 1.75736 12.75 3V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V3C11.25 2.58579 10.9142 2.25 10.5 2.25H7.5Z"
                        />
                        <path
                          d="M3.75 3.75C4.16421 3.75 4.5 4.08579 4.5 4.5V15C4.5 15.4142 4.83579 15.75 5.25 15.75H12.75C13.1642 15.75 13.5 15.4142 13.5 15V4.5C13.5 4.08579 13.8358 3.75 14.25 3.75C14.6642 3.75 15 4.08579 15 4.5V15C15 16.2426 13.9926 17.25 12.75 17.25H5.25C4.00736 17.25 3 16.2426 3 15V4.5C3 4.08579 3.33579 3.75 3.75 3.75Z"
                        />
                        <path
                          d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                        />
                        <path
                          d="M11.25 8.25C11.25 7.83579 10.9142 7.5 10.5 7.5C10.0858 7.5 9.75 7.83579 9.75 8.25V12.75C9.75 13.1642 10.0858 13.5 10.5 13.5C10.9142 13.5 11.25 13.1642 11.25 12.75V8.25Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  class="add-another-btn tappable"
                  *ngIf="
                    this.questionData?.answers?.length < 6 &&
                    this.questionEditorView === 'english'
                  "
                  (click)="handleAddAnswerChoice()"
                >
                  <svg
                    class="add-icon"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8333 4.16683C10.8333 3.70659 10.4602 3.3335 9.99998 3.3335C9.53974 3.3335 9.16665 3.70659 9.16665 4.16683V9.16683H4.16665C3.70641 9.16683 3.33331 9.53993 3.33331 10.0002C3.33331 10.4604 3.70641 10.8335 4.16665 10.8335H9.16665V15.8335C9.16665 16.2937 9.53974 16.6668 9.99998 16.6668C10.4602 16.6668 10.8333 16.2937 10.8333 15.8335V10.8335H15.8333C16.2936 10.8335 16.6666 10.4604 16.6666 10.0002C16.6666 9.53993 16.2936 9.16683 15.8333 9.16683H10.8333V4.16683Z"
                    />
                  </svg>
                  <div class="body-bold text-color-neutral-600">
                    Add Another
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="h5-medium text-color-shades-orange-1 tappable mt-4"
            (click)="handleDelete()"
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
