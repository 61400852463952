<div class="geckoboard-frame">
  <h4 class="h5-bold text-color-neutral-800 geckoboard-title">
    {{ geckoboardDisplayData.widget_display_name }}
  </h4>
  <iframe
    width="100%"
    height="740px"
    [src]="
      sanitizer.bypassSecurityTrustResourceUrl(geckoboardDisplayData.source)
    "
  ></iframe>
</div>
