import screen1 from './screen1';
import screen2 from './screen2';
import screen3 from './screen3';
import screen4 from './screen4';
import screen5 from './screen5';
import screen6 from './screen6';

const previewScreens = [screen1, screen2, screen3, screen4, screen5, screen6];

export default previewScreens;
