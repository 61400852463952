import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notif-alert',
  templateUrl: './notif-alert.component.html',
  styleUrls: ['./notif-alert.component.css'],
})
export class NotifAlertComponent implements OnInit {
  @Input() status: string;
  @Input() message: string;
  @Input() dismiss: any;
  @Input() buttonText: string;
  @Input() buttonFunc: any;

  constructor() {}

  ngOnInit(): void {}
}
