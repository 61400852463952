export const TEST_DATA = {
  dashboard_info: {
    tabs: [
      {
        tab_name: 'All Platforms',
        platform: 'all',
        sub_tabs: null,
        widget_groups: [
          {
            source_type: 'group',
            source: 'number-cards', //the end of the path where the base url is https://api.zogo.com/{env}/{version}/dashboard/overview/
            widgets: [
              {
                widget_type: 'number_display',
                widget_display_name: 'users',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Hours spent learning',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name:
                  'Reported improvement in financial literacy',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Avgerage activities completed per user',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Activities completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Redeemed',
                source: null,
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'progress_bars',
                widget_display_name: 'User Interests',
                source: 'user-interests',
              },
            ],
          },
        ],
      },
      {
        tab_name: 'Standalone',
        platform: 'native',
        sub_tabs: null,
        widget_groups: [
          {
            source_type: 'group',
            source: 'number-cards',
            widgets: [
              {
                widget_type: 'number_display',
                widget_display_name: 'users',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Hours spent learning',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Modules completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Referrals',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Average activities completed per user',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Activities completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Pineapple parties completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Redeemed',
                source: null,
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'user_demographics',
                widget_display_name: 'User Demographics',
                source: 'user-demographics',
              },
              {
                widget_type: 'goals',
                widget_display_name: 'Goals Created',
                source: 'goals',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'progress_bars',
                widget_display_name: 'User Interests',
                source: 'user-interests',
              },
              {
                widget_type: 'progress_bars',
                widget_display_name: 'Users by Zip Code',
                source: 'user-zip-codes',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'survey_results',
                widget_display_name: 'Survey Questions',
                source: 'survey-results',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'user_messages',
                widget_display_name: 'User Messages',
                source: 'user-messages',
              },
            ],
          },
        ],
      },
      {
        tab_name: 'Mobile Banking',
        platform: 'mobile_banking',
        sub_tabs: null,
        widget_groups: [
          {
            source_type: 'group',
            source: 'number-cards',
            widgets: [
              {
                widget_type: 'number_display',
                widget_display_name: 'users',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Hours spent learning',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Average activities completed per user',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Activities completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Modules completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Pineapple parties completed',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Redeemed',
                source: null,
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'table',
                widget_display_name: 'Objective Metrics',
                source: 'objective-metrics',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'user_data_reports',
                widget_display_name: 'User Data Reports',
                source: 'user-data-reports',
              },
            ],
          },
        ],
      },
      {
        tab_name: 'Zogo Web',
        platform: 'enterprise_web',
        sub_tabs: [
          {
            sub_tab_name: 'Default view',
            platform: 'enterprise_web',
            widget_groups: [
              {
                source_type: 'group',
                source: 'number-cards',
                widgets: [
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'users',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Hours spent learning',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Average modules completed per user',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Modules completed',
                    source: null,
                  },
                ],
              },
              {
                source_type: 'individual',
                source: null,
                widgets: [
                  {
                    widget_type: 'progress_bar',
                    widget_display_name: 'User Interests',
                    source: 'user-interests',
                  },
                ],
              },
              {
                source_type: 'individual',
                source: null,
                widgets: [
                  {
                    widget_type: 'link_display',
                    widget_display_name: 'Zogo Web Link Display',
                    source: 'https://usbank.zogo.com',
                  },
                ],
              },
            ],
          },
          {
            sub_tab_name: 'Classroom',
            platform: 'classroom_for_enterprise_web',
            widget_groups: [
              {
                source_type: 'group',
                source: 'number-cards',
                widgets: [
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'students',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Hours spent learning',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Average modules completed per user',
                    source: null,
                  },
                  {
                    widget_type: 'number_display',
                    widget_display_name: 'Modules completed',
                    source: null,
                  },
                ],
              },
              {
                source_type: 'individual',
                source: null,
                widgets: [
                  {
                    widget_type: 'link_display',
                    widget_display_name: 'Classroom Link Display',
                    source: 'https://usbank.zogofinance.com/student-login',
                  },
                ],
              },
              {
                source_type: 'individual',
                source: null,
                widgets: [
                  {
                    widget_type: 'table',
                    widget_display_name: 'Classroom Overview',
                    source: 'class-overview',
                  },
                ],
              },
            ],
          },
        ],
        widget_groups: null,
      },
      {
        tab_name: 'Custom Integrations',
        platform: 'sandbox',
        sub_tabs: null,
        widget_groups: [
          {
            source_type: 'group',
            source: 'number-cards',
            widgets: [
              {
                widget_type: 'number_display',
                widget_display_name: 'Users',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Hours spent learning',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Average modules completed per user',
                source: null,
              },
              {
                widget_type: 'number_display',
                widget_display_name: 'Modules completed',
                source: null,
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'top_skills',
                widget_display_name: 'Top Ten Skills',
                source: 'sandbox-top-skills',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'table',
                widget_display_name: 'Deep Linked Modules',
                source: 'deep-linked-modules',
              },
            ],
          },
          {
            source_type: 'individual',
            source: null,
            widgets: [
              {
                widget_type: 'geckoboard',
                widget_display_name: 'Survey Statistics',
                source:
                  'https://share.geckoboard.com/dashboards/2QJVOMQFYNHZ3J3P',
              },
            ],
          },
        ],
      },
    ],
  },
  overview_number_cards: {
    //widgets sent in display order
    widgets: [
      {
        widget_type: 'number_display',
        widget_display_name: 'users',
        data: {
          value: '32,323',
          value_change_text: null, //I don't like this
          percent_change: null, //can be null
          change_direction: null, //can be positive or null
          time_frame_text: null, //can be null
          data_platform_text: null,
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'users',
        data: {
          value: '32,323',
          value_change_text: '-3 users', //I don't like this
          percent_change: 15, //can be null
          change_direction: 'negative', //can be positive or null
          time_frame_text: 'From the last 30 days', //can be null
          data_platform_text: null,
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'Hours Spent Learning',
        data: {
          value: '129,201',
          value_change_text: '+10 hours', //I don't like this
          percent_change: 10, //can be null
          change_direction: 'positive', //can be positive or null
          time_frame_text: 'From the last 30 days', //can be null
          data_platform_text: null,
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'Redeemed',
        data: {
          value: '$1,234',
          value_change_text: '+$3', //I don't like this
          percent_change: 5, //can be null
          change_direction: 'positive', //can be positive or null
          time_frame_text: 'From the last 30 days', //can be null
          data_platform_text: 'data from standalone',
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'users',
        data: {
          value: '32,323',
          value_change_text: null, //I don't like this
          percent_change: null, //can be null
          change_direction: null, //can be positive or null
          time_frame_text: null, //can be null
          data_platform_text: null,
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'Reported improvement in financial literacy',
        data: {
          value: '32,323',
          value_change_text: '-3 users', //I don't like this
          percent_change: 15, //can be null
          change_direction: 'negative', //can be positive or null
          time_frame_text: 'From the last 30 days', //can be null
          data_platform_text: null,
        },
      },
      {
        widget_type: 'number_display',
        widget_display_name: 'Reported improvement in financial literacy',
        data: {
          value: '129,201',
          value_change_text: '+10 hours', //I don't like this
          percent_change: 10, //can be null
          change_direction: 'positive', //can be positive or null
          time_frame_text: 'From the last 30 days', //can be null
          data_platform_text: null,
        },
      },
    ],
  },
  overview_user_interests: {
    value_display_type: 'percent',
    max_value: 100,
    data_platform_text: 'data from standalone', //can be null
    progress_bar_data: [
      {
        item_name: 'Savings and Spendings',
        value: 50,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Insurance',
        value: 20,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Housing and Transportation',
        value: 10,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Education',
        value: 10,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Careers',
        value: 10,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Taxes',
        value: 5,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
      {
        item_name: 'Information and Security',
        value: 5,
        popup_header: '36722 Users',
        popup_items: [
          '<18 - 40%',
          '18-24 - 50%',
          '25-40 - 8%',
          '>40 - 1%',
          'Age unknown - 1%',
        ],
      },
    ],
  },
  overview_user_zip_codes: {
    value_display_type: 'value',
    max_value: 570, //highest value in list when display type is value
    data_platform_text: null, //can be null
    progress_bar_data: [
      {
        item_name: '23680',
        value: 570,
        popup_header: null,
        popup_items: null,
      },
      {
        item_name: '23456',
        value: 480,
        popup_header: null,
        popup_items: null,
      },
      {
        item_name: '12345',
        value: 250,
        popup_header: null,
        popup_items: null,
      },
    ],
  },
  overview_user_demographics: {
    tabs: [
      {
        tab_name: 'Age',
        tab_header: 'User Demographics',
        tab_sub_header: 'User breakdown by age',
        value_display_type: 'percent',
        data: [
          {
            item_name: '<18',
            value: 95,
            popup_header: 'Age <18',
            popup_items: ['643 Users'],
          },
          {
            item_name: '18-24',
            value: 25,
            popup_header: 'Age 18-24',
            popup_items: ['643 Users'],
          },
          {
            item_name: '25-40',
            value: 10,
            popup_header: 'Age 25-40',
            popup_items: ['643 Users'],
          },
          {
            item_name: '41+',
            value: 5,
            popup_header: 'Age 41+',
            popup_items: ['643 Users'],
          },
        ],
      },
      {
        tab_name: 'Activity',
        tab_header: 'User Activity',
        tab_sub_header: 'Total time spent learning by age',
        value_display_type: 'percent',
        data: [
          {
            item_name: '<18',
            value: 30,
            popup_header: 'Age <18',
            popup_items: ['643 Hours'],
          },
          {
            item_name: '18-24',
            value: 20,
            popup_header: 'Age 18-24',
            popup_items: ['643 Hours'],
          },
          {
            item_name: '25-40',
            value: 40,
            popup_header: 'Age 25-40',
            popup_items: ['643 Hours'],
          },
          {
            item_name: '41+',
            value: 10,
            popup_header: 'Age 41+',
            popup_items: ['643 Hours'],
          },
        ],
      },
    ],
  },
  overview_user_goals: {
    goals: [
      {
        goal_name: 'Reduce Expenses',
        value: 1429,
      },
      {
        goal_name: 'Save Money',
        value: 1705,
      },
      {
        goal_name: 'Start Investing',
        value: 6517,
      },
    ],
  },
  overview_deep_linked_modules: {
    table: {
      columns: [
        {
          column_key: 'module_name',
          column_text: 'module',
          column_width: 0.44, //percent of table column should take up
          column_alignment: 'left', // left | right | center
        },
        {
          column_key: 'accuracy',
          column_text: 'accuracy',
          column_width: 0.24, //percent of table column should take up
          column_alignment: 'center', // left | right | center
        },
        {
          column_key: 'started_count',
          column_text: 'started',
          column_width: 0.16, //percent of table column should take up
          column_alignment: 'center', // left | right | center
        },
        {
          column_key: 'completed_count',
          column_text: 'completed',
          column_width: 0.16, //percent of table column should take up
          column_alignment: 'right', // left | right | center
        },
      ],
      rows: [
        {
          module_name: {
            value: 'A module name',
            text_color: null, //null if using default?
          },
          accuracy: {
            value: '50%',
            text_color: '#BF4417', //null if using default?
          },
          started_count: {
            value: '100',
            text_color: null, //null if using default?
          },
          completed_count: {
            value: '50',
            text_color: null, //null if using default?
          },
        },
        {
          module_name: {
            value: 'A second module name',
            text_color: null, //null if using default?
          },
          accuracy: {
            value: '100%',
            text_color: '#148060', //null if using default?
          },
          started_count: {
            value: '100',
            text_color: null, //null if using default?
          },
          completed_count: {
            value: '50',
            text_color: null, //null if using default?
          },
        },
        {
          module_name: {
            value: 'Another module name',
            text_color: null, //null if using default?
          },
          accuracy: {
            value: '50%',
            text_color: '#BF4417', //null if using default?
          },
          started_count: {
            value: '32',
            text_color: null, //null if using default?
          },
          completed_count: {
            value: '11',
            text_color: null, //null if using default?
          },
        },
      ],
    },
  },
  link_display: {
    widget_type: 'link_display',
    widget_display_name: 'Share Zogo Web Link',
    source: 'https://becu.zogo.com',
  },
  overview_survey_results: {
    surveys: [
      {
        badge_text: 'partner',
        content:
          'Are you more loyal to Zogo Web because they have sponsored Zogo for you?',
        content_description: null,
        answer_options: [
          {
            answer: 'Yes!',
            percentage: 76,
          },
          {
            answer: 'No.',
            percentage: 24,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content:
          'Has your opinion of Zogo Web improved because they have sponsored Zogo for you?',
        content_description: 'here is a long a description',
        answer_options: [
          {
            answer: 'Yes!',
            percentage: 84,
          },
          {
            answer: 'No.',
            percentage: 16,
          },
        ],
      },
      {
        badge_text: 'partner',
        content:
          'Are you more loyal to Zogo Web because they have sponsored Zogo for you?',
        content_description: null,
        answer_options: [
          {
            answer: 'Yes!',
            percentage: 76,
          },
          {
            answer: 'No.',
            percentage: 24,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content:
          'Has your opinion of Zogo Web improved because they have sponsored Zogo for you?',
        content_description: 'here is a long a description',
        answer_options: [
          {
            answer: 'Yes!',
            percentage: 84,
          },
          {
            answer: 'No.',
            percentage: 16,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content: 'FIs Zo making a wise investment decision?\n',
        content_description: null,
        answer_options: [
          {
            answer:
              'Yes! If you invest all of your money in one stock, make sure it’s solid.',
            percentage: 73,
          },
          {
            answer: 'No! Zo should not put all of her money in one stock.',
            percentage: 10,
          },
          {
            answer: 'No! Zo should not put all of her money in one stock.',
            percentage: 4,
          },
          {
            answer: 'No! Zo should not put all of her money in one stock.',
            percentage: 2,
          },
          {
            answer: 'No! Zo should not put all of her money in one stock.',
            percentage: 11,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content: 'Zogo has improved my financial literacy.',
        content_description: null,
        answer_options: [
          {
            answer: 'Strongly Agree',
            percentage: 52,
          },
          {
            answer: 'Slightly Agree',
            percentage: 45,
          },
          {
            answer: 'Slightly Disagree',
            percentage: 2,
          },
          {
            answer: 'Strongly Disagree',
            percentage: 1,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content: 'Do you have an account with Zogo Web?',
        content_description: null,
        answer_options: [
          {
            answer: 'Yes, I already have one!',
            percentage: 5,
          },
          {
            answer: "No, I don't have one!",
            percentage: 90,
          },
          {
            answer: 'No, but I would like one!',
            percentage: 5,
          },
        ],
      },
      {
        badge_text: 'zogo',
        content:
          'Are you more loyal to Zogo Web because they have sponsored Zogo for you?',
        content_description: null,
        answer_options: [
          {
            answer: 'Yes!',
            percentage: 76,
          },
          {
            answer: 'No.',
            percentage: 24,
          },
        ],
      },
    ],
  },
  overview_user_data_reports: {
    user_data_reports: [
      {
        id: 1997,
        platform: 'mobile-banking',
        platform_name: 'Mobile Banking',
        created_at: '2024-06-05T20:28:19.000Z',
        status: 'pending',
      },
      {
        id: 83,
        platform: 'mobile-banking',
        platform_name: 'Mobile Banking',
        created_at: '2024-06-05T20:28:11.000Z',
        status: 'ready',
      },
      {
        id: 1997,
        platform: 'mobile-banking',
        platform_name: 'Mobile Banking',
        created_at: '2024-06-05T20:28:19.000Z',
        status: 'error',
      },
    ],
  },
  overview_user_messages: {
    inbox: [
      {
        id: 19569,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'Dan',
        last_name: 'Hepworth',
        city: 'West Danville',
        state: 'VT',
        timestamp: '2020-06-14T20:57:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message:
          'I really enjoy using this app to learn!. I really enjoy using this app to learn!. I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19568,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'Woonggyu',
        last_name: 'Jin',
        city: 'West Danville',
        state: 'VT',
        timestamp: '2020-06-12T20:57:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19591,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'Brandy',
        last_name: 'NguyGuo',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2020-06-12T20:57:08Z',
        membership_status: 'Non-customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19580,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'sjsks',
        last_name: 'shsj',
        city: 'Sutton',
        state: 'VT',
        timestamp: '2020-06-11T22:57:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19585,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Durham',
        state: 'NC',
        timestamp: '2020-06-11T21:57:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19573,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'alina',
        last_name: 'Yan',
        city: 'West Burke',
        state: 'VT',
        timestamp: '2020-06-11T20:59:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19572,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Marc',
        last_name: 'Bacvanski',
        city: 'West Burke',
        state: 'VT',
        timestamp: '2020-06-11T20:58:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19570,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Kyle',
        last_name: 'Tran',
        city: 'West Burke',
        state: 'VT',
        timestamp: '2020-06-11T20:57:20Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19587,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2020-06-11T20:57:18Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19588,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Brandy',
        last_name: 'NguyGuo',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2020-06-11T20:57:18Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19574,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'eheje',
        last_name: 'shs',
        city: 'Sutton',
        state: 'VT',
        timestamp: '2020-06-11T20:57:10Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19594,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'helena',
        last_name: 'Wu',
        city: 'Edison',
        state: 'NJ',
        timestamp: '2020-06-11T20:57:09Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19595,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'adf',
        last_name: 'afda',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2020-06-11T20:57:03Z',
        membership_status: 'Non-customer',
        is_read: 0,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19592,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Durham',
        state: 'NC',
        timestamp: '2020-06-11T20:56:08Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19571,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'shannon',
        last_name: 'Reilly',
        city: 'West Burke',
        state: 'VT',
        timestamp: '2020-06-11T20:56:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19593,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'sam',
        last_name: 'smith',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2020-06-11T20:55:08Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19576,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Testing',
        last_name: 'Testing',
        city: 'Sutton',
        state: 'VT',
        timestamp: '2020-05-11T20:57:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19578,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Sutton',
        state: 'VT',
        timestamp: '2020-03-11T20:57:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19583,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Durham',
        state: 'NC',
        timestamp: '2020-02-11T20:57:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19584,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Durham',
        state: 'NC',
        timestamp: '2020-01-11T20:57:08Z',
        membership_status: 'Customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19582,
        thank_you_text: null,
        zogo_response_message: 'Thank you!',
        email: null,
        first_name: 'Sam',
        last_name: 'Smith',
        city: 'Durham',
        state: 'NC',
        timestamp: '2019-08-11T20:57:08Z',
        membership_status: 'Customer',
        is_read: 2,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19589,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Brandy',
        last_name: 'NguyGuo',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2019-06-11T20:57:08Z',
        membership_status: 'Non-customer',
        is_read: 1,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
      {
        id: 19590,
        thank_you_text: 'Send Thanks!',
        zogo_response_message: null,
        email: null,
        first_name: 'Brandy',
        last_name: 'NguyGuo',
        city: 'Anchorage',
        state: 'AK',
        timestamp: '2018-06-11T20:57:08Z',
        membership_status: 'Non-customer',
        is_read: 0,
        message: 'I really enjoy using this app to learn!',
        question_text: 'Tell Zogo about your experience using Zogo!',
      },
    ],
    message: 'Successful!',
  },
  geckoboard: {
    widget_type: 'geckoboard',
    widget_display_name: 'Survey Statistics',
    source: 'https://share.geckoboard.com/dashboards/2QJVOMQFYNHZ3J3P',
  },
  pre_post_skill_results_info: {
    tabs: [
      {
        tab_name: 'All Platforms',
        platform: 'all',
        sub_tabs: null,
      },
      {
        tab_name: 'Standalone',
        platform: 'native',
        sub_tabs: null,
      },
      {
        tab_name: 'Mobile Banking',
        platform: 'mobile_banking',
        sub_tabs: null,
      },
      {
        tab_name: 'Zogo Web',
        platform: 'enterprise_web',
        sub_tabs: [
          {
            sub_tab_name: 'Default view',
            platform: 'enterprise_web',
          },
          {
            sub_tab_name: 'Classroom',
            platform: 'classroom_for_enterprise_web',
          },
        ],
      },
      {
        tab_name: 'Custom Integrations',
        platform: 'sandbox',
        sub_tabs: null,
      },
    ],
  },
  pre_post_skill_results_data: {
    category_list: [
      {
        category_name: 'Information Security',
        category_id: 1,
      },
      {
        category_name: 'Saving and Spending',
        category_id: 2,
      },
      {
        category_name: 'Entrepeneurship',
        category_id: 3,
      },
      {
        category_name: 'Category 4',
        category_id: 4,
      },
      {
        category_name: 'No data category',
        category_id: 12,
      },
    ],
    category_overviews: [
      {
        category_name: 'Information Security',
        category_id: 1,
        accuracy_change: {
          percent_change: 90, //can be null
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 10, //can be null
          change_direction: 'positive', //can be positive or negative
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 2,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'negative', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 3,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 4,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 5,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 6,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 7,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
      {
        category_name: 'Saving and Spending',
        category_id: 8,
        accuracy_change: {
          percent_change: 40,
          change_direction: 'positive', //can be positive or negative
        },
        confidence_change: {
          percent_change: 30,
        },
      },
    ],
    skill_results: [
      {
        skill_name: 'Buy a car',
        category_id: 1,
        accuracy: {
          percent_change: 11,
          change_direction: 'positive',
          pre_accuracy: 60,
          post_accuracy: 71,
        },
        confidence_increase: 45,
        surveys: [
          {
            badge_text: 'pre-test',
            content: 'What are your savings goals',
            content_description: null,
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'post-test',
            content: 'What are your savings goals',
            content_description: 'here is description text',
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'post-test',
            content: 'What are your savings goals',
            content_description: 'here is description text',
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'post-test',
            content: 'What are your savings goals',
            content_description: 'here is description text',
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'post-test',
            content: 'What are your savings goals',
            content_description: 'here is description text',
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'post-test',
            content: 'What are your savings goals',
            content_description: 'here is description text',
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
        ],
      },
      {
        skill_name: 'Another Skill',
        category_id: 1,
        accuracy: {
          percent_change: 11,
          change_direction: 'positive',
          pre_accuracy: 60,
          post_accuracy: 71,
        },
        confidence_increase: 45,
        surveys: [
          {
            badge_text: 'pre-test',
            content: 'What are your savings goals',
            content_description: null,
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
          {
            badge_text: 'pre-test',
            content: 'What are your savings goals',
            content_description: null,
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
        ],
      },
      {
        skill_name: 'Another Skill 2',
        category_id: 2,
        accuracy: {
          percent_change: 11,
          change_direction: 'positive',
          pre_accuracy: 60,
          post_accuracy: 71,
        },
        confidence_increase: 45,
        surveys: [
          {
            badge_text: 'pre-test',
            content: 'What are your savings goals',
            content_description: null,
            answer_options: [
              {
                answer: 'building emergency funds',
                percentage: 23,
              },
              {
                answer: 'Taking a vacation',
                percentage: 24,
              },
              {
                answer: 'Making a milestone purchase (car, home, etc.)',
                percentage: 23,
              },
              {
                answer: 'Planning for the future',
                percentage: 31,
              },
            ],
          },
        ],
      },
    ],
  },
};
