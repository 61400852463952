import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/requests.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailReminderModalComponent } from './email-reminder-modal/email-reminder-modal.component';

@Component({
  selector: 'app-social-calendar',
  templateUrl: './social-calendar.component.html',
  styleUrls: ['./social-calendar.component.css'],
})
export class SocialCalendarComponent implements OnInit {
  timeFrame: 'upcoming' | 'archived' = 'upcoming';
  socialCalendarData: any;
  activePanels: any = new Map();
  userData: any;

  constructor(private dialog: MatDialog, private req: RequestsService) {}

  ngOnInit(): void {
    // Amplitude event - Social Calendar Viewed
    this.req.ampTrack('Social Calendar Viewed');
    // Client Success event - Social Calendar Viewed
    this.req.csTrack('Social Calendar Viewed');

    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.req.getDashboardSocialCalendar(this.timeFrame).subscribe({
      next: data => {
        this.socialCalendarData = data;
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
    this.activePanels.set(0);
  }

  onSelectionChange() {
    this.req.getDashboardSocialCalendar(this.timeFrame).subscribe({
      next: data => {
        this.socialCalendarData = data;
        this.activePanels = new Map();
        if (this.timeFrame === 'upcoming') this.activePanels.set(0);
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  handleGetEmailReminders() {
    this.dialog.open(EmailReminderModalComponent, {
      width: '560px',
      height: '320px',
      data: {
        email: this.userData.institution_user.email,
      },
    });
  }

  togglePanel(index) {
    if (this.activePanels.has(index)) {
      this.activePanels.delete(index);
    } else {
      this.activePanels.set(index);
    }
  }
}
