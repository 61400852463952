import { buildConfigValues } from './config';

const { environment } = buildConfigValues;

class BuildConfig {
  environment = environment;

  isDevelopment() {
    return environment === 'development';
  }
  isProduction() {
    return environment === 'production';
  }
}

export const buildConfig = new BuildConfig();
