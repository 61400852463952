import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ZogoIconComponent } from 'src/app/design-system/global-components';
import {
  ZogoBadgeComponent,
  ZogoBtnComponent,
  ZogoTextInputComponent,
} from 'src/app/design-system/web-components';
import { ToastService } from 'src/app/design-system/web-components/toast.service';
import { CustomValidationDirective } from 'src/app/directives/custom-validation.directive';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-billing-contact-modal',
  templateUrl: './billing-contact-modal.component.html',
  styleUrls: ['./billing-contact-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZogoBtnComponent,
    ZogoTextInputComponent,
    ZogoIconComponent,
    ZogoBadgeComponent,
    CustomValidationDirective,
  ],
})
export class BillingContactModalComponent implements OnInit {
  @Input() headerText: string = 'Edit Billing Contact';
  @Input() description?: string;
  @Input() contactEmail: string = '';
  @Input() contactName: string = '';
  @Input() contactPhone: string = '';
  @Input() showBadge?: boolean = false;
  userData: any;
  hasEmailInputError: boolean = false;
  hasPhoneInputError: boolean = false;

  constructor(
    private req: RequestsService,
    private toastService: ToastService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  async dismiss() {
    await this.modalController.dismiss(null, 'cancel');
  }

  onPhoneChange(number: string) {
    let phoneNumber = number;
    if (!number.startsWith('+1')) {
      phoneNumber = '+1' + number;
    }

    const phoneRegex = /^(?:\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (number && phoneRegex.test(phoneNumber)) {
      this.hasPhoneInputError = false;
      this.contactPhone = phoneNumber;
    } else {
      this.hasPhoneInputError = true;
    }
  }

  onSubmitForm() {
    const request = {
      page: 'payment-settings',
      contact_name: this.contactName,
      contact_email: this.contactEmail,
      contact_phone: this.contactPhone,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Institution Information Changes Saved
        this.req.ampTrack('Payment Information Changes Saved');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        this.req.updateUserDataSub(this.userData);
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.toastService.showToast('Changes saved.', 6000);

        this.modalController.dismiss(
          {
            contactName: data.institution.contact_name,
            contactEmail: data.institution.contact_email,
            contactPhone: data.institution.contact_phone,
          },
          'save'
        );
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
