<div class="modal-container">
  <svg
    *ngIf="cancelBtnText"
    class="dismiss-cross"
    (click)="data.cancelFunct()"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <div>
    <div class="h5-bold text-color-neutral-800 mb-2">{{ headerText }}</div>
    <markdown class="body-medium text-color-neutral-600 mb-4">{{
      bodyText
    }}</markdown>
  </div>
  <div *ngIf="zoImage" class="dialog-zo-container">
    <img class="dialog-zo-image" [src]="zoImage" />
  </div>

  <div class="d-flex justify-content-end">
    <zogo-btn
      *ngIf="cancelBtnText"
      buttonStyle="secondary"
      [label]="cancelBtnText"
      (clickFunction)="data.cancelFunct()"
    >
    </zogo-btn>
    <zogo-btn
      class="ml-2"
      buttonStyle="primary"
      [label]="confirmBtnText"
      (clickFunction)="data.confirmFunct()"
    >
    </zogo-btn>
  </div>
</div>
