import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-geckoboard-display',
  templateUrl: './geckoboard-display.component.html',
  styleUrls: ['./geckoboard-display.component.scss'],
})
export class GeckoboardDisplayComponent implements OnInit {
  @Input() geckoboardDisplayData: any;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
