export type Asset = {
  title: string;
  url: string;
  fontSize: number;
  fontColor?: string;
  useAltLogo: boolean;
  useCustomColor?: boolean;
  logoSizeMultiplier: number;
  qrCodeSizeMultiplier: number;
  coordinates: {
    logoX?: number;
    logoY?: number;
    accessCodeX?: number;
    accessCodeY?: number;
    qrCodeX?: number;
    qrCodeY?: number;
  };
};

export const socialHeaders: Asset[] = [
  {
    title: 'partner-branded-header-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/E37dlpKsxNKNA46ls9pGf/f4e5c943df3efe45014c525ab46baf43/partner-header-1.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 215,
      accessCodeX: 500,
      accessCodeY: 675,
    },
  },
  {
    title: 'social-header-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/bd06Eqd5LxUMQssjEQPt7/7b2776c7eae8f7411651a33c8451a2ce/header-1.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 190,
      accessCodeX: 500,
      accessCodeY: 675,
    },
  },
  {
    title: 'social-header-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/RAaG9ax5lgjzqL8ggI7LM/bfadeac8ed9d9f92b46dc5dc89db5a0c/header-2.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 190,
      accessCodeX: 690,
      accessCodeY: 630,
      qrCodeX: 125,
      qrCodeY: 450,
    },
  },
  {
    title: 'social-header-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/57COOeq7VMwCfXSQvlIRDw/017303c4f85bca41d6c1f2c52e2db246/header-3.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 190,
      accessCodeX: 690,
      accessCodeY: 630,
      qrCodeX: 125,
      qrCodeY: 455,
    },
  },
  {
    title: 'social-header-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4VOjcDHKhH0WLOtrzKq0d0/91c89be452a1175c29fdaad341ac188f/header-4.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 550,
      logoY: 190,
      accessCodeX: 690,
      accessCodeY: 645,
      qrCodeX: 125,
      qrCodeY: 470,
    },
  },
];

export const squarePosts: Asset[] = [
  {
    title: 'branded-square-post-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/uOcAwN1UrHpqzWeuB3b74/2c02537e559859da16e086dde7bacd14/branded-post-1.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 600,
      logoY: 180,
      accessCodeX: 418,
      accessCodeY: 1305,
    },
  },
  {
    title: 'square-post-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3fgXt9uepLqktIfNhDE6y0/37e1ea2e8803ca75938abc03fcb73ce6/square-post-1.svg',
    fontSize: 72,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 200,
      logoY: 1750,
      accessCodeX: 1420,
      accessCodeY: 1870,
    },
  },
  {
    title: 'square-post-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/93cGfcOp7XG74pxhoJitb/95505a7a6a997f5bc55eacfcb0e6d746/square-post-2.svg',
    fontSize: 72,
    fontColor: '#148060',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 200,
      logoY: 1750,
      accessCodeX: 1425,
      accessCodeY: 1870,
    },
  },
  {
    title: 'square-post-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7pRWp6yMdLul5FtadZEnh7/50e5992b4944311f9a1e7361d7658dc1/square-post-3.svg',
    fontSize: 72,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 200,
      logoY: 1750,
      accessCodeX: 1405,
      accessCodeY: 1870,
    },
  },
  {
    title: 'square-post-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7pSAdYemNZ4RKchtxpB8ug/e168d82eca4856954c5d4350e122d74f/square-post-4.svg',
    fontSize: 60,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1070,
      logoY: 200,
      accessCodeX: 1000,
      accessCodeY: 915,
    },
  },
  {
    title: 'square-post-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2QNF4j4iDswFAv7RD46QHd/069ed85c143b469e478dd7c4da2f1010/square-post-5.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 1600,
      accessCodeX: 410,
      accessCodeY: 1307,
    },
  },
  {
    title: 'square-post-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1ku8zDKyUZ3S8u4b5brVTR/8aa17aa070ce571aa6a11f8723532f3f/square-post-6.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 1600,
      accessCodeX: 410,
      accessCodeY: 1315,
    },
  },
  {
    title: 'square-post-7',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6LkYRwkq5NNKP7ogI6UFuU/9c40d0459d18edd0f87bec3cf295bf37/square-post-7.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 1600,
      accessCodeX: 410,
      accessCodeY: 1310,
      qrCodeX: 1610,
      qrCodeY: 220,
    },
  },
  {
    title: 'square-post-8',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4URHNQf8v4JZI8fvg5Z6yl/6a27f75d0bdd03922c13e40c19a75bad/square-post-8.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 1600,
      accessCodeX: 415,
      accessCodeY: 1315,
      qrCodeX: 1560,
      qrCodeY: 215,
    },
  },
  {
    title: 'square-post-9',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/70rVFRsciz5JVOjv0Yr3K1/c75973fb2a5ecea12a06471c2fc83fb7/square-post-9.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 150,
      logoY: 1600,
      accessCodeX: 415,
      accessCodeY: 1315,
    },
  },
  {
    title: 'square-post-10',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3PUEJ9q2jNYOUvbOIlEuUJ/6b3dc9aab3551739bbeb6af7c83ba79c/square-post-10.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 650,
      logoY: 1670,
      accessCodeX: 1605,
      accessCodeY: 1765,
      qrCodeX: 180,
      qrCodeY: 1170,
    },
  },
];

export const spanishSquarePosts: Asset[] = [
  {
    title: 'spanish-square-post-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5LXI83sSbAsG00vc6PSEOt/a65c8915e9d4089bf740dd956077f3fe/spanish-square-post-1.svg',
    fontSize: 60,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1070,
      logoY: 200,
      accessCodeX: 1000,
      accessCodeY: 915,
    },
  },
  {
    title: 'spanish-square-post-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3NCnII6ILRc2kljWhDQAWp/5e6f8afab0c3c1d823c77a8c0ab6b81c/spanish-square-post-2.svg',
    fontSize: 72,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 125,
      logoY: 1700,
      accessCodeX: 420,
      accessCodeY: 1320,
    },
  },
];

export const rectanglePosts: Asset[] = [
  {
    title: 'rectangle-post-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3yz0B11L2WIWk3hAo99qV8/4e23319ae0ebba388db3eeab4368a72a/rectangle-post-1.svg',
    fontSize: 72,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1000,
      logoY: 1300,
      accessCodeX: 2260,
      accessCodeY: 1420,
    },
  },
  {
    title: 'rectangle-post-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1KJaVWXRDiiaODMpD2yWMM/c49a526088e494f576c6a53ecb21cbca/rectangle-post-2.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1000,
      logoY: 1300,
      accessCodeX: 2220,
      accessCodeY: 1400,
    },
  },
  {
    title: 'rectangle-post-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6iIAMJRpg0yfa3FcvOV8H1/7247c6b406ebefd66a423e6728403433/rectangle-post-3.svg',
    fontSize: 72,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 1000,
      logoY: 1300,
      accessCodeX: 2430,
      accessCodeY: 1440,
      qrCodeX: 1840,
      qrCodeY: 1280,
    },
  },
  {
    title: 'rectangle-post-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5I4R05pkEk6ARgOtNv0nmD/9b41a258b5104e6cbfbd04ef75a333db/rectangle-post-4.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 115,
      logoY: 1300,
      accessCodeX: 1180,
      accessCodeY: 1370,
      qrCodeX: 565,
      qrCodeY: 1210,
    },
  },
  {
    title: 'rectangle-post-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1s4kipd49OojLHAIO6ewZo/68d0ee8bd79cec0b1978f3b8fe3ef1ee/rectangle-post-5.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 115,
      logoY: 1300,
      accessCodeX: 1180,
      accessCodeY: 1370,
      qrCodeX: 565,
      qrCodeY: 1210,
    },
  },
  {
    title: 'rectangle-post-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5eorfxHUsraAiiuwIoEcXr/d077b4dfbb70f458f22f19596169cd73/rectangle-post-6.svg',
    fontSize: 72,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 115,
      logoY: 1300,
      accessCodeX: 1185,
      accessCodeY: 1370,
      qrCodeX: 565,
      qrCodeY: 1210,
    },
  },
];

export const stories: Asset[] = [
  {
    title: 'branded-story-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5bSUeYK0z3wPomYoJbZcR4/7c6e72b3415bb1ec005cdc4dc8000c26/partner-story-1.svg',
    fontSize: 54,
    fontColor: '#313131',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 600,
      logoY: 170,
      accessCodeX: 540,
      accessCodeY: 1750,
    },
  },
  {
    title: 'story-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6640PvRI1ogYyI00kPgTDc/cbca2036785b47481953b4198ead6324/story-1.svg',
    fontSize: 54,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 540,
      accessCodeY: 1750,
    },
  },
  {
    title: 'story-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/nzhQwxRB4j8ijsOekHVyw/5b38a72a42fb013e1c4b454b497c6768/story-2.svg',
    fontSize: 54,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 540,
      accessCodeY: 1750,
    },
  },
  {
    title: 'story-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/75n45pV3fmG0YIHXa4MqPb/492da93c48826f15f1f7702bd51b9880/story-3.svg',
    fontSize: 54,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 0.75,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 640,
      accessCodeY: 1720,
      qrCodeX: 165,
      qrCodeY: 1630,
    },
  },
  {
    title: 'story-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5Rp03fDWzyGM7fHi3L2sU9/3c097fbe2d0962a5ba54bb56a28d70af/story-4.svg',
    fontSize: 54,
    fontColor: '#233876',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 540,
      accessCodeY: 1760,
    },
  },
  {
    title: 'story-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/179PHYqNnruwhzIIPBU6sZ/1914d969a88a95495b966cd4695e3208/story-5.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 0.65,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 660,
      accessCodeY: 1760,
      qrCodeX: 200,
      qrCodeY: 1660,
    },
  },
  {
    title: 'story-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1hJLL1JbSrytKlmPpFETco/ec547efdb2cb6f22c150370f1a260671/story-6.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 0.65,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 660,
      accessCodeY: 1765,
      qrCodeX: 200,
      qrCodeY: 1665,
    },
  },
  {
    title: 'story-7',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3UA8NctTFI5qX6gsJAEd76/9b3952cc86a77f9a3b3b00790bc79ffe/story-7.svg',
    fontSize: 54,
    fontColor: '#1c64f2',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 165,
      accessCodeX: 540,
      accessCodeY: 745,
    },
  },
  {
    title: 'story-8',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5aFfz5yG3sieUrmZkJ75Sz/665a75654468e7051dba6b0960b46218/story-8.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 590,
      logoY: 215,
      accessCodeX: 540,
      accessCodeY: 1775,
    },
  },
  {
    title: 'story-9',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2segKMdZ2Hm2OKKFpC2LLZ/cec1658fe93cac11c19dd759ebb15d46/story-9.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    useAltLogo: true,
    coordinates: {
      logoX: 120,
      logoY: 1260,
      accessCodeX: 320,
      accessCodeY: 1050,
    },
  },
  {
    title: 'story-10',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6KJ8xTlnH6zmTziQkq3zN7/c8c40e79104df89e30ee6d5484273955/story-10.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 115,
      logoY: 1250,
      accessCodeX: 318,
      accessCodeY: 1050,
    },
  },
  {
    title: 'story-11',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7mKlpo58Yv8GIelf8FTpy/d84f89b3e5a2eeb0632c7710d1e668cb/story-11.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 115,
      logoY: 1250,
      accessCodeX: 318,
      accessCodeY: 1050,
    },
  },
  {
    title: 'story-12',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/QG75JrsuAzlnvjQEKRbfJ/f95644901e6806fa13f2eda81f2d4b47/story-12.svg',
    fontSize: 54,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 0.65,
    coordinates: {
      logoX: 625,
      logoY: 1670,
      accessCodeX: 665,
      accessCodeY: 1440,
      qrCodeX: 200,
      qrCodeY: 1340,
    },
  },
];

export const banners: Asset[] = [
  {
    title: 'branded-banner-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1vNFmchypkRolS1P58wpf8/290e835d8b4be0cd9c9f9075edf13d6e/partner-banner-1.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 690,
      logoY: 55,
      accessCodeX: 1750,
      accessCodeY: 210,
    },
  },
  {
    title: 'banner-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5hSaKlyuvNDF4uwoRWN4Fp/a1ed17fdd13306add346111d30aba8c1/banner-1.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      accessCodeX: 1750,
      accessCodeY: 210,
    },
  },
  {
    title: 'banner-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6BoiohpmCOiJsSYALvmEnX/b6cab018326d09e400a2570003055780/banner-2.svg',
    fontSize: 60,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 620,
      logoY: 50,
      accessCodeX: 1750,
      accessCodeY: 210,
    },
  },
  {
    title: 'banner-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/DI9l7gB61VKLFbKzJTGqF/6a7d24f3f3be9bf876985082a423fe95/banner-3.svg',
    fontSize: 48,
    fontColor: '#1c64f2',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 0.7,
    coordinates: {
      accessCodeX: 1630,
      accessCodeY: 203,
      qrCodeX: 1895,
      qrCodeY: 60,
    },
  },
  {
    title: 'banner-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3xHhTmF7pegGwL3wx2xO6d/e498939a0c0e044d82b36a6985a6ffe2/banner-4.svg',
    fontSize: 48,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 0.7,
    coordinates: {
      accessCodeX: 1635,
      accessCodeY: 195,
      qrCodeX: 1895,
      qrCodeY: 60,
    },
  },
];

export const printResources: Asset[] = [
  {
    title: 'branded-print-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2yXjFbS1dSbFXBcODp3raL/3a835f79e373c7b19563380f1211d278/partner-print-1.svg',
    fontSize: 60,
    fontColor: 'institutionColor',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 2,
    coordinates: {
      logoX: 1350,
      logoY: 3245,
      accessCodeX: 500,
      accessCodeY: 2935,
      qrCodeX: 1970,
      qrCodeY: 2520,
    },
  },
  {
    title: 'print-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2kJReeLySsTaxJBstSn5Uf/5e04f210de0ee8bc395259ae757eb575/print-1.svg',
    fontSize: 60,
    fontColor: '#1c64f2',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 2,
    coordinates: {
      logoX: 1350,
      logoY: 3245,
      accessCodeX: 500,
      accessCodeY: 2935,
      qrCodeX: 1970,
      qrCodeY: 2520,
    },
  },
  {
    title: 'print-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2KCH3YLbmN7yPKR9ZD0giW/38ab6d43a04e42bef7dcaca4a843ce53/print-2.svg',
    fontSize: 42,
    fontColor: '#1c64f2',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 520,
      logoY: 1950,
      accessCodeX: 735,
      accessCodeY: 1715,
    },
  },
  {
    title: 'print-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3z9H5Hcw3ZtJCwDkH2lpje/c44273189f8fd43bb1c1f3d774d7c4ee/print-3.svg',
    fontSize: 80,
    fontColor: '#ffffff',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 520,
      logoY: 1950,
    },
  },
  {
    title: 'print-brochure-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3uIwOxs05ozlzCo8Z4exqI/d7117b8ba122f6f18eb0aa86a948924d/print-brochure-1.svg',
    fontSize: 60,
    fontColor: '#1c64f2',
    useAltLogo: false,
    logoSizeMultiplier: 1.2,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 2000,
      logoY: 135,
      accessCodeX: 1920,
      accessCodeY: 1780,
    },
  },
  {
    title: 'print-banner-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6BhJcb4y3npwOfvFQPKyiT/a8e5e0344c8377471d10d544b6ed26f6/print-banner-1.svg',
    fontSize: 48,
    fontColor: '#1c64f2',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 470,
      logoY: 960,
      accessCodeX: 320,
      accessCodeY: 660,
    },
  },
  {
    title: 'print-banner-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4cyEjPu9zCEXXhmq5qyInl/25cf3f835298bebd1c556cccab73fdcb/print-banner-2.svg',
    fontSize: 48,
    fontColor: '#1c64f2',
    useAltLogo: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 90,
      logoY: 980,
      accessCodeX: 320,
      accessCodeY: 810,
    },
  },
  {
    title: 'print-banner-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5vQqPtZdDYoDl7hsq6d6U8/aa0b8e0076f5d8c5fac7f47cf134e8e2/print-banner-3.svg',
    fontSize: 48,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 100,
      logoY: 670,
      accessCodeX: 1880,
      accessCodeY: 710,
    },
  },
];

export const spanishPrintResources: Asset[] = [
  {
    title: 'spanish-print-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3ikSOgGFhIyK1KEvoDpPDS/3f0592911b38590d28fc82f71911d609/spanish-print-1.svg',
    fontSize: 60,
    fontColor: '#1c64f2',
    useAltLogo: true,
    logoSizeMultiplier: 1.5,
    qrCodeSizeMultiplier: 2,
    coordinates: {
      logoX: 1380,
      logoY: 3250,
      accessCodeX: 495,
      accessCodeY: 2975,
      qrCodeX: 1970,
      qrCodeY: 2550,
    },
  },
  {
    title: 'spanish-brochure-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3oGX6HB4631rHKXejuAjio/8cbaf8ce69185b281a21706609fac27f/spanish-brochure-1.svg',
    fontSize: 60,
    fontColor: '#1c64f2',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 2000,
      logoY: 140,
      accessCodeX: 1910,
      accessCodeY: 1780,
    },
  },
  {
    title: 'spanish-banner-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/6AWVngTSYeytN01douMZSK/5328727f52efaf6154b5c9ad8fd5517a/spanish-print-2.svg',
    fontSize: 48,
    fontColor: '#ffffff',
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 100,
      logoY: 670,
      accessCodeX: 1880,
      accessCodeY: 715,
    },
  },
];

export const screenshots: Asset[] = [
  {
    title: 'screenshot-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3XdWYUod0u1UCrCvvVFkAG/02bece1d288e9935f5c3e627b027fb52/screenshot-1.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 260,
      logoY: 90,
    },
  },
  {
    title: 'screenshot-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/3UCsOOmFWirNap3TuCx6Ad/d443528724a3fb4ec24fec0fdb882530/screenshot-2.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 260,
      logoY: 150,
    },
  },
  {
    title: 'screenshot-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7qKsxz4t9ghRM8dEpKvjTn/5e594be94e41447e202aba93f11b9161/screenshot-3.svg',
    fontSize: 80,
    useAltLogo: true,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 260,
      logoY: 670,
    },
  },
  {
    title: 'screenshot-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/1bfuCsQ1ns34XfANRt48mN/19d16dae40546f14fb7b96971ef2079e/screenshot-4.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'screenshot-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/CGdz33Ckl4Lfp125x9kwY/06dc0910c9647a3bdd0e8339aa1a259b/screenshot-5.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'screenshot-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4Igmch0193IY0UWPHb1PEW/7bbc9e06f2622f734a329d13e3320eec/screenshot-6.svg',
    fontSize: 80,
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
];

export const mockups: Asset[] = [
  {
    title: 'mockup-1',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2tPXVovzxVBF81tAmXyVfP/0efe70083c5ed124e60c5cbc352928e8/mockup-1.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 280,
      logoY: 160,
    },
  },
  {
    title: 'mockup-2',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/5EGJRTy3GOC6F6URzjYjx1/333d9ca615300b484aea1db5d10e3db3/mockup-2.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 280,
      logoY: 220,
    },
  },
  {
    title: 'mockup-3',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/4qATY8akFt8obbtdqcN7LI/12304a98d8ac2c9235befdccbab7ea4d/mockup-3.svg',
    fontSize: 80,
    useAltLogo: true,
    useCustomColor: true,
    logoSizeMultiplier: 0.4,
    qrCodeSizeMultiplier: 1,
    coordinates: {
      logoX: 280,
      logoY: 740,
    },
  },
  {
    title: 'mockup-4',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/2rpTZz9Y7k7OvhRY27qG0h/bd711112fa39e372fe4eb318b77ffea0/mockup-4.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'mockup-5',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/77yzB3osWgmxvGvOH9KkLD/7c70632e7b9326b9b82a32587b3aeff3/mockup-5.svg',
    fontSize: 80,
    useAltLogo: false,
    useCustomColor: true,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
  {
    title: 'mockup-6',
    url: 'https://images.ctfassets.net/o1xn7flb83yr/7LTAujl0l67OBXzXiJh20R/6c95c30d2647d7b42f78cc474dcf3244/mockup-6.svg',
    fontSize: 80,
    useAltLogo: false,
    logoSizeMultiplier: 1,
    qrCodeSizeMultiplier: 1,
    coordinates: {},
  },
];
