<div class="dashboard-view">
  <app-notif-alert
    *ngIf="isNotifAlertEnabled"
    [status]="notifAlertStatus"
    [message]="notifAlertMessage"
    [dismiss]="dismissNotifAlert.bind(this)"
    [buttonText]="notifAlertButtonText"
    [buttonFunc]="notifAlertButtonFunc"
  ></app-notif-alert>
  <div class="page-header-flex-container">
    <h1 class="h3-bold">
      {{ copy?.page_header }}
    </h1>
    <div *ngIf="!isNotifAlertEnabled" class="d-flex-row gap-16">
      <zogo-btn
        [label]="copy?.cancel_button_text"
        buttonStyle="secondary"
        (clickFunction)="abortChanges()"
      ></zogo-btn>
      <zogo-btn
        [label]="copy?.save_button_text"
        [disabled]="
          !isAdmin ||
          !isValidContrast ||
          !this.userData?.institution.can_edit_color
        "
        (clickFunction)="saveColor()"
      ></zogo-btn>
    </div>
  </div>
  <div class="d-flex-row gap-24">
    <div
      class="color-selection-wrapper background-neutral-white border-radius-8 d-flex-col gap-24 padding-16"
    >
      <p class="body-medium text-color-neutral-800">
        {{ copy?.color_select_label }}
      </p>
      <div
        class="color-preview"
        [ngStyle]="{
          'background-color': colorHex
        }"
      ></div>
      <input
        [(ngModel)]="colorHex"
        [(colorPicker)]="colorHex"
        class="input-container body-medium text-color-neutral-800"
        [cpWidth]="'400px'"
        [cpHeight]="'auto'"
        [cpOutputFormat]="'hex'"
        [cpAlphaChannel]="'disabled'"
        [cpFallbackColor]="'#0050AA'"
        [cpPosition]="'bottom'"
        [cpDisableInput]="true"
        [cpDialogDisplay]="'inline'"
        [cpToggle]="true"
        (colorPickerChange)="handleColorPickerChange()"
      />

      <div class="d-flex-col gap-4">
        <p class="body-bold text-color-neutral-800">
          {{ copy?.requirements_header }}
        </p>
        <p class="caption-medium text-color-neutral-600">
          {{ copy?.requirements_body }}
        </p>
      </div>

      <div class="contrast-ratio-frame background-neutral-100 gap-16">
        <p class="body-medium">
          {{ copy?.contrast_ratio_pre_text }}
          <span [ngClass]="{ 'text-color-shades-green-1': isValidContrast }">{{
            contrastRatio.toFixed(2)
          }}</span>
        </p>

        <zogo-badge
          [type]="isValidContrast ? 'success' : 'warning'"
          [label]="
            isValidContrast ? copy?.accepted_text : copy?.not_accepted_text
          "
        ></zogo-badge>
      </div>
    </div>
    <div class="preview-wrapper">
      <div class="preview-text background-neutral-white padding-y-12">
        <p class="body-medium">{{ copy?.preview_header_text }}</p>
      </div>
      <div
        *ngIf="
          (this.platformAccess.includes('Standalone') ||
            this.platformAccess.includes('Mobile Banking')) &&
          this.platformAccess.includes('Zogo Web')
        "
        class="d-flex-row gap-12"
      >
        <zogo-chip
          [shouldManageSelect]="false"
          label="Mobile"
          [isSelected]="previewType === 'mobile'"
          (selectedChange)="handlePreviewSelection('mobile')"
        ></zogo-chip>
        <zogo-chip
          [shouldManageSelect]="false"
          label="Desktop"
          [isSelected]="previewType === 'desktop'"
          (selectedChange)="handlePreviewSelection('desktop')"
        ></zogo-chip>
      </div>
      <div class="progress-dots-wrapper">
        <div
          class="progress-dot"
          *ngFor="let items of progressArray; let i = index"
          [ngClass]="{ current: i === previewIndex }"
        ></div>
      </div>
      <div class="pos-rel margin-bottom-24">
        <zogo-pagination-arrow
          *ngIf="previewIndex > 0"
          [class]="
            'left-arrow-' + (previewType === 'mobile' ? 'mobile' : 'desktop')
          "
          (pageChange)="previousScreen()"
          icon="left"
        ></zogo-pagination-arrow>
        <div *ngIf="previewType === 'mobile'" class="phone-preview-wrapper">
          <div class="preview-image" [innerHTML]="getCurrentScreen()"></div>
          <div class="institution-logo-wrapper">
            <img
              *ngIf="previewIndex === 0 || previewIndex === 2"
              [src]="userData.institution.image"
              class="module-institution-logo"
              [class.invert-logo]="previewIndex === 2"
              alt=""
            />
          </div>
        </div>
        <div *ngIf="previewType === 'desktop'" class="desktop-preview-wrapper">
          <div class="desktop-image" [innerHTML]="getCurrentScreen()"></div>
          <div class="institution-logo-wrapper">
            <img
              *ngIf="previewIndex === 0"
              [src]="userData.institution.image"
              class="web-institution-logo"
              alt=""
            />
            <img
              *ngIf="previewIndex === 1"
              [src]="userData.institution.image"
              class="web-snippet-institution-logo"
              alt=""
            />
          </div>
        </div>
        <zogo-pagination-arrow
          *ngIf="previewIndex < progressArray.length - 1"
          [class]="
            'right-arrow-' + (previewType === 'mobile' ? 'mobile' : 'desktop')
          "
          (pageChange)="nextScreen()"
          icon="right"
        ></zogo-pagination-arrow>
      </div>
    </div>
  </div>
</div>
