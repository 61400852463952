import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-zogo-web-preview',
  templateUrl: './zogo-web-preview.component.html',
  styleUrls: ['./zogo-web-preview.component.scss'],
})
export class ZogoWebPreviewComponent implements OnInit {
  @Input() config: any;
  userData: any;
  trendingSkillCardData: any = [
    {
      topic: 'Save Money',
      short_description: 'Discover practical ways to save money.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/01lsKUxaIYxtTkyJaXDQoK/8c06ed883f52d5df3f21bd019ccdb3d4/003-gold.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/3qH6Z98l85qqgF4LNaAIsb/201974f3ea1544da3f3b0b560991e802/014-money.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/1mDrlF5DGm7HLHC6sdZT5a/20d020d3cff6aab978a76c52324fc5aa/035-savings.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 15,
      estimated_completion_time: 33,
      reward_value: 2500,
    },
    {
      topic: 'Do Your Taxes',
      short_description:
        'Learn how to file your taxes and avoid common mistakes.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/ZgRbZqTnkId4qBEaKow1H/a678ae12459b0f7cc55d94afaad25ce7/033-taxes-2.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/5ksnk2cgHo7MzP1dp6gMXY/3d55d82843bf2ec4be6ebe8f6074b4cc/027-liability.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/1u13XoN12lT3e14rFhUGNw/1083b9b564c9fc9989ee25968d920930/040-email.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 17,
      estimated_completion_time: 34,
      reward_value: 2500,
    },
    {
      topic: 'Start Investing',
      short_description:
        'Learn the basics of investing and how to get started.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/5l6LKYOXV6RvWujexabEGX/e11fa43b68057b9d90709821bd387207/038-invoices.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/G67j5oFlPWlZYqW5927Hy/1d49e7c95363b8b8836f761cb3cafa29/022-investing.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/54xAELO9Cj5uaNh723hU9o/f95a881bd6e70f22f074aa708ffe55de/004-bar_chart.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 18,
      estimated_completion_time: 36,
      reward_value: 2500,
    },
  ];
  leftColumnSkillCardData: any = [
    {
      topic: 'Earn',
      short_description:
        'Learn how to find and land the jobs that are right for you.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/2nyZZD57lWOV450i9S72pc/68cfacc9bdae3c565c28808a281c2fb1/Frame_1461.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/5mLHxzlbPtZgNl6JzalgQA/0f8daba6479bad1ad63511db9ee4a1b5/034-refund.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/5eThaKzAxMv3lQyjlx0etm/2babb725f980a3c28ca1674eb6bb4faf/112-money.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 29,
      estimated_completion_time: 61,
      reward_value: 2500,
    },
    {
      topic: 'Spend',
      short_description:
        'Learn to manage your money and make smart spending decisions.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/2R2Clb9v3jc2uCLUgEkTF4/e8dc1bad7f2edb42a9d105bc423d73f6/014-coins.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/6Cii3NUhOBUNaihhzGgZ65/3a1c81ea56375e1ca55391d0764cabbc/005-money.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/3H3OXk3Tgnuhky8gkLomDB/36b62831b78bf4d3cc8b060c7ac06550/020-invoice.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 27,
      estimated_completion_time: 57,
      reward_value: 2500,
    },
    {
      topic: 'Protect',
      short_description:
        'Understand your risks and what you can do to mitigate them.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/71hZmXRoyoHc45px49rs6x/5ea87e8ca4562788e5d255bfe8061bd8/024-lock.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/4ioY0rXg4NVZUWVl8hMeWJ/8fbd8e0b433a44612d72412436ac4f9b/020-case.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/4tRczqt3qlY3daYb6BEGet/069da302dc2fd2242ebc5c2f8f01cf2d/012-donation.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 24,
      estimated_completion_time: 51,
      reward_value: 2500,
    },
  ];
  rightColumnSkillCardData: any = [
    {
      topic: 'Save & Invest',
      short_description: 'Learn how to save money and invest for your future.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/6nkDMdWYQicEOqPckeVGBT/aa6cd29d15da80ce3895e737e0e034b1/039-money-2.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/3lfcbFSIG0oDLm0HE9UhnT/f94b0de956a05b95d2c32be016a6a6c7/046-target.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/3lfcbFSIG0oDLm0HE9UhnT/f94b0de956a05b95d2c32be016a6a6c7/046-target.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 26,
      estimated_completion_time: 55,
      reward_value: 2500,
    },
    {
      topic: 'Borrow',
      short_description: 'Learn about borrowing money and managing debt.',
      image: {
        classic:
          'https://images.ctfassets.net/o1xn7flb83yr/6YZlIeifGQlVOxpe0dktYR/0b9cc036a4f9b5dd652868a3096b512a/017-money_tag.svg',
        playful:
          'https://images.ctfassets.net/o1xn7flb83yr/4PjQasiANJM8YaFGGoUk7a/6ceaef89eb73304af232b7b0a7dc2134/009-income.svg',
        clean:
          'https://images.ctfassets.net/o1xn7flb83yr/7lgKKk9sluZpgPsQ40acLh/4e46af0a5cb32c8225b9217df7da294a/011-coin.svg',
      },
      progress: 0,
      modules_completed: 0,
      modules_total_count: 25,
      estimated_completion_time: 53,
      reward_value: 2500,
    },
  ];

  constructor() {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  substituteInstitutionName(text) {
    if (text) {
      return text.replaceAll(
        '${institution_name}',
        this.userData.institution.short_name
      );
    } else {
      return '';
    }
  }

  substituteClassName(text) {
    if (text) {
      return text.replaceAll('${class_name}', 'My Class 101');
    } else {
      return '';
    }
  }
}
