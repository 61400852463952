<div *ngIf="!tableData && !errorLoading">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div *ngIf="tableData" class="metric-table-frame">
  <div class="table-title h5-bold text-color-neutral-800">
    {{ widgetData.widget_display_name }}
  </div>
  <table class="metric-table">
    <thead>
      <tr class="header-row">
        <th
          *ngFor="let column of tableData.table.columns; let i = index"
          class="column-header overline-medium text-color-neutral-800"
          [ngStyle]="{
            'text-align': column.column_alignment,
            width: column.column_width * 100 + '%'
          }"
        >
          {{ column.column_text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of tableData.table.rows; let i = index"
        class="body-row"
      >
        <td
          *ngFor="let column of tableData.table.columns"
          class="body-medium"
          [ngClass]="{ last: i === tableData.table.rows.length - 1 }"
          [ngStyle]="{
            'text-align': column.column_alignment,
            color: row[column.column_key].text_color
              ? row[column.column_key].text_color
              : 'var(--neutral-800)'
          }"
        >
          {{ row[column.column_key].value }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
