<div class="email-reminder-modal-wrapper">
  <div class="email-reminder-modal-header">
    <div class="h4-medium text-color-neutral-800">Get Email Reminders</div>
    <svg
      class="dismiss-cross"
      (click)="dismiss()"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#6F6F6F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#6F6F6F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div
    class="email-reminder-modal-sub-header caption-book text-color-neutral-800"
  >
    Receive updates in your inbox when new social calendar samples are added
  </div>
  <app-field-text-input
    class="email-input"
    inputId="snippet-title"
    inputTitle="Email"
    [disableMaxLength]="true"
    [isRequired]="true"
    [isEditable]="true"
    wrap="soft"
    [value]="email"
    rows="1"
    (valueChange)="handleEmailInput($event)"
  ></app-field-text-input>
  <zogo-btn
    class="submit-btn"
    label="Submit"
    [fullWidth]="false"
    size="medium"
    buttonStyle="primary"
    (clickFunction)="submit()"
    [disabled]="!isValidEmail()"
  >
  </zogo-btn>
</div>
