import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RequestsService } from '../../requests.service';
import { CopyService } from '../../copy.service';
import { MatDialog } from '@angular/material/dialog';
import { BudgetingDialogComponent } from './budgeting-dialog/budgeting-dialog.component';

export interface DialogData {
  budgetingMarkdown: any;
}
@Component({
  // eslint-disable-next-line
  selector: 'side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.css'],
})
export class SideComponent implements OnInit {
  userData: any;
  isAdmin: boolean;
  isInstitutionGroup: boolean;
  isAllInstitution: boolean;
  activeMenuIndex: string = 'analytics';
  expandInstitutionSelect: boolean = false;
  marketingAssetPageTab: string = 'social-media';

  constructor(
    public req: RequestsService,
    public appCopy: CopyService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.isInstitutionGroup = Boolean(
      this.userData.institution_user.institution_options
    );

    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    if (
      this.userData.institution.platform_access_types.some(
        item => item.platform === 'native'
      )
    ) {
      this.marketingAssetPageTab = 'social-media';
    } else {
      this.marketingAssetPageTab = 'brand-graphics';
    }

    if (this.userData.institution.id === 100) {
      this.isAllInstitution = true;
    } else {
      this.isAllInstitution = false;
    }
    this.setActiveMenuIndex(this.router.url);
  }

  setActiveMenuIndex(route: string) {
    if (
      route === '/custom-color' ||
      route === '/custom-logos' ||
      route === '/zogo-web-customizations'
    ) {
      this.activeMenuIndex = 'branding';
    } else if (
      route === '/content-library' ||
      route === '/modules' ||
      route === '/custom-surveys'
    ) {
      this.activeMenuIndex = 'content';
    } else if (route.includes('/settings') || route === '/access-codes') {
      this.activeMenuIndex = 'institution';
    } else if (
      route === '/resources' ||
      route === '/social-calendar' ||
      route.includes('/marketing-assets')
    ) {
      this.activeMenuIndex = 'resources';
    } else if (route === '/contact-us') {
      this.activeMenuIndex = 'support';
    } else {
      this.activeMenuIndex = 'analytics';
    }
  }

  isActiveMenuLink(link: string): boolean {
    return this.router.url.startsWith(`/${link}`);
  }

  goToOverview() {
    this.router.navigate(['overview']);
    this.setActiveMenuIndex('/overview');
  }

  openBillingAlert() {
    // Amplitude event - Billing Breakdown Viewed
    this.req.ampTrack('Billing Breakdown Viewed');
    this.dialog.open(BudgetingDialogComponent, {
      width: '400px',
      data: {
        budgetingMarkdown:
          this.userData.institution.budgeting.budgeting_markdown_explanation,
      },
    });
  }

  openHelpCenter() {
    this.req.getHelpCenterLink().subscribe({
      next: data => {
        // Amplitude event - Help Center Viewed
        this.req.ampTrack('Help Center Viewed');
        // Client Success event - Help Center Viewed
        this.req.csTrack('Help Center Viewed');

        this.req.openURL(data.help_center_link);
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  toggleDropdown(section: string) {
    this.activeMenuIndex = this.activeMenuIndex === section ? null : section;
  }

  toggleInstitutionSelect() {
    this.expandInstitutionSelect = !this.expandInstitutionSelect;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent) {
    if (this.expandInstitutionSelect) {
      this.expandInstitutionSelect = false;
      return;
    }
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const clickedInside = element.closest('.institution-select');

    if (!clickedInside) {
      this.expandInstitutionSelect = false;
    }
  }

  selectInstitution(institution) {
    this.req
      .patchDashboardUserInstitution({
        institution_id: institution.id,
      })
      .subscribe({
        next: data => {
          window.location.href = '/';
        },
        error: err => {
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }
}
