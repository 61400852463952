<div class="modal-container">
  <svg
    class="dismiss-cross"
    (click)="dismiss()"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#6F6F6F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <div class="dialog-header-text header-text-margin">
    {{ data.inviteHeader }}
  </div>
  <div class="dialog-body-text">{{ data.inviteBody }}</div>
  <div class="dialog-header-text">{{ data.shareLinkHeader }}</div>
  <div class="dialog-body-text">{{ data.shareLinkBody }}</div>
  <div class="share-container">
    <div class="share-text">{{ data.shareLink }}</div>
    <button
      (click)="copyToClipboard()"
      class="blue dialog-confirm-btn-text"
      mat-button
    >
      Copy
    </button>
  </div>
</div>
