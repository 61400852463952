<div class="highlight-wrapper">
  <div class="category-highlights" #scrollContainer>
    <div class="highlight-card" *ngFor="let category of categoryHighlightsData">
      <div class="stats-wrapper">
        <div class="accuracy-wrapper">
          <span class="h3-bold text-color-neutral-800 accuracy-display">
            <zogo-icon
              *ngIf="category.accuracy_change.percent_change !== 0"
              [iconName]="
                category.accuracy_change.change_direction === 'positive'
                  ? 'arrow-up'
                  : 'arrow-down'
              "
            ></zogo-icon>
            {{ category.accuracy_change.percent_change }}%
          </span>
          <div class="overline-medium text-color-neutral-800">
            Change in Accuracy
          </div>
        </div>
        <div class="confidence-wrapper">
          <span class="h3-bold text-color-neutral-800">
            {{ category.confidence_change.percent_change }}%
          </span>
          <div class="overline-medium text-color-neutral-800">
            Increased Confidence
          </div>
        </div>
      </div>
      <h4 class="h5-bold text-color-neutral-800 margin-top-16 category-name">
        {{ category.category_name }}
      </h4>
    </div>
  </div>
  <zogo-pagination-arrow
    *ngIf="categoryHighlightsData.length > 1"
    class="back-arrow"
    type="bold"
    icon="left"
    [disabled]="disableScrollLeft"
    (pageChange)="scrollBack()"
  >
  </zogo-pagination-arrow>
  <zogo-pagination-arrow
    *ngIf="categoryHighlightsData.length > 1"
    class="next-arrow"
    type="bold"
    icon="right"
    [disabled]="disableScrollRight"
    (pageChange)="scrollNext()"
  >
  </zogo-pagination-arrow>
</div>
