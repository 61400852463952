import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  constructor() {}

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const email = control.value as string;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(email)) {
        return { invalidEmailFormat: true };
      }

      return null;
    };
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const phonePattern =
        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return phonePattern.test(control.value)
        ? null
        : { invalidPhoneNumber: true };
    };
  }

  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const urlPattern =
        /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
      return urlPattern.test(control.value) ? null : { invalidUrl: true };
    };
  }

  subdomainValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const validCharacters = /^[a-zA-Z0-9-]*$/.test(control.value);

      const noEdgeHyphens = !/^[-]|[-]$/.test(control.value);

      const withinMaxLength = control.value.length <= 30;

      const isValid = validCharacters && noEdgeHyphens && withinMaxLength;

      return isValid ? null : { invalidSubdomain: true };
    };
  }

  accessCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const accessCodePattern = /^[a-zA-Z0-9]{4,10}$/;
      return accessCodePattern.test(control.value)
        ? null
        : { invalidAccessCode: true };
    };
  }
}
