import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests.service';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css'],
})
export class SchoolsComponent implements OnInit {
  searchInput: any;
  classData: any;
  emptyClassData: any;
  filteredClassData: any;
  userData: any;

  constructor(public req: RequestsService) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.req.getDashboardSchoolOverview().subscribe({
      next: data => {
        this.classData = data.classes;
        this.emptyClassData = data.empty_classes;
        this.filteredClassData = this.classData;
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  isZogoWeb() {
    return this.userData.institution.platform_access_types.some(
      type => type.platform === 'web'
    );
  }

  filterResults() {
    const keyword = this.searchInput.trim().toLowerCase();
    const filteredResults = this.classData.filter(classroom => {
      const className = String(classroom.name).trim().toLowerCase();
      const classSchool = String(classroom.school).trim().toLowerCase();
      return (
        className.indexOf(keyword) !== -1 || classSchool.indexOf(keyword) !== -1
      );
    });
    this.filteredClassData = filteredResults;
  }

  calculateClassroomLink() {
    return `https://app.zogofinance.com?institution_code=${this.userData.institution.budgeting.code}`;
  }

  copyClassroomLink() {
    navigator.clipboard.writeText(this.calculateClassroomLink()).then(() => {
      this.req.openSnackBar('LINK COPIED!', 'Okay');
    });
  }
}
