<div class="d-flex-col gap-24">
  <zogo-btn
    class="ion-align-self-end"
    label="Edit"
    (clickFunction)="editAccount()"
  ></zogo-btn>
  <div class="account-card d-flex-row gap-24">
    <div class="icon-wrapper">
      <zogo-icon
        iconName="briefcase"
        class="text-color-neutral-white"
      ></zogo-icon>
    </div>
    <div class="text-wrapper d-flex-col gap-16">
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Full Insitution Name</p>
        <p class="body-book text-color-neutral-600">
          {{ accountFormData.fullInstName }}
        </p>
      </div>
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Preferred Institution Abbreviation</p>
        <p class="body-book text-color-neutral-600">
          {{ accountFormData.preferredAbbr }}
        </p>
      </div>
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Institution Type</p>
        <p class="body-book text-color-neutral-600">
          {{ accountFormData.institutionType }}
        </p>
      </div>
      <div class="d-flex-col gap-4">
        <p class="caption-medium">Website Url</p>
        <p class="body-book text-color-neutral-600">
          {{ accountFormData.websiteUrl }}
        </p>
      </div>
    </div>
  </div>
</div>
