import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPdfModalComponent } from 'src/app/dialogs/request-pdf-modal/request-pdf-modal.component';
import { CopyService } from 'src/app/copy.service';
import { TEST_DATA } from '../overview/TEST_DATA';

@Component({
  selector: 'app-pre-post-test',
  templateUrl: './pre-post-test.component.html',
  styleUrls: ['./pre-post-test.component.scss'],
})
export class PrePostTestComponent implements OnInit {
  TEST_DATA: any;
  skillResultsInfoData;
  copy: any;
  copyGlobal: any;
  userData: any;
  timeFrameSelectedOption: any = 'all'; // thirty | ninety | all
  currentSegment: any;
  activeTab: any;
  activeSubTab: any;
  notifAlertMessage1: string;
  notifAlertButtonText1: string;
  notifAlertButtonFunc1: any;
  notifAlertStatus1: string;
  isNotifAlertEnabled1: boolean = false;
  skillData: any;
  filteredSkillData: any;
  selectedCategory: any = 'all';

  ALL_ACCESS_CODE_OPTION: any = {
    value: 'all',
    display_value: 'All Users',
  };

  constructor(
    public req: RequestsService,
    public appCopy: CopyService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Amplitude event - Pre/Post Test Results Viewed
    this.req.ampTrack('Pre/Post Test Results Viewed');

    this.TEST_DATA = TEST_DATA;
    this.copy = this.appCopy.getOverviewPage();
    this.copyGlobal = this.appCopy.getDashboardGlobalCopy();
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData.institution.community_segments_enabled) {
      this.currentSegment = this.ALL_ACCESS_CODE_OPTION;
    }

    this.req.getDashboardSkillResultsInfo().subscribe({
      next: data => {
        this.skillResultsInfoData = data;
        this.activeTab = this.skillResultsInfoData.tabs[0];
        if (this.activeTab.sub_tabs?.length) {
          this.activeSubTab = this.activeTab.sub_tabs[0];
        }
        this.updateSkillData();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  updateSkillData() {
    this.filteredSkillData = null;
    const reqObj = {
      platform: this.getActivePlatform(),
      time_frame: this.timeFrameSelectedOption,
      code: this.currentSegment?.value,
    };
    this.req.getDashboardSkillResultsData(reqObj).subscribe({
      next: data => {
        this.skillData = data;
        this.filterSkillData();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  getActivePlatform() {
    if (this.activeTab.sub_tabs?.length) {
      return this.activeSubTab.platform;
    }
    return this.activeTab.platform;
  }

  onSelectionChange(event) {
    this.timeFrameSelectedOption = event.value;
    this.updateSkillData();
  }

  onCategorySelectionChange(event) {
    this.filterSkillData();
  }

  filterSkillData() {
    if (this.selectedCategory === 'all') {
      this.filteredSkillData = this.skillData;
    } else {
      const filteredData = {
        category_overviews: [],
        skill_results: [],
      };
      filteredData.category_overviews =
        this.skillData.category_overviews.filter(
          cat => cat.category_id === this.selectedCategory
        );
      filteredData.skill_results = this.skillData.skill_results.filter(
        skill => skill.category_id === this.selectedCategory
      );

      this.filteredSkillData = filteredData;
    }
  }

  updateSegment(event) {
    this.currentSegment = event;
    this.updateSkillData();
  }

  openRequestPdfDialog() {
    // Amplitude event - Request Pre/Post Test PDF Clicked
    this.req.ampTrack('Request Pre/Post Test PDF Clicked');

    this.dialog.open(RequestPdfModalComponent, {
      width: '460px',
      height: '500px',
      panelClass: 'no-scroll-modal',
      data: {
        currentSegment: this.currentSegment,
        timeFrame: this.timeFrameSelectedOption,
        requestType: 'pre-post-test',
        requestPdfReport: this.requestPdfReport.bind(this),
        dismiss: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  requestPdfReport(body) {
    this.req.postDashboardRequestPdf(body).subscribe({
      next: data => {
        this.setNotifAlertRequestPdf();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  setNotifAlertRequestPdf() {
    this.notifAlertMessage1 =
      'Your pre/post test report has been sent to your email! It may take up to 15 minutes.';
    this.notifAlertButtonText1 = null;
    this.notifAlertButtonFunc1 = null;
    this.notifAlertStatus1 = 'success';
    this.isNotifAlertEnabled1 = true;
  }

  dismissNotifAlert1() {
    this.isNotifAlertEnabled1 = false;
  }

  selectTab(selectedTab) {
    if (this.activeTab.platform === selectedTab.platform) {
      return;
    }
    if (selectedTab.sub_tabs?.length) {
      this.activeSubTab = selectedTab.sub_tabs[0];
      this.req.updateOverviewPlatform(this.activeSubTab.platform);
    }
    this.activeTab = selectedTab;
    this.updateSkillData();
  }

  handleSubTabSelection(selectedSubTab) {
    if (this.activeSubTab.platform === selectedSubTab.platform) {
      return;
    }
    this.activeSubTab = selectedSubTab;
    this.updateSkillData();
  }

  getViewOnlyLink() {
    this.req.getDashboardShareableWidgetLink().subscribe({
      next: data => {
        const url = data.url + '?page=pre-post-test';
        this.copyToClipboard(url);
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Amplitude event - Pre/Post Test Share Link Copied
        this.req.ampTrack('Pre/Post Test Share Link Copied');
        this.req.openSnackBar('View-only share link copied', 'Okay');
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }
}
