import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SideComponent } from './navigation/side/side.component';
import { HeaderComponent } from './navigation/header/header.component';
import { ModulesComponent } from './customization/modules/modules.component';
import { ContentLibraryComponent } from './customization/content-library/content-library.component';
import { EditorComponent } from './customization/modules/editor/editor.component';
import { SnippetsComponent } from './customization/modules/editor/snippets/snippets.component';
import { QuestionsComponent } from './customization/modules/editor/questions/questions.component';
import { LoginComponent } from './login/login.component';
import { CanDeactivateGuard } from './guards/can-deactivate-guard.service';
import { DialogService } from './guards/dialog.service';

// Google Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DemoComponent } from './demo/demo.component';
import { ErrorDialogComponent } from './components/dialog/error.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgChartsModule } from 'ng2-charts';

import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PublicComponent } from './public/public.component';
import { SchoolsComponent } from './schools/schools.component';
import { BudgetingDialogComponent } from './navigation/side/budgeting-dialog/budgeting-dialog.component';
import { ConfirmDialogComponent } from './customization/modules/confirm-dialog/confirm-dialog.component';
import { MetricCardComponent } from './overview/metric-card/metric-card.component';
import { CxEmailSnippetComponent } from './cx-email-snippet/cx-email-snippet.component';
import { ZogoPartnersLogoComponent } from './zogo-partners-logo/zogo-partners-logo.component';
import { OnboardingAccountSettingsComponent } from './onboarding/onboarding-account-settings/onboarding-account-settings.component';
import { OnboardingPaymentSettingsComponent } from './onboarding/onboarding-payment-settings/onboarding-payment-settings.component';
import { OnboardingTeamMembersComponent } from './onboarding/onboarding-team-members/onboarding-team-members.component';
import { OnboardingLogoUploadComponent } from './onboarding/onboarding-logo-upload/onboarding-logo-upload.component';
import { OnboardingZipCodesComponent } from './onboarding/onboarding-zip-codes/onboarding-zip-codes.component';
import { OnboardingHeaderComponent } from './onboarding/onboarding-header/onboarding-header.component';
import { NewUserOnboardingComponent } from './onboarding/new-user-onboarding/new-user-onboarding.component';
import { OnboardingWebSubdomainComponent } from './onboarding/onboarding-web-subdomain/onboarding-web-subdomain.component';
import { MyAccountComponent } from './settings/my-account/my-account.component';
import { ErrorComponent } from './error/error.component';
import { AccessCodesComponent } from './settings/access-codes/access-codes.component';
import { ConfirmationModalComponent } from './dialogs/confirmation-modal/confirmation-modal.component';
import { TeamMembersComponent } from './settings/team-members/team-members.component';
import { PaymentsComponent } from './settings/payments/payments.component';
import { MarkdownModule, MarkdownService, provideMarkdown } from 'ngx-markdown';
import { NotifAlertComponent } from './components/notif-alert/notif-alert.component';
import { DndDirective } from './directives/dnd.directive';
import { CustomColorComponent } from './customization/custom-color/custom-color.component';
import { LeaderboardDownloadComponent } from './leaderboard-download/leaderboard-download.component';
import { IconSelectComponent } from './customization/modules/editor/icon-select/icon-select.component';
import { MarketingKitComponent } from './resources/marketing-kit/marketing-kit.component';
import { MarketingKitCardComponent } from './resources/marketing-kit/marketing-kit-card/marketing-kit-card.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HighlightsModalComponent } from './dialogs/highlights-modal/highlights-modal.component';
import { UserDemographicsChartComponent } from './overview/user-demographics-chart/user-demographics-chart.component';
import { GoalsCreatedChartComponent } from './overview/goals-created-chart/goals-created-chart.component';
import { CustomModuleModalComponent } from './dialogs/custom-module-modal/custom-module-modal.component';
import { SelectInstitutionComponent } from './select-institution/select-institution.component';
import { InstitutionSelectDialogComponent } from './navigation/header/institution-select-dialog/institution-select-dialog.component';
import { TeacherAdminComponent } from './settings/teacher-admin/teacher-admin.component';
import { CommunitySegmentSelectComponent } from './components/community-segment-select/community-segment-select.component';
import { InviteConfirmComponent } from './components/invite-confirm/invite-confirm.component';
import { RequestPdfModalComponent } from './dialogs/request-pdf-modal/request-pdf-modal.component';
import { CustomModuleSelectionDialogComponent } from './customization/content-library/custom-module-selection-dialog/custom-module-selection-dialog.component';
import { ModuleConfirmationDialogComponent } from './customization/modules/module-confirmation-dialog/module-confirmation-dialog.component';
import { ZogoLabelComponent } from './components/zogo-label/zogo-label.component';
import { FieldTextInputComponent } from './components/field-text-input/field-text-input.component';
import { SurveyQuestionComponent } from './customization/survey-questions/survey-questions.component';
import { QuestionEditorComponent } from './customization/survey-questions/question-editor/question-editor.component';
import { SocialCalendarComponent } from './resources/social-calendar/social-calendar.component';
import { SocialCalendarEventComponent } from './resources/social-calendar/social-calendar-event/social-calendar-event.component';
import { EmailReminderModalComponent } from './resources/social-calendar/email-reminder-modal/email-reminder-modal.component';
import { MarketingAssetsComponent } from './resources/marketing-assets/marketing-assets.component';
import { OverviewComponent } from './overview/overview.component';
import { PrePostTestComponent } from './pre-post-test/pre-post-test.component';
import { ProgressBarChartComponent } from './overview/progress-bar-chart/progress-bar-chart.component';
import { MetricTableComponent } from './overview/metric-table/metric-table.component';
import { LinkDisplayComponent } from './overview/link-display/link-display.component';
import { SurveyDisplayComponent } from './overview/survey-display/survey-display.component';
import { UserDataReportsComponent } from './overview/user-data-reports/user-data-reports.component';
import { UserMessagesComponent } from './overview/user-messages/user-messages.component';
import { GeckoboardDisplayComponent } from './overview/geckoboard-display/geckoboard-display.component';
import { ProductHighlightsComponent } from './overview/product-highlights/product-highlights.component';
import { CategoryHighlightsComponent } from './pre-post-test/category-highlights/category-highlights.component';
import { SkillDisplayComponent } from './pre-post-test/skill-display/skill-display.component';
import { WidgetDisplayComponent } from './overview/widget-display/widget-display.component';
import { ZogoBadgeComponent } from './design-system/web-components/zogo-badge/zogo-badge.component';
import { ZogoBtnComponent } from './design-system/web-components/zogo-btn/zogo-btn.component';
import { ZogoCheckboxComponent } from './design-system/web-components/zogo-checkbox/zogo-checkbox.component';
import { ZogoChipComponent } from './design-system/web-components/zogo-chip/zogo-chip.component';
import { ZogoDecoratedLinkComponent } from './design-system/web-components/zogo-decorated-link/zogo-decorated-link.component';
import { ZogoDropdownComponent } from './design-system/web-components/zogo-dropdown/zogo-dropdown.component';
import { ZogoFormComponent } from './design-system/web-components/zogo-form/zogo-form.component';
import { ZogoHelpButtonComponent } from './design-system/web-components/zogo-help-button/zogo-help-button.component';
import { ZogoPaginationArrowComponent } from './design-system/web-components/zogo-pagination-arrow/zogo-pagination-arrow.component';
import { ZogoRadioGroupComponent } from './design-system/web-components/zogo-radio-group/zogo-radio-group.component';
import { ZogoRadioButtonComponent } from './design-system/web-components/zogo-radio-group/zogo-radio-button/zogo-radio-button.component';
import { ZogoTextInputComponent } from './design-system/web-components/zogo-text-input/zogo-text-input.component';
import { ZogoUploaderComponent } from './components/zogo-uploader/zogo-uploader.component';
import { ZogoIconComponent } from './design-system/global-components/zogo-icon/zogo-icon.component';
import { ZogoWebCustomizationsComponent } from './zogo-web-customizations/zogo-web-customizations.component';
import { ZogoWebPreviewComponent } from './zogo-web-customizations/zogo-web-preview/zogo-web-preview.component';
import { ZogoWebSkillCardComponent } from './zogo-web-customizations/zogo-web-preview/zogo-web-skill-card/zogo-web-skill-card.component';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LogoCustomizationComponent } from './customization/logo-customization/logo-customization.component';
import { SettingsComponent } from './settings/settings.component';
import { ZipCodesComponent } from './settings/zip-codes/zip-codes.component';
import { CustomValidationDirective } from './directives/custom-validation.directive';
@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    SideComponent,
    HeaderComponent,
    ModulesComponent,
    EditorComponent,
    SnippetsComponent,
    ErrorDialogComponent,
    QuestionsComponent,
    LoginComponent,
    ResetPasswordComponent,
    DemoComponent,
    PublicComponent,
    SchoolsComponent,
    BudgetingDialogComponent,
    ConfirmDialogComponent,
    MetricCardComponent,
    ContentLibraryComponent,
    OnboardingAccountSettingsComponent,
    CxEmailSnippetComponent,
    ZogoPartnersLogoComponent,
    OnboardingPaymentSettingsComponent,
    OnboardingLogoUploadComponent,
    OnboardingZipCodesComponent,
    MyAccountComponent,
    ErrorComponent,
    AccessCodesComponent,
    ConfirmationModalComponent,
    TeamMembersComponent,
    PaymentsComponent,
    NotifAlertComponent,
    NewUserOnboardingComponent,
    DndDirective,
    CustomColorComponent,
    LeaderboardDownloadComponent,
    IconSelectComponent,
    MarketingKitComponent,
    MarketingKitCardComponent,
    ContactUsComponent,
    HighlightsModalComponent,
    UserDemographicsChartComponent,
    GoalsCreatedChartComponent,
    CustomModuleModalComponent,
    SelectInstitutionComponent,
    InstitutionSelectDialogComponent,
    TeacherAdminComponent,
    CommunitySegmentSelectComponent,
    InviteConfirmComponent,
    RequestPdfModalComponent,
    CustomModuleSelectionDialogComponent,
    ModuleConfirmationDialogComponent,
    ZogoLabelComponent,
    FieldTextInputComponent,
    SurveyQuestionComponent,
    QuestionEditorComponent,
    SocialCalendarComponent,
    SocialCalendarEventComponent,
    EmailReminderModalComponent,
    PrePostTestComponent,
    ProgressBarChartComponent,
    MetricTableComponent,
    LinkDisplayComponent,
    SurveyDisplayComponent,
    UserDataReportsComponent,
    UserMessagesComponent,
    GeckoboardDisplayComponent,
    ProductHighlightsComponent,
    CategoryHighlightsComponent,
    WidgetDisplayComponent,
    SkillDisplayComponent,
    ZogoWebCustomizationsComponent,
    ZogoWebPreviewComponent,
    ZogoWebSkillCardComponent,
    MarketingAssetsComponent,
    OnboardingHeaderComponent,
    OnboardingTeamMembersComponent,
    OnboardingWebSubdomainComponent,
    LogoCustomizationComponent,
    SettingsComponent,
    ZipCodesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MatSidenavModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatListModule,
    MatCardModule,
    MatProgressBarModule,
    MatRippleModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    DragDropModule,
    MatTooltipModule,
    MatDialogModule,
    FormsModule,
    MatBottomSheetModule,
    HttpClientModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ColorPickerModule,
    MarkdownModule.forRoot(),
    NgChartsModule,
    MatSlideToggleModule,
    ZogoBadgeComponent,
    ZogoBtnComponent,
    ZogoIconComponent,
    ZogoChipComponent,
    ZogoPaginationArrowComponent,
    ZogoFormComponent,
    ZogoCheckboxComponent,
    ZogoRadioGroupComponent,
    ZogoRadioButtonComponent,
    ZogoTextInputComponent,
    ZogoDecoratedLinkComponent,
    ZogoUploaderComponent,
    ZogoDropdownComponent,
    ZogoHelpButtonComponent,
    CustomValidationDirective,
  ],
  providers: [
    DatePipe,
    CanDeactivateGuard,
    DialogService,
    MarkdownService,
    provideMarkdown(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
