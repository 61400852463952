import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { RequestsService } from '../../../../requests.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-snippets',
  templateUrl: './snippets.component.html',
  styleUrls: ['./snippets.component.css'],
})
export class SnippetsComponent implements OnInit {
  snippet: any = {};
  changed: any = {};
  language: string; // english | spanish

  constructor(
    public dialogRef: MatDialogRef<SnippetsComponent>,
    public req: RequestsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.changed = JSON.parse(JSON.stringify(this.data.snippet));
      this.snippet = this.data.snippet;
      this.language = this.data.language;
    }
  }

  close() {
    if (!this.changed.content_title['en-US']) {
      this.changed.content_title['en-US'] = 'Untitled content';
      this.dialogRef.close({ snippet: this.changed });
    } else {
      this.dialogRef.close({
        snippet: this.snippet,
        cancel: true,
        isNew: this.data.isNew,
      });
    }
  }

  save() {
    this.dialogRef.close({ snippet: this.changed });
  }

  deleteItem() {
    this.dialogRef.close({ snippet: this.changed, delete: true });
  }

  handleCloseButton() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        closeWithoutSaving: this.close.bind(this),
      },
    });
  }

  validateInputs() {
    if (this.language === 'spanish') {
      return true;
    }

    if (
      this.changed.content_title['en-US'].length < 1 ||
      this.changed.content_title['en-US'].length > 50
    ) {
      return false;
    }
    if (
      this.changed.content['en-US'].length < 1 ||
      this.changed.content['en-US'].length > 500
    ) {
      return false;
    }

    return true;
  }

  handleSnippetTitleInput(input: string) {
    if (this.language === 'english') {
      this.changed.content_title['en-US'] = input;
    } else {
      this.changed.content_title['es-US'] = input;
    }
  }

  handleSnippetContentInput(input: string) {
    if (this.language === 'english') {
      this.changed.content['en-US'] = input;
    } else {
      this.changed.content['es-US'] = input;
    }
  }
}
