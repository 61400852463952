<div class="add-teacher-modal-wrapper">
  <zogo-icon
    class="dismiss-icon text-color-neutral-800"
    iconName="x"
    (click)="dismiss()"
  ></zogo-icon>

  <!-- Edit existing teacher -->
  <div *ngIf="mode === 'edit' && !invited">
    <div class="d-flex-col gap-32">
      <h2 class="h4-bold">{{ headerText }}</h2>
      <form
        class="d-flex-col gap-32"
        #form="ngForm"
        (ngSubmit)="onSubmitForm()"
      >
        <div class="d-flex-col gap-24">
          <div class="d-flex-row gap-12">
            <zogo-dropdown
              [(ngModel)]="title"
              name="Title"
              class="title-input"
              label="Title"
              placeholder=""
              required
              [options]="titleOptions"
              #titleDropdown="ngModel"
            ></zogo-dropdown>
            <zogo-text-input
              [(ngModel)]="firstName"
              name="firstName"
              class="name-input"
              inputId="first-name"
              inputLabel="First Name"
              autocompleteType="given-name"
              ariaLabel="Team Member First Name"
              [isRequired]="true"
              [currentValue]="firstName"
              #firstNameInput="ngModel"
            ></zogo-text-input>
            <zogo-text-input
              [(ngModel)]="lastName"
              name="lastName"
              class="name-input"
              inputId="last-name"
              inputLabel="Last Name"
              autocompleteType="family-name"
              ariaLabel="Team Member Last Name"
              [isRequired]="true"
              [currentValue]="lastName"
              #lastNameInput="ngModel"
            ></zogo-text-input>
          </div>

          <zogo-text-input
            [(ngModel)]="email"
            name="contactEmail"
            inputId="contact-email"
            inputLabel="Email Address"
            inputType="email"
            autocompleteType="email"
            ariaLabel="Team Member Email"
            [isRequired]="true"
            [currentValue]="email"
            appCustomValidation="email"
            #teacherEmailInput="ngModel"
          ></zogo-text-input>
          <div
            *ngIf="
              teacherEmailInput.errors?.invalidEmailFormat &&
              teacherEmailInput.touched
            "
            class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
          >
            <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
            <p>Invalid email format</p>
          </div>

          <div class="d-flex-row gap-8">
            <zogo-btn
              buttonStyle="tertiary"
              label="Password reset"
              (clickFunction)="resetPassword(teacherId)"
            ></zogo-btn>
            <zogo-btn
              buttonStyle="tertiary"
              label="Resend invite"
              (clickFunction)="resendInvitation(email)"
            ></zogo-btn>
            <zogo-btn
              buttonStyle="tertiary"
              iconName="trash-2"
              (clickFunction)="removeTeacher(teacherId)"
            ></zogo-btn>
          </div>
        </div>

        <div class="d-flex-row gap-8 padding-top-24 border-top">
          <zogo-btn
            [disabled]="form.invalid"
            type="submit"
            label="Save Changes"
          ></zogo-btn>
          <zogo-btn
            buttonStyle="tertiary"
            label="Cancel"
            (clickFunction)="dismiss()"
          ></zogo-btn>
        </div>
      </form>
    </div>
  </div>

  <!-- Invite new teacher -->
  <div *ngIf="mode === 'invite' && !invited">
    <div class="d-flex-col gap-32">
      <h2 class="h4-bold">{{ headerText }}</h2>
      <form #form="ngForm" (ngSubmit)="onSubmitForm()">
        <div class="d-flex-col gap-32">
          <zogo-text-input
            [(ngModel)]="email"
            name="contactEmail"
            inputId="contact-email"
            inputLabel="Email Address"
            inputType="email"
            autocompleteType="email"
            ariaLabel="Team Member Email"
            [isRequired]="true"
            [currentValue]="email"
            appCustomValidation="email"
            #teacherEmailInput="ngModel"
          ></zogo-text-input>
          <div
            *ngIf="
              teacherEmailInput.errors?.invalidEmailFormat &&
              teacherEmailInput.touched
            "
            class="caption-medium text-color-shades-orange-1 d-flex-row gap-4 ion-align-items-center"
          >
            <zogo-icon iconName="x-circle" size="xsmall"></zogo-icon>
            <p>Invalid email format</p>
          </div>

          <div class="d-flex-row gap-8">
            <zogo-btn
              [disabled]="form.invalid"
              type="submit"
              label="Send Invite"
            ></zogo-btn>
            <zogo-btn
              buttonStyle="tertiary"
              label="Cancel"
              (clickFunction)="dismiss()"
            ></zogo-btn>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- After invite sent -->
  <div *ngIf="invited">
    <div class="d-flex-col gap-32">
      <div class="d-flex-col gap-8">
        <h2 class="h4-bold">Invite sent</h2>
        <p class="body-large-book">
          The teacher will receive an invitation link shortly. Feel free to
          share the link below directly with them as well.
        </p>
      </div>
      <div
        class="email-copy-wrapper d-flex-row ion-justify-content-between ion-align-items-center"
      >
        <div class="d-flex-row gap-12">
          <zogo-icon iconName="copy"></zogo-icon>
          <p class="body-book email-text">{{ inviteLink }}</p>
        </div>
        <zogo-btn
          [label]="copyButtonLabel"
          (clickFunction)="copyText(inviteLink)"
        ></zogo-btn>
      </div>
    </div>
  </div>
</div>
