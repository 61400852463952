import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RequestsService } from '../../requests.service';
import { CopyService } from '../../copy.service';
import { MatDialog } from '@angular/material/dialog';
import { InstitutionSelectDialogComponent } from './institution-select-dialog/institution-select-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('institutionSelect', { static: false })
  public institutionSelectRef: ElementRef;

  userData: any;
  isAdmin: boolean;
  selectedOption: any;
  isInstitutionGroup: boolean;

  constructor(
    public req: RequestsService,
    public appCopy: CopyService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.isInstitutionGroup = Boolean(
      this.userData.institution_user.institution_options
    );

    if (this.isInstitutionGroup) {
      this.selectedOption =
        this.userData.institution_user.institution_options.find(inst => {
          return inst.id === this.userData.institution.id;
        });
    }
    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.req.userData.subscribe({
      next: data => {
        this.userData = data;
      },
      error: err => {},
    });

    const hasSeenInstitutionSelectTutorial = localStorage.getItem(
      'hasSeenInstitutionSelectTutorial'
    );

    if (
      !hasSeenInstitutionSelectTutorial &&
      this.userData.institution_user.institution_options
    ) {
      localStorage.setItem('hasSeenInstitutionSelectTutorial', 'true');
      this.dialog.open(InstitutionSelectDialogComponent, {
        hasBackdrop: true,
        backdropClass: 'institution-select-backdrop',
        width: '300px',
        position: {
          top: '68px',
          right: '240px',
        },
      });
    }
  }

  onSelectionChange(event) {
    this.selectedOption = event.value;
    this.selectInstitution();
  }

  selectInstitution() {
    this.req
      .patchDashboardUserInstitution({
        institution_id: this.selectedOption.id,
      })
      .subscribe({
        next: data => {
          window.location.href = '/';
        },
        error: err => {
          this.req.openSnackBar(
            'There was an error: ' + err.error.message,
            'Okay'
          );
        },
      });
  }
}
