import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestsService } from 'src/app/requests.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/guards/dialog.service';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/dialogs/confirmation-modal/confirmation-modal.component';
interface AnswerObject {
  'en-US': string;
  'es-US': string;
}

interface QuestionDataObject {
  id?: number;
  spanish_enabled: boolean;
  question: {
    'en-US': string;
    'es-US': string;
  };
  description: {
    'en-US': string;
    'es-US': string;
  };
  answers: AnswerObject[];
  status: {
    value: string;
    display: string;
  };
}

@Component({
  selector: 'app-question-editor',
  templateUrl: './question-editor.component.html',
  styleUrls: ['./question-editor.component.css'],
})
export class QuestionEditorComponent implements OnInit {
  questionData: QuestionDataObject;
  questionEditorView: string = 'english';
  originalQuestion: QuestionDataObject;
  hasUnsavedChanges: boolean = false;

  constructor(
    private req: RequestsService,
    private router: Router,
    public dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const navigation = this.router.lastSuccessfulNavigation;
    if (navigation) {
      this.questionData = navigation.extras?.state?.question;
      while (this.questionData?.answers.length < 2) {
        this.handleAddAnswerChoice();
      }
    }
    if (!this.questionData) {
      this.questionData = {
        spanish_enabled: false,
        question: {
          'en-US': '',
          'es-US': '',
        },
        description: {
          'en-US': '',
          'es-US': '',
        },
        answers: [
          { 'en-US': '', 'es-US': '' },
          { 'en-US': '', 'es-US': '' },
        ],
        status: {
          value: 'inactive',
          display: 'Draft',
        },
      };
    }
    this.originalQuestion = JSON.parse(JSON.stringify(this.questionData));
  }

  setUnsavedChange() {
    this.hasUnsavedChanges = true;
  }

  handleBack() {
    this.router.navigate(['custom-surveys']);
  }

  submitSurveyQuestion() {
    this.req.createSurveyQuestionOrUpdate(this.questionData).subscribe({
      next: data => {
        if (this.questionData.status.value === 'inactive') {
          this.req.openSnackBar('Survey question saved as draft.', '');
        } else {
          this.req.openSnackBar('Survey question submitted for approval!', '');
        }
        this.questionData = { ...data };
        this.originalQuestion = JSON.parse(JSON.stringify(this.questionData));
        this.handleBack();
      },
      error: err => {
        this.req.openSnackBar(
          'There was a problem: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  handleSaveAsDraft() {
    if (
      this.questionData.status.value === 'active' ||
      this.questionData.status.value === 'pending'
    ) {
      this.dialog.open(ConfirmationModalComponent, {
        width: '488px',
        data: {
          headerText: 'Save survey question as draft? ',
          bodyText:
            'This action will overwrite your existing survey question contents and change its status to "draft".',
          confirmBtnText: 'Save as Draft',
          cancelBtnText: 'Cancel',
          confirmFunct: () => {
            // Amplitude event - Custom Survey Saved as Draft
            this.req.ampTrack('Custom Survey Saved as Draft', {
              survey_id: this.questionData.id,
            });
            if (
              this.questionData.status.value === 'active' ||
              this.questionData.status.value === 'pending'
            ) {
              this.questionData.status.value = 'inactive';
              this.questionData.status.display = 'Draft';
            }
            this.submitSurveyQuestion();
            this.dialog.closeAll();
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      // Amplitude event - Custom Survey Saved as Draft
      this.req.ampTrack('Custom Survey Saved as Draft', {
        survey_id: this.questionData.id,
      });
      this.submitSurveyQuestion();
    }
  }

  handleSubmitForApproval() {
    if (this.questionData.status.value === 'active') {
      this.dialog.open(ConfirmationModalComponent, {
        width: '488px',
        data: {
          headerText: 'Submit survey question for approval? ',
          bodyText: 'This survey question will appear for users once approved.',
          confirmBtnText: 'Submit',
          cancelBtnText: 'Cancel',
          confirmFunct: () => {
            // Amplitude event - Custom Survey Submitted for Approval
            this.req.ampTrack('Custom Survey Submitted for Approval', {
              survey_id: this.questionData.id,
            });
            if (this.questionData.status.value === 'active') {
              this.questionData.status.value = 'pending';
              this.questionData.status.display = 'Pending Approval';
            }
            this.submitSurveyQuestion();
            this.dialog.closeAll();
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      this.questionData.status.value = 'pending';
      this.questionData.status.display = 'Pending Approval';
      // Amplitude event - Custom Survey Submitted for Approval
      this.req.ampTrack('Custom Survey Submitted for Approval', {
        survey_id: this.questionData.id,
      });
      this.submitSurveyQuestion();
    }
  }

  handleDelete() {
    if (this.questionData?.id) {
      this.dialog.open(ConfirmationModalComponent, {
        width: '488px',
        data: {
          headerText: 'Delete Survey Question? ',
          bodyText:
            'This survey question will be permanently deleted from your list.',
          confirmBtnText: 'Delete',
          cancelBtnText: 'Cancel',
          confirmFunct: () => {
            this.req.deleteSurveyQuestion(this.questionData?.id).subscribe({
              next: data => {
                this.req.openSnackBar('Survey question deleted.', '');
                this.dialog.closeAll();
                this.handleBack();
              },
              error: err => {
                this.req.openSnackBar(
                  'There was a problem deleting: ' + err.error.message,
                  'Okay'
                );
              },
            });
          },
          cancelFunct: () => {
            this.dialog.closeAll();
          },
        },
      });
    } else {
      this.handleBack();
    }
  }

  handleIncludeSpanish() {
    if (this.questionData.question['es-US'].length > 0) {
      return true;
    }
    return false;
  }

  handleAddAnswerChoice() {
    this.questionData.answers.push({ 'en-US': '', 'es-US': '' });
  }

  handleDeleteAnswerChoice(index: number) {
    this.questionData.answers.splice(index, 1);
  }

  handleLanguageChange(language: string) {
    this.questionEditorView = language;
  }

  handleQuestionInput(question: string) {
    if (this.questionEditorView === 'english') {
      this.questionData.question['en-US'] = question;
    } else {
      this.questionData.question['es-US'] = question;
      this.questionData.spanish_enabled = this.handleIncludeSpanish();
    }
  }

  handleQuestionDescriptionInput(description: string) {
    if (this.questionEditorView === 'english') {
      this.questionData.description['en-US'] = description;
    } else {
      this.questionData.description['es-US'] = description;
    }
  }

  handleAnswerChoiceInput(answer: string, index: number) {
    if (this.questionEditorView === 'english') {
      this.questionData.answers[index]['en-US'] = answer;
    } else {
      this.questionData.answers[index]['es-US'] = answer;
    }
  }

  validateForm(): boolean {
    return this.validateQuestionField() && this.validateAnswerFields();
  }

  validateQuestionField(): boolean {
    if (this.questionData.spanish_enabled) {
      if (
        this.questionData.question['en-US']?.length > 0 &&
        this.questionData.question['es-US']?.length > 0
      ) {
        return true;
      }
    } else if (this.questionData.question['en-US']?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validateAnswerFields(): boolean {
    if (this.questionData.spanish_enabled) {
      return this.questionData.answers.every(
        answer => answer['en-US']?.length > 0 && answer['es-US']?.length > 0
      );
    } else {
      return this.questionData.answers.every(
        answer => answer['en-US']?.length > 0
      );
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.questionData) {
      return;
    }
    if (
      JSON.stringify(this.questionData) !==
      JSON.stringify(this.originalQuestion)
    ) {
      return this.dialogService.confirm(
        'You have unsaved changes! Are you sure you want to leave?'
      );
    }
    return true;
  }
}
