<div class="dashboard-view">
  <div class="page-header-flex-container">
    <h1 class="marketing-kit-title h3-bold">Guides & Resources</h1>
  </div>
  <div *ngIf="!marketingKitData">
    <ion-spinner name="circular"></ion-spinner>
  </div>

  <section *ngIf="marketingKitData" class="d-flex-col gap-24">
    <h2 class="h4-bold">Get Started</h2>
    <div *ngIf="marketingKitData" class="download-card-wrapper">
      <app-marketing-kit-card
        *ngFor="let cardData of marketingKitData?.get_started_resources"
        [marketingCardData]="cardData"
      ></app-marketing-kit-card>
    </div>

    <h2 class="h4-bold">Platform Resources</h2>
    <div *ngIf="marketingKitData" class="download-card-wrapper">
      <app-marketing-kit-card
        *ngFor="let cardData of marketingKitData?.platform_resources"
        [marketingCardData]="cardData"
      ></app-marketing-kit-card>
    </div>
  </section>
</div>
