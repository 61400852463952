<div class="question-app-frame">
  <div class="header header-area h4-bold text-color-neutral-800">
    Add a Question
    <div (click)="handleCloseButton()" class="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
  </div>
  <div class="question-text-field-area">
    <app-field-text-input
      inputId="question"
      inputTitle="Question"
      [maxLength]="125"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'english'"
      [value]="changed.content_title['en-US']"
      isResizable="true"
      (valueChange)="handleQuestionTitleInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="question"
      inputTitle="Question"
      [maxLength]="125"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'spanish'"
      [preview]="changed.content_title['en-US']"
      [value]="changed.content_title['es-US']"
      isResizable="true"
      (valueChange)="handleQuestionTitleInput($event)"
    ></app-field-text-input>

    <app-field-text-input
      inputId="question-description"
      inputTitle="Question Description"
      [maxLength]="200"
      [isRequired]="false"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'english'"
      [value]="changed.content_description['en-US']"
      isResizable="true"
      (valueChange)="handleQuestionDescriptionInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="question-description"
      inputTitle="Question Description"
      [maxLength]="200"
      [isRequired]="false"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'spanish'"
      [preview]="changed.content_description['en-US']"
      [value]="changed.content_description['es-US']"
      isResizable="true"
      (valueChange)="handleQuestionDescriptionInput($event)"
    ></app-field-text-input>

    <app-field-text-input
      inputId="question-explanation"
      inputTitle="Question Explanation"
      [maxLength]="250"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'english'"
      [value]="changed.content['en-US']"
      isResizable="true"
      (valueChange)="handleQuestionExplanationInput($event)"
    ></app-field-text-input>
    <app-field-text-input
      inputId="question-explanation"
      inputTitle="Question Explanation"
      [maxLength]="250"
      [isRequired]="true"
      [isEditable]="true"
      wrap="soft"
      *ngIf="language === 'spanish'"
      [preview]="changed.content['en-US']"
      [value]="changed.content['es-US']"
      isResizable="true"
      (valueChange)="handleQuestionExplanationInput($event)"
    ></app-field-text-input>
    <div
      class="body-medium text-color-neutral-800 top-question input-header-container"
    >
      Multiple Choice Answers
      <div class="error-text-color">*</div>
    </div>
    <div class="gray-card">
      <div
        *ngIf="language === 'english'"
        class="draggable-list"
        cdkDropList
        (cdkDropListDropped)="dropQuestion($event)"
      >
        <div
          class="draggable-item"
          *ngFor="let ans of changed?.answers; let i = index"
          cdkDrag
        >
          <div class="draggable-header">
            <app-field-text-input
              inputId="answer"
              inputTitle="Answer"
              [maxLength]="125"
              [isRequired]="false"
              [isEditable]="true"
              wrap="soft"
              [value]="ans.answer['en-US']"
              isResizable="true"
              rows="1"
              (valueChange)="handleAnswerInput($event, i)"
            ></app-field-text-input>
          </div>
          <div class="draggable-actionbar" style="padding-bottom: 8px">
            <mat-radio-group
              *ngIf="!is_survey"
              name="mark"
              [(ngModel)]="ansOption"
              aria-label="Select an option"
            >
              <mat-radio-button
                name="mark body-medium text-color-neutral-800"
                (change)="setNewAns(ans)"
                [value]="ans.answer"
                >Mark as correct</mat-radio-button
              >
            </mat-radio-group>
            <div
              (click)="removeItem(i)"
              class="remove-item caption-bold text-color-shades-orange-1"
            >
              Remove
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="language === 'spanish'" class="draggable-list">
        <div
          class="draggable-item"
          *ngFor="let ans of changed?.answers; let i = index"
        >
          <div class="draggable-header">
            <app-field-text-input
              inputId="answer"
              inputTitle="Answer"
              [maxLength]="125"
              [isRequired]="false"
              [isEditable]="true"
              wrap="soft"
              [preview]="ans.answer['en-US']"
              [value]="ans.answer['es-US']"
              isResizable="true"
              rows="1"
              (valueChange)="handleAnswerInput($event, i)"
            ></app-field-text-input>
          </div>
          <div class="draggable-actionbar" style="padding-bottom: 8px">
            <mat-radio-group
              *ngIf="!is_survey"
              name="mark"
              [(ngModel)]="ansOption"
              aria-label="Select an option"
            >
              <mat-radio-button
                name="mark body-medium text-color-neutral-800"
                (change)="setNewAns(ans)"
                [value]="ans.answer"
                disabled
                >Mark as correct</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div
        *ngIf="language === 'english'"
        (click)="addOption()"
        class="add-draggable"
      >
        <span class="icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line></svg
        ></span>
        <span class="body-medium text-color-neutral-600"
          >Add another option</span
        >
      </div>
    </div>
  </div>
  <div class="footer-area">
    <zogo-btn
      *ngIf="language === 'english'"
      label="Delete"
      [fullWidth]="false"
      size="medium"
      buttonStyle="secondary"
      (clickFunction)="deleteItem()"
    >
    </zogo-btn>
    <div *ngIf="language !== 'english'" class="btn-placeholder"></div>
    <zogo-btn
      [disabled]="!validateInputs()"
      label="Save"
      [fullWidth]="false"
      size="medium"
      buttonStyle="primary"
      (clickFunction)="save()"
    >
    </zogo-btn>
  </div>
</div>
