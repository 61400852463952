import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-category-highlights',
  templateUrl: './category-highlights.component.html',
  styleUrls: ['./category-highlights.component.scss'],
})
export class CategoryHighlightsComponent implements OnInit, OnChanges {
  @Input() categoryHighlightsData: any;

  disableScrollLeft: boolean = true;
  disableScrollRight: boolean = true;

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.checkScrollPosition();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.checkScrollPosition();
    }, 0);
  }

  scrollNext(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 412;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 412;
    const currentPosition = wrapperElement.scrollLeft;
    const nextPosition = currentPosition + calculatedSectionWidth;
    wrapperElement.scrollTo({ left: nextPosition, behavior: 'smooth' });
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  scrollBack(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 412;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 412;
    const currentPosition = wrapperElement.scrollLeft;
    const previousPosition = currentPosition - calculatedSectionWidth;
    wrapperElement.scrollTo({ left: previousPosition, behavior: 'smooth' });
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  checkScrollPosition(): void {
    const wrapperElement: HTMLElement = this.scrollContainer.nativeElement;
    const maxScrollLeft =
      wrapperElement.scrollWidth - wrapperElement.clientWidth;
    this.disableScrollLeft = wrapperElement.scrollLeft === 0;
    this.disableScrollRight = wrapperElement.scrollLeft >= maxScrollLeft;
  }
}
