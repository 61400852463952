import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { RequestsService } from 'src/app/requests.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-logo-upload',
  templateUrl: './onboarding-logo-upload.component.html',
  styleUrls: ['./onboarding-logo-upload.component.css'],
})
export class OnboardingLogoUploadComponent implements OnInit {
  userData: any;
  logoUrl: any;
  whiteLogoUrl: any;

  constructor(
    private onboarding: OnboardingService,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  async initializeInputs() {
    if (this.userData.institution.image) {
      this.logoUrl = await this.getBase64FromUrl(
        this.userData.institution.image
      );
    }
    if (this.userData.institution.snippet_logo_image) {
      this.whiteLogoUrl = await this.getBase64FromUrl(
        this.userData.institution.snippet_logo_image
      );
    }
  }

  async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  onImageUpload(imageObject, logoType: 'primary' | 'white') {
    if (logoType === 'primary') {
      this.logoUrl = imageObject.croppedImage;
    } else {
      this.whiteLogoUrl = imageObject.croppedImage;
    }
  }

  hasValidLogos() {
    return this.logoUrl && this.whiteLogoUrl;
  }

  onSubmitLogos() {
    const request = {
      page: 'logo',
      logo_image: this.logoUrl,
      logo_image_white: this.whiteLogoUrl,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Logo Upload Completed
        this.req.ampTrack('Logo Upload Completed');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));
        this.req.postDashboardOnboarding({ page: 'logo' }).subscribe({
          next: data => {
            this.onboarding.handleContinue();
          },
          error: err => {
            this.toastService.showToast(
              'There was an error: ' + err.error.message,
              6000
            );
            console.log('errors', err.error);
          },
        });
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }

  handleSkip() {
    this.req.postDashboardOnboarding({ page: 'logo' }).subscribe({
      next: data => {
        // Amplitude event - Logo Upload Skipped
        this.req.ampTrack('Logo Upload Skipped');
        this.onboarding.handleContinue();
        this.onboarding.markPromptAsSeen('logo');
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
