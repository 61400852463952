<button
  [class]="'pagination-button ' + type"
  [ngClass]="{
    disabled: disabled === true
  }"
  [disabled]="disabled"
  (click)="handlePageChange()"
  [attr.aria-label]="icon + 'arrow'"
>
  <div class="pagination-arrow">
    <zogo-icon
      [iconName]="'arrow-' + icon"
      role="button"
      [attr.aria-label]="icon === 'left' ? 'previous page' : 'next page'"
    ></zogo-icon>
  </div>
</button>
