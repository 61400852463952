<div class="dashboard-view">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-8">
        <div class="h3-medium text-color-neutral-800">Survey Questions</div>
        <div class="body-medium text-color-neutral-600">
          <span
            >{{ surveyQuestionData?.created_count }} created
            {{ surveyQuestionData?.available_count }} available</span
          >
        </div>
      </div>
      <div class="col-md-4 text-right">
        <div class="survey-cta">
          <div *ngIf="orderChanges">
            <svg
              class="tappable undo-icon"
              (click)="handleUndoChanges()"
              matTooltip="Undo Changes"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 9H5C4.4 9 4 8.6 4 8V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V7H10C10.6 7 11 7.4 11 8C11 8.6 10.6 9 10 9Z"
              />
              <path
                d="M12.0002 21.0002C10.9002 21.0002 9.8002 20.8002 8.8002 20.4002C7.0002 19.7002 5.5002 18.5002 4.4002 16.9002C4.1002 16.4002 4.2002 15.8002 4.7002 15.5002C5.2002 15.2002 5.8002 15.3002 6.1002 15.8002C6.9002 17.0002 8.1002 18.0002 9.5002 18.5002C10.9002 19.0002 12.4002 19.1002 13.9002 18.7002C15.3002 18.3002 16.6002 17.4002 17.5002 16.2002C18.4002 15.0002 18.9002 13.6002 19.0002 12.1002C19.0002 10.6002 18.6002 9.10019 17.7002 7.9002C16.8002 6.7002 15.6002 5.8002 14.2002 5.3002C12.8002 4.8002 11.3002 4.8002 9.8002 5.3002C8.4002 5.8002 7.2002 6.7002 6.3002 7.9002C6.0002 8.4002 5.4002 8.5002 4.9002 8.20019C4.4002 7.90019 4.3002 7.2002 4.7002 6.8002C5.8002 5.2002 7.4002 4.0002 9.2002 3.4002C11.0002 2.8002 13.0002 2.8002 14.8002 3.4002C16.6002 4.0002 18.2002 5.2002 19.3002 6.8002C20.4002 8.4002 21.0002 10.2002 20.9002 12.2002C20.9002 14.1002 20.2002 16.0002 19.0002 17.5002C17.8002 19.0002 16.2002 20.1002 14.3002 20.7002C13.7002 20.9002 12.8002 21.0002 12.0002 21.0002Z"
              />
            </svg>
          </div>
          <zogo-btn
            class="ml-3"
            label="Save Order"
            size="medium"
            [fullWidth]="false"
            [disabled]="!orderChanges"
            buttonStyle="secondary"
            (clickFunction)="savePositionOrder()"
          ></zogo-btn>
          <zogo-btn
            class="ml-3"
            label="Add"
            size="medium"
            [fullWidth]="false"
            buttonStyle="primary"
            [disabled]="handleDisableAddButton()"
            (clickFunction)="createSurveyQuestion()"
          ></zogo-btn>
        </div>
      </div>
    </div>
  </div>

  <div class="content-area">
    <div class="container-fluid p-0">
      <div class="row mt-1">
        <div class="col-md-12">
          <div
            class="custom-module-table"
            *ngIf="surveyQuestionData && surveyQuestionData.surveys.length > 0"
          >
            <table
              mat-table
              cdkDropList
              [dataSource]="this.surveyQuestionData?.surveys"
              (cdkDropListDropped)="onDragEnded($event)"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; index as i">
                  <div class="ordering-col">
                    <img
                      class="drag-image tappable"
                      matTooltip="Drag and drop to reorder"
                      src="../../../assets/imgs/draganddrop.svg"
                    />
                    <div class="text-color-shades-primary-1 h5-medium">
                      {{ i + 1 }}
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-template cdkDragPreview></ng-template>

              <!-- Question Column -->
              <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef>Question</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex align-items-center">
                    <div
                      class="body-medium text-color-neutral-800 question-text"
                    >
                      {{ element?.question['en-US'] }}
                    </div>
                    <div
                      matTooltip="Question available in Spanish"
                      *ngIf="element?.spanish_enabled"
                      class="spanish-skill-icon"
                    >
                      <img
                        src="https://images.ctfassets.net/o1xn7flb83yr/6wZ1lDAiB4mkbLqjRs5p2E/1c26eb3e7139b9ffb9bc06b5afd7ea4f/spain.png"
                        alt="spanish-flag"
                      />
                    </div>
                  </div>
                  <div class="answer-count">
                    <svg
                      class="answers-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 4.5H15.75"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 9H15.75"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 13.5H15.75"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 4.5H2.25"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 9H2.25"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 13.5H2.25"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="caption-medium text-color-neutral-600">
                      {{ element?.answers.length }} Answers
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element.status.value">
                    <app-zogo-label
                      *ngSwitchCase="'inactive'"
                      type="neutral"
                      [label]="element.status.display"
                    ></app-zogo-label>
                    <app-zogo-label
                      *ngSwitchCase="'pending'"
                      type="notice"
                      [label]="element.status.display"
                    ></app-zogo-label>
                    <app-zogo-label
                      *ngSwitchCase="'active'"
                      type="success"
                      [label]="element.status.display"
                    ></app-zogo-label>
                  </div>
                </td>
              </ng-container>

              <!-- Selection Column -->
              <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; index as i">
                  <svg
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    class="tappable"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0013 10.8307C10.4615 10.8307 10.8346 10.4576 10.8346 9.9974C10.8346 9.53716 10.4615 9.16406 10.0013 9.16406C9.54106 9.16406 9.16797 9.53716 9.16797 9.9974C9.16797 10.4576 9.54106 10.8307 10.0013 10.8307Z"
                      stroke="#484848"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0013 5.0026C10.4615 5.0026 10.8346 4.62951 10.8346 4.16927C10.8346 3.70903 10.4615 3.33594 10.0013 3.33594C9.54106 3.33594 9.16797 3.70903 9.16797 4.16927C9.16797 4.62951 9.54106 5.0026 10.0013 5.0026Z"
                      stroke="#484848"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0013 16.6667C10.4615 16.6667 10.8346 16.2936 10.8346 15.8333C10.8346 15.3731 10.4615 15 10.0013 15C9.54106 15 9.16797 15.3731 9.16797 15.8333C9.16797 16.2936 9.54106 16.6667 10.0013 16.6667Z"
                      stroke="#484848"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <mat-menu #menu="matMenu">
                    <button
                      class="menu-btn-icon"
                      mat-menu-item
                      (click)="handleSubmitForApproval(i)"
                      matTooltip="Submit is disabled until all required fields are completed"
                      [matTooltipDisabled]="validateQuestion(element)"
                      matTooltipPosition="before"
                      [disabled]="!validateQuestion(element)"
                      *ngIf="
                        this.surveyQuestionData?.surveys[i]?.status?.value ===
                        'inactive'
                      "
                    >
                      <svg
                        [ngClass]="
                          this.validateQuestion(element)
                            ? 'enabled'
                            : 'disabled'
                        "
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.11888 5.35993C5.06113 3.08749 8.26673 2.36323 10.9973 3.5799C11.3757 3.74849 11.819 3.57844 11.9876 3.20008C12.1562 2.82173 11.9862 2.37835 11.6078 2.20976C8.27043 0.722719 4.35249 1.60793 1.97862 4.38535C-0.395241 7.16276 -0.659527 11.1708 1.32908 14.2358C3.3177 17.3009 7.08549 18.6929 10.5892 17.657C14.0929 16.621 16.498 13.4035 16.5 9.74983V9.05233C16.5 8.63812 16.1643 8.30233 15.75 8.30233C15.3358 8.30233 15 8.63812 15 9.05233V9.7494C14.9983 12.7388 13.0306 15.3709 10.1639 16.2185C7.29722 17.0661 4.21449 15.9272 2.58744 13.4194C0.960396 10.9116 1.17663 7.63236 3.11888 5.35993Z"
                        />
                        <path
                          d="M17.0304 3.53016C17.3233 3.23727 17.3233 2.7624 17.0304 2.4695C16.7375 2.17661 16.2626 2.17661 15.9697 2.4695L8.25005 10.1892L6.53038 8.4695C6.23748 8.17661 5.76261 8.17661 5.46972 8.4695C5.17682 8.7624 5.17682 9.23727 5.46972 9.53016L7.71972 11.7802C8.01261 12.0731 8.48748 12.0731 8.78038 11.7802L17.0304 3.53016Z"
                        />
                      </svg>

                      <span
                        class="caption-medium"
                        [ngClass]="
                          this.validateQuestion(element)
                            ? 'text-color-neutral-700'
                            : 'text-color-neutral-500'
                        "
                        >Submit for Approval</span
                      >
                    </button>
                    <button
                      class="menu-btn-icon"
                      mat-menu-item
                      (click)="editSurveyQuestion(element)"
                    >
                      <svg
                        class="enabled"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.0303 0.96967C13.7374 0.676777 13.2626 0.676777 12.9697 0.96967L5.46967 8.46967C5.32902 8.61032 5.25 8.80109 5.25 9V12C5.25 12.4142 5.58579 12.75 6 12.75H9C9.19891 12.75 9.38968 12.671 9.53033 12.5303L17.0303 5.03033C17.3232 4.73744 17.3232 4.26256 17.0303 3.96967L14.0303 0.96967ZM6.75 11.25V9.31066L13.5 2.56066L15.4393 4.5L8.68934 11.25H6.75Z"
                        />
                        <path
                          d="M3 3.75C3 3.33579 3.33579 3 3.75 3H7.755C8.16921 3 8.505 2.66421 8.505 2.25C8.505 1.83579 8.16921 1.5 7.755 1.5H3.75C2.50736 1.5 1.5 2.50736 1.5 3.75V14.25C1.5 15.4926 2.50736 16.5 3.75 16.5H14.25C15.4926 16.5 16.5 15.4926 16.5 14.25V10.245C16.5 9.83079 16.1642 9.495 15.75 9.495C15.3358 9.495 15 9.83079 15 10.245V14.25C15 14.6642 14.6642 15 14.25 15H3.75C3.33579 15 3 14.6642 3 14.25V3.75Z"
                        />
                      </svg>

                      <span class="caption-medium text-color-neutral-700"
                        >Edit</span
                      >
                    </button>
                    <button
                      class="menu-btn-icon"
                      mat-menu-item
                      (click)="deleteSurveyQuestion(element?.id, i)"
                    >
                      <svg
                        class="delete-icon"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.25 4.5H3.75H15.75"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 2.25C7.08579 2.25 6.75 2.58579 6.75 3V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V3C5.25 1.75736 6.25736 0.75 7.5 0.75H10.5C11.7426 0.75 12.75 1.75736 12.75 3V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V3C11.25 2.58579 10.9142 2.25 10.5 2.25H7.5Z"
                        />
                        <path
                          d="M3.75 3.75C4.16421 3.75 4.5 4.08579 4.5 4.5V15C4.5 15.4142 4.83579 15.75 5.25 15.75H12.75C13.1642 15.75 13.5 15.4142 13.5 15V4.5C13.5 4.08579 13.8358 3.75 14.25 3.75C14.6642 3.75 15 4.08579 15 4.5V15C15 16.2426 13.9926 17.25 12.75 17.25H5.25C4.00736 17.25 3 16.2426 3 15V4.5C3 4.08579 3.33579 3.75 3.75 3.75Z"
                        />
                        <path
                          d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                        />
                        <path
                          d="M11.25 8.25C11.25 7.83579 10.9142 7.5 10.5 7.5C10.0858 7.5 9.75 7.83579 9.75 8.25V12.75C9.75 13.1642 10.0858 13.5 10.5 13.5C10.9142 13.5 11.25 13.1642 11.25 12.75V8.25Z"
                        />
                      </svg>

                      <span class="caption-medium text-color-shades-orange-1"
                        >Delete</span
                      >
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                cdkDrag
              ></tr>
            </table>
          </div>
          <div
            class="empty-module-container"
            *ngIf="surveyQuestionData?.surveys.length === 0"
          >
            <svg
              class="no-question-icon"
              width="87"
              height="88"
              viewBox="0 0 87 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="43.4999"
                cy="43.871"
                r="41.7085"
                stroke-width="3.57501"
                stroke-dasharray="12 12"
              />
              <path
                d="M63.7188 35.7497C64.426 35.7497 65 35.1617 65 34.4372V31.8122C65 31.3274 64.7403 30.8829 64.3218 30.6537L45.103 20.1536C44.7255 19.9488 44.2762 19.9488 43.8987 20.1536L24.6799 30.6537C24.2597 30.8829 24 31.3274 24 31.8122V34.4372C24 35.1617 24.574 35.7497 25.2812 35.7497H29.125V56.7499H25.7083C24.7653 56.7499 24 57.534 24 58.5V60.25C24 61.216 24.7653 62 25.7083 62H63.2917C64.2347 62 65 61.216 65 60.25V58.5C65 57.534 64.2347 56.7499 63.2917 56.7499H59.875V35.7497H63.7188ZM56.4583 56.7499H32.5417V35.7497H56.4583V56.7499Z"
              />
              <path
                d="M43.816 47.7743H45.184C45.7996 47.7743 46.3 48.2469 46.3 48.8454C46.3 49.4268 45.7996 49.8995 45.184 49.8995H41.35C40.6048 49.8995 40 50.4707 40 51.1746C40 51.8785 40.6048 52.4497 41.35 52.4497H43.15V53.7249C43.15 54.4287 43.7548 55 44.5 55C45.2452 55 45.85 54.4287 45.85 53.7249V52.3868C47.6356 52.0876 49 50.6153 49 48.8301C49 46.8426 47.2882 45.2257 45.184 45.2257H43.816C43.2004 45.2257 42.7 44.7531 42.7 44.1546C42.7 43.5732 43.2004 43.1005 43.816 43.1005H47.65C48.3952 43.1005 49 42.5293 49 41.8254C49 41.1215 48.3952 40.5503 47.65 40.5503H45.85V39.2751C45.85 38.5713 45.2452 38 44.5 38C43.7548 38 43.15 38.5713 43.15 39.2751V40.6132C41.3644 40.9124 40 42.3847 40 44.1699C40 46.1574 41.7118 47.7743 43.816 47.7743Z"
              />
            </svg>

            <div class="h4-bold text-color-neutral-500">
              No survey questions created yet
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
