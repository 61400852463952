<div class="zogo-web-link-section">
  <h4 class="h5-bold text-color-neutral-800">
    {{ linkDisplayData.widget_display_name }}
  </h4>
  <div class="share-section">
    <svg
      class="text-color-neutral-600"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3284_20725)">
        <path
          d="M7.62891 9.83542C7.951 10.266 8.36192 10.6223 8.83382 10.8801C9.30571 11.1379 9.82754 11.2913 10.3639 11.3297C10.9003 11.3681 11.4386 11.2907 11.9424 11.1028C12.4462 10.9148 12.9037 10.6207 13.2839 10.2404L15.5339 7.99042C16.217 7.28317 16.595 6.33591 16.5864 5.35267C16.5779 4.36943 16.1835 3.42888 15.4882 2.7336C14.7929 2.03832 13.8524 1.64394 12.8692 1.6354C11.8859 1.62685 10.9387 2.00483 10.2314 2.68792L8.94141 3.97042"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6286 8.33537C10.3065 7.90478 9.89555 7.54849 9.42365 7.29067C8.95176 7.03285 8.42994 6.87954 7.89358 6.84112C7.35722 6.80271 6.81888 6.8801 6.31506 7.06804C5.81124 7.25598 5.35373 7.55007 4.97357 7.93037L2.72357 10.1804C2.04048 10.8876 1.6625 11.8349 1.67104 12.8181C1.67958 13.8014 2.07397 14.7419 2.76925 15.4372C3.46453 16.1325 4.40508 16.5269 5.38831 16.5354C6.37155 16.5439 7.31881 16.166 8.02607 15.4829L9.30857 14.2004"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3284_20725">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.128906 0.0854492)"
          />
        </clipPath>
      </defs>
    </svg>

    <div class="divider"></div>

    <div class="body-mediuem text-color-neutral-600">
      {{ linkDisplayData.source }}
    </div>
    <zogo-btn
      label="COPY"
      [fullWidth]="false"
      size="small"
      buttonStyle="primary"
      (clickFunction)="copyLink()"
      class="copy-btn"
    >
    </zogo-btn>
  </div>
</div>
