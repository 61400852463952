<div *ngIf="!userDataReportsData">
  <ion-spinner class="overview-widget-loader" name="circular"></ion-spinner>
</div>
<div *ngIf="userDataReportsData" class="metric-table-frame">
  <div class="title-wrapper" tappable (click)="toggleAccordian()">
    <div class="table-title h5-bold text-color-neutral-800">
      {{ widgetData.widget_display_name }}
    </div>
    <div class="title-right">
      <mat-form-field
        appearance="none"
        class="contact-date"
        appearance="fill"
        (click)="$event.stopPropagation()"
      >
        <mat-label>Select date range</mat-label>
        <mat-date-range-input (click)="picker.open()" [rangePicker]="picker">
          <input
            matInput
            (dateChange)="setDate('start', $event)"
            matStartDate
            placeholder="Start date"
          />
          <input
            matInput
            (dateChange)="setDate('end', $event)"
            matEndDate
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <zogo-btn
        label="Download CSV"
        (click)="stopPropagation($event)"
        (clickFunction)="requestReport()"
      ></zogo-btn>
      <div class="margin-left-8">
        <zogo-icon
          *ngIf="expanded"
          class="text-color-neutral-800"
          iconName="chevron-up"
        ></zogo-icon>

        <zogo-icon
          *ngIf="!expanded"
          class="text-color-neutral-800"
          iconName="chevron-down"
        ></zogo-icon>
      </div>
    </div>
  </div>
  <div
    *ngIf="expanded && !userDataReportsData.user_data_reports.length"
    class="caption-medium text-color-neutral-800 no-data-reports"
  >
    User data reports will be displayed here when ready
  </div>
  <table
    *ngIf="expanded && userDataReportsData.user_data_reports.length"
    class="metric-table"
  >
    <thead>
      <tr class="header-row">
        <th
          class="column-header overline-medium text-color-neutral-800"
          [ngStyle]="{
            'text-align': 'left',
            width: '30%'
          }"
        >
          Platform
        </th>
        <th
          class="column-header overline-medium text-color-neutral-800"
          [ngStyle]="{
            'text-align': 'center',
            width: '20%'
          }"
        >
          Date Created
        </th>
        <th
          class="column-header overline-medium text-color-neutral-800"
          [ngStyle]="{
            'text-align': 'center',
            width: '20%'
          }"
        >
          Status
        </th>
        <th
          class="column-header"
          [ngStyle]="{
            'text-align': 'right',
            width: '30%'
          }"
        >
          <button
            (click)="updateUserDataReportsData(false)"
            class="text-color-brand-primary caption-medium refresh-btn"
          >
            <zogo-icon iconName="refresh-ccw"></zogo-icon> Refresh
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of userDataReportsData.user_data_reports; let i = index"
        class="body-row"
      >
        <td
          class="body-medium"
          [ngClass]="{
            last: i === userDataReportsData.user_data_reports.length - 1
          }"
          [ngStyle]="{
            'text-align': 'left',
          }"
        >
          {{ row.platform_name }}
        </td>
        <td
          class="body-medium"
          [ngClass]="{
            last: i === userDataReportsData.user_data_reports.length - 1
          }"
          [ngStyle]="{
            'text-align': 'center',
          }"
        >
          {{ row.created_at | date }}
        </td>
        <td
          class="body-medium"
          [ngClass]="{
            last: i === userDataReportsData.user_data_reports.length - 1
          }"
          [ngStyle]="{
            'text-align': 'center',
          }"
        >
          <zogo-badge
            *ngIf="row.status === 'ready'"
            type="success"
            label="Ready"
          >
          </zogo-badge>
          <zogo-badge
            *ngIf="row.status === 'pending'"
            type="notice"
            label="Pending"
          >
          </zogo-badge>
          <zogo-badge
            *ngIf="row.status === 'error'"
            type="warning"
            label="Error"
          >
          </zogo-badge>
        </td>
        <td
          class="body-medium"
          [ngClass]="{
            last: i === userDataReportsData.user_data_reports.length - 1
          }"
          [ngStyle]="{
            'text-align': 'right',
          }"
        >
          <zogo-btn
            label="Download"
            (clickFunction)="getReport(row.id)"
            [disabled]="row.status !== 'ready'"
            buttonStyle="secondary"
          ></zogo-btn>
        </td>
      </tr>
    </tbody>
  </table>
</div>
