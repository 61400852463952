import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { RequestsService } from 'src/app/requests.service';

@Component({
  selector: 'app-survey-display',
  templateUrl: './survey-display.component.html',
  styleUrls: ['./survey-display.component.css'],
})
export class SurveyDisplayComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() widgetData: any;
  @Input() mode: 'overview' | 'pre-post-skill' = 'overview';
  @Input() skillSurveyData;
  surveyData: any;
  platformSub: Subscription;
  platform: string;
  timeFrameSub: Subscription;
  timeFrame: string;
  communitySegmentSub: Subscription;
  communitySegment: any;
  selectedSurveyOption: 'all' | 'partner' | 'zogo' = 'all';
  filteredSurveys: any;
  isSurveyNextButtonVisible: boolean = true;
  isSurveyBackButtonVisible: boolean = false;
  disableScrollLeft: boolean = true;
  disableScrollRight: boolean = true;
  errorLoading: boolean = false;

  @ViewChild('surveyScrollContainer', { static: false })
  surveyScrollContainer!: ElementRef;

  constructor(private req: RequestsService) {}

  ngOnInit(): void {
    if (this.mode === 'overview') {
      this.selectedSurveyOption = 'all';
      this.platform = this.req.overviewPlatformStore;
      this.platformSub = this.req.overviewPlatform.subscribe({
        next: data => {
          this.platform = data;
          this.updateSurveyData();
        },
        error: err => {},
      });
      this.timeFrame = this.req.overviewTimeFrameStore;
      this.timeFrameSub = this.req.overviewTimeFrame.subscribe({
        next: data => {
          this.timeFrame = data;
          this.updateSurveyData();
        },
        error: err => {},
      });
      this.communitySegment = this.req.overviewCommunitySegmentStore;
      this.communitySegmentSub = this.req.overviewCommunitySegment.subscribe({
        next: data => {
          this.communitySegment = data;
          this.updateSurveyData();
        },
        error: err => {},
      });
      this.updateSurveyData();
    } else {
      this.surveyData = this.skillSurveyData;
      this.filterSurveys();
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    if (this.mode === 'overview') {
      this.platformSub.unsubscribe();
      this.timeFrameSub.unsubscribe();
      this.communitySegmentSub.unsubscribe();
    }
  }

  updateSurveyData() {
    this.surveyData = null;
    this.errorLoading = false;
    const reqObj = {
      source: this.widgetData.source,
      platform: this.platform,
      time_frame: this.timeFrame,
      code: this.communitySegment?.value,
    };
    this.req
      .getDashboardOverviewData(reqObj)
      .pipe(takeUntil(this.req.unsubscribeOveriew))
      .subscribe({
        next: data => {
          this.surveyData = data;
          this.filterSurveys();
        },
        error: err => {
          console.log(err);
          if (err.error.message) {
            this.req.openSnackBar(
              'There was an error: ' + err.error.message,
              'Okay'
            );
          } else {
            this.req.openSnackBar('Unable to load data.', 'Okay');
          }
          this.errorLoading = true;
        },
      });
  }

  checkBadgeTextExists(badgeText): boolean {
    return this.surveyData.surveys.some(
      survey => survey.badge_text === badgeText
    );
  }

  filterSurveys() {
    if (!this.surveyData.surveys) {
      this.filteredSurveys = [];
    } else {
      if (this.selectedSurveyOption === 'all') {
        this.filteredSurveys = this.surveyData.surveys;
      } else {
        this.filteredSurveys = this.surveyData.surveys.filter(
          survey => survey && survey.badge_text === this.selectedSurveyOption
        );
      }
      setTimeout(() => {
        this.checkScrollPosition();
      }, 0);
    }
  }

  scrollNextSurvey(): void {
    const wrapperElement: HTMLElement =
      this.surveyScrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 330;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 330;
    const currentPosition = wrapperElement.scrollLeft;
    const nextPosition = currentPosition + calculatedSectionWidth;
    wrapperElement.scrollTo({ left: nextPosition, behavior: 'smooth' });
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  scrollBackSurvey(): void {
    const wrapperElement: HTMLElement =
      this.surveyScrollContainer.nativeElement;
    const sectionWidth = wrapperElement.offsetWidth / 330;
    const calculatedSectionWidth = Math.floor(sectionWidth) * 330;
    const currentPosition = wrapperElement.scrollLeft;
    const previousPosition = currentPosition - calculatedSectionWidth;
    wrapperElement.scrollTo({
      left: previousPosition,
      behavior: 'smooth',
    });
    this.isSurveyBackButtonVisible = previousPosition > 0;
    // timeout to give time for scroll to happen before update
    setTimeout(() => {
      this.checkScrollPosition();
    }, 500);
  }

  checkScrollPosition(): void {
    const wrapperElement: HTMLElement =
      this.surveyScrollContainer.nativeElement;
    const maxScrollLeft =
      wrapperElement.scrollWidth - wrapperElement.clientWidth;
    this.disableScrollLeft = wrapperElement.scrollLeft === 0;
    this.disableScrollRight = wrapperElement.scrollLeft >= maxScrollLeft;
  }
}
