import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { RequestsService } from 'src/app/requests.service';
import { ToastService } from 'src/app/design-system/web-components/toast.service';

@Component({
  selector: 'app-onboarding-web-subdomain',
  templateUrl: './onboarding-web-subdomain.component.html',
  styleUrls: ['./onboarding-web-subdomain.component.scss'],
})
export class OnboardingWebSubdomainComponent implements OnInit {
  userData: any;
  subdomain: string = '';
  hasSubdomainInputError: boolean = true;

  constructor(
    private onboarding: OnboardingService,
    private req: RequestsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  onSubmitSubdomain() {
    const request = {
      page: 'zogo-web',
      sub_domain: this.subdomain,
    };

    this.req.patchDashboardInstitution(request).subscribe({
      next: data => {
        // Amplitude event - Zogo Web Subdomain Completed
        this.req.ampTrack('Zogo Web Subdomain Completed');
        const currentUserData = JSON.parse(localStorage.getItem('userData'));
        currentUserData.institution = data.institution;
        this.userData = currentUserData;
        localStorage.setItem('userData', JSON.stringify(currentUserData));

        this.req.postDashboardOnboarding({ page: 'zogo-web' }).subscribe({
          next: data => {
            this.onboarding.handleContinue();
          },
          error: err => {
            this.toastService.showToast(
              'There was an error: ' + err.error.message,
              6000
            );
            console.log('errors', err.error);
          },
        });
      },
      error: err => {
        this.toastService.showToast(
          'There was an error: ' + err.error.message,
          6000
        );
        console.log('errors', err.error);
      },
    });
  }
}
