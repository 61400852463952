import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownComponent } from 'ngx-markdown';
import { ZogoIconComponent } from '../../global-components/zogo-icon/zogo-icon.component';

type AccordionItem = {
  title: string;
  content: string;
  expanded: boolean;
};

@Component({
  selector: 'zogo-accordion',
  templateUrl: './zogo-accordion.component.html',
  styleUrls: ['./zogo-accordion.component.scss'],
  standalone: true,
  imports: [CommonModule, MarkdownComponent, ZogoIconComponent],
})
export class ZogoAccordionComponent {
  @Input() items: AccordionItem[] = [];
  @Output() itemToggled = new EventEmitter<number>();

  constructor() {}

  handleKeydown(event: Event, index: number) {
    event.preventDefault();
    this.toggleItem(index);
  }

  toggleItem(index: number) {
    if (this.items[index].expanded) {
      this.items[index].expanded = false;
    } else {
      this.items.forEach((item, i) => (item.expanded = i === index));
    }
    this.itemToggled.emit(index);
  }
}
