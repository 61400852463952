import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import Panzoom from '@panzoom/panzoom';
import { RequestsService } from '../requests.service';
import { HelperFunctionsService } from 'src/app/design-system/helper-functions.service';
import { ModalController } from '@ionic/angular';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-zogo-web-customizations',
  templateUrl: './zogo-web-customizations.component.html',
  styleUrls: ['./zogo-web-customizations.component.scss'],
})
export class ZogoWebCustomizationsComponent implements OnInit, AfterViewInit {
  userData: any;
  isAdmin: boolean;
  pageMode: 'view' | 'edit' = 'view';
  previewType: 'zogo-web' | 'classroom' = 'zogo-web';
  webMessageType: 'default' | 'custom' = 'default';
  classroomMessageType: 'default' | 'custom' = 'default';
  panzoom: any;
  background: 'color' | 'image' = 'color';
  imageName: string;
  defaultText: any = {
    english_header: '',
    english_sub_header: '',
    english_description: '',
    spanish_header: '',
    spanish_sub_header: '',
    spanish_description: '',
    classroom_english_header: '',
    classroom_english_sub_header: '',
    classroom_english_description: '',
    classroom_spanish_header: '',
    classroom_spanish_sub_header: '',
    classroom_spanish_description: '',
  };
  customText: any = {
    english_header: '',
    english_sub_header: '',
    english_description: '',
    spanish_header: '',
    spanish_sub_header: '',
    spanish_description: '',
    classroom_english_header: '',
    classroom_english_sub_header: '',
    classroom_english_description: '',
    classroom_spanish_header: '',
    classroom_spanish_sub_header: '',
    classroom_spanish_description: '',
  };
  config: any = {
    image: null,
    font: 'gotham', // gotham | notosans | raleway | merriweather
    icon_pack: 'classic', // classic | playful | clean
    preview_type: 'zogo-web',
    header: 'Welcome to ${institution_name} Financial Wellness Center', // title
    sub_header: '#1 Rated Financial Education App on Apple Store (Zogo)', // sub-title
    description:
      'Boost your confidence and take control of your finances through a gamified learning experience.', // description
    classroom_header: 'Welcome to ${class_name}', // title
    classroom_sub_header: '', // sub-title
    classroom_description:
      'This gamified financial learning experience consists of bite-sized modules, tools, and real world examples designed to build a strong foundation for financial confidence in your future.', // description
  };

  constructor(
    private req: RequestsService,
    private helpers: HelperFunctionsService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    // Amplitude event - Zogo Web Customization Viewed
    this.req.ampTrack('Zogo Web Customization Viewed');

    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData.institution_user.access_level === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.setColors();
    this.initializeValues();
  }

  ngAfterViewInit(): void {
    const elem = document.getElementById('panzoom-element');
    this.panzoom = Panzoom(elem, {
      maxScale: 5,
    });
    this.reset();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.pageMode === 'edit') {
      //eslint-disable-next-line
      return new Promise<boolean>(async resolve => {
        const componentProps = {
          header: 'Leave page?',
          description: 'Changes you made might not be saved.',
          primaryButtonText: 'Leave',
          primaryButtonFunc: () => {
            modal.dismiss();
            resolve(true); // Resolve the promise when 'Leave' is clicked
          },
          secondaryButtonText: 'Cancel',
          secondaryButtonFunc: () => {
            modal.dismiss();
            resolve(false); // Resolve the promise when 'Cancel' is clicked
          },
        };

        const modal = await this.modalController.create({
          component: AlertModalComponent,
          componentProps,
          cssClass: 'alert-modal',
        });

        // Present the modal
        await modal.present();
      });
    } else {
      return true;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.pageMode === 'edit') {
      $event.returnValue = true;
    }
  }

  setColors() {
    const colors = this.helpers.calculateColors(
      this.userData.institution.primary_hex
    );

    if (colors.color) {
      document.documentElement.style.setProperty(
        '--zogo-web-primary-app-color',
        colors.color
      );
    }
    if (colors.colorTint) {
      document.documentElement.style.setProperty(
        '--zogo-web-primary-app-custom-background',
        colors.colorTint
      );
    }
  }

  initializeValues() {
    const webCustomizations = this.userData.institution.web_customizations;
    if (webCustomizations.hero_banner_image) {
      this.onBackgroundChange('image');
      this.config.image = webCustomizations.hero_banner_image;
      this.imageName = this.getFileNameFromUrl(
        webCustomizations.hero_banner_image
      );
    }
    if (webCustomizations.icon_pack) {
      this.config.icon_pack = webCustomizations.icon_pack;
    }
    if (webCustomizations.enterprise_web_typeface) {
      this.config.font = webCustomizations.enterprise_web_typeface;
    }
    // if all values are null then they are using default copy
    if (
      webCustomizations.english_header === null &&
      webCustomizations.english_sub_header === null &&
      webCustomizations.english_description === null
    ) {
      this.webMessageType = 'default';
      // set values for default inputs
      this.defaultText.english_header =
        webCustomizations.english_header_default;
      this.defaultText.english_sub_header =
        webCustomizations.english_sub_header_default;
      this.defaultText.english_description =
        webCustomizations.english_description_default;
      this.defaultText.spanish_header =
        webCustomizations.spanish_header_default;
      this.defaultText.spanish_sub_header =
        webCustomizations.spanish_sub_header_default;
      this.defaultText.spanish_description =
        webCustomizations.spanish_description_default;
      // set values for preview
      this.config.header = this.defaultText.english_header;
      this.config.sub_header = this.defaultText.english_sub_header;
      this.config.description = this.defaultText.english_description;
    } else {
      this.webMessageType = 'custom';
      // set values for custom inputs
      this.customText.english_header = webCustomizations.english_header;
      this.customText.english_sub_header = webCustomizations.english_sub_header;
      this.customText.english_description =
        webCustomizations.english_description;
      this.customText.spanish_header = webCustomizations.spanish_header;
      this.customText.spanish_sub_header = webCustomizations.spanish_sub_header;
      this.customText.spanish_description =
        webCustomizations.spanish_description;
      // set values for preview
      this.config.header = this.customText.english_header;
      this.config.sub_header = this.customText.english_sub_header;
      this.config.description = this.customText.english_description;
    }

    // if all values are null then they are using default copy
    if (
      webCustomizations.classroom_english_header === null &&
      webCustomizations.classroom_english_sub_header === null &&
      webCustomizations.classroom_english_description === null
    ) {
      this.classroomMessageType = 'default';
      // set values for default inputs
      this.defaultText.classroom_english_header =
        webCustomizations.classroom_english_header_default;
      this.defaultText.classroom_english_sub_header =
        webCustomizations.classroom_english_sub_header_default;
      this.defaultText.classroom_english_description =
        webCustomizations.classroom_english_description_default;
      this.defaultText.classroom_spanish_header =
        webCustomizations.classroom_spanish_header_default;
      this.defaultText.classroom_spanish_sub_header =
        webCustomizations.classroom_spanish_sub_header_default;
      this.defaultText.classroom_spanish_description =
        webCustomizations.classroom_spanish_description_default;
      // set values for preview
      this.config.classroom_header = this.defaultText.classroom_english_header;
      this.config.classroom_sub_header =
        this.defaultText.classroom_english_sub_header;
      this.config.classroom_description =
        this.defaultText.classroom_english_description;
    } else {
      this.classroomMessageType = 'custom';
      // set values for custom inputs
      this.customText.classroom_english_header =
        webCustomizations.classroom_english_header;
      this.customText.classroom_english_sub_header =
        webCustomizations.classroom_english_sub_header;
      this.customText.classroom_english_description =
        webCustomizations.classroom_english_description;
      this.customText.classroom_spanish_header =
        webCustomizations.classroom_spanish_header;
      this.customText.classroom_spanish_sub_header =
        webCustomizations.classroom_spanish_sub_header;
      this.customText.classroom_spanish_description =
        webCustomizations.classroom_spanish_description;
      // set values for preview
      this.config.classroom_header = this.customText.classroom_english_header;
      this.config.classroom_sub_header =
        this.customText.classroom_english_sub_header;
      this.config.classroom_description =
        this.customText.classroom_english_description;
    }
  }

  getFileNameFromUrl(url) {
    // Split the URL by '/' and get the last element
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  handleUploadImage(event) {
    this.selectImageFile(event.target.files[0]);
  }

  selectImageFile(file) {
    if (file) {
      const reader = new FileReader();
      if (!this.validateLogoFile(file)) {
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.config.image = event.target.result;
      };
    }
  }

  validateLogoFile(file) {
    console.log('name', file.name);
    console.log('size', Math.round(file.size / 1024));
    if (
      !file.name.includes('.png') &&
      !file.name.includes('.jpg') &&
      !file.name.includes('.jpeg')
    ) {
      this.req.openSnackBar('File is not of type PNG or JPG', 'Okay');
      return false;
    }
    if (Math.round(file.size / 1024) > 5000) {
      this.req.openSnackBar('File size exceeds 5MB', 'Okay');
      return false;
    }
    this.imageName = file.name;
    return true;
  }

  handleDeleteImage() {
    this.config.image = null;
  }

  onBackgroundChange(event) {
    if (event === 'color') {
      this.config.image = null;
      this.imageName = null;
    }
    this.background = event;
  }

  onTypefaceChange(event) {
    this.config.font = event;
    document.documentElement.style.setProperty('--zogo-web-font-family', event);
  }

  onIconStyleChange(event) {
    this.config.icon_pack = event;
  }

  onWebMessageTypeChange(event) {
    this.webMessageType = event;
    const webCustomizations = this.userData.institution.web_customizations;
    if (this.webMessageType === 'default') {
      // set values for default inputs
      this.defaultText.english_header =
        webCustomizations.english_header_default;
      this.defaultText.english_sub_header =
        webCustomizations.english_sub_header_default;
      this.defaultText.english_description =
        webCustomizations.english_description_default;
      this.defaultText.spanish_header =
        webCustomizations.spanish_header_default;
      this.defaultText.spanish_sub_header =
        webCustomizations.spanish_sub_header_default;
      this.defaultText.spanish_description =
        webCustomizations.spanish_description_default;
      // set values for preview
      this.config.header = this.defaultText.english_header;
      this.config.sub_header = this.defaultText.english_sub_header;
      this.config.description = this.defaultText.english_description;
    } else {
      // set values for preview
      this.config.header = this.customText.english_header;
      this.config.sub_header = this.customText.english_sub_header;
      this.config.description = this.customText.english_description;
    }
  }

  onClassroomMessageTypeChange(event) {
    this.classroomMessageType = event;
    const webCustomizations = this.userData.institution.web_customizations;
    if (this.classroomMessageType === 'default') {
      // set values for default inputs
      this.defaultText.classroom_english_header =
        webCustomizations.classroom_english_header_default;
      this.defaultText.classroom_english_sub_header =
        webCustomizations.classroom_english_sub_header_default;
      this.defaultText.classroom_english_description =
        webCustomizations.classroom_english_description_default;
      this.defaultText.classroom_spanish_header =
        webCustomizations.classroom_spanish_header_default;
      this.defaultText.classroom_spanish_sub_header =
        webCustomizations.classroom_spanish_sub_header_default;
      this.defaultText.classroom_spanish_description =
        webCustomizations.classroom_spanish_description_default;
      // set values for preview
      this.config.classroom_header = this.defaultText.classroom_english_header;
      this.config.classroom_sub_header =
        this.defaultText.classroom_english_sub_header;
      this.config.classroom_description =
        this.defaultText.classroom_english_description;
    } else {
      // set values for preview
      this.config.classroom_header = this.customText.classroom_english_header;
      this.config.classroom_sub_header =
        this.customText.classroom_english_sub_header;
      this.config.classroom_description =
        this.customText.classroom_english_description;
    }
  }

  onTextChange(event, textProperty) {
    this.customText[textProperty] = event;
    if (textProperty === 'english_header') {
      this.config.header = event;
    }

    if (textProperty === 'english_sub_header') {
      this.config.sub_header = event;
    }

    if (textProperty === 'english_description') {
      this.config.description = event;
    }

    if (textProperty === 'classroom_english_header') {
      this.config.classroom_header = event;
    }

    if (textProperty === 'classroom_english_sub_header') {
      this.config.classroom_sub_header = event;
    }

    if (textProperty === 'classroom_english_description') {
      this.config.classroom_description = event;
    }
  }

  enableEditMode() {
    if (this.pageMode === 'view') {
      this.pageMode = 'edit';
    } else {
      this.pageMode = 'view';
    }
    console.log('enable edit mode clicked');
  }

  handlePreviewSelection(preview: 'zogo-web' | 'classroom') {
    this.previewType = preview;
    this.config.preview_type = preview;
  }

  zoomIn() {
    this.panzoom.zoomIn({ animate: false });
  }

  zoomOut() {
    this.panzoom.zoomOut({ animate: false });
  }

  reset() {
    this.panzoom.zoom(0.55);
    setTimeout(() => this.panzoom.pan(-200, -630));
  }

  async handleDiscardChanges() {
    const componentProps = {
      header: 'Are you sure?',
      description: 'Changes you made will be discarded.',
      dontShowAgainToken: 'web-customization-cancel',
      primaryButtonText: 'Cancel changes',
      primaryButtonFunc: this.discardChanges.bind(this),
      secondaryButtonText: 'Back to editing',
      secondaryButtonFunc: () => modal.dismiss(),
    };
    const modal = await this.modalController.create({
      component: AlertModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });
    modal.present();
  }

  discardChanges() {
    this.initializeValues();
    this.modalController.dismiss();
    this.pageMode = 'view';
  }

  async handleSaveChangesModal() {
    const dontShowAgainToken = localStorage.getItem('web-customization-submit');
    if (!dontShowAgainToken) {
      const componentProps = {
        header: 'Are you sure?',
        description:
          'All changes will be published instantly. This action cannot be undone.',
        dontShowAgainToken: 'web-customization-submit',
        primaryButtonText: 'Confirm all changes',
        primaryButtonFunc: this.saveChanges.bind(this),
        secondaryButtonText: 'Back to editing',
        secondaryButtonFunc: () => modal.dismiss(),
      };
      const modal = await this.modalController.create({
        component: AlertModalComponent,
        componentProps,
        cssClass: 'alert-modal',
      });
      modal.present();
    } else {
      this.saveChanges();
    }
  }

  saveChanges() {
    const body = {
      hero_banner_image: this.config.image,
      hero_banner_image_name: this.imageName,
      english_header:
        this.webMessageType === 'custom'
          ? this.customText.english_header
          : null,
      english_sub_header:
        this.webMessageType === 'custom'
          ? this.customText.english_sub_header
          : null,
      english_description:
        this.webMessageType === 'custom'
          ? this.customText.english_description
          : null,
      spanish_header:
        this.webMessageType === 'custom'
          ? this.customText.spanish_header
          : null,
      spanish_sub_header:
        this.webMessageType === 'custom'
          ? this.customText.spanish_sub_header
          : null,
      spanish_description:
        this.webMessageType === 'custom'
          ? this.customText.spanish_description
          : null,
      classroom_english_header:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_english_header
          : null,
      classroom_english_sub_header:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_english_sub_header
          : null,
      classroom_english_description:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_english_description
          : null,
      classroom_spanish_header:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_spanish_header
          : null,
      classroom_spanish_sub_header:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_spanish_sub_header
          : null,
      classroom_spanish_description:
        this.classroomMessageType === 'custom'
          ? this.customText.classroom_spanish_description
          : null,
      enterprise_web_typeface: this.config.font,
      icon_pack: this.config.icon_pack,
    };
    this.req.patchDashboardWebCustomizations(body).subscribe({
      next: data => {
        // Amplitude event - Zogo Web Customization Changes Saved
        this.req.ampTrack('Zogo Web Customization Changes Saved');

        this.req.openSnackBar(
          'Changes are saved and will be displayed instantly.',
          'Okay'
        );
        this.userData.institution.web_customizations = data;
        localStorage.setItem('userData', JSON.stringify(this.userData));
        this.pageMode = 'view';
        this.modalController.dismiss();
      },
      error: err => {
        console.log(err);
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
      },
    });
  }

  substituteInstitutionName(text) {
    if (text) {
      return text.replaceAll(
        '${institution_name}',
        this.userData.institution.short_name
      );
    } else {
      return '';
    }
  }

  async openClassroomSyntaxHelpModal() {
    const componentProps = {
      header: 'Dynamically inject Classroom name',
      description: `Use the following syntax to automatically inject the class name into the page copy. This will work respectively across any class in your account with the same syntax snippet.<br><br>**$(class_name)**<br><br>Type this syntax out directly in the input field. Do not write in the class name yourself.
        `,
    };

    const modal = await this.modalController.create({
      component: AlertModalComponent,
      componentProps,
      cssClass: 'alert-modal',
    });

    // Present the modal
    await modal.present();
  }
}
