import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ZogoRadioGroupComponent } from '../zogo-radio-group.component';
import { ZogoIconComponent } from 'src/app/design-system/global-components';

@Component({
  selector: 'zogo-radio-button',
  templateUrl: './zogo-radio-button.component.html',
  styleUrls: ['./zogo-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZogoRadioButtonComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ZogoIconComponent],
})
export class ZogoRadioButtonComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() value: any;
  @Input() isDisabled: boolean = false;
  @Input() customClass: string = '';
  @Input() iconName: string = '';
  @Input() iconLocation: 'leading' | 'tracking' = 'leading';
  group!: ZogoRadioGroupComponent;
  isSelected: boolean = false;

  public onChange = (value: any) => {};
  public onTouched = () => {};

  writeValue(value: any): void {
    this.isSelected = value === this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleCheck(): void {
    if (!this.isDisabled) {
      if (!this.isSelected) {
        this.group.updateValue(this);
      }
      this.onTouched();
    }
  }
}
