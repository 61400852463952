import { Component, OnInit } from '@angular/core';
import { CopyService } from '../../copy.service';
import { RequestsService } from '../../requests.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-marketing-kit',
  templateUrl: './marketing-kit.component.html',
  styleUrls: ['./marketing-kit.component.css'],
})
export class MarketingKitComponent implements OnInit {
  copy: any;
  marketingKitData: any;

  constructor(
    public appCopy: CopyService,
    public req: RequestsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Amplitude event - Guides & Links Viewed
    this.req.ampTrack('Guides & Links Viewed');
    // Client Success event - Guides & Links Viewed
    this.req.csTrack('Guides & Links Viewed');

    this.copy = this.appCopy.getMarketingKitPage();

    this.req.getDashboardResources().subscribe({
      next: data => {
        this.marketingKitData = data;
      },
      error: err => {
        this.req.openSnackBar(
          'There was an error: ' + err.error.message,
          'Okay'
        );
        console.log('errors', err);
      },
    });
  }
}
