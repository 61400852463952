<button
  [disabled]="disabled"
  [class]="size + ' ' + buttonStyle"
  [ngClass]="{
    'full-width': fullWidth,
    'body-medium': size === 'large',
    'caption-medium': size === 'medium',
    'overline-medium': size === 'small',
    'icon-medium': !label && size === 'medium',
    'icon-large': !label && size === 'large',
    'dark-background': background === 'dark'
  }"
  (click)="onClick()"
  [attr.aria-label]="ariaLabel"
  [type]="type"
>
  <div class="button-label d-flex-row gap-8">
    <zogo-icon *ngIf="iconName" [iconName]="iconName" size="small"></zogo-icon>
    <div *ngIf="label">{{ label }}</div>
  </div>
</button>
