<div class="image-upload-modal-wrapper">
  <div class="modal-header">
    <zogo-icon></zogo-icon>
    <p class="body-large-medium text-color-neutral-800">Crop Image</p>
    <zogo-icon
      tappable
      class="text-color-neutral-800"
      iconName="x"
      (click)="dismiss()"
    ></zogo-icon>
  </div>
  <div class="modal-content d-flex-col gap-16">
    <div
      [class.background-neutral-800]="showDarkBackground"
      class="border-radius-8"
    >
      <image-cropper
        [imageFile]="imageFile"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="true"
        [aspectRatio]="16 / 9"
        [alignImage]="'center'"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed.emit($event)"
      ></image-cropper>
    </div>

    <div class="d-flex-row gap-8">
      <zogo-btn label="Save" (clickFunction)="saveCroppedImage()"></zogo-btn>
      <zogo-btn
        label="Cancel"
        buttonStyle="tertiary"
        (clickFunction)="dismiss()"
      ></zogo-btn>
    </div>
  </div>
</div>
